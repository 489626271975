import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const ConfirmationTextModal = styled.div`
  margin-top: 5px;
  max-width: 700px;
  text-align: center;
  color: ${theme.colors.grey1};

  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
`

export const MyReckoningConfirmationContainer = styled.div`
  margin-top: 50px;
  font-size: 20px;
  line-height: 28px;

  & p {
    max-width: 100%;
  }

  & button {
    margin: 50px auto 0 auto;
  }
`
export const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

export const TextContainer = styled.div`
  margin: 50px 0 45px 0;
`

export const TextDocumentContainer = styled.div`
  margin: 8px 0;
`

export const DataContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  margin: 24px auto;
`

export const DataText = styled.p`
  font-size: 14px;
  font-weight: 700;

  & span {
    font-weight: 400;
  }
`

