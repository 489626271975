import { makeAutoObservable } from "mobx";
import {
  TLzsSkyriziStage02
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/stage02/type";
import {
  TLzsSkyriziStage04
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/stage04/type";
import {
  TLzsSkyriziStage03
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/stage03/type";
import { TLzss } from "../../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/type";
import {
  TLzsSkyriziStage01
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/stage01/type";
import {
  TLzsSkyriziStage05
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/Stage05/type";

export class LzssStore {
  case: TLzss | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCase(value: TLzss) {
    this.case = value;
  }

  get caseStage() {
    return this.case?.step;
  }

  get firstStage() {
    return this.case as TLzsSkyriziStage01;
  }

  get secondStage() {
    return this.case as TLzsSkyriziStage02;
  }

  get thirdStage() {
    return this.case as TLzsSkyriziStage03;
  }

  get fourthStage() {
    return this.case as TLzsSkyriziStage04;
  }

  get fifthStage() {
    return this.case as TLzsSkyriziStage05;
  }

  resetCase() {
    this.case = null;
  }
}
