import { useRootStore } from "../../RootStateContext";

export const useZzskStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.zzskStore) {
    throw new Error("zzskStore store should be defined");
  }

  return rootStore.zzskStore;
};
