import styled from "styled-components";

import { Grid } from '@mui/material';
import theme from '../../../../../../../../../../theme/theme';

export const DrugContainer = styled(Grid)`
   border-top: 1px solid ${theme.colors.grey2};
   padding-top: 16px;

   & button{
     position: absolute;
     right: 0;
     top: -8px;

     & path {
        fill: ${theme.colors.abbvie};
     }
   }
`

export const Title = styled.h2`
   color: ${theme.colors.grey1};
   font-weight: 700;
   font-size: 20px;
   line-height: 24px;
`