import styled from "styled-components";

export const Container = styled.div<{ $bcgImage: string }>`
  min-height: 750px;
  height: calc(100vh - 40px);
  width: 100%;
  position: relative;
  background: ${props => `url(${props.$bcgImage})`} no-repeat center top;
  background-size: cover;
  display: flex;
`

export const Doctor = styled.img`
  width: auto;
  height: 80%;
  max-width: 599px;
  max-height: 719px;
`