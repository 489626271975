import styled from "styled-components";
import theme from "../../../../theme/theme";

export const Button = styled.button<{ isActive: boolean }>`
  width: 90px;
  cursor: pointer;
  background: ${props => props.isActive ? theme.colors.blue3 : theme.colors.orange};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  height: 36px;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid ${theme.colors.blue3};
  color: ${props => props.isActive ? theme.colors.orange : theme.colors.blue3};
`