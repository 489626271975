import { observer } from "mobx-react";

// hooks
import { useStage01ViewModel } from "./Stage01.vm";

// components
import { Grid, Typography } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { MultipleSelectString } from "../../../../../../../../shared/ui/multipleSelectString/MultipleSelectString";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";

// lib
import theme from "../../../../../../../../../theme/theme";
import { RadioForm } from "../../../styles";
import { checkEmptyNumber } from "../../../../../../../../../utils/checkEmptyNumber";

export const Stage01 = observer(() => {
  const {formik, comorbiditiesOptions} = useStage01ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      mx={"auto"}
      my={4}
      component="form"
    >
      {formik.errors.area && formik.touched.area && (
        <Typography>{JSON.stringify(formik)}</Typography>
      )}
      <Grid item xs={12} alignSelf={"center"}>
        <Input
          maxLength={64}
          isRequired={true}
          size={"small"}
          label={"Tytuł opisu przypadku"}
          isUpperCase={true}
          name={"title"}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          colorLabel={theme.colors.grey1}
          background={theme.colors.white}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={"Wpisz tytuł opisu przypadku..."}
          isError={formik.touched.title && Boolean(formik.errors.title)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        mt={2}
        mb={2}
        justifyContent={"space-between"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Label
          size={"small"}
          isRequired={true}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Czy pacjent był leczony nlpz?"}
        />
        <RadioForm>
          <RadioBox
            isError={formik.touched.nlpzCured && Boolean(formik.errors.nlpzCured)}
            id={"nlpzCured"}
            name={"nlpzCured"}
            value={"tak"}
            checked={formik.values.nlpzCured === "tak"}
            onChange={formik.handleChange}
          />
          <RadioBox
            isError={formik.touched.nlpzCured && Boolean(formik.errors.nlpzCured)}
            id={"nlpzCured2"}
            name={"nlpzCured"}
            value={"nie"}
            checked={formik.values.nlpzCured === "nie"}
            onChange={formik.handleChange}
          />
        </RadioForm>
        {formik.touched.nlpzCured && Boolean(formik.errors.nlpzCured) && formik.errors.nlpzCured &&
          <Typography variant={'caption'} fontWeight={'500'} fontSize={'10px'}
                      color={theme.colors.red}>{formik.errors.nlpzCured}</Typography>}
      </Grid>
      {formik.values.nlpzCured === "tak" && <>
        <Grid
          container
          justifyContent={"space-between"}
          alignContent={"stretch"}
          mt={2}
        >
          <Grid item xs={3}>
            <Input
              before={
                <Label
                  isUpperCase={false}
                  style={{margin: "8px 0 0"}}
                  color={theme.colors.orange}
                  size={"superSmall"}
                  label={"3 znaki"}
                />
              }
              maxWidth={"72px"}
              isRequired={true}
              label={"Inicjały pacjenta"}
              isUpperCase={true}
              name={"initials"}
              type={"text"}
              maxLength={3}
              colorLabel={theme.colors.grey1}
              value={formik.values.initials ? formik.values.initials.toUpperCase() : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={
                formik.touched.initials &&
                Boolean(formik.errors.initials)
              }
            />
          </Grid>
          <Grid
            item
            justifyContent={"space-between"}
            height={"100%"}
            pb={2}
            display={"flex"}
            flexDirection={"column"}
          >
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"płeć"}
              isRequired={true}
            />
            <RadioForm>
              <RadioBox
                isError={formik.touched.gender && Boolean(formik.errors.gender)}
                id={"gender1"}
                name={"gender"}
                value={"kobieta"}
                checked={formik.values.gender === "kobieta"}
                onChange={formik.handleChange}
              />
              <RadioBox
                isError={formik.touched.gender && Boolean(formik.errors.gender)}
                id={"gender2"}
                name={"gender"}
                value={"mężczyzna"}
                checked={formik.values.gender === "mężczyzna"}
                onChange={formik.handleChange}
              />
              <RadioBox
                isError={formik.touched.gender && Boolean(formik.errors.gender)}
                id={"gender3"}
                name={"gender"}
                value={"inne"}
                checked={formik.values.gender === "inne"}
                onChange={formik.handleChange}
              />
            </RadioForm>
          </Grid>
          <Grid
            item
            justifyContent={"space-between"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Label
              isRequired={true}
              style={{margin: "8px 0 4px 0"}}
              size={"small"}
              color={theme.colors.grey1}
              label={"Wiek"}
            />
            <Input
              isRequired={true}
              isUpperCase={true}
              name={"age"}
              type={"number"}
              onChangeCustom={() => formik.setFieldValue('age', null)}
              colorLabel={theme.colors.grey1}
              value={formik.values.age}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={formik.touched.age && Boolean(formik.errors.age)}
            />
          </Grid>
        </Grid>
        <Grid container gap={"20px"} alignContent={"stretch"} mt={2}>
          <Grid item xs={3}>
            <Input
              maxWidth={"72px"}
              isRequired={true}
              label={"Masa ciała w kg"}
              isUpperCase={true}
              name={"weightKg"}
              type={"number"}
              maxLength={3}
              onChangeCustom={() => formik.setFieldValue('weightKg', null)}
              colorLabel={theme.colors.grey1}
              value={checkEmptyNumber(formik.values.weightKg)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={formik.touched.weightKg && Boolean(formik.errors.weightKg)}
            />
          </Grid>
          <Grid item>
            <Input
              maxWidth={"72px"}
              isRequired={true}
              label={"Wzrost w cm"}
              isUpperCase={true}
              name={"heightCm"}
              type={"number"}
              maxLength={3}
              colorLabel={theme.colors.grey1}
              onChangeCustom={() => formik.setFieldValue('heightCm', null)}
              value={checkEmptyNumber(formik.values.heightCm)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={formik.touched.heightCm && Boolean(formik.errors.heightCm)}
            />
          </Grid>
          <Grid item md={3} xs={12} mt={{xs: 2, md: 0}} mb={{xs: 1, md: 0}}>
            <Label color={theme.colors.grey1} size={"small"} label={"BMI"}/>
            <div style={{lineHeight: "2.25rem", color: theme.colors.grey1}}>
              {formik.values.bmi || ""}
            </div>
          </Grid>
        </Grid>
        <Grid mt={2} item xs={12}>
          <Input
            label={"Czas trwania ZZSK"}
            before={
              <Label
                isUpperCase={false}
                style={{margin: "8px 0 0"}}
                color={theme.colors.orange}
                size={"superSmall"}
                label={"W latach"}
              />
            }
            isUpperCase={true}
            maxWidth={"72px"}
            name={"durationZzsk"}
            type={"number"}
            onChangeCustom={() => formik.setFieldValue('durationZzsk', null)}
            colorLabel={theme.colors.grey1}
            value={checkEmptyNumber(formik.values.durationZzsk)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.durationZzsk && Boolean(formik.errors.durationZzsk)
            }
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <MultipleSelectString
            size={"medium"}
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.comorbidities && Boolean(formik.errors.comorbidities)
            }
            label={
              "Choroby towarzyszące"
            }
            // handleChange={handleComorbidities}
            // name={"comorbidities"}
            setFieldValue={formik.setFieldValue}
            selectedValues={formik.values.comorbidities || []}
            elementName={"comorbidities"}
            options={comorbiditiesOptions}
          />
        </Grid>
        {formik.values.comorbidities?.includes("Inne") && (
          <Grid item xs={12} mt={2}>
            <Input
              label={"Inne"}
              isRequired={true}
              isUpperCase={true}
              name={"comorbiditiesOther"}
              type={"textarea"}
              maxLength={64}
              colorLabel={theme.colors.grey1}
              value={formik.values.comorbiditiesOther || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={
                formik.touched.comorbiditiesOther &&
                Boolean(formik.errors.comorbiditiesOther)
              }
            />
          </Grid>
        )}
        <Grid item xs={12} mt={3}>
          <Typography
            variant={"h6"}
            color={theme.colors.grey1}
            textTransform={"uppercase"}
            fontWeight={700}
            marginBottom={2}
          >
            Wywiad
          </Typography>
        </Grid>
        {(formik.values.gender === 'kobieta' || formik.values.gender === 'inne') && <><Grid
          item
          xs={12}
          mb={2}
          justifyContent={"space-between"}
          pb={2}
          display={"flex"}
          flexDirection={"column"}
        >
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Hormonalna terapia zastępcza (HTZ)"}
          />
          <RadioForm>
            <RadioBox
              isError={formik.touched.htz && Boolean(formik.errors.htz)}
              id={"htz"}
              name={"htz"}
              value={"tak"}
              checked={formik.values.htz === "tak"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.htz && Boolean(formik.errors.htz)}
              id={"htz2"}
              name={"htz"}
              value={"nie"}
              checked={formik.values.htz === "nie"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
          <Grid
            item
            xs={12}
            mb={2}
            justifyContent={"space-between"}
            pb={2}
            display={"flex"}
            flexDirection={"column"}
          >
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Antykoncepcja hormonalna"}
            />
            <RadioForm>
              <RadioBox
                isError={
                  formik.touched.contraception &&
                  Boolean(formik.errors.contraception)
                }
                id={"contraception"}
                name={"contraception"}
                value={"tak"}
                checked={formik.values.contraception === "tak"}
                onChange={formik.handleChange}
              />
              <RadioBox
                isError={
                  formik.touched.contraception &&
                  Boolean(formik.errors.contraception)
                }
                id={"contraception2"}
                name={"contraception"}
                value={"nie"}
                checked={formik.values.contraception === "nie"}
                onChange={formik.handleChange}
              />
            </RadioForm>
          </Grid>
        </>}
        <Grid container display={"flex"} gap={"20px"} mb={2}>
          <Grid
            item
            justifyContent={"space-between"}
            pb={2}
            display={"flex"}
            flexDirection={"column"}
          >
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Palenie tytoniu"}
              isRequired={true}
            />
            <RadioForm>
              <RadioBox
                isError={formik.touched.smoking && Boolean(formik.errors.smoking)}
                id={"smoking"}
                name={"smoking"}
                value={"tak"}
                checked={formik.values.smoking === "tak"}
                onChange={formik.handleChange}
              />
              <RadioBox
                isError={formik.touched.smoking && Boolean(formik.errors.smoking)}
                id={"smoking2"}
                name={"smoking"}
                value={"nie"}
                checked={formik.values.smoking === "nie"}
                onChange={formik.handleChange}
              />
            </RadioForm>
          </Grid>
          {formik.values.smoking === "tak" && <Grid>
            <Input
              isRequired={true}
              label={"liczba paczkolat"}
              isUpperCase={true}
              name={"packYears"}
              type={"number"}
              maxWidth={"72px"}
              onChangeCustom={() => formik.setFieldValue('packYears', null)}
              colorLabel={theme.colors.grey1}
              value={formik.values.packYears}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz"}
              isError={
                formik.touched.packYears && Boolean(formik.errors.packYears)
              }
            />
          </Grid>}
        </Grid>
        <Grid
          item
          xs={12}
          mb={2}
          justifyContent={"space-between"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"HLA B27"}
          />
          <RadioForm>
            <RadioBox
              isError={formik.touched.hlab27 && Boolean(formik.errors.hlab27)}
              id={"hlab27"}
              name={"hlab27"}
              value={"pozytywny"}
              checked={formik.values.hlab27 === "pozytywny"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.hlab27 && Boolean(formik.errors.hlab27)}
              id={"hlab272"}
              name={"hlab27"}
              value={"negatywny"}
              checked={formik.values.hlab27 === "negatywny"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid
          item
          xs={12}
          mt={2}
          mb={2}
          justifyContent={"space-between"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Czy RTG stawów krzyżowo-biodrowych potwierdza obecność sacroiliitis?"}
          />
          <RadioForm>
            <RadioBox
              isError={formik.touched.rtg && Boolean(formik.errors.rtg)}
              id={"rtg"}
              name={"rtg"}
              value={"tak"}
              checked={formik.values.rtg === "tak"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.rtg && Boolean(formik.errors.rtg)}
              id={"rtg2"}
              name={"rtg"}
              value={"nie"}
              checked={formik.values.rtg === "nie"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid></>}
    </Grid>
  );
});