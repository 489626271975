import styled, { css } from "styled-components";
import theme from "../../../../theme/theme";
import { Link } from "react-router-dom";

interface IIsActive {
  $isActive?: boolean;
}


export const ButtonsContainer = styled.div`
  display: flex;
  gap: 6px;
`

const buttonCommon = css`
  color: white;
  display: flex;
  width: 46px;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  height: 36px;
  padding: 12px 16px;
  border-radius: 10px;
`;
export const ButtonProfile = styled.div<{ $isActive: boolean }>`
  ${buttonCommon};
  background: ${props => props.$isActive ? theme.colors.green : theme.colors.abbvie};
  cursor: pointer;
  width: 230px;

  & img {
    margin-left: 15px;
  }
`

export const ButtonTop = styled.div<IIsActive>`
  ${buttonCommon};
  background: ${props => props.$isActive ? theme.colors.green : theme.colors.abbvie};
  cursor: pointer;
`

export const styledTopButton = css`
  text-decoration: none;
  color: ${theme.colors.white};
  width: 100%;
  text-align: center;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
`

export const StyledLink = styled(Link)`
  ${styledTopButton};
`

export const TextTop = styled.span`
  ${styledTopButton};
  cursor: default;
`