import styled from "styled-components";
import theme from '../../../../../../../theme/theme';

export const Step = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${theme.colors.green};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`

export const Paragraph = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  color: ${theme.colors.color4};
`

