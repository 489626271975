import React from 'react';
import {observer} from 'mobx-react-lite';

import {useOthers} from './useOthers';
import {AdminLayout} from '../../../shared/admin/layout/AdminLayout';
import {CustomTable} from '../../../shared/customTables/customTable/CustomTable';
export const Others = observer(() => {
  const {othersIsNotNull, tableDataRows, headCells, cellsSwitcher} = useOthers();

  return (
    <AdminLayout>
      {othersIsNotNull &&
          <CustomTable isSearchBar={true} cellsSwitcher={cellsSwitcher} tableDataRows={tableDataRows}
                       isDataDownloaded={true}
                       headCells={headCells}/>}
    </AdminLayout>
  )
});