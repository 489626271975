import { AxiosRequestConfig } from "axios";

// hooks
import { useCallback } from "react";
import { useApiCall } from "./useApiCall";
import {
  useCaseSettingsStore,
  useHcvStore,
  useHistoryStore,
  useLzsrStore,
  useLzssStore,
  useNpsStore,
  useRzsStore,
  useZzskStore,
} from "../store/hooks";

// interfaces
import { IFile } from "../interfaces/user/draftCase/file";

// types
import { TRzsStage01 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage01/type";
import { EnAreaNames, EnFormNames } from "../store/caseSettingsStore";
import { THcvStage01 } from "../components/pages/user/draftCase/forms/virusology/hcv/stages/stage01/type";
import { THcv } from "../components/pages/user/draftCase/forms/virusology/hcv/type";
import { TRzs } from "../components/pages/user/draftCase/forms/rheumatology/rzs/type";
import { TRzsStage02 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage02/type";
import { TRzsStage03 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage03/type";
import { TRzsStage04 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage04/type";
import { TRzsStage05 } from "../components/pages/user/draftCase/forms/rheumatology/rzs/stages/Stage05/type";
import { THcvStage02 } from "../components/pages/user/draftCase/forms/virusology/hcv/stages/stage02/type";
import { THcvStage03 } from "../components/pages/user/draftCase/forms/virusology/hcv/stages/stage03/type";
import { THcvStage04 } from "../components/pages/user/draftCase/forms/virusology/hcv/stages/stage04/type";
import {
  TLzsSkyriziStage01
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/stage01/type";
import {
  TLzsSkyriziStage02
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/stage02/type";
import {
  TLzsSkyriziStage03
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/stage03/type";
import {
  TLzsSkyriziStage04
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/stage04/type";
import {
  TLzsSkyriziStage05
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/stages/Stage05/type";
import { TLzss } from "../components/pages/user/draftCase/forms/rheumatology/lzs-skyrizi/type";
import {
  TLzsRinvoqStage05
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/Stage05/type";
import {
  TLzsRinvoqStage04
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/stage04/type";
import {
  TLzsRinvoqStage03
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/stage03/type";
import {
  TLzsRinvoqStage01
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/stage01/type";
import {
  TLzsRinvoqStage02
} from "../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/stage02/type";
import { TLzsr } from "../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/type";
import { TZzsk } from "../components/pages/user/draftCase/forms/rheumatology/zzsk/type";
import { TZzskStage01 } from "../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/stage01/type";
import { TZzskStage03 } from "../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/stage03/type";
import { TZzskStage02 } from "../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/stage02/type";
import { TZzskStage04 } from "../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/stage04/type";
import { TZzskStage05 } from "../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/Stage05/type";
import { TNps } from "../components/pages/user/draftCase/forms/rheumatology/nps/type";
import { TNpsStage01 } from "../components/pages/user/draftCase/forms/rheumatology/nps/stages/stage01/type";
import { TNpsStage02 } from "../components/pages/user/draftCase/forms/rheumatology/nps/stages/stage02/type";
import { TNpsStage03 } from "../components/pages/user/draftCase/forms/rheumatology/nps/stages/stage03/type";
import { TNpsStage04 } from "../components/pages/user/draftCase/forms/rheumatology/nps/stages/stage04/type";
import { TNpsStage05 } from "../components/pages/user/draftCase/forms/rheumatology/nps/stages/Stage05/type";

// TODO refactor this function?
export type TUniversalStage =
  | TRzsStage01
  | TRzsStage02
  | TRzsStage03
  | TRzsStage04
  | TRzsStage05
  | THcvStage01
  | THcvStage02
  | THcvStage03
  | THcvStage04
  | TLzsSkyriziStage01
  | TLzsSkyriziStage02
  | TLzsSkyriziStage03
  | TLzsSkyriziStage04
  | TLzsSkyriziStage05
  | TLzsRinvoqStage01
  | TLzsRinvoqStage02
  | TLzsRinvoqStage03
  | TLzsRinvoqStage04
  | TLzsRinvoqStage05
  | TZzskStage01
  | TZzskStage02
  | TZzskStage03
  | TZzskStage04
  | TZzskStage05
  | TNpsStage01
  | TNpsStage02
  | TNpsStage03
  | TNpsStage04
  | TNpsStage05;

export const useCaseApi = () => {
  const {api} = useApiCall();
  const historyStore = useHistoryStore();
  const caseSettingsStore = useCaseSettingsStore();
  const hcvStore = useHcvStore();
  const rzsStore = useRzsStore();
  const lzssStore = useLzssStore();
  const lzsrStore = useLzsrStore();
  const zzskStore = useZzskStore();
  const npsStore = useNpsStore();

  const getCase = useCallback(
    async ({
             area,
             form,
             caseId,
           }: {
      area: EnAreaNames;
      form: EnFormNames;
      caseId: string;
    }) => {
      const res: THcv | TRzs | TLzss | TLzsr | TZzsk | TNps =
        await api.apiInstance.get(`situation/${area}/${form}/${caseId}`);

      if (!res?.step) {
        throw new Error("Case step not found");
      }

      // check if current area, form, stage and stage count exist
      const isCurrentAreaExist = caseSettingsStore.currentArea;

      if (!isCurrentAreaExist) {
        caseSettingsStore.setCurrentArea(area);
      }

      const isCurrentFormExist = caseSettingsStore.currentForm;

      if (!isCurrentFormExist) {
        caseSettingsStore.setCurrentForm(form);
      }

      const isCurrentStageExist = caseSettingsStore.currentStage;

      if (!isCurrentStageExist) {
        caseSettingsStore.setCurrentStage(res.step);
      }

      const isStageCountExist = caseSettingsStore.stageCount;

      if (!isStageCountExist) {
        caseSettingsStore.setStageCount(area, form);
      }

      // set form data
      if (form === EnFormNames.hcv) {
        hcvStore.setCase(res as THcv);
      }

      if (form === EnFormNames.rzs) {
        rzsStore.setCase(res as TRzs);
      }
      if (form === EnFormNames.lzss) {
        lzssStore.setCase(res as TLzss);
      }

      if (form === EnFormNames.lzsr) {
        lzsrStore.setCase(res as TLzsr);
      }
      if (form === EnFormNames.zzsk) {
        zzskStore.setCase(res as TZzsk);
      }
      if (form === EnFormNames.nps) {
        npsStore.setCase(res as TNps);
      }
    },
    [
      api.apiInstance,
      caseSettingsStore,
      hcvStore,
      rzsStore,
      lzssStore,
      lzsrStore,
      zzskStore,
      npsStore,
    ]
  );

  // create new case
  const createNewCase = useCallback(
    async (
      value:
        | TRzsStage01
        | THcvStage01
        | TLzsSkyriziStage01
        | TLzsRinvoqStage01
        | TZzskStage01
        | TNpsStage01,
      area: EnAreaNames,
      form: EnFormNames
    ) => {
      try {
        const res = await api.apiInstance.post(
          `situation/${area}/${form}`,
          value
        );
        return res?.id;
      } finally {
        caseSettingsStore.unCLickNextButton();
      }
    },
    [api.apiInstance, caseSettingsStore]
  );

  const updateCase = useCallback(
    async ({
             value,
             area,
             form,
             caseId,
             stage,
           }: {
      value: TUniversalStage;
      area: EnAreaNames;
      form: EnFormNames;
      caseId: string;
      stage: number;
    }) => {
      if (!area || !form || !caseId || !stage) {
        throw new Error(
          "In editCase function some of the arguments are missing"
        );
      }

      try {
        const res = await api.apiInstance.put(
          `situation/${area}/${form}/${caseId}/${stage}`,
          value
        );

        if (res) {
          await getCase({area, form, caseId});
          caseSettingsStore.increaseCurrentStage();
        }
      } catch {
        throw new Error("Edit stage api, some rules are broken");
      } finally {
        caseSettingsStore.unCLickNextButton();
      }
    },

    [api.apiInstance, caseSettingsStore, getCase]
  );


  const postAbdominalFilesSecond = useCallback(
    async (
      id: number,
      file: File,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/abdominalFile/second`,
        form,
        true,
        {headers: {"Content-Type": "multipart/form-data"}}
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // delete abdominalFiles
  const deleteAbdominalFilesSecond = useCallback(
    async (
      id: number,
      idFile: number | string,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/abdominalFile/${idFile}/second`
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  const postRheumatologyFileFifthStage = useCallback(
    async (id: number, file: File, callback: (res: IFile[]) => void) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/rheumatology/${id}/file/fifth`,
        form,
        true,
        {headers: {"Content-Type": "multipart/form-data"}}
      );
      if (res) {
        callback(res);
      }
    },
    [api.apiInstance]
  );

  // delete abdominalFiles
  const deleteRheumatologyFileFifthStage = useCallback(
    async (
      id: number,
      idFile: number | string,
      callback: (res: IFile[]) => void
    ) => {
      const res = await api.apiInstance.delete(
        `situation/rheumatology/${id}/file/${idFile}/fifth`
      );
      if (res) {
        callback(res);
      }

    },
    [api.apiInstance]
  );

  const postFileFifthStage = useCallback(
    async (
      id: number,
      file: File,
      callback: (res: IFile[]) => void,
      formType: keyof typeof EnFormNames
    ) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${formType}/${id}/file/fifth`,
        form,
        true,
        {headers: {"Content-Type": "multipart/form-data"}}
      );
      if (res) {
        callback(res);
      }

    },
    [api.apiInstance]
  );

  // delete abdominalFiles
  const deleteFileFifthStage = useCallback(
    async (
      id: number,
      idFile: number | string,
      callback: (res: IFile[]) => void,
      formType: keyof typeof EnFormNames
    ) => {
      const res = await api.apiInstance.delete(
        `situation/${formType}/${id}/file/${idFile}/fifth`
      );
      if (res) {
        callback(res);
      }

    },
    [api.apiInstance]
  );

  // post drugInteractionFiles
  const postDrugInteractionFilesSecond = useCallback(
    async (
      id: number,
      file: File,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/potentialDrugInteractionFile/second`,
        form,
        true,
        {headers: {"Content-Type": "multipart/form-data"}}
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // dlete drugInteractionFiles
  const deleteDrugInteractionFilesSecond = useCallback(
    async (
      id: number,
      idFile: number | string,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/potentialDrugInteractionFile/${idFile}/second`
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // post liverFiles
  const postLiverFilesSecondStage = useCallback(
    async (
      id: number,
      file: File,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/liverFile/second`,
        form,
        true,
        {headers: {"Content-Type": "multipart/form-data"}}
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // delete liverFiles
  const deleteLiverFilesSecondStage = useCallback(
    async (
      id: number,
      idFile: number | string,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/liverFile/${idFile}/second`
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // post abdominalFiles after treatment
  const postAbdominalFileAfterTreatmentThirdStage = useCallback(
    async (
      id: number,
      file: File,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/abdominalFile/third`,
        form,
        true,
        {headers: {"Content-Type": "multipart/form-data"}}
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // delete abdominalFiles after treatment
  const deleteAbdominalFileAfterTreatmentThirdStage = useCallback(
    async (
      id: number,
      idFile: number | string,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/abdominalFile/${idFile}/third`
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // post abdominalFiles after treatment
  const postAbdominalFileAfterTreatment12ThirdStage = useCallback(
    async (
      id: number,
      file: File,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/abdominalFile12/third`,
        form,
        true,
        {headers: {"Content-Type": "multipart/form-data"}}
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // delete abdominalFiles after treatment
  const deleteAbdominalFileAfterTreatment12ThirdStage = useCallback(
    async (
      id: number,
      idFile: number | string,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/abdominalFile12/${idFile}/third`
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // post conclusionFiles
  const postConclusionFileFourthStage = useCallback(
    async (
      id: number,
      file: File,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const form = new FormData();
      form.append("file", file);
      const res: IFile[] = await api.apiInstance.post(
        `situation/${id}/conclusionFile/fourth`,
        form,
        true,
        {headers: {"Content-Type": "multipart/form-data"}}
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  // delete conclusionFiles
  const deleteConclusionFileFourthStage = useCallback(
    async (
      id: number,
      idFile: number | string,
      callback: (res: IFile[], key: string) => void,
      key: string
    ) => {
      const res = await api.apiInstance.delete(
        `situation/${id}/conclusionFile/${idFile}/fourth`
      );
      if (res) {
        callback(res, key);
      }
    },
    [api.apiInstance]
  );

  const publishDraftCase = useCallback(
    async ({
             area,
             form,
             caseId,
           }: {
      area: EnAreaNames;
      form: EnFormNames;
      caseId: string;
    }) => {
      const res = await api.apiInstance.post(
        `situation-flow/${area}/${form}/${caseId}/send-to-accept`,
        {}
      );
      return !!res;
    },
    [api.apiInstance]
  );

  const getCaseComments = useCallback(
    async (
      caseId: number,
      area: EnAreaNames,
      form: EnFormNames,
      isLoading: boolean = true
    ) => {
      const res = await api.apiInstance.get(
        `situation/${area}/${form}/${caseId}/comments`,
        isLoading
      );
      historyStore.setHistory(res);
      return !!res;
    },
    [historyStore, api.apiInstance]
  );

  const getCasePDF = useCallback(
    async (id: number, area: EnAreaNames, form: EnFormNames) => {
      // TODO: common method for every forms.

      const config: AxiosRequestConfig = {responseType: "blob"};
      return await api.apiInstance.get(
        `situation-flow/${area}/${form}/${id}/zip`,
        true,
        config
      );
    },
    [api.apiInstance]
  );

  const saveDraft = useCallback(
    async ({
             values,
             caseId,
             area,
             form,
           }: {
      values: TUniversalStage;
      caseId: number;
      area: EnAreaNames;
      form: EnFormNames;
    }) => {
      const res = await api.apiInstance.put(
        `situation-flow/${area}/${form}/${caseId}/draft`,
        values,
        false
      );
      if (res) {
        await getCase({area, form, caseId: String(caseId)});
      }
    },
    [api.apiInstance, getCase]
  );

  const sendQuestionEmail = useCallback(
    async (
      id: number,
      questionObj: any,
      area: EnAreaNames,
      form: EnFormNames
    ) => {
      // TODO: common method for every forms.
      const res = await api.apiInstance.post(
        `situation-flow/${area}/${form}/${id}/email`,
        questionObj,
        true
      );
      if (res) {
        return !!res;
      }
    },
    [api.apiInstance]
  );

  return {
    getCasePDF,
    postAbdominalFilesSecond,
    deleteAbdominalFilesSecond,
    postLiverFilesSecondStage,
    deleteLiverFilesSecondStage,
    postAbdominalFileAfterTreatmentThirdStage,
    deleteAbdominalFileAfterTreatmentThirdStage,
    postConclusionFileFourthStage,
    deleteConclusionFileFourthStage,
    publishDraftCase,
    getCaseComments,
    deleteAbdominalFileAfterTreatment12ThirdStage,
    postAbdominalFileAfterTreatment12ThirdStage,
    saveDraft,
    sendQuestionEmail,
    deleteDrugInteractionFilesSecond,
    postDrugInteractionFilesSecond,
    // new architecture
    createNewCase,
    getCase,
    updateCase,
    deleteRheumatologyFileFifthStage,
    postRheumatologyFileFifthStage,
    deleteFileFifthStage,
    postFileFifthStage,
  };
};
