import { FC } from "react";

// hooks
import { useCompletedCase } from "./CompletedCase.vm";

// components
import { Grid } from "@mui/material";
import { ReactComponent as Thick } from "../../../../../../../assets/icon/thick.svg";
import { ReactComponent as Pen } from "../../../../../../../assets/icon/pencilIcon.svg";
import { ReactComponent as Search } from "../../../../../../../assets/icon/search.svg";
import { ReactComponent as SendCase } from "../../../../../../../assets/icon/sendCase.svg";
import { Button } from "../../../../../../shared/button/Button";
import { Paragraph, Step } from "./CompletedCase.styled";

// types
import { IComplitedCase } from "../type";

// lib
import theme from "../../../../../../../theme/theme";

export const CompletedCase: FC<IComplitedCase> = ({
  viewLink,
  finishProcess,
  onClickSubmit,
}) => {
  const { navigateToView, submitHandler, navigateToHome } = useCompletedCase(
    viewLink,
    onClickSubmit,
    finishProcess
  );

  return (
    <Grid
      container
      maxWidth={"714px"}
      justifyContent={"center"}
      display={"flex"}
      margin={"auto auto"}
      mt={7}
    >
      <Grid
        item
        alignItems={"center"}
        xs={12}
        display={"flex"}
        flexDirection={"column"}
      >
        <Step>
          <Thick />
        </Step>
        <Paragraph>
          Opis przypadku medycznego został ukończony. <br />
          Kliknij WYŚLIJ DO ZATWIERDZENIA aby wysłać opis teraz lub ZAPISZ JAKO
          SZKIC i wyślij do akceptacji w innym terminie. Po wysłaniu do
          akceptacji w ciągu kilku dni opis przypadku zostanie zweryfikowany i
          po zaakceptowaniu będzie widoczny dla innych użytkowników.{" "}
        </Paragraph>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        gap={"16px"}
        mt={12}
      >
        <Button
          fontWeight={400}
          size={"tiny"}
          backgroundColor={theme.colors.abbvie}
          hoverColor={theme.colors.lightAbbvie}
          icon={<Pen />}
          width={"unset"}
          color={theme.colors.white}
          clickHandler={navigateToHome}
          text={"zapisz jako szkic i wyjdź"}
        />
        <Button
          fontWeight={400}
          size={"tiny"}
          width={"unset"}
          backgroundColor={theme.colors.abbvie}
          hoverColor={theme.colors.lightAbbvie}
          icon={<Search />}
          color={theme.colors.white}
          clickHandler={navigateToView}
          text={"podgląd"}
        />
        <Button
          fontWeight={400}
          size={"tiny"}
          width={"unset"}
          backgroundColor={theme.colors.abbvie}
          hoverColor={theme.colors.lightAbbvie}
          icon={<SendCase />}
          color={theme.colors.white}
          clickHandler={submitHandler}
          text={"wyślij do zatwierdzenia"}
        />
      </Grid>
    </Grid>
  );
};
