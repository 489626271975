import { makeAutoObservable } from "mobx";

import { TZzsk } from "../../components/pages/user/draftCase/forms/rheumatology/zzsk/type";
import { TZzskStage01 } from "../../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/stage01/type";
import { TZzskStage02 } from "../../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/stage02/type";
import { TZzskStage03 } from "../../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/stage03/type";
import { TZzskStage04 } from "../../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/stage04/type";
import { TZzskStage05 } from "../../components/pages/user/draftCase/forms/rheumatology/zzsk/stages/Stage05/type";

export class ZzskStore {
  case: TZzsk | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCase(value: TZzsk) {
    this.case = value;
  }

  get caseStage() {
    return this.case?.step;
  }

  get firstStage() {
    return this.case as TZzskStage01;
  }

  get secondStage() {
    return this.case as TZzskStage02;
  }

  get thirdStage() {
    return this.case as TZzskStage03;
  }

  get fourthStage() {
    return this.case as TZzskStage04;
  }

  get fifthStage() {
    return this.case as TZzskStage05
  }

  resetCase() {
    this.case = null;
  }
}
