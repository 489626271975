export const vasOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const variantOptions = ['Inhibitory TNF', 'Inhibitory IL-17', 'Inhibitory JAK']

export const tnfOptions = ['Certolizumab pegol', 'Adalimumab - Humira', 'Adalimumab inne', 'Etanercept', 'Golimumab', 'Infliksimab']

export const il17Options = ['Iksekizumab', 'Sekukinumab', 'Bimekizumab', 'Inne']

export const jakOptions = ['Upadacytynib (Rinvoq 15mg/dobę)', 'Tofacytynib', 'Inne']

export const combinationsOptions = ['Metotreksat', 'Sulfasalazyna', 'Leflunomid', 'Inne']