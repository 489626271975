import styled from "styled-components";
import theme from "../../../../theme/theme";

interface IColor {
  color: string;

}

interface IIcon extends IColor {
  $position?: string;
}

export const DataMiddle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 1.2px;
`

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: ${theme.colors.blue3};
  margin: 16px 0;
`

export const DataLarge = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 16.8px */
  letter-spacing: 1.4px;
  margin-top: 16px;
  margin-bottom: 8px;
  max-height: 108px;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
`

export const StyledStatus = styled.p<IColor>`
  font-weight: 700;
  font-size: 11px;
  line-height: 120%;
  letter-spacing: 1.65px;
  text-transform: uppercase;
  color: ${theme.colors.abbvie}
`

interface ICaseCardContainer {
  maxWidth?: string;
}

export const CaseCardContainer = styled.div<ICaseCardContainer>`
  position: relative;
  padding: 16px 12px 12px 12px;
  width: 100%;
  background: linear-gradient(180deg, #446CA9 0%, #86B4FF 100%);
  color: ${theme.colors.white};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  height: 288px;
  border-radius: 10px;
  max-width: ${props => props.maxWidth ? props.maxWidth : 'unset'};
  cursor: pointer;
`

export const Icon = styled.div<IIcon>`
  border-radius: 50%;
  position: ${props => props.$position ?? 'absolute'};
  background-color: ${props => props.color};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -15px;
  right: 10px;

  & img {
    width: auto;
    height: 12px;
  }
`

export const Date = styled.div`
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 13.2px */
  letter-spacing: 1.1px;
  margin: 4px 0

`

export const StyledNext = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.abbvie};
  border-radius: 50%;

  & img {
    width: auto;
    height: 8px;
  }
`

export const Action= styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  bottom: 14px;
  right: 10px;
  align-items: center;
  color: ${theme.colors.white};
  gap: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 14.4px */
  letter-spacing: 2.4px;
  text-transform: uppercase;
  width: 100%;

`