import styled from 'styled-components';
import theme from "../../../../theme/theme";

export const TextModal = styled.div`
  & p:first-child {
    font-size: 20px;
    line-height: 27px;
  }

  & p:nth-child(2) {
    margin-top: 15px;
    font-size: 14px;
    line-height: 19px;
  }
`

export const Title = styled.h1`
    height: 36px;
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${theme.colors.abbvie};
    position: absolute;
    top: 40px;
    left: 15px;
`
