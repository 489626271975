import {FC} from "react";
import { observer } from "mobx-react-lite";

import { AdminLayout } from '../../../shared/admin/layout/AdminLayout';
import { Modal } from '../../../shared/modal/Modal';
import { ConfirmationModal } from '../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import { AdminLink } from '../../../../utils';
import { AreaForm } from '../../../shared/admin/areaForm/AreaForm';
import { useAddArea } from './useAddArea';
import { TextModal, Title} from './AddArea.styled';
interface IAddAreaProps {
  isEdit?: boolean
}

const AddArea : FC<IAddAreaProps> = observer(({isEdit = false}) => {
  const {
    actionHandler,
    isModalOpened,
    closeModal,
    handleNo,
    areaEdit}
   = useAddArea(isEdit)

  return <AdminLayout centralize={true}>
    <Title>{isEdit ? 'edytowanie' : 'dodawanie'} obszaru terapeutycznego</Title>
    {(!isEdit || (isEdit && areaEdit)) && <AreaForm areaForm={areaEdit}  handleNo={handleNo} link={AdminLink.ADD_AREA} actionHandler={actionHandler}/>}
    {isModalOpened && <Modal clickHandler={() => closeModal()}>
      <ConfirmationModal
        text={
          <TextModal><p>Obszar został <b>{isEdit ? 'wyedytowany' : 'dodany'} poprawnie.</b></p>
          </TextModal>}
      /></Modal>}
  </AdminLayout>
})

export default AddArea;

