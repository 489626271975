import { ChangeEvent } from "react";

export type FieldToClear<T> = {
  name: keyof T;
  value?: null | "" | [];
};

type CondictionValue = string | number;

type ExtraValue<T> = { name: keyof T; value: string };

export const clearFields = <T>(
  formik: any,
  event: ChangeEvent<HTMLInputElement>,
  conditionValue: CondictionValue,
  field: FieldToClear<T>[] | FieldToClear<T>,
  extraValues?: ExtraValue<T>[] | null,
  handleChange?: ((e: ChangeEvent<HTMLInputElement>) => void) | null
) => {
  handleChange ? handleChange(event) : formik.handleChange(event);

  const main = event.target.value === conditionValue;

  if (extraValues) {
    const isMatch = [...extraValues].every(
      (item) => formik.values[item.name] === item.value
    );

    if (isMatch && main) {
      clearFieldsFormik<T>(formik, field);
    }
  } else if (main) {
    clearFieldsFormik<T>(formik, field);
  }
};

const clearFieldsFormik = <T>(
  formik: any,
  field: FieldToClear<T>[] | FieldToClear<T>
) => {
  Array.isArray(field)
    ? field.forEach((f) => formik.setFieldValue(f.name, f.value))
    : formik.setFieldValue(field.name, field.value);
};
