import {useRootStore} from '../RootStateContext';

export const useHistoryStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.historyStore) {
    throw new Error('historyStore store should be defined');
  }

  return rootStore.historyStore;
};
