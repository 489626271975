import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

// hooks
import { useCaseSettingsStore, useHcvStore, } from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";

// types
import { THcvStage04 } from "./type";
// lib
import { EnAreaNames, EnFormNames, } from "../../../../../../../../../store/caseSettingsStore";
import { IFile } from "../../../../../../../../../interfaces/user/draftCase/file";


export const validationSchema = yup.object().shape({
  infoAboutTherapy: yup.string().required(),
  conclusion: yup.string().required(),
  conclusionFiles: yup.array(),
  conclusionCheckbox: yup.boolean().test(
    "isConclusionCheckbox",
    function (value) {
      const files = this.parent.conclusionFiles;
      if (files.length > 0) {
        return !!value;
      }
      return true;
    }
  ),
});

const initialValues: THcvStage04 = {
  infoAboutTherapy: "",
  conclusion: "",
  conclusionCheckbox: false,
};

export const useFourthStageVm = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // define API calls
  const {
    deleteConclusionFileFourthStage,
    postConclusionFileFourthStage,
    updateCase,
  } = useCaseApi();

  // rzs store
  const hcvStore = useHcvStore();
  const {fourthStage, caseStage} = hcvStore;

  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fourthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<THcvStage04>({
    enableReinitialize: true,
    initialValues: {
      ...valuesObj,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );

      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  // handlers conclusionFile

  const setFormikValue = (res: IFile[], key: string) => {
    formik.setFieldValue(key, res)
  }
  const addConclusionFile = async (file: File | null) => {
    if (file && caseId) {
      await postConclusionFileFourthStage(+caseId, file, setFormikValue, 'conclusionFiles');
    }
  };

  const removeConclusionFile = async (idFile: number | string) => {
    if (idFile && caseId) {
      await deleteConclusionFileFourthStage(+caseId, idFile, setFormikValue, 'conclusionFiles');
    }
  };

  return {
    formik,
    removeConclusionFile,
    addConclusionFile,
  };
};
