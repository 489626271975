export const placesOfDetectionSelect = [
  {
    id: 1,
    title: "POZ",
  },
  {
    id: 2,
    title: "Szpital",
  },
  {
    id: 3,
    title: "Poradnia",
  },
  {
    id: 4,
    title: "Akcja Screeningowa",
  },
  {
    id: 5,
    title: "Laboratorium",
  },
  {
    id: 6,
    title: "Punkt anonimowego testowania",
  },
  {
    id: 7,
    title: "Inne",
  },
];

export const previouslyTreatedCountSelect = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const metavirSelect = [
  "F0",
  "F0-F1",
  "F1",
  "F2",
  "F3",
  "F3-F4",
  "F4",
  "brak informacji",
];

export const rnaUnitSelect = ["IU/ml", "kopii/ml"];

export const genotypeSelect = [
  "GT1 (bez subgenotypu)",
  "GT1a",
  "GT1b",
  "GT2",
  "GT3",
  "GT4",
  "GT5",
  "GT6",
  "GT mieszany",
  "GT nieznany",
];
