import { UserLayout } from "../../../shared/user/layout/UserLayout";
import { Header, NoCases, Section } from "./Home.styled";
import { Grid } from "@mui/material";
import { Button } from "../../../shared/button/Button";
import theme from "../../../../theme/theme";
import { CaseCard } from "../../../shared/user/cards/CaseCard";
import { useHome } from "./useHome.vm";
import { observer } from "mobx-react-lite";

export const Home = observer(() => {
  const {
    listOfLastCases,
    addCase,
    affiliation,
    fullName,
    navigateToOthersCases,
    isParticipant,
    listOfLast3Cases,
  } = useHome();

  const listOfCases = isParticipant ? listOfLast3Cases : listOfLastCases;

  return (
    <UserLayout isDoctor={true}>
      <Grid
        container
        maxWidth={"50%"}
        margin={"auto 0"}
        gap={isParticipant ? "90px" : 0}
      >
        <Grid item xs={12}>
          <Header>
            Miło Cię widzieć, <br />
            <span>
              {affiliation} {fullName}
            </span>
          </Header>
        </Grid>
        {!isParticipant && (
          <Grid item xs={12}>
            <Button
              margin={"20px auto 35px"}
              clickHandler={addCase}
              text={"+ dodaj nowy opis przypadku"}
              backgroundColor={theme.colors.orange}
              color={theme.colors.b3}
              width={"263px"}
              size={"tiny"}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          justifyContent={"center"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Section>
            {listOfCases && listOfCases.length !== 0 ? (
              <>
                {listOfCases.map(
                  ({
                    status,
                    id,
                    customId,
                    coInfection,
                    title,
                    updatedAt,
                    area,
                    form,
                  }) => (
                    <Grid item xs={6} lg={4} p={1} key={id}>
                      <CaseCard
                        area={area}
                        form={form}
                        key={id}
                        id={id}
                        status={status}
                        customId={customId}
                        coInfection={coInfection}
                        updatedAt={updatedAt}
                        title={title}
                      />
                    </Grid>
                  )
                )}
                <Grid item xs={12}>
                  <Button
                    backgroundColor={theme.colors.orange}
                    color={theme.colors.b3}
                    text={"zobacz więcej >>"}
                    size={"tiny"}
                    margin={"14px 0 0 auto"}
                    maxWidth={"163px"}
                    clickHandler={navigateToOthersCases}
                  />
                </Grid>
              </>
            ) : (
              <NoCases>
                Brak opisów przypadków.
              </NoCases>
            )}
          </Section>
        </Grid>
      </Grid>
    </UserLayout>
  );
});
