export const validatePWZ = (pwz: string) => {
  if (pwz.length !== 7) {
    return false;
  }

  // Sprawdzenie pierwszej cyfry
  const firstDigit = parseInt(pwz.charAt(0));
  if (firstDigit === 0) {
    return false;
  }

  // Sprawdzenie cyfry kontrolnej
  const weights = [10, 1, 2, 3, 4, 5, 6];
  let controlSum = 0;
  for (let i = 0; i < 7; i++) {
    const digit = parseInt(pwz.charAt(i));
    if (isNaN(digit)) {
      return false;
    }
    controlSum += digit * weights[i];
  }

  const controlDigit = controlSum % 11;

  if (controlDigit !== 0) {
    return false;
  }

  return true;
};
