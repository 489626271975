// hooks
import { useFormik } from "formik";
import { useCaseSettingsStore, useRzsStore, } from "../../../../../../../../../store/hooks";

// types
import { TRzsStage05 } from "./type";
import { useParams } from "react-router-dom";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import { EnAreaNames, EnFormNames, } from "../../../../../../../../../store/caseSettingsStore";
import { IFile } from "../../../../../../../../../interfaces/user/draftCase/file";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";
import * as yup from "yup";

export const validationSchema = yup.object().shape({
  conclusions: yup.string(),
  rzsFiles: yup.array(),
  statement: yup.boolean().test(
    "isCheckbox",
    function (value) {
      const files = this.parent.rzsFiles;
      if (files.length > 0) {
        return !!value;
      }
      return true;
    }
  ),
});

const initialValues: TRzsStage05 = {
  conclusions: "",
  statement: false,
  rzsFiles: [],
};

export const useStage05ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const rzsStore = useRzsStore();
  const {fifthStage, caseStage} = rzsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fifthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TRzsStage05>({
    enableReinitialize: true,
    initialValues: {
      ...valuesObj,
      statement: valuesObj.statement ?? false
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const setFormikValue = (res: IFile[]) => {
    formik.setFieldValue("rzsFiles", [...res]);
  };

  const addPicturesFile = async (file: File | null) => {
    if (file && caseId) {
      await caseApi.postRheumatologyFileFifthStage(+caseId, file, setFormikValue);
    }
  };

  const removePicturesFile = async (idFile: number | string) => {
    if (idFile && caseId) {
      await caseApi.deleteRheumatologyFileFifthStage(+caseId, idFile, setFormikValue);
    }
  };

  return {
    formik,
    removePicturesFile,
    addPicturesFile,
  };
};
