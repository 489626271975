import React, { ChangeEvent } from 'react';
import { FormControl, ListItemText, MenuItem, Select } from '@mui/material';
import { Label, RedSpan, SingleSelectStyled } from './SingleSelect.styled';
import { IOption } from "../../../../interfaces/common/form/option";


interface IProps {
  selectedValue?: number | string;
  elementName: string;
  defaultValue?: string;
  label?: string;
  options: (string | number)[] | IOption[];
  handleChange: (value: string | number, e: ChangeEvent<HTMLInputElement>) => void;
  subtitleLength?: number;
  isRequired?: boolean;
  isError?: boolean;
  color?: string;
  size?: string;
  after?: JSX.Element;
  before?: JSX.Element;
  disabled?: boolean;
}

export const SelectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
      <path
        d="M0.477798 1.95236L1.60758 0.688477L5.31971 4.84125L9.03184 0.688477L10.1616 1.95236L5.31971 7.36903L0.477798 1.95236Z"
        fill="#646464"
      />
    </svg>
  );
};

export const SingleSelect: React.FC<IProps> = ({
                                                 defaultValue = 'Wybierz',
                                                 selectedValue,
                                                 elementName,
                                                 options,
                                                 handleChange,
                                                 label,
                                                 isRequired,
                                                 isError,
                                                 color,
                                                 size,
                                                 before,
                                                 after,
                                                 disabled
                                               }) => {
  return (
    <SingleSelectStyled disabled={disabled}>
      <FormControl
        fullWidth={true}
        sx={{
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {border: 'none'},
          '.MuiOutlinedInput-notchedOutline': {border: 'none'},
          '&:hover .MuiOutlinedInput-notchedOutline': {border: 'none'},
        }}
      >
        {label && (
          <Label color={color} size={'small'}>
            {label}
            {isRequired && <RedSpan>*</RedSpan>}
          </Label>
        )}
        {before}
        <Select
          disabled={disabled}
          IconComponent={() => <SelectIcon/>}
          defaultValue={'Wybierz'}
          error={isError}
          labelId={elementName}
          id={elementName}
          value={selectedValue}
          displayEmpty={true}
          renderValue={(value: string | number) => {
            if (value === 0 || typeof value === 'number' || options.some(option =>
              typeof option === 'object' ? option.title === value : option === value)) {
              return value;
            }

            return <span style={{color: disabled ? '#BDBDBD' : '#AFAFAF'}}>{defaultValue}</span>;
          }}
          onChange={(e) => {
            handleChange(e.target.value as string | number, e as ChangeEvent<HTMLInputElement>);
          }}
          sx={{
            color: disabled ? '#BDBDBD' : '#646464',
            borderRadius: '10px',
            fontSize: '16px',
            lineHeight: '36px',
            height: '36px',
            outline: 'none',
            backgroundColor: disabled ? '#F2F2F2' : 'white',
            border: isError ? '2px solid #A41313' : '2px solid #E0E0E0',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {border: 'none'},
          }}
        >
          {Array.isArray(options) && options.length > 0
            ? options.map((option) =>
              typeof option === 'object' ? (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  sx={{
                    padding: '12px 16px',
                    height: 'auto',
                    margin: 0,
                    whiteSpace: 'normal',
                    maxWidth: '100%',
                    borderBottom: '1px solid #D3D3D3',
                  }}
                >
                  <ListItemText primary={option.title}/>
                </MenuItem>
              ) : (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{
                    padding: '12px 16px',
                    height: 'auto',
                    margin: 0,
                    whiteSpace: 'normal',
                    maxWidth: '100%',
                    borderBottom: '1px solid #D3D3D3',
                  }}
                >
                  <ListItemText primary={option}/>
                </MenuItem>
              )
            )
            : null}
        </Select>
        {after}
      </FormControl>
    </SingleSelectStyled>
  );
};