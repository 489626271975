import * as yup from "yup";

// hooks
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useCaseSettingsStore, useNpsStore } from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";

// types
import { TNpsStage02 } from "./type";
import { EnAreaNames, EnFormNames, } from "../../../../../../../../../store/caseSettingsStore";

const validationSchema = yup.object().shape({
  diagnosisDate: yup.string().required(),
  symptoms: yup.string().nullable(),
  treatmentOption: yup.string().required(),
  metotreksatDose: yup.string().nullable().when('treatmentOption', {
    is: (value: string) => value === "Metotreksat",
    then: (schema) => schema.required()
  }),
  sulfasalazynaDose: yup.string().nullable().when('treatmentOption', {
    is: (value: string) => value === "Sulfasalazyna",
    then: (schema) => schema.required()
  }),
  gks: yup.string().required(),
  gksType: yup.string().nullable().when('gks', {
    is: (value: string) => value === "tak",
    then: (schema) => schema.required()
  }),
  singleGks: yup.string().nullable().when('gksType', {
    is: (value: string) => value === "iniekcja",
    then: (schema) => schema.required()
  }),
  likertPatient: yup.number().required(),
  likertDoctor: yup.number().required(),
  assessment: yup.number().required(),
  tenderJointsCount: yup.number().min(0).integer(),
  swollenJointsCount: yup.number().min(0).integer(),
  resultScaleOption: yup.string().required(),
  result: yup.number().required(),
  degreeOfBack: yup.number().required(),
  generalAssessment2: yup.number().required(),
  relevantComment: yup.string().nullable()
});

const initialValues: TNpsStage02 = {
  diagnosisDate: null,
  symptoms: "",
  treatmentOption: '',
  metotreksatDose: '',
  sulfasalazynaDose: '',
  gks: '',
  gksType: '',
  singleGks: '',
  likertPatient: null,
  likertDoctor: null,
  assessment: null,
  tenderJointsCount: null,
  swollenJointsCount: null,
  resultScaleOption: '',
  result: null,
  degreeOfBack: null,
  generalAssessment2: null,
  relevantComment: ''
};

export const useStage02ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const npsStore = useNpsStore();
  const {secondStage, caseStage} = npsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(secondStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TNpsStage02>({
    initialValues: {
      ...valuesObj
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`drugs[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };


  return {
    formik,
    setOption,
  };
};
