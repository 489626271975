import React from 'react'
import theme from "../../../../../theme/theme";
import {RegisterFormViewingComponent} from "./form/RegisterFormViewingComponent";
import background from "../../../../../assets/img/background_purple.jpg";
import {AuthLayout} from "../layout/RegisterLayout";

const RegisterViewingUser = () => {

  return (
    <AuthLayout bcgImage={background}>
      <RegisterFormViewingComponent labelColor={theme.colors.white}
                                    buttonBcgColor={theme.colors.abbvie}
                                    buttonTextColor={theme.colors.white}/>

    </AuthLayout>
  )
}

export default RegisterViewingUser