import styled from "styled-components";
import theme from "../../../../theme/theme";

export const ImageMain = styled.img`
  max-height: 85vh;
  height: auto;

  @media (max-width: ${theme.breakpoints.small}px) {
    margin: auto 16px;
    width: 100%;
  }

`