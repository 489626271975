import {UserLink} from '../../../../../utils';
import {RoleFunction} from '../../../../../constants/projectUserRoles';

export const navigation = [
  {
    title: 'moje opisy przypadków',
    path: UserLink.MY_CASES,
    roles: [RoleFunction.ROLE_PUBLISHING]
  },
  {
    title: 'wszystkie opisy przypadków',
    path: UserLink.OTHER_CASES,
    roles: [RoleFunction.ROLE_PUBLISHING, RoleFunction.ROLE_PARTICIPANT]
  }
]