import React, { createContext, useContext } from 'react'
import { RootStore } from './rootStore';

let rootStore: RootStore

const RootStateContext = createContext<RootStore | undefined>(undefined)

interface IProps {
  children: React.ReactNode
}

const initializeStore = (): RootStore => {
  const store = rootStore ?? new RootStore()

  if (typeof window === 'undefined') {
    return store;
  }
  if (!rootStore) {
    rootStore = store;
  }

  return store;

}

export const RootStateProvider: React.FC<IProps> = ({children}) => (
  <RootStateContext.Provider value={initializeStore()}>{children}</RootStateContext.Provider>
);

export const useRootStore = () => useContext(RootStateContext);