interface ICoinfectionType {
  id: number,
  optionId: number;
  value: string
}

export enum COINFECTIONTYPE {
  NOCOINFECTION,
  HIVHCV,
  HBVHCV,
  HIVHBHCV
}

export const coinfectonTypes: ICoinfectionType[] = [
  {
    id: 1,
    value: 'Brak koinfekcji',
    optionId: 0
  },
  {
    id: 2,
    value: 'HIV + HCV',
    optionId: 1
  },
  {
    id: 3,
    value: 'HBV + HCV',
    optionId: 2
  }, {
    id: 4,
    value: 'HIV + HBV + HCV',
    optionId: 3

  }
]