import { makeAutoObservable } from "mobx";

import { TLzsr } from "../../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/type";
import {
  TLzsRinvoqStage01
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/stage01/type";
import {
  TLzsRinvoqStage02
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/stage02/type";
import {
  TLzsRinvoqStage03
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/stage03/type";
import {
  TLzsRinvoqStage04
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/stage04/type";
import {
  TLzsRinvoqStage05
} from "../../components/pages/user/draftCase/forms/rheumatology/lzs-rinvoq/stages/Stage05/type";

export class LzsrStore {
  case: TLzsr | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCase(value: TLzsr) {
    this.case = value;
  }

  get caseStage() {
    return this.case?.step;
  }

  get firstStage() {
    return this.case as TLzsRinvoqStage01;
  }

  get secondStage() {
    return this.case as TLzsRinvoqStage02;
  }

  get thirdStage() {
    return this.case as TLzsRinvoqStage03;
  }

  get fourthStage() {
    return this.case as TLzsRinvoqStage04;
  }

  get fifthStage() {
    return this.case as TLzsRinvoqStage05;
  }

  resetCase() {
    this.case = null;
  }
}
