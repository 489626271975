import styled from 'styled-components';


export const TopContainer = styled.div`
  display: flex;
  gap: 16px;
`
export const RoleSelectContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  width: 300px;
  
  & div {
    width: 100% !important;
  }
  span {
    margin-bottom: 15px;
  }
`