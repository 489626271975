import styled from "styled-components";
import theme from '../../../../../../../../../theme/theme';

export const Subtitle = styled.h1`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  color: ${theme.colors.grey1};
  margin-top: 24px;
  margin-bottom: 16px;
`

export const Paragraph = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
  color: ${theme.colors.orange};
  margin: 8px auto 8px 8px;
`

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
`

export const ButtonContainer = styled.div`
  border-top: 1px solid ${theme.colors.grey2};
  padding-top: 16px;
  display: flex;
  justify-content: center;
`