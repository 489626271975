import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const RegisterViewingFormContainer = styled.form`
  z-index: 50;
  margin: auto 0;
  padding: 0 32px;
  max-width: 345px;
  width: 100%;

  & button {
    margin: 62px 0 0 auto;
    @media (max-width: ${theme.breakpoints.small}px) {
      max-width: 100% !important;
      margin-top: 34px;
    }
  }

  & path {
    fill: white;
  }
;
}
`