import styled from "styled-components";
import { Grid } from "@mui/material";
import theme from "../../../../theme/theme";

export const Section = styled.div`
  display: flex;
  margin: auto 0;
  height: 656px;
`

export const NoCases = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin: auto;
`

export const ButtonContainer = styled(Grid)`
  display: flex;
  gap: 25px;
  margin-top: 42px;
  align-items: center;
  border-bottom: 2px solid #A6B2C4;
  margin-bottom: 52px;

  & svg {
    margin-top: 2px;
    width: 20px;
  }

  . Mui-checked {
    color: ${theme.colors.abbvie} !important
  }

  & label {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

`;

export const ButtonContainerCoinfection = styled(Grid)`
  display: flex;
  gap: 24px;
  border-bottom: 2px solid #A6B2C4;
  margin-bottom: 30px;

  & span {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }

  & svg {
    width: 20px;
  }

  .Mui-checked {
    color: ${theme.colors.abbvie} !important
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`