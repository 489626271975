import {styled as muiStyled, Table, TableCell, TableContainer, Typography} from "@mui/material";
import theme from "../../../../theme/theme";
import styled from 'styled-components'

export const TableCustom = muiStyled(Table)`
  height: auto;
  min-width: 700px;
  overflow-y: auto;

  th {
    border-bottom: none;
  }
`

export const TableContainerCustom = muiStyled(TableContainer)`
  width: 100%;
  overflow: auto;
  height: 100%;
`

export const TableCellHeader = muiStyled(TableCell)`
  background: ${theme.colors.blue2};
  text-transform: uppercase;
  color: white;
  border-bottom: none;
  height: 50px;
  padding: 6px;
`

export const TableCellActions = muiStyled(TableCell)`
  padding: 0px;
  height: 50px;
  width: min-content;
  display: flex;
  border: none;
  
  rect {
  width: auto;
  height: auto
  }
`

export interface ITableCellActionsHead {
  width: number;
}

export const TableCellActionsHead = muiStyled(TableCell)<ITableCellActionsHead>`
  width: ${props => props.width + 2}px;
`

export const ParagraphNoData = muiStyled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
`

export const ExcelContainer = styled.div`
  width: 45px;
  height: 45px;
  background-color: rgba(65, 187, 164, 1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
`

export const ExcelCell = styled.th`
  width: 52px;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
`