import { Type } from '../../../../interfaces/ui/uiInfo'
import styled from "styled-components";
import theme from "../../../../theme/theme";

interface IInputContainerProps {
  $isRequired?: boolean;
  type: Type;
  $width?: string;
  isUpperCase?: boolean;
}

interface IInputElement {
  $isError: boolean;
  $maxWidth?: string;
  height?: string;
  $border?: string
  $background?: string;
  $placeholderColor?: string;
  $margin?: string;
}

export const InputContainer = styled.div<IInputContainerProps>`
  width: ${props => props.$width ?? '100%'};

  p {
    padding-left: 10px;
    color: ${theme.colors.red};
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    margin-top: -14px;
    margin-bottom: 2px;
  }

  input, textarea {
    width: ${props => props.$width ?? '100%'};
  }
`
export const InputElement = styled.input<IInputElement>`
  color: ${props => props.disabled ? '#BDBDBD' : props.color};
  height: ${props => props.height ?? '2.25rem'};
  border: 2px solid ${props => props.$border};
  background: ${props => props.$background ?? 'rgba(255, 255, 255)'};
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 10px;
  border: ${props => props.$isError && `2px solid ${theme.colors.red}`};
  ${props => props.$maxWidth && `max-width: ${props.$maxWidth}`};
  -moz-appearance: textfield;
  margin: ${props => props.$margin || '0 0 16px 0'};
  background-color: ${props => props.disabled && '#F2F2F2'};

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill {
    background-color: rgba(255, 255, 255, 0.10);;
  }

  &::placeholder {
    color: ${props => props.disabled ? '#BDBDBD' : props.$placeholderColor || props.$border};
  }

  &:focus {
    outline: none;
  }

`

export const TextareaElement = styled.textarea<IInputElement>`
  color: ${props => props.color};
  height: ${props => props.height ?? '5rem'};
  border: 2px solid ${props => props.$border};
  background: ${props => props.$background ?? 'rgba(255, 255, 255)'};
  border-radius: 10px;
  font-size: 16px;
  resize: none;
  line-height: 22px;
  padding: 8px 10px;
  border: ${props => props.$isError && `2px solid ${theme.colors.red}`};
  ${props => props.$maxWidth && `max-width: ${props.$maxWidth}`};
  -moz-appearance: textfield;
  margin: ${props => props.$margin || '0 0 16px 0'};

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill {
    background-color: rgba(255, 255, 255, 0.10);;
  }

  &::placeholder {
    color: ${props => props.disabled ? '#E0E0E0' : props.$placeholderColor || props.$border};

  }

  &:focus {
    outline: none;
  }
`

export const PasswordIcon = styled.img`
  width: 22px;
  position: absolute;
  right: 20px;
  bottom: 25px;
`
