import React, { FC, useEffect, useState } from 'react';

import { SuccessIcon } from '../../../../../shared/modal/modalIcons/SuccessIcon';
import {
  ConfirmationTextModal
} from '../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled';
import { ContentModal } from '../../../../../shared/modal/ContentModal';
import { Button } from '../../../../../shared/button/Button';
import { RadioButtons } from './AcceptCase.styled';
import { ReactComponent as PublicImage } from '../../../../../../assets/icon/public.svg';
import { ReactComponent as UnPublicImage } from '../../../../../../assets/icon/unpublic.svg';
import { useAuthStore } from '../../../../../../store/hooks';
import { checkPermission } from '../../../../../../utils/checkPermission';
import { RoleFunction } from '../../../../../../constants/projectUserRoles';
import { ModalType } from '../../../../../../constants/modalType';

interface IProps {
  handleSave: (isPublic: boolean) => void;
  modalType: ModalType;
  isPublicInit: boolean | null;
}

export const AcceptCase: FC<IProps> = ({handleSave, modalType, isPublicInit}) => {
  const authStore = useAuthStore();
  const {roleFunction} = authStore;
  const [isPublic, setIsPublic] = useState<null | boolean>(null);

  useEffect(() => {
    if (isPublic === null) {
      setIsPublic(isPublicInit);
    }
  }, [isPublicInit, isPublic])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPublic(event.target.value === 'public');
  };

  const onSave = () => {
    if (isPublic !== null) {
      handleSave(isPublic);
      setIsPublic(null)
    }
  }

  return (<ContentModal
      iconType={<SuccessIcon/>}
      text={
        <ConfirmationTextModal>{checkPermission(roleFunction, [RoleFunction.ROLE_ACCEPTING]) && modalType === ModalType.ACCEPT_CASE_MODAL ?
          <>{modalType === ModalType.ACCEPT_CASE_MODAL && <p>Gratulacje! OPIS PRZYPADKU został <br/>
            <strong>wysłany do administratora merytorycznego.</strong>
          </p>}</> :
          <>{modalType === ModalType.ACCEPT_CASE_MODAL && <><p>Właśnie
            zatwierdzasz OPIS PRZYPADKU...</p><br/></>}<p>Zdecyduj <b>czy OPIS PRZYPADKU ma być
            publiczny </b>dla wszystkich użytkowników czy<b> ma być niepubliczny</b> (widoczny tylko dla autora)</p></>
        }</ConfirmationTextModal>}>
      <>
        {(checkPermission(roleFunction, [RoleFunction.ROLE_SCIENTIFIC]) || (checkPermission(roleFunction, [RoleFunction.ROLE_ACCEPTING]) && modalType !== ModalType.ACCEPT_CASE_MODAL)) && <>
          <RadioButtons>
            <label>
              <input onChange={onChange} name={'isPublic'} type={'radio'} value={'public'} checked={isPublic === true}/>
              <PublicImage/>
            </label>
            <label>
              <input onChange={onChange} name={'isPublic'} type={'radio'} value={'unpublic'}
                     checked={isPublic === false}/>
              <UnPublicImage/>
            </label>
          </RadioButtons>
          <Button margin={'32px'} text={'Zapisz'} clickHandler={onSave} size={'small'}
                  width={'189px'}
                  backgroundColor={'black'} color={'white'}/></>
        }
      </>
    </ContentModal>
  );
};
