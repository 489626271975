import styled from "styled-components";
import theme from "../../../theme/theme";
import {Grid} from "@mui/material";
import {ReactComponent as Bin} from "../../../assets/icon/bin.svg";

interface IColor {
  color: string;
}

interface ILabel {
  $isCenter?: boolean;
}

export const Container = styled(Grid)<{ disabled?: boolean }>`
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 10px;
  border: 2px solid ${theme.colors.grey5};
  display: flex;
  justify-content: center;
  background-color: ${props => props.disabled ? theme.colors.grey6 : theme.colors.white};
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  @media (max-width: ${theme.breakpoints.small}px) {
    flex-direction: column;
  }
`

export const ContainerFiles = styled(Grid)`
  position: relative;
`

export const StyledPlusBig = styled.div<{ disabled?: boolean }>`
  background: ${props => props.disabled ? theme.colors.grey4 : theme.colors.color4};
  width: 31px;
  height: 31px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: ${props => props.disabled ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)'};

  & svg {
    width: 10px;
    height: 10px;
  }

  & path {
    fill: white
  }
`


export const StyledPlus = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${theme.colors.grey4};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;

  & svg {
    width: 6px;
    height: 6px;
  }

  & path {
    fill: white
  }
`

export const StyledBin = styled(Bin)`
  cursor: pointer;
  margin-right: 10px;

  & svg {
    width: 12px;
    height: 16px;
  }

  path {
    fill: ${theme.colors.red};
  }
`

export const ParagraphImage = styled.p`
  color: ${theme.colors.abbvie};
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding-right: 10px;
`;

export const Label = styled.label<ILabel>`
  color: ${theme.colors.grey4};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  ${props => props.$isCenter && 'position: absolute; top: calc(50% - 15px); right: 10%;'}
`

export const SpanText = styled.span<IColor>`
  color: ${props => props.color};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;

`