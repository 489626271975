import {IAreaForm} from '../interfaces/admin/areas/areaForm';
import {IAreas} from "../interfaces/common/areas";
import {makeAutoObservable} from "mobx";

export class AreasStore {
    areas: IAreas[] | null = null
    isAreasLoaded: boolean = false;
    areaEdit: IAreaForm | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setAreas(value: IAreas[]) {
        this.areas = value;
        this.isAreasLoaded = true
    }

    resetAreas() {
        this.areas = null
        this.isAreasLoaded = false;
    }

    setAreaEdit(area: IAreaForm, id: number) {
        const areaEditElement = {...area};
        areaEditElement.id = id;
        this.areaEdit = areaEditElement;
    }

    clearAreaEdit() {
        this.areaEdit = null;
    }
}