import React from "react";
import {
  CaseCardContainer,
  DataLarge,
  DataMiddle,
  Date,
  Icon,
  Action,
  StyledNext,
  StyledStatus,
  Separator,
} from "./CaseCard.styled";
import {
  dateStringify,
  generateLink,
  timeStringify,
  UserLink,
} from "../../../../utils";
import success from "../../../../assets/icon/success.svg";
import pending from "../../../../assets/icon/pending.svg";
import draft from "../../../../assets/icon/edit.svg";
import reject from "../../../../assets/icon/reject.svg";
import check from "../../../../assets/icon/doubleCheck.svg";
import search from "../../../../assets/icon/search.svg";
import refuse from "../../../../assets/icon/refuse.svg";
import theme from "../../../../theme/theme";
import { useNavigate } from "react-router-dom";
import { CaseStatus } from "../../../../interfaces/user/draftCase/draft";
import { coinfectonTypes } from "../../../../constants/coinfection";
// types
import { TArea, TForm } from "../../../../store/caseSettingsStore";

interface IProps {
  id: number;
  area: TArea;
  form: TForm;
  status: CaseStatus;
  customId: string;
  coInfection: number;
  title: string;
  updatedAt: string;
  maxWidth?: string;
}

interface IStatusIcons {
  [key: string]: string;
}

interface IStatus {
  [key: string]: string;
}

export const statusIcons: IStatusIcons = {
  [CaseStatus.DRAFT]: draft,
  [CaseStatus.ACCEPT]: success,
  [CaseStatus.REJECT]: reject,
  [CaseStatus.SENT]: pending,
  [CaseStatus.TO_ACCEPT]: pending,
  [CaseStatus.REFUSE]: refuse,
};

export const statusColors: IStatus = {
  [CaseStatus.DRAFT]: theme.colors.abbvie,
  [CaseStatus.ACCEPT]: theme.colors.green,
  [CaseStatus.REJECT]: theme.colors.orange,
  [CaseStatus.SENT]: theme.colors.yellow,
  [CaseStatus.TO_ACCEPT]: theme.colors.yellow,
  [CaseStatus.REFUSE]: theme.colors.claret,
};

export const statusName: IStatus = {
  [CaseStatus.DRAFT]: "szkic",
  [CaseStatus.ACCEPT]: "zaakceptowany",
  [CaseStatus.REJECT]: "do edycji",
  [CaseStatus.SENT]: "oczekujący",
  [CaseStatus.TO_ACCEPT]: "oczekujący",
  [CaseStatus.REFUSE]: "odrzucony",
};

const statusFurther: IStatus = {
  [CaseStatus.DRAFT]: "dokończ",
  [CaseStatus.ACCEPT]: "zobacz",
  [CaseStatus.REJECT]: "popraw",
  [CaseStatus.SENT]: "podgląd",
  [CaseStatus.TO_ACCEPT]: "podgląd",
  [CaseStatus.REFUSE]: "podgląd",
};

const statusFurtherIcon: IStatus = {
  [CaseStatus.DRAFT]: draft,
  [CaseStatus.ACCEPT]: check,
  [CaseStatus.REJECT]: reject,
  [CaseStatus.SENT]: search,
  [CaseStatus.TO_ACCEPT]: search,
  [CaseStatus.REFUSE]: refuse,
};
export const CaseCard: React.FC<IProps> = ({
  id,
  status,
  customId,
  coInfection,
  title,
  updatedAt,
  maxWidth,
  area,
  form,
}) => {
  const navigate = useNavigate();
  const icon = statusIcons[status];
  const color = statusColors[status];
  const name = statusName[status];
  const furtherStep = statusFurther[status];
  const furtherIcon = statusFurtherIcon[status];

  const navigateToDraftCaseHandler = () => {
    if (status === CaseStatus.DRAFT) {
      navigate(
        generateLink(UserLink.EDIT_CASE, [
          [":caseId", id],
          [":area", area.name],
          [":form", form.name],
        ])
      );
    } else {
      navigate(
        generateLink(UserLink.VIEW_CASE, [
          [":caseId", id],
          [":area", area.name],
          [":form", form.name],
        ])
      );
    }
  };

  const convertCoinfection = () => {
    const foundElement = coinfectonTypes.find(
      (el) => el.optionId === coInfection
    );
    return foundElement?.value;
  };

  return (
    <CaseCardContainer maxWidth={maxWidth} onClick={navigateToDraftCaseHandler}>
      <Icon color={color}>
        <img src={icon} alt={"status"} />
      </Icon>
      <StyledStatus color={color}>{name}</StyledStatus>
      <Date>
        {timeStringify(updatedAt)} {` - `} {dateStringify(updatedAt)}
      </Date>
      <DataMiddle>{form.name.toLocaleUpperCase()}</DataMiddle>
      <Separator />
      <DataMiddle>Id: {customId}</DataMiddle>
      <DataLarge>{title}</DataLarge>
      <Date>{convertCoinfection()}</Date>
      <Action>
        {furtherStep}
        <StyledNext>
          <img src={furtherIcon} alt={"status"} />
        </StyledNext>
      </Action>
    </CaseCardContainer>
  );
};
