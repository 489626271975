import React from "react";
import { observer } from "mobx-react-lite";

import { useCases } from "./useCases";
import { AdminLayout } from "../../../shared/admin/layout/AdminLayout";
import { CustomTable } from "../../../shared/customTables/customTable/CustomTable";
import { Modal } from "../../../shared/modal/Modal";
import { ConfirmationModal } from "../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal";
import { AcceptCase } from "./modal/acceptCase/AcceptCase";
import { RejectCase } from "./modal/reject/RejectCase";
import { ModalType } from "../../../../constants/modalType";
import { AcceptCaseModalText, ParagraphFirst } from "./Cases.styled";
import { checkPermission } from "../../../../utils/checkPermission";
import { RoleFunction } from "../../../../constants/projectUserRoles";
import { ContentModal } from "../../../shared/modal/ContentModal";
import { SuccessIcon } from "../../../shared/modal/modalIcons/SuccessIcon";

export const Cases = observer(() => {
  const {
    casesIsNotNull,
    cellsSwitcher,
    generateActions,
    acceptCaseModalAcceptingStart,
    acceptCaseHandler,
    editStatusCaseHandler,
    rejectCaseHandler,
    addedContent,
    headCells,
    tableDataRows,
    modalType,
    isModalOpened,
    closeModal,
    isPublic,
    roleFunction,
  } = useCases();

  return (
    <AdminLayout>
      {casesIsNotNull && (
        <CustomTable
          searchBarAddedContent={addedContent}
          actions={generateActions()}
          cellsSwitcher={cellsSwitcher}
          isSearchBar={true}
          tableDataRows={tableDataRows}
          isDataDownloaded={true}
          headCells={headCells}
        />
      )}
      {isModalOpened && (
        <Modal clickHandler={() => closeModal()}>
          {modalType === ModalType.ACCEPT_CASE_MODAL_START && (
            <ContentModal
              iconType={<SuccessIcon />}
              handleYes={acceptCaseModalAcceptingStart}
              handleNo={closeModal}
              text={
                <AcceptCaseModalText>
                  Czy jesteś pewny, że chcesz <b> zaakceptować</b> opis
                  przypadku i wysłać
                  <br /> do administratora merytorycznego?
                </AcceptCaseModalText>
              }
            />
          )}
          {(modalType === ModalType.ACCEPT_CASE_MODAL ||
            modalType === ModalType.EDIT_STATUS_CASE_MODAL) && (
            <AcceptCase
              isPublicInit={isPublic}
              modalType={modalType}
              handleSave={
                modalType === ModalType.ACCEPT_CASE_MODAL
                  ? acceptCaseHandler
                  : editStatusCaseHandler
              }
            />
          )}
          {modalType === ModalType.ACCEPT_CASE_CONFIRMATION_MODAL && (
            <ConfirmationModal
              text={
                <>
                  <p>
                    Gratulacje! OPIS PRZYPADKU został
                    <br /> <strong>zatwierdzony.</strong>
                  </p>
                  <p>
                    Przypisałeś mu status&nbsp;
                    {isPublic ? "publiczny" : "niepubliczny"}.
                  </p>
                </>
              }
            />
          )}
          {modalType === ModalType.REJECT_CASE_MODAL && (
            <RejectCase handleSave={rejectCaseHandler} />
          )}
          {modalType === ModalType.REJECT_CASE_CONFIRMATION_MODAL && (
            <ConfirmationModal
              text={
                <>
                  <ParagraphFirst>
                    <b>Opis przypadku został zwrócony do korekty.</b>
                  </ParagraphFirst>
                  <p>Komentarz zotał zwrócony do autora opisu przypadku.</p>
                  <br />
                  {checkPermission(roleFunction, [
                    RoleFunction.ROLE_SCIENTIFIC,
                  ]) && (
                    <p>
                      OPIS PRZYPADKU nie będzie widoczny publicznie do czasu
                      poprawienia go przez autora i przejścia przez cały proces
                      akceptacji.
                    </p>
                  )}
                </>
              }
            />
          )}
        </Modal>
      )}
    </AdminLayout>
  );
});
