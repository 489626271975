import React, {PropsWithChildren} from 'react';
import { observer } from "mobx-react-lite";

import { UserTop } from "../../appLayer/userTop/UserTop";
import { useAdminLayout } from "./useAdminLayout";
import { NavBar } from "./navBar/navBar/NavBar";
import {AdminLayoutContainer, AdminLayoutContentContainer, AdminLayoutHeader} from './AdminLayout.styled';

interface IProps extends PropsWithChildren {
  centralize?: boolean;
}

export const AdminLayout: React.FC<IProps> = observer(({children, centralize = false}) => {
  const {links} = useAdminLayout();

  return (
    <AdminLayoutContainer
      container
      direction='row'
      flexWrap='nowrap'>

      <NavBar links={links ?? []}/>

      <AdminLayoutHeader><UserTop /></AdminLayoutHeader>
      <AdminLayoutContentContainer $iscenter={centralize} item>
        {children}
      </AdminLayoutContentContainer>
    </AdminLayoutContainer>
  )
});
