import theme from "../../../../theme/theme";
import { Button } from "../../button/Button";
import React, { MouseEvent } from "react";
import { useNavigate } from 'react-router-dom';
import { ButtonContainer, Paragraph, StyledLink } from "./FormButton.styled";

interface IProps {
  isButtonActive: boolean;
  confirmForm?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
  link?: string,
  onClickCancel?: () => void;
  buttonText: string
}

export const FormButtons: React.FC<IProps> = ({isButtonActive, confirmForm, link, onClickCancel, buttonText}) => {
  const navigate = useNavigate();

  const linkHandler = () => {
    if (link) {
      navigate(link);
    }
  }

  return (
    <>
      <ButtonContainer color={isButtonActive ? theme.colors.white : theme.colors.white}>
        <StyledLink onClick={(e) => onClickCancel ? onClickCancel() : linkHandler()}>
          <Button
            hoverColor={theme.colors.blue4}
            type={'button'}
            text={'anuluj'}
            backgroundColor={theme.colors.white}
            color={theme.colors.abbvie}
            size={'tiny'}
            maxWidth={'85px'}
          />
        </StyledLink>
        <Button type='submit'
                clickHandler={confirmForm}
                text={buttonText}
                hoverColor={theme.colors.lightAbbvie}
                backgroundColor={theme.colors.abbvie}
                color={isButtonActive ? theme.colors.white : theme.colors.white}
                size={'tiny'}
                maxWidth={'172px'}
        />
      </ButtonContainer>
      <Paragraph>*Pola obowiązkowe</Paragraph>
    </>
  )
}
