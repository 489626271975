import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";

// hooks
import { useCaseSettingsStore, useHcvStore, } from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";

// types
import { THcvStage02 } from "./type";
import { EnAreaNames, EnFormNames, } from "../../../../../../../../../store/caseSettingsStore";

// lib
import { clearFields } from "../../../../../../../../../utils/clearFields";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";
import { IFile } from "../../../../../../../../../interfaces/user/draftCase/file";

export const validationSchema = yup.object().shape({
  dateOfDetection: yup
    .string()
    .test("dateOfDetection", "Should not be empty", function (value) {
      return !!value;
    }),
  placeOfDetection: yup.number().min(0).required(),
  routeOfInfection: yup.string().required(),
  routeOfInfectionDesc: yup
    .string()
    .nullable()
    .test(
      "isRouteOfInfectionKnown",
      "Pole routeOfInfectionDesc jest wymagane, gdy routeOfInfection jest znana.",
      function (value) {
        const routeOfInfection = this.parent.routeOfInfection;
        if (routeOfInfection === "znana droga zakażenia") {
          return !!value;
        }
        return true;
      }
    ),
  scaleScore: yup.string().required(),
  metavirDesc: yup
    .string()
    .nullable()
    .test(
      "isSelectedScaleScore",
      "Pole metavirDesc jest wymagane, gdy scaleScore jest w skali metavir.",
      function (value) {
        const scaleScore = this.parent.scaleScore;
        if (scaleScore === "wynik w skali METAVIR") {
          return !!value;
        }
        return true;
      }
    ),
  apriDesc: yup
    .string()
    .nullable()
    .test(
      "isSelectedScaleScore",
      "Pole apriDesc jest wymagane, gdy scaleScore jest apri.",
      function (value) {
        const scaleScore = this.parent.scaleScore;
        if (scaleScore === "APRI lub FIB-4") {
          return !!value;
        }
        return true;
      }
    ),
  clinicalSignsOfCirrhosis: yup.string(),
  extrahepaticManifestationsOfHCV: yup.string(),
  hcvRna: yup.string(),
  hcvRnaUnit: yup
    .string()
    .test(
      "is-not-empty",
      "hcvRnaUnit is required if hcvRna is filled",
      function (value) {
        const hcvRna = this.resolve(yup.ref("hcvRna"));
        if (hcvRna) {
          return !!value;
        }
        return true;
      }
    ),
  rbc: yup.string().required(),
  hgb: yup.string().required(),
  htc: yup.string().required(),
  plt: yup.string().required(),
  wbc: yup.string().required(),
  mcv: yup.string().required(),
  mch: yup.string().required(),
  mchc: yup.string().required(),
  aiat: yup.string().required(),
  albumin: yup.string().required(),
  bilirubin: yup.string().required(),
  prothrombin: yup.string().required(),
  prothrombinUnit: yup.string().required(),
  creatinine: yup.string().required(),
  egfr: yup.string().required(),
  antiHiv: yup.string().required(),
  hbs: yup.string().required(),
  abdominalUltrasound: yup.string(),
  abdominalUltrasoundCheckbox: yup.boolean().test(
    "isAbdominalUltrasoundCheckbox",
    function (value) {
      const files = this.parent.abdominalUltrasoundFiles;
      if (files.length > 0) {
        return !!value;
      }
      return true;
    }
  ),
  abdominalUltrasoundFiles: yup.array(),
  liverFibrosis: yup.string().required(),
  fibroSkanDesc: yup
    .string()
    .nullable()
    .test(
      "isSelectedliverFibrosis1",
      "Pole fibroSkanDesc jest wymagane, gdy liverFibrosis jest FibroSkan/Elastografia.",
      function (value) {
        const liverFibrosis = this.parent.liverFibrosis;
        if (liverFibrosis === "FIBROSKAN/ELASTIGRAFIA [kPa]") {
          return !!value;
        }
        return true;
      }
    ),
  apriLiverDesc: yup
    .string()
    .nullable()
    .test(
      "isSelectedliverFibrosis2",
      "Pole apriLiverDesc jest wymagane, gdy liverFibrosis jest APRI.",
      function (value) {
        const liverFibrosis = this.parent.liverFibrosis;
        if (liverFibrosis === "APRI") {
          return !!value;
        }
        return true;
      }
    ),
  fibLiverDesc: yup
    .string()
    .nullable()
    .test(
      "isSelectedliverFibrosis3",
      "Pole fibLiverDesc jest wymagane, gdy liverFibrosis jest FIB-4.",
      function (value) {
        const liverFibrosis = this.parent.liverFibrosis;
        if (liverFibrosis === "FIB-4") {
          return !!value;
        }
        return true;
      }
    ),
  liverBiopsy: yup.boolean(),
  liverBiopsyDesc: yup
    .string()
    .test(
      "isSelectedLiverBiopsy",
      "Pole liverBiopsyDesc jest wymagane, gdy liverBiopsy jest true",
      function (value) {
        const liverBiopsy = this.parent.liverBiopsy;
        if (liverBiopsy === true) {
          return !!value;
        }
        return true;
      }
    ),
  liverBiopsyCheckbox: yup.boolean().test(
    "isLiverBiopsyCheckbox",
    function (value) {
      const files = this.parent.liverBiopsyFiles;
      if (files.length > 0) {
        return !!value;
      }
      return true;
    }
  ),
  liverBiopsyFiles: yup.array(),
  hcvGenotype: yup.string(),
  hcvGenotypeMix: yup
    .string()
    .test(
      "ishcvGenotypeMix",
      "Pole hcvGenotypeMix jest wymagane, gdy hcvGenotype jest GT mieszany",
      function (value) {
        const liverBiopsy = this.parent.hcvGenotype;
        if (liverBiopsy === "GT mieszany") {
          return !!value;
        }
        return true;
      }
    ),
  presenceOfComorbidities: yup.string(),
  dataHiv: yup.string(),
  dataHbv: yup.string(),
  previousHistory: yup.string().required(),
  otherElements: yup.array().min(1).required(),
  otherElementsDesc: yup
    .string()
    .test(
      "isSelectedOther",
      "Pole otherElementsDesc jest wymagane, gdy otherElements zawiera inne",
      function (value) {
        const otherElements = this.parent.otherElements;
        if (otherElements?.includes("inne")) {
          return !!value;
        }
        return true;
      }
    ),
  patientPreviouslyTreatedWithAntivirals: yup.string().required(),
  patientPreviouslyTreatedCount: yup
    .number()
    .nullable()
    .when("patientPreviouslyTreatedWithAntivirals", {
      is: "tak",
      then: (schema) => schema.required(),
    }),
  patientPreviouslyTreatedItems: yup.array().of(
    yup.object().shape({
      year: yup.string().required("Rok jest wymagany"),
      description: yup.string().required("Rok jest wymagany"),
      response: yup.string().required("Rok jest wymagany"),
    })
  ),
  drugs: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        drugName: yup.string().required("drugName jest wymagany"),
        drugDose: yup.string().required("drugDose jest wymagany"),
      })
    )
    .required(),
  potentialDrugInteractions: yup.string().required(),
});

const initialValues: THcvStage02 = {
  dateOfDetection: null,
  placeOfDetection: null,
  routeOfInfection: "",
  routeOfInfectionDesc: "",
  scaleScore: "",
  metavirDesc: "",
  apriDesc: "",
  clinicalSignsOfCirrhosis: "",
  extrahepaticManifestationsOfHCV: "",
  hcvRna: "",
  hcvRnaUnit: "",
  rbc: "",
  hgb: "",
  htc: "",
  plt: "",
  wbc: "",
  mcv: "",
  mch: "",
  mchc: "",
  aiat: "",
  albumin: "",
  bilirubin: "",
  prothrombin: "",
  prothrombinUnit: "",
  creatinine: "",
  egfr: "",
  antiHiv: "",
  hbs: "",
  abdominalUltrasound: "",
  abdominalUltrasoundCheckbox: false,
  liverFibrosis: "",
  fibroSkanDesc: "",
  apriLiverDesc: "",
  fibLiverDesc: "",
  liverBiopsy: false,
  liverBiopsyDesc: "",
  liverBiopsyCheckbox: false,
  hcvGenotype: "",
  hcvGenotypeMix: "",
  presenceOfComorbidities: "",
  dataHiv: "",
  dataHbv: "",
  previousHistory: "",
  otherElements: [],
  otherElementsDesc: "",
  patientPreviouslyTreatedWithAntivirals: "",
  patientPreviouslyTreatedCount: null,
  patientPreviouslyTreatedItems: [],
  drugs: [{id: new Date().toISOString(), drugDose: "", drugName: ""}],
  potentialDrugInteractions: "",
};
const idDrug = new Date().toISOString()
export const useStage02ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // define API calls
  const {
    postAbdominalFilesSecond,
    deleteAbdominalFilesSecond,
    postLiverFilesSecondStage,
    deleteLiverFilesSecondStage,
    postDrugInteractionFilesSecond,
    deleteDrugInteractionFilesSecond,
    updateCase,
  } = useCaseApi();

  // rzs store
  const hcvStore = useHcvStore();
  const {firstStage, secondStage, caseStage} = hcvStore;

  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(secondStage))
      : JSON.parse(JSON.stringify(initialValues));

  const newDrugs = valuesObj.drugs.length === 0 ? [{
    id: idDrug,
    drugDose: "",
    drugName: ""
  }] : [...valuesObj.drugs]

  const formik = useFormik<THcvStage02>({
    enableReinitialize: true,
    initialValues: {
      ...valuesObj,
      drugs: newDrugs
    },
    validationSchema: validationSchema,
    onSubmit: async (values: THcvStage02) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );

      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const extraChangeOptionPreviouslyTreatedCount = (value: number) => {
    setFieldValue("patientPreviouslyTreatedCount", value);

    if (
      value > 0 &&
      secondStage &&
      secondStage.patientPreviouslyTreatedItems.length === 0
    ) {
      setFieldValue("patientPreviouslyTreatedItems", [
        ...Array(value).map((el) => ({
          year: null,
          description: "",
          response: "",
        })),
      ]);
    } else if (value === 0) {
      setFieldValue("patientPreviouslyTreatedItems", []);
    }
    if (
      secondStage &&
      secondStage.patientPreviouslyTreatedItems.length > 0 &&
      value !== -1
    ) {
      if (secondStage.patientPreviouslyTreatedItems.length > value) {
        setFieldValue("patientPreviouslyTreatedItems", [
          ...formik.values.patientPreviouslyTreatedItems.slice(0, value),
        ]);
      } else if (
        secondStage.patientPreviouslyTreatedItems.length > 0 &&
        secondStage.patientPreviouslyTreatedItems.length < value
      ) {
        const diff = value - secondStage.patientPreviouslyTreatedItems.length;
        setFieldValue("patientPreviouslyTreatedItems", [
          ...formik.values.patientPreviouslyTreatedItems,
          ...Array(diff).map((el) => ({
            year: null,
            description: "",
            response: "",
          })),
        ]);
      }
    }
  };

  // handlers abdominalUltrasound

  const setFormikValue = (res: IFile[], key: string) => {
    formik.setFieldValue(key, res)
  }

  const addAbdominalUltrasoundFile = async (file: File | null) => {
    if (file && caseId) {
      await postAbdominalFilesSecond(+caseId, file, setFormikValue, 'abdominalUltrasoundFiles');
    }
  };

  const removeAbdominalUltrasoundFile = async (idFile: number | string) => {
    if (idFile && caseId) {
      await deleteAbdominalFilesSecond(+caseId, idFile, setFormikValue, 'abdominalUltrasoundFiles');
    }
  };

  // handlers liver file
  const addLiverFile = async (file: File | null) => {
    if (file && caseId) {
      await postLiverFilesSecondStage(+caseId, file, setFormikValue, 'liverBiopsyFiles');
    }
  };
  const removeLiverFile = async (idFile: number | string) => {
    if (idFile && caseId) {
      await deleteLiverFilesSecondStage(+caseId, idFile, setFormikValue, 'liverBiopsyFiles');
    }
  };

  // handlers drug interaction file
  const addDrugInteractionFile = async (file: File | null) => {
    if (file && caseId) {
      await postDrugInteractionFilesSecond(+caseId, file, setFormikValue, 'potentialDrugInteractionFiles');
    }
  };


  const removeDrugInteractionFile = async (idFile: number | string) => {
    if (idFile && caseId) {
      await deleteDrugInteractionFilesSecond(+caseId, idFile, setFormikValue, 'potentialDrugInteractionFiles');
    }
  };

  // handlers drug
  const addDrug = () => {
    setFieldValue("drugs", [
      ...formik.values.drugs,
      {drugName: "", drugDose: "", id: new Date().toISOString()},
    ]);
  };

  const deleteDrug = (index: string | number) => {
    setFieldValue("drugs", [
      ...formik.values.drugs.filter((el, indexCheck) => indexCheck !== index),
    ]);
  };

  const handleCheckbox = (value: string) => {
    const newValues = formik.values.otherElements
      ? [...formik.values.otherElements]
      : [];
    const newOperation = newValues.filter((el) => el !== value);
    if (value) {
      if (formik.values.otherElements?.includes(value)) {
        setFieldValue("otherElements", newOperation);
      } else if (!formik.values.otherElements?.includes(value)) {
        setFieldValue("otherElements", [...newValues, value]);
      }
    }
  };

  return {
    formik,
    addAbdominalUltrasoundFile,
    removeAbdominalUltrasoundFile,
    addLiverFile,
    removeLiverFile,
    addDrug,
    deleteDrug,
    handleCheckbox,
    clearFields,
    extraChangeOptionPreviouslyTreatedCount,
    addDrugInteractionFile,
    removeDrugInteractionFile,
    coInfection: firstStage?.coInfection,
  };
};
