import React from 'react'
import {FormikErrors} from "formik";
import {ParagraphErrorMessage} from "./ErrorMessage.styled";

interface IErrorMessageProps {
  errorServer?: boolean;
  errors: FormikErrors<any>
  message: string;
}

export const ErrorMessage: React.FC<IErrorMessageProps> = ({errors, errorServer = false, message}) => {
  let isErrorGlobal = errorServer || false;

  if (!errorServer) {
    Object.keys(errors).forEach(field => {
      const isError = Boolean(errors[field]);
      if (isError) isErrorGlobal = isError;
    });
  }

  return <>{(isErrorGlobal || message) && <ParagraphErrorMessage>{message}</ParagraphErrorMessage>}</>
}

