import {useRootStore} from '../RootStateContext';

export const useRolesStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.rolesStore) {
    throw new Error('rolesStore store should be defined');
  }

  return rootStore.rolesStore;
};
