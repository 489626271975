import {TableSortOrder} from '../../../../interfaces/common/table';

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) return -1
  if (b[orderBy] > a[orderBy]) return 1

  return 0;
}

export const getComparator = <Key extends keyof any>(order: TableSortOrder, orderBy: Key)
  : (a: { [key in Key]: number | string }, b: { [key in Key]: number | string })
  => number =>
  order === TableSortOrder.Descending
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);