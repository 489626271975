import React from 'react';
import { observer } from 'mobx-react-lite';

import { Rings } from 'react-loader-spinner';
import { GlobalLoaderContainer } from './GlobalLoader.styles';
import { useLoadersStore } from '../../../../store/hooks';

export const GlobalLoader = observer(() => {
  const loadersStore = useLoadersStore();
  const {isGlobalLoader} = loadersStore;

  return isGlobalLoader ? (
    <GlobalLoaderContainer>
      <Rings color="#071C47" height={150} width={150} radius={1}/>
    </GlobalLoaderContainer>
  ) : null;
});
