import styled from "styled-components";
import theme from "../../../theme/theme";

export const Container = styled.div<{ $bcg: string }>`
  min-height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: ${props => `url(${props.$bcg}) no-repeat right top`};
  background-size: auto 100%;
  z-index: 1180;

  h1 {
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: ${theme.colors.violet};
  }

  p {
    text-align: center;
    color: ${theme.colors.abbvie};
  }

  svg {
    display: flex;
    margin: 0 auto;
  }

`