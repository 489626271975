import { useRootStore } from "../RootStateContext";

export const useLoadersStore = () => {
	const rootStore = useRootStore();

	if (!rootStore?.loadersStore) {
		throw new Error('loadersStore store should be defined');
	}

	return rootStore.loadersStore;
}