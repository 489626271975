import {makeAutoObservable} from "mobx";
import {IOthers} from "../interfaces/admin/others/others";

export class OthersStore {

  others: IOthers[] | null = null;
  isOthersLoaded: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setOthers(others: IOthers[]) {
    this.others = others;
    this.isOthersLoaded = true;
  }

  clearOthers() {
    this.isOthersLoaded = false;
  }

}
