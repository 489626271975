import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";

// components
import { CompletedCase } from "./completed-case/CompletedCase";
import { FinishMessage } from "./finish-message/FinishMessage";
import { useCaseSettingsStore } from "../../../../../../store/hooks";

// helpers
import { UserLink, generateLink } from "../../../../../../utils";
import { useCaseApi } from "../../../../../../apiHooks";
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../store/caseSettingsStore";

export const Summary = observer(() => {
  // navigate and params
  const { caseId, area, form } = useParams();
  const navigate = useNavigate();

  // api
  const { publishDraftCase } = useCaseApi();

  // case settings-store
  const caseSettingsStore = useCaseSettingsStore();
  const { isPrevButtonClicked } = caseSettingsStore;

  const [isFinish, setIsFinish] = useState<boolean>(false);

  // isPreviosButtonClicked true then go to previos stage without submit form
  // and set isPreviosButtonClicked to false
  useEffect(() => {
    if (isPrevButtonClicked) {
      caseSettingsStore.decreaseCurrentStage();
    }
  }, [caseSettingsStore, isPrevButtonClicked]);

  const viewLink = () => {
    navigate(
      generateLink(UserLink.VIEW_CASE, [
        [":caseId", String(caseId)],
        [":area", String(area)],
        [":form", String(form)],
      ])
    );
  };

  const onClickSubmit = async () => {
    if (caseId && area && form) {
      await publishDraftCase({
        caseId,
        area: area as EnAreaNames,
        form: form as EnFormNames,
      });
    }
  };

  if (!isFinish) {
    return (
      <CompletedCase
        viewLink={viewLink}
        onClickSubmit={onClickSubmit}
        finishProcess={() => setIsFinish(true)}
      />
    );
  } else {
    return <FinishMessage />;
  }
});
