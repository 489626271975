import * as yup from "yup";

// hooks
import {useFormik} from "formik";
import {useCaseSettingsStore, useZzskStore,} from "../../../../../../../../../store/hooks";
import {useParams} from "react-router-dom";
import {useCaseApi} from "../../../../../../../../../apiHooks";
import {useButtonsEffects} from "../../../../hooks/useButtonsEffects";

// types
import {TZzskStage03} from "./type";
import {EnAreaNames, EnFormNames} from "../../../../../../../../../store/caseSettingsStore";

const validationSchema = yup.object().shape({
  programEntryDate: yup.string().required(),
  resultScaleOption3: yup.string().required(),
  result3: yup.number().required(),
  vasResult3: yup.number().required(),
  obResult3: yup.number().nullable(),
  crpResult3: yup.number().required(),
  treatmentLines: yup.array().of(
    yup.object().shape({
      variants: yup.string().required(),
      tnfInhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory TNF",
        then: (schema) => schema.required(),
      }),
      adalimumabOtherVariant: yup.string().when("tnfInhibitorType", {
        is: (value: string) => value === "Adalimumab inne",
        then: (schema) => schema.required(),
      }),
      adalimumabOtherDose: yup.string().when("tnfInhibitorType", {
        is: (value: string) => value === "Adalimumab inne",
        then: (schema) => schema.required(),
      }),
      il17InhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory IL-17",
        then: (schema) => schema.required(),
      }),
      jakInhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory JAK",
        then: (schema) => schema.required(),
      }),
      therapyDuration: yup.number().nullable(),
      durationUnit: yup.string().nullable().when("therapyDuration", {
        is: (value: number) => value > 0,
        then: (schema) => schema.required(),
      }),
      monotherapy: yup.string().required(),
      combinationWithMedicine: yup
        .array()
        .of(
          yup.object().shape({
            combinationDrugName: yup.string().required(),
            otherCombinationDrug: yup.string().when("combinationDrugName", {
              is: (value: string) => value === "Inne",
              then: (schema) => schema.required(),
            }),
            combinationDose: yup.number().required(),
            combinationUnit: yup.string().required(),
            otherCombinationUnit: yup.string().when("combinationUnit", {
              is: (value: string) => value === "Inna jednostka",
              then: (schema) => schema.required(),
            }),
            combinationDuration: yup.number().integer().required(),
          })
        )
        .when("monotherapy", {
          is: (value: string) => value === "kombinacja",
          then: (schema) => schema.min(1).required(),
        }),
      resultScaleOption3followup: yup.string().required(),
      result3followup: yup.number().required(),
      vasResult3followup: yup.number().required(),
      obResult3followup: yup
        .number()
        .nullable(),
      crpResult3followup: yup
        .number().required(),
      symptomsOfIntolerance: yup.string().required(),
    })
  ),
});

const initialValues: TZzskStage03 = {
  programEntryDate: null,
  resultScaleOption3: "",
  result3: null,
  vasResult3: null,
  obResult3: null,
  crpResult3: null,
  treatmentLines: [
    {
      variants: "",
      tnfInhibitorType: "",
      adalimumabOtherVariant: "",
      adalimumabOtherDose: "",
      il17InhibitorType: "",
      jakInhibitorType: "",
      therapyDuration: null,
      durationUnit: '',
      monotherapy: "",
      combinationWithMedicine: [],
      resultScaleOption3followup: "",
      result3followup: null,
      vasResult3followup: null,
      obResult3followup: null,
      crpResult3followup: null,
      symptomsOfIntolerance: "",
    },
  ],
};

export const useStage03ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const zzskStore = useZzskStore();
  const {thirdStage, caseStage} = zzskStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(thirdStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TZzskStage03>({
    initialValues: {
      ...valuesObj,
      treatmentLines: valuesObj.treatmentLines.length === 0 ? [{
        variants: "",
        tnfInhibitorType: "",
        adalimumabOtherVariant: "",
        adalimumabOtherDose: "",
        il17InhibitorType: "",
        jakInhibitorType: "",
        therapyDuration: null,
        durationUnit: '',
        monotherapy: "",
        combinationWithMedicine: [],
        resultScaleOption3followup: "",
        result3followup: null,
        vasResult3followup: null,
        obResult3followup: null,
        crpResult3followup: null,
        symptomsOfIntolerance: "",
      }] : valuesObj.treatmentLines
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`treatmentLines[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }

    if (field === 'variants') {
      if (value !== 'Inhibitory TNF') {
        setFieldValue(`treatmentLines[${index}].tnfInhibitorType`, '')
      }

      if (value !== 'Inhibitory IL-17') {
        setFieldValue(`treatmentLines[${index}].il17InhibitorType`, '')
      }
      if (value !== 'Inhibitory JAK') {
        setFieldValue(`treatmentLines[${index}].jakInhibitorType`, '')
      }
    }

    if (field === 'tnfInhibitorType') {
      if (value !== 'Adalimumab inne') {
        setFieldValue(`treatmentLines[${index}].adalimumabOtherVariant`, '')
        setFieldValue(`treatmentLines[${index}].adalimumabOtherDose`, '')
      }
    }

  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `treatmentLines[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const deleteTreatment = (index: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines.filter((el, idx) => idx !== index),
    ];
    setFieldValue("treatmentLines", filteredItems);
  };

  const addLine = () => {
    setFieldValue("treatmentLines", [
      ...formik.values.treatmentLines,
      {
        variants: "",
        tnfInhibitorType: "",
        adalimumabOtherVariant: "",
        adalimumabOtherDose: "",
        il17InhibitorType: "",
        jakInhibitorType: "",
        therapyDuration: null,
        durationUnit: '',
        monotherapy: "",
        combinationWithMedicine: [],
        resultScaleOption3followup: "",
        result3followup: null,
        vasResult3followup: null,
        obResult3followup: null,
        crpResult3followup: null,
        symptomsOfIntolerance: "",
      },
    ]);
  };

  const addCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const deleteCombination = (index: number, idx: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines[index].combinationWithMedicine.filter(
        (el, i) => idx !== i
      ),
    ];
    setFieldValue(
      `treatmentLines[${index}].combinationWithMedicine`,
      filteredItems
    );
  };

  const addOtherCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      ...formik.values.treatmentLines[index].combinationWithMedicine,
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  return {
    formik,
    setOption,
    deleteTreatment,
    addLine,
    addCombination,
    deleteCombination,
    addOtherCombination,
    setCombinationOption,
  };
};
