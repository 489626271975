// hooks
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCaseSettingsStore } from "../../../../store/hooks";
import { useCaseApi } from "../../../../apiHooks";

// components
import { UserLayout } from "../../../shared/user/layout/UserLayout";
import { ProgressBar } from "./progressBar/ProgressBar";
import { Grid } from "@mui/material";
import { CaseCreator } from "./caseCreator/CaseCreator";
import { EnAreaNames, EnFormNames } from "../../../../store/caseSettingsStore";
import { SaveDraftBtn } from "./saveDraftBtn/SaveDraftBtn";
import { observer } from "mobx-react";
import { Button } from "../../../shared/button/Button";
import theme from "../../../../theme/theme";
import { ReactComponent as WhiteArrow } from './../../../../assets/icon/whiteThinArrow.svg'
import { ReactComponent as BlueArrow } from './../../../../assets/icon/blueThinArrow.svg'
import { scrollToTop } from "../../../../utils/scrollToTop";

export const DraftCase = observer(() => {
  const {area, form, caseId} = useParams();
  const location = useLocation();
  const caseSettingsStore = useCaseSettingsStore();

  const {
    stageCount,
    currentStage,
    isNextButtonClicked,
    isPrevButtonClicked,
  } = caseSettingsStore;

  const caseApi = useCaseApi();
  const {getCase} = caseApi;
  const isValidArea = Object.values(EnAreaNames).includes(area as EnAreaNames);
  const isValidForm = Object.values(EnFormNames).includes(form as EnFormNames);

  useEffect(() => {
    return () => {
      caseSettingsStore.reset();
    };
  }, [caseSettingsStore]);

  useEffect(() => {
    scrollToTop()
  }, [currentStage])

  useEffect(() => {
    if (!area || !form || !isValidArea || !isValidForm) return;

    const isNewCase = location.pathname.includes("add");

    const currentArea = area as EnAreaNames;
    const currentForm = form as EnFormNames;
    const currentStep = 1;

    if (isNewCase) {
      caseSettingsStore.setCurrentArea(currentArea);
      caseSettingsStore.setCurrentForm(currentForm);
      caseSettingsStore.setCurrentStage(currentStep);
      caseSettingsStore.setStageCount(currentArea, currentForm);
    }

    if (caseId) {
      void getCase({area: currentArea, form: currentForm, caseId});
    }

  }, [
    isValidArea,
    isValidForm,
    area,
    form,
    caseId,
    caseSettingsStore,
    location,
    getCase,
  ]);

  const isLastStage = currentStage && stageCount && currentStage > stageCount;

  if (!isValidArea || !isValidForm) {
    return (
      <UserLayout>
        <Grid container>Invalid URL</Grid>
      </UserLayout>
    );
  }
  const isPrevButtonVisible = currentStage && currentStage > 1;
  const isNextButtonVisible =
    currentStage && stageCount && currentStage <= stageCount;
  const isButtonsDisabled = isNextButtonClicked || isPrevButtonClicked;

  return (
    <UserLayout>
      <Grid container position={"relative"} justifyContent={"center"}>
        <ProgressBar/>
        <Grid
          container
          mt={4}
          flexDirection={"row"}
          justifyContent={isLastStage ? "center" : "flex-start"}
        >
          <Grid item xs={10}>
            <CaseCreator/>
            <Grid display={'flex'} maxWidth={'714px'} margin={'0 auto 32px auto'} justifyContent={'space-between'}>
              <Grid item display="flex">
                {isPrevButtonVisible && currentStage < 5 && (
                  <Button
                    clickHandler={() => caseSettingsStore.clickPrevButton()}
                    disabled={isButtonsDisabled}
                    text={"wstecz"}
                    startIcon={<BlueArrow/>}
                    size={"tiny"}
                    width={"100px"}
                    backgroundColor={theme.colors.white}
                    hoverColor={theme.colors.blue4}
                    fontWeight={400}
                    color={theme.colors.abbvie}
                  />
                )}
              </Grid>
              <Grid item display="flex" justifyContent="flex-end">
                {isNextButtonVisible && (
                  <Button
                    clickHandler={() => caseSettingsStore.clickNextButton()}
                    disabled={isButtonsDisabled}
                    text={"dalej"}
                    size={"tiny"}
                    width={"100px"}
                    icon={<WhiteArrow/>}
                    backgroundColor={theme.colors.abbvie}
                    hoverColor={theme.colors.lightAbbvie}
                    fontWeight={400}
                    color={theme.colors.white}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {!isLastStage && (
            <Grid item xs={2}>
              <Grid container position={"relative"}>
                <SaveDraftBtn/>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </UserLayout>
  );
});
