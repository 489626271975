import styled from "styled-components";
import theme from '../../../../theme/theme';

export const Container = styled.div`
  width: 500px;
`
export const Label = styled.label<{ color?: string }>`
  font-weight: 700;
  text-transform: uppercase;
  color: #646464;
  font-size: 14px;
  line-height: unset;
  margin-bottom: 4px;
  margin-left: 8px;
  display: block;

  & span {
    color: ${theme.colors.red};
    margin-left: 2px;
  }
`;
