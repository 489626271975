import { Button } from './FilterBar.styles'
import React from "react";
import { yearArrayGenerate } from "./filter.helper";
import { Grid } from "@mui/material";

interface IProps {
  filter: number
  switchFilters: (year: number) => void
}

export const FilterBar: React.FC<IProps> = ({switchFilters, filter}) => {

  return (
    <Grid container gap={1}>
      {yearArrayGenerate().map(el =>
        <Button key={el.id} isActive={el.year === filter}
                onClick={() => switchFilters(el.year)}>
          {el.year}
        </Button>
      )}
    </Grid>
  )
}