import { TStage } from "../../../../../../../interfaces/user/draftCase/draft";

export const npsStages: TStage[] = [
  {
    stage: 1,
    title: "Opis klinczny",
    isStepper: true,
  },
  {
    stage: 2,
    title: "Dotychczasowe leczenie",
    isStepper: true,
  },
  {
    stage: 3,
    title: "Program lekowy",
    isStepper: true,
  },
  {
    stage: 4,
    title: "Rinvoq",
    isStepper: true,
  },
  {
    stage: 5,
    title: "Wnioski",
    isStepper: true,
  },
  {
    stage: 6,
    title: "Gratulacje",
    isStepper: false,
  },
];
