import {FC, useState} from "react";

import {
  AdminComment,
  AdminCommentContainer,
  AdminCommentFirstContainer,
  AdminDate,
  ExtendedButtonLess,
  ExtendedButtonMore,
} from "./Comments.styled";
import {Grid} from "@mui/material";
import {CommentsProps} from "./Comments.types";
import {ReactComponent as Arrow} from "../../../../../../../assets/icon/arrowRight.svg";

export const Comments: FC<CommentsProps> = ({comments}) => {
  const [extended, setExtended] = useState<boolean | null>(
    comments.length > 1 ? false : null
  );

  const Container =
    extended === false ? AdminCommentFirstContainer : AdminCommentContainer;

  return (
    <Grid
      item
      xs={12}
      mt={"40px"}
      display={"flex"}
      flexDirection={"column"}
      gap={3}
      position={"relative"}
    >
      {comments.length >= 1 && (
        <Container>
          <AdminDate>{comments[0].createdAt.substring(0, 10)}</AdminDate>
          <AdminComment>{comments[0].comment}</AdminComment>
        </Container>
      )}
      {extended === false && (
        <ExtendedButtonMore onClick={() => setExtended(true)}>
          ROZWIŃ <Arrow/>
        </ExtendedButtonMore>
      )}
      {extended === true && (
        <>
          {comments.slice(1, comments.length).map((comment) => (
            <AdminCommentContainer key={comment.id}>
              <AdminDate>{comment.createdAt.substring(0, 10)}</AdminDate>
              <AdminComment>{comment.comment}</AdminComment>
            </AdminCommentContainer>
          ))}
          <ExtendedButtonLess onClick={() => setExtended(false)}>
            <span>ZWIŃ</span> <Arrow/>
          </ExtendedButtonLess>
        </>
      )}
    </Grid>
  );
};
