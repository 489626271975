import { observer } from "mobx-react-lite";

// hooks
import { useEffect, useState } from "react";

// components
import { Box, Grid, Typography } from "@mui/material";
import { useCaseSettingsStore } from "../../../../../store/hooks";
import { Button } from "../../../../shared/button/Button";

// lib
import theme from "../../../../../theme/theme";
import { stageSwitcher } from "./lib/stage-switcher";

// types


// images
import thickImage from "../../../../../assets/icon/thick.svg";
import { ReactComponent as BlueArrow } from "../../../../../assets/icon/blueThinArrow.svg";
import { ReactComponent as WhiteArrow } from "../../../../../assets/icon/whiteThinArrow.svg";
import { TStage } from "../../../../../interfaces/user/draftCase/draft";

export const ProgressBar = observer(() => {
  const caseSettingsStore = useCaseSettingsStore();
  const {
    stageCount,
    currentStage,
    currentForm,
    isNextButtonClicked,
    isPrevButtonClicked,
  } = caseSettingsStore;

  const [stageTitle, setStageTitle] = useState<string | null>(null);
  const [stages, setStages] = useState<TStage[] | null>([]);
  const [isStepper, setIsStepper] = useState<boolean>(false);

  const isPrevButtonVisible = currentStage && currentStage > 1;
  const isNextButtonVisible =
    currentStage && stageCount && currentStage <= stageCount;
  const isButtonsDisabled = isNextButtonClicked || isPrevButtonClicked;

  useEffect(() => {
    if (!currentForm) return;
    setStages(stageSwitcher(currentForm));
  }, [currentForm, setStages]);

  useEffect(() => {
    if (!currentForm || !stages || !currentStage) return;
    setStageTitle(stages[currentStage - 1]?.title);
    setIsStepper(stages[currentStage - 1]?.isStepper);
  }, [currentStage, currentForm, stages]);

  return (
    <>
      {/* BUTTON AND TITLE BLOCK */}
      <Grid item xs={12} display="flex" mb={2}>
        <Grid item xs={2} display="flex">
          {isPrevButtonVisible && (
            <Button
              clickHandler={() => caseSettingsStore.clickPrevButton()}
              disabled={isButtonsDisabled}
              text={"wstecz"}
              startIcon={<BlueArrow/>}
              size={"tiny"}
              width={"100px"}
              backgroundColor={theme.colors.white}
              hoverColor={theme.colors.blue4}
              fontWeight={400}
              color={theme.colors.abbvie}
            />
          )}
        </Grid>
        <Grid item xs={8} display="flex" justifyContent="center">
          <Typography sx={styles.title}>
            {isStepper && (
              <Box component={"span"} sx={styles.stepper}>
                {currentStage}/{stageCount}
              </Box>
            )}
            {stageTitle}
          </Typography>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="flex-end">
          {isNextButtonVisible && (
            <Button
              clickHandler={() => caseSettingsStore.clickNextButton()}
              disabled={isButtonsDisabled}
              text={"dalej"}
              size={"tiny"}
              width={"100px"}
              icon={<WhiteArrow/>}
              backgroundColor={theme.colors.abbvie}
              hoverColor={theme.colors.lightAbbvie}
              fontWeight={400}
              color={theme.colors.white}
            />
          )}
        </Grid>
      </Grid>

      {/* PROGRESS BLOCK */}
      <Grid
        container
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        overflow="hidden"
      >
        {currentStage &&
          stages?.map(
            (stage, index) =>
              stage.isStepper && (
                <Grid
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  width={`${100 / stageCount!}%`}
                  position="relative"
                  key={stage.title}
                >
                  <Grid sx={styles.line}/>
                  <Grid sx={styles.column}>
                    <Grid sx={styles.step(stage.stage, currentStage)}>
                      <img src={thickImage} alt="thick"/>
                    </Grid>
                    <Typography sx={styles.stepName}>{stage.title}</Typography>
                  </Grid>
                  {stageCount && stageCount - 1 === index && (
                    <Grid sx={styles.lastLine}/>
                  )}
                </Grid>
              )
          )}
      </Grid>
    </>
  );
});

const styles = {
  title: {
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "120%",
    letterSpacing: "6.4px",
    textTransform: "uppercase",
    color: theme.colors.abbvie,
    alignSelf: "center",
  },
  stepper: {
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "3.2px",
    mr: 3,
  },
  line: {
    flex: "1 1 auto",
    position: "absolute",
    top: "15px",
    left: "calc(-50% + 15px)",
    right: "calc(50% + 15px)",
    display: "block",
    borderColor: "rgb(189, 189, 189)",
    borderTopStyle: "solid",
    borderTopWidth: "1px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    padding: "0 8px",
    flex: "1 1 0%",
    position: "relative",
    alignItems: "center",
  },
  step: (stage: number, currentStage: number) => ({
    display: "flex",
    paddingRight: "0",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    backgroundColor:
      stage === currentStage
        ? theme.colors.blue3
        : stage < currentStage
          ? theme.colors.green
          : theme.colors.grey2,
    alignItems: "center",
    justifyContent: "center",
  }),
  stepName: {
    textAlign: "center",
    width: "100%",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "1.6px",
    textTransform: "uppercase",
    paddingTop: "8px",
    color: theme.colors.abbvie,
  },
  lastLine: {
    flex: "1 1 auto",
    position: "absolute",
    top: "15px",
    left: "calc(50% + 15px)",
    right: "calc(-50% + 15px)",
    display: "block",
    borderColor: "rgb(189, 189, 189)",
    borderTopStyle: "solid",
    borderTopWidth: "1px",
  },
};
