import { makeAutoObservable } from "mobx";
import { IPublishingUser } from "../interfaces/admin/user/publishingUser";
import { IViewingUser } from "../interfaces/admin/user/viewingUser";
import { IOthersAdmins } from "../interfaces/admin/user/othersAdmins";
import { IRole } from "../interfaces/common/roles";
import { IUserForm } from "../interfaces/admin/user/userForm";
import { sortUsers } from "../utils";

export class UsersStore {
  private _publishingUsers: IPublishingUser[] | null = null;
  private _viewingUsers: IViewingUser[] | null = null;
  private _otherUsers: IOthersAdmins[] | null = null;

  private _isPublishingUsersLoaded: boolean = false;
  private _isViewingUsersLoaded: boolean = false;
  private _isOtherUsersLoaded: boolean = false;

  private _userEdit: IUserForm | null = null;


  constructor() {
    makeAutoObservable(this);
  }

  setPublishingUsers(publishingUsers: IPublishingUser[]) {
    this._publishingUsers = sortUsers(publishingUsers) as IPublishingUser[];
    this._isPublishingUsersLoaded = true;
  }

  setViewingUsers(viewingUsers: IViewingUser[]) {
    this._viewingUsers = viewingUsers;
    this._isViewingUsersLoaded = true;
  }

  setOtherUsers(otherUsers: IOthersAdmins[], roles: IRole[]) {
    let othersAdmins: IOthersAdmins[] = [];
    otherUsers.forEach(user => {
      const roleObject = roles.find(role => (role.id === user.roleId));
      const role = roleObject ? roleObject.title : "";
      const otherAdmin: IOthersAdmins = {...user, role};
      othersAdmins.push(otherAdmin);
    });
    this._otherUsers = sortUsers([...othersAdmins]) as IOthersAdmins[];
    this._isOtherUsersLoaded = true;
  }


  setUserEdit(user: IUserForm, id: number) {
    const userEditElement = {...user};
    userEditElement.id = id;
    this.userEdit = userEditElement;
  }

  clearUserEdit() {
    this.userEdit = null;
  }

  resetPublishingUsers() {
    this._isPublishingUsersLoaded = false;
  }

  resetViewingUsers() {
    this._isViewingUsersLoaded = false;
  }

  resetOtherUsers() {
    this._isOtherUsersLoaded = false;
  }


  get isPublishingUsersLoaded(): boolean {
    return this._isPublishingUsersLoaded;
  }

  set isPublishingUsersLoaded(value: boolean) {
    this._isPublishingUsersLoaded = value;
  }


  get publishingUsers(): IPublishingUser[] | null {
    return this._publishingUsers;
  }

  set publishingUsers(value: IPublishingUser[] | null) {
    this._publishingUsers = value;
  }

  get viewingUsers(): IViewingUser[] | null {
    return this._viewingUsers;
  }

  set viewingUsers(value: IViewingUser[] | null) {
    this._viewingUsers = value;
  }

  get otherUsers(): IOthersAdmins[] | null {
    return this._otherUsers;
  }

  set otherUsers(value: IOthersAdmins[] | null) {
    this._otherUsers = value;
  }

  get isViewingUsersLoaded(): boolean {
    return this._isViewingUsersLoaded;
  }

  set isViewingUsersLoaded(value: boolean) {
    this._isViewingUsersLoaded = value;
  }

  get isOtherUsersLoaded(): boolean {
    return this._isOtherUsersLoaded;
  }

  set isOtherUsersLoaded(value: boolean) {
    this._isOtherUsersLoaded = value;
  }


  get userEdit(): IUserForm | null {
    return this._userEdit;
  }

  set userEdit(value: IUserForm | null) {
    this._userEdit = value;
  }
}
