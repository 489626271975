import { generalLinks } from "./RoutesGuest";
import { UserLink } from "../../../../../utils";
import { OtherCases } from "../../../../pages/user/otherCases/OtherCases";
import { Home } from "../../../../pages/user/home/Home";
import { Profile } from "../../../../pages/user/profile/Profile";
import { ViewCaseCreator } from "../../../../pages/user/viewCase/viewCaseCreator/ViewCaseCreator";

export const onlyViewingUserLinks = [
  {
    path: UserLink.HOME,
    element: <Home />,
  },
  {
    path: UserLink.OTHER_CASES,
    element: <OtherCases />,
  },
  {
    path: UserLink.PROFILE,
    element: <Profile />,
  },
  {
    path: UserLink.VIEW_CASE,
    element: <ViewCaseCreator />,
  },
];

export const viewingUserLinksExtends =
  generalLinks.concat(onlyViewingUserLinks);
