import {observer} from "mobx-react";

// hooks
import {useNpsStore} from "../../../../../../../../store/hooks";

// components
import {Divider, Grid, Typography} from "@mui/material";
import {GroupForm, Label, NormalText,} from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hcv/HcvViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";
import React from "react";
import theme from "../../../../../../../../theme/theme";
import {ITreatmentLines} from "../../../../../draftCase/forms/rheumatology/nps/stages/stage03/type";

export const TreatmentProgramSection = observer(() => {
  const caseStore = useNpsStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={4}>
      <Grid item xs={12} mb={4}>
        <GroupForm>Program lekowy:</GroupForm>
      </Grid>
      <Grid container bgcolor={"white"} p={2}>
        <Label>Moment Włączenia do programu lekowego</Label>
        <Grid item xs={12} mb={4} mt={2}>
          <Label>DATA WEJŚCIA DO PROGRAMU LEKOWEGO</Label>
          <NormalText>
            {checkValueIsEmpty(dateStringify(caseElement.programEntryDate))}
          </NormalText>
        </Grid>

        <Grid container gap={"20px"} mb={4}>
          <Grid item>
            <Label>Wynik w skali Likerta pacjent</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.likertPatient3)}
            </NormalText>
          </Grid>
          <Grid item>
            <Label>Wynik w skali Likerta lekarz</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.likertDoctor3)}
            </NormalText>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={4}>
          <Label>OGÓLNA OCENA STANU CHOROBY</Label>
          <NormalText>{checkValueIsEmpty(caseElement.assessment3)}</NormalText>
        </Grid>
        <Grid container gap={"20px"}>
          <Grid item>
            <Label>lICZBA bOLESNYCH STAWÓW</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.tenderJointsCount3)}
            </NormalText>
          </Grid>
          <Grid item>
            <Label>lICZBA obrzękniętych STAWÓW</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.swollenJointsCount3)}
            </NormalText>
          </Grid>
        </Grid>
        <Grid container gap={"20px"} mt={4}>
          <Grid item>
            <Label>podaj wynik w skali</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.resultScaleOption3?.toUpperCase())}:{" "}
              {checkValueIsEmpty(caseElement.result3)}
            </NormalText>
          </Grid>
          <Grid item>
            <Label>Stopień bólu kręgosłupa</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.degreeOfBack3)}
            </NormalText>
          </Grid>
          <Grid item>
            <Label>OGÓLNA OCENA STANU CHOROBY</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.generalAssessment23)}
            </NormalText>
          </Grid>
        </Grid>
      </Grid>

      {caseElement.treatmentLines.map(
        (drug: ITreatmentLines, index: number) => (
          <Grid container mt={5} bgcolor={"white"} p={2} key={index}>
            <Grid item xs={12} mb={2}>
              <Label $size={"large"} $mb={0}>
                {`Linia leczenia ${index + 1}`}
              </Label>
            </Grid>
            <Grid container>
              <Grid item xs={3}>
                <Label>WARIANT</Label>
                <NormalText>{checkValueIsEmpty(drug.variants)}</NormalText>
              </Grid>

              {drug.tnfInhibitorType && (
                <Grid item xs={12} mt={2}>
                  <Label>Rodzaj inhibitora TNF</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.tnfInhibitorType)}
                  </NormalText>
                </Grid>
              )}

              {drug.il17InhibitorType && (
                <Grid item xs={12} mt={2}>
                  <Label>Rodzaj inhibitora IL-17</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.il17InhibitorType)}
                  </NormalText>
                </Grid>
              )}

              {drug.jakInhibitorType && (
                <Grid item xs={12} mt={2}>
                  <Label>Rodzaj inhibitora JAK</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.jakInhibitorType || "Upadacytynib")}
                  </NormalText>
                </Grid>
              )}
              <Grid item xs={12} mt={4}>
                <Label>Czas trwania
                  terapii {drug.durationUnit && drug.therapyDuration ? drug.durationUnit === 'miesiące' ? ' (w miesiącach)' : ' (w latach)' : ''}</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.therapyDuration)}
                </NormalText>
              </Grid>
              <Grid item xs={12} mt={4} mb={4}>
                <Label>Monoterapia czy kombinacja z innym lekiem?</Label>
                <NormalText>{checkValueIsEmpty(drug.monotherapy)}</NormalText>
              </Grid>
              {drug.combinationWithMedicine.length > 0 && (
                <Divider
                  style={{width: "100%"}}
                  variant={"fullWidth"}
                  sx={{height: "2px", margin: "0 0 32px 0"}}
                />
              )}
              {drug.combinationWithMedicine.map((el, idx) => (
                <Grid container key={idx}>
                  <Grid item xs={12} mb={2}>
                    <Label $size={"large"} $mb={0}>
                      {`Kombinacja ${idx + 1}`}
                    </Label>
                  </Grid>
                  <Grid container gap={2}>
                    <Grid item xs={12}>
                      <Label>Kombinacja Z jakim lekiem?</Label>
                      <NormalText>
                        {checkValueIsEmpty(el.combinationDrugName)}
                      </NormalText>
                    </Grid>
                    {el.otherCombinationDrug &&
                      el.combinationDrugName === "Inne" && (
                        <Grid item xs={12}>
                          <Label>nazwa innego leku</Label>
                          <NormalText>
                            {checkValueIsEmpty(el.otherCombinationDrug)}
                          </NormalText>
                        </Grid>
                      )}
                    <Grid item xs={12}>
                      <Label>Dawka</Label>
                      <NormalText>
                        {checkValueIsEmpty(el.combinationDose)}
                        {checkValueIsEmpty(
                          el.combinationUnit === "Inna jednostka"
                            ? el.otherCombinationUnit
                            : el.combinationUnit
                        )}
                      </NormalText>
                    </Grid>
                    <Grid item xs={12}>
                      <Label>Zakres czasu przyjmowania</Label>
                      <Typography
                        variant={"caption"}
                        color={theme.colors.orange}
                      >
                        liczba tygodni
                      </Typography>
                      <NormalText>
                        {checkValueIsEmpty(el.combinationDuration)}
                      </NormalText>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{width: "100%"}}
                    variant={"fullWidth"}
                    sx={{height: "2px", margin: "32px 0"}}
                  />
                </Grid>
              ))}

              <Grid container gap={"20px"} mb={4}>
                <Grid item>
                  <Label>Wynik w skali Likerta pacjent</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.likertPatient3followup)}
                  </NormalText>
                </Grid>
                <Grid item>
                  <Label>Wynik w skali Likerta lekarz</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.likertDoctor3followup)}
                  </NormalText>
                </Grid>
              </Grid>
              <Grid item xs={12} mb={4}>
                <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.assessment3followup)}
                </NormalText>
              </Grid>
              <Grid container gap={"20px"}>
                <Grid item>
                  <Label>lICZBA bOLESNYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.tenderJointsCount3followup)}
                  </NormalText>
                </Grid>
                <Grid item>
                  <Label>lICZBA obrzękniętych STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.swollenJointsCount3followup)}
                  </NormalText>
                </Grid>
              </Grid>
              <Grid container gap={"20px"} mt={4}>
                <Grid item>
                  <Label>podaj wynik w skali</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.resultScaleOption3followup?.toUpperCase())}:{" "}
                    {checkValueIsEmpty(drug.result3followup)}
                  </NormalText>
                </Grid>
                <Grid item>
                  <Label>Stopień bólu kręgosłupa</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.degreeOfBack3followup)}
                  </NormalText>
                </Grid>
                <Grid item>
                  <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                  <NormalText>
                    {checkValueIsEmpty(drug.generalAssessment23followup)}
                  </NormalText>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={4}>
                <Label>Czy były objawy nietolerancji wybranego leku?</Label>
                <NormalText>
                  {checkValueIsEmpty(drug.symptomsOfIntolerance)}
                </NormalText>
              </Grid>
            </Grid>
          </Grid>
        )
      )}

      <Grid container bgcolor={"white"} p={2} mb={4} mt={4}>
        <Grid item>
          <Label>Glikokortykosteroidy (GKS)</Label>
          <NormalText>{checkValueIsEmpty(caseElement.gks3)}</NormalText>
        </Grid>
        {caseElement.gks3 === "tak" && (
          <Grid item>
            <Label>Rodzaj GKS</Label>
            <NormalText>{checkValueIsEmpty(caseElement.gksType3)}</NormalText>
          </Grid>
        )}
        {caseElement.gksType3 === "iniekcja" && (
          <Grid item>
            <Label>Jednokrotne miejscowe podania GKS</Label>
            <NormalText>{checkValueIsEmpty(caseElement.singleGks3)}</NormalText>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});
