import {useRootStore} from '../RootStateContext';

export const useProfileStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.profileStore) {
    throw new Error('profileStore store should be defined');
  }

  return rootStore.profileStore;
};
