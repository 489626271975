import { Container } from "./AuthLabel.styled";
import React from "react";

interface IProps {
  title: string
  bcg: string
  subTitle?: string
}

export const AuthLabel: React.FC<IProps> = ({title, bcg, subTitle}) => {
  return (
    <Container $bcg={bcg}>{title}
      <span>{subTitle}</span>
    </Container>
  )
}