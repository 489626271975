import { observer } from "mobx-react";
// hooks
import { useSetAreaAndFormViewModel } from "./useSetAreaAndForm.vm";

// components
import { UserLayout } from "../../../../shared/user/layout/UserLayout";
import { Grid, Typography } from "@mui/material";

// image
import documents from "../../../../../assets/icon/documents.svg";
import theme from "../../../../../theme/theme";
import { Select } from "../../../../shared/ui/select/Select";
import { Button } from "../../../../shared/button/Button";

export const SetAreaAndForm = observer(() => {
  const {
    areaOptions,
    formOptions,
    currentAreaId,
    currentFormId,
    setCurrentAreaId,
    setCurrentFormId,
    homePageRedirectHandler,
    newCaseRedirectHandler,
  } = useSetAreaAndFormViewModel();

  return (
    <UserLayout>
      <Grid container display="flex" justifyContent="center">
        <Grid
          container
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="100%"
          width="480px"
        >
          <img
            src={documents}
            width="120px"
            height="120px"
            alt="documents"
            style={{ marginTop: "50px" }}
          />
          <Typography variant="h3" mt={2} sx={styles.paragraph}>
            Wybierz Wskazanie
          </Typography>
          <Typography sx={styles.description}>
            {areaOptions && areaOptions.length > 1
              ? "Aby utworzyć nowy opis przypadku medycznego należy wybrać obszar terapeutycznny i wskazanie, który go dotyczy."
              : "Aby utworzyć nowy opis przypadku medycznego należy wybrać wskazanie, który go dotyczy."}
          </Typography>

          {areaOptions && areaOptions?.length > 1 && (
            <Select
              selectValue={currentAreaId ?? -1}
              defaultValue="Wybierz"
              elementName={"areaId"}
              isRequired={true}
              disabled={areaOptions === null}
              setValue={setCurrentAreaId}
              optionsArray={areaOptions || []}
              containerStyle={{
                width: "100%",
                marginTop: "32px",
              }}
              background={"white"}
              label={"Obszar terapeutyczny"}
            />
          )}

          {currentAreaId !== null && (
            <Select
              selectValue={currentFormId ?? -1}
              defaultValue="Wybierz"
              elementName={"formId"}
              isRequired={true}
              setValue={setCurrentFormId}
              optionsArray={formOptions || []}
              disabled={formOptions === null || formOptions?.length === 1}
              containerStyle={{
                width: "100%",
                marginTop: "32px",
              }}
              background={"white"}
              label={"wskazanie"}
            />
          )}
          {formOptions && formOptions.length === 1 && (
            <Typography variant="body1" sx={styles.warningText}>
              Istnieje tylko jedno wskazanie w obszarze
            </Typography>
          )}

          {/* Button block */}
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={3}
          >
            <Button
              clickHandler={homePageRedirectHandler}
              text={"anuluj"}
              backgroundColor={theme.colors.white}
              hoverColor={theme.colors.blue4}
              color={theme.colors.grey1}
              width={"125px"}
              size={"tiny"}
            />
            <Button
              text={"Dalej >"}
              backgroundColor={theme.colors.abbvie}
              hoverColor={theme.colors.lightAbbvie}
              color={theme.colors.white}
              width={"125px"}
              size={"tiny"}
              clickHandler={newCaseRedirectHandler}
            />
          </Grid>
        </Grid>
      </Grid>
    </UserLayout>
  );
});

const styles = {
  paragraph: {
    color: theme.colors.abbvie,
    mt: 2,
    fontFamily: "Lato",
    textTransform: "uppercase",
    fontWeight: 800,
    fontSize: "32px",
    lineHeight: "38px",
    letterSpacing: "3px",
  },
  description: {
    textAlign: "center",
    color: theme.colors.color4,
    mt: 2,
  },
  warningText: {
    fontSize: "12px",
    fontWeight: 700,
    letterSpacing: "1.2px",
    mt: 1,
    color: theme.colors.orange,
    alignSelf: "flex-start",
  },
};
