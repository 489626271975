import {useLocation, useNavigate} from 'react-router-dom';

import {UserTop} from '../../../appLayer/userTop/UserTop';
import {Button} from '../../../button/Button';
import {navigation} from './Header.helper';
import {useAuthStore} from '../../../../../store/hooks';
import {checkPermission} from '../../../../../utils/checkPermission';
import {HeaderStyled} from './Header.styled';
import theme from '../../../../../theme/theme';

export const Header = () => {
  const authStore = useAuthStore();
  const {roleFunction} = authStore;
  const navigate = useNavigate();
  const lacation = useLocation();

  return <HeaderStyled>
    <nav>
      {navigation.map(element => checkPermission(roleFunction, element.roles) && <Button fontWeight={300} size={'tiny'} width={'263px'} backgroundColor={lacation.pathname === element.path ? theme.colors.green : theme.colors.abbvie} color={theme.colors.white} clickHandler={() => navigate(element.path)} key={element.title} text={element.title}/>)}
    </nav>
    <UserTop/>
  </HeaderStyled>
}