import * as yup from 'yup'
import { useFormik } from 'formik';
import { MouseEvent } from "react";
import { ValidationMessages } from '../../../../../constants/validationMessages'
import { useAuthApi } from "../../../../../apiHooks";
import { useNavigate } from "react-router-dom";
import { GeneralLink } from "../../../../../utils";

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, ValidationMessages.WEAK_PASSWORD)
    .required(ValidationMessages.EMPTY_FIELD),
  repeatPassword: yup
    .string()
    .test('passwords-match', ValidationMessages.INVALID_PASSWORD, function (value) {
      return this.parent.password === value
    })
    .required(ValidationMessages.EMPTY_FIELD),
  termsAndConditions: yup.boolean().oneOf([true])
})

export const useChangePasswordForm = () => {
  const {changePassword} = useAuthApi()
  const navigate = useNavigate()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      repeatPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await changePassword(values.password)

      if (res) {
        formik.resetForm()
        navigate(GeneralLink.LOGIN)
      }
    }
  })

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  }

  return {formik, confirmForm}
}
  
  

