import {makeAutoObservable} from "mobx";
import {THcv} from "../../components/pages/user/draftCase/forms/virusology/hcv/type";
import {THcvStage01} from "../../components/pages/user/draftCase/forms/virusology/hcv/stages/stage01/type";
import {THcvStage02} from "../../components/pages/user/draftCase/forms/virusology/hcv/stages/stage02/type";
import {THcvStage03} from "../../components/pages/user/draftCase/forms/virusology/hcv/stages/stage03/type";
import {THcvStage04} from "../../components/pages/user/draftCase/forms/virusology/hcv/stages/stage04/type";

export class HcvStore {
  case: THcv | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCase(value: THcv) {
    this.case = value;
  }

  get caseStage() {
    return this.case?.step;
  }

  get firstStage() {
    return this.case as THcvStage01;
  }

  get secondStage() {
    return this.case as THcvStage02;
  }

  get thirdStage() {
    return this.case as THcvStage03;
  }

  get fourthStage() {
    return this.case as THcvStage04;
  }

  resetCase() {
    this.case = null;
  }
}
