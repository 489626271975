import { makeAutoObservable } from "mobx";

import { ICase } from "../interfaces/user/draftCase/draft";

export class CaseStore {
  case: ICase | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCase(value: ICase | null) {
    this.case = value;
  }

  resetCase() {
    this.case = null;
  }
}
