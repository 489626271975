import * as yup from "yup";
// hooks
import { useFormik } from "formik";
import { useCaseSettingsStore, useRzsStore, } from "../../../../../../../../../store/hooks";
// types
import { TRzsStage04 } from "./type";
import { EnAreaNames, EnFormNames, } from "../../../../../../../../../store/caseSettingsStore";
import { useParams } from "react-router-dom";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";

const validationSchema = yup.object().shape({
  wasUsed: yup.string().required(),
  drugInitiationDate: yup.string().required(),
  das28Result3: yup.string().required(),
  result3: yup.number().required(),
  vasResult3: yup.number().required(),
  obResult3: yup
    .number()
    .nullable()
    .when("das28Result3", {
      is: (value: string) => value === "crp",
      then: (schema) => schema.required(),
    }),
  crpResult3: yup
    .number()
    .nullable()
    .when("das28Result3", {
      is: (value: string) => value === "ob",
      then: (schema) => schema.required(),
    }),
  tenderJointsCount3: yup.number().min(0).integer().required(),
  swollenJointsCount3: yup.number().min(0).integer().required(),
  observationIntervals: yup.string().required(),
  observations: yup
    .array()
    .of(
      yup.object().shape({
        monotherapy: yup.string().required(),
        combinationWithMedicine: yup
          .array()
          .of(
            yup.object().shape({
              combinationDrugName: yup.string().required(),
              otherCombinationDrug: yup.string().when("combinationDrugName", {
                is: (value: string) => value === "Inne",
                then: (schema) => schema.required(),
              }),
              combinationDose: yup.number().required(),
              combinationUnit: yup.string().required(),
              otherCombinationUnit: yup.string().when("combinationUnit", {
                is: (value: string) => value === "Inna jednostka",
                then: (schema) => schema.required(),
              }),
              combinationDuration: yup.number().integer().required(),
            })
          )
          .when("monotherapy", {
            is: (value: string) => value === "kombinacja",
            then: (schema) => schema.min(1).required(),
          }),
        das28Result: yup.string().required(),
        result: yup.number().required(),
        vasResult: yup.number().required(),
        obResult: yup
          .number()
          .nullable()
          .when("das28Result", {
            is: (value: string) => value === "crp",
            then: (schema) => schema.required(),
          }),
        crpResult: yup
          .number()
          .nullable()
          .when("das28Result", {
            is: (value: string) => value === "ob",
            then: (schema) => schema.required(),
          }),
        tenderJointsCount: yup.number().min(0).required(),
        swollenJointsCount: yup.number().min(0).required(),
        psoriasisChanges: yup.string().nullable(),
      })
    )
    .when("observationIntervals", {
      is: (value: string) => value === "co 1 msc.",
      then: (schema) => schema.length(6).required(),
    })
    .when("observationIntervals", {
      is: (value: string) => value === "co 3 msc.",
      then: (schema) => schema.length(2).required(),
    })
    .when("observationIntervals", {
      is: (value: string) => value === "co 6 msc.",
      then: (schema) => schema.length(1).required(),
    }),
  gks: yup.string().required(),
});

const initialValues: TRzsStage04 = {
  wasUsed: '',
  drugInitiationDate: null,
  das28Result3: "",
  result3: null,
  vasResult3: null,
  obResult3: null,
  crpResult3: null,
  tenderJointsCount3: null,
  swollenJointsCount3: null,
  observationIntervals: "",
  observations: [{
    monotherapy: "",
    combinationWithMedicine: [],
    das28Result: "",
    result: null,
    vasResult: null,
    obResult: null,
    crpResult: null,
    tenderJointsCount: null,
    swollenJointsCount: null,
    psoriasisChanges: '',
  }],
  gks: ''
};

export const useStage04ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const rzsStore = useRzsStore();
  const {fourthStage, caseStage, thirdStage} = rzsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(fourthStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TRzsStage04>({
    initialValues: {
      ...valuesObj,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue, setValues} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`observations[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `observations[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const observationObject = {
    monotherapy: "",
    combinationWithMedicine: [],
    das28Result: "",
    result: null,
    vasResult: null,
    obResult: null,
    crpResult: null,
    tenderJointsCount: null,
    swollenJointsCount: null,
    psoriasisChanges: '',
  }

  const modifyObservations = (value: string) => {
    switch (value) {
      case "co 1 msc.":
        const additionalObservations = 6 - formik.values.observations.length;

        const additionalObjects = Array.from(
          {length: additionalObservations},
          () => JSON.parse(JSON.stringify(observationObject))
        );
        formik.setFieldValue("observations", [
          ...formik.values.observations,
          ...additionalObjects,
        ]);
        return;
      case "co 3 msc.":
        const additionalObservations3 = 2 - formik.values.observations.length;

        if (formik.values.observations.length >= 2) {
          const newObservations = [...formik.values.observations.slice(0, 2)];
          formik.setFieldValue("observations", [...newObservations]);
        } else {
          const additionalObjects3 = Array.from(
            {length: additionalObservations3},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          formik.setFieldValue("observations", [
            ...formik.values.observations,
            ...additionalObjects3,
          ]);
        }
        return;
      case "co 6 msc.":
        const additionalObservations6 = 1 - formik.values.observations.length;
        if (formik.values.observations.length >= 1) {
          const newObservations = [...formik.values.observations.slice(0, 1)];
          formik.setFieldValue("observations", [...newObservations]);
        } else {
          const additionalObjects6 = Array.from(
            {length: additionalObservations6},
            () => JSON.parse(JSON.stringify(observationObject))
          );
          formik.setFieldValue("observations", [
            ...formik.values.observations,
            ...additionalObjects6,
          ]);
        }
        //formik.validateForm().then();
        return;
    }
  };

  const addCombination = (index: number) => {
    setFieldValue(`observations[${index}].combinationWithMedicine`, [
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const deleteCombination = (index: number, idx: number) => {
    const filteredItems = [
      ...formik.values.observations[index].combinationWithMedicine.filter(
        (el, i) => idx !== i
      ),
    ];
    setFieldValue(
      `observations[${index}].combinationWithMedicine`,
      filteredItems
    );
  };

  const addOtherCombination = (index: number) => {
    setFieldValue(`observations[${index}].combinationWithMedicine`, [
      ...formik.values.observations[index].combinationWithMedicine,
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const handleYes = () => {
    setValues({
      ...formik.values,
      drugInitiationDate: thirdStage.programEntryDate,
      das28Result3: thirdStage.das28ResultMoi,
      result3: thirdStage.resultMoi,
      vasResult3: thirdStage.vasResultMoi,
      obResult3: thirdStage.obResultMoi,
      crpResult3: thirdStage.crpResultMoi,
      tenderJointsCount3: thirdStage.tenderJointsCountMoi,
      swollenJointsCount3: thirdStage.swollenJointsCountMoi,
      observations: formik.values.observations?.length > 0 ? [
        {
          ...formik.values.observations[0],
          monotherapy: thirdStage.treatmentLines[0].monotherapy,
          das28Result: thirdStage.treatmentLines[0].das28FollowupResult,
          result: thirdStage.treatmentLines[0].followupResult,
          vasResult: thirdStage.treatmentLines[0].followupVasResult,
          obResult: thirdStage.treatmentLines[0].followupObResult,
          crpResult: thirdStage.treatmentLines[0].followupCrpResult,
          tenderJointsCount: thirdStage.treatmentLines[0].followupTenderJointsCount,
          swollenJointsCount: thirdStage.treatmentLines[0].followupSwollenJointsCount,
          combinationWithMedicine: thirdStage.treatmentLines[0]?.combinationWithMedicine?.length > 0 ? [
            {
              ...thirdStage.treatmentLines[0]?.combinationWithMedicine[0],
            },
            ...formik.values.observations[0]?.combinationWithMedicine?.slice(1) || []
          ] : []
        },
        ...formik.values.observations.slice(1) // Kopiowanie reszty elementów bez zmian
      ] : [{
        ...formik.values.observations[0],
        monotherapy: thirdStage.treatmentLines[0].monotherapy,
        das28Result: thirdStage.treatmentLines[0].das28FollowupResult,
        result: thirdStage.treatmentLines[0].followupResult,
        vasResult: thirdStage.treatmentLines[0].followupVasResult,
        obResult: thirdStage.treatmentLines[0].followupObResult,
        crpResult: thirdStage.treatmentLines[0].followupCrpResult,
        tenderJointsCount: thirdStage.treatmentLines[0].followupTenderJointsCount,
        swollenJointsCount: thirdStage.treatmentLines[0].followupSwollenJointsCount,
        combinationWithMedicine: thirdStage.treatmentLines[0]?.combinationWithMedicine?.length > 0 ? [
          {
            ...thirdStage.treatmentLines[0]?.combinationWithMedicine[0],
          },
          ...formik.values.observations[0]?.combinationWithMedicine?.slice(1) || []
        ] : []
      }]
    })
  }

  const handleNo = () => {
    setValues({
      ...formik.values,
      drugInitiationDate: null,
      das28Result3: "",
      result3: null,
      vasResult3: null,
      obResult3: null,
      crpResult3: null,
      tenderJointsCount3: null,
      swollenJointsCount3: null,
      observations: formik.values.observations?.length > 0 ? [
        {
          ...formik.values.observations[0],
          monotherapy: '',
          das28Result: '',
          result: null,
          vasResult: null,
          obResult: null,
          crpResult: null,
          tenderJointsCount: null,
          swollenJointsCount: null,
          combinationWithMedicine: [
            {
              combinationDrugName: "",
              otherCombinationDrug: "",
              combinationDose: null,
              combinationUnit: "",
              otherCombinationUnit: "",
              combinationDuration: null,
            },
            ...formik.values.observations[0]?.combinationWithMedicine.slice(1)
          ]
        },
        ...formik.values.observations.slice(1) // Kopiowanie reszty elementów bez zmian
      ] : []
    })
  }

  return {
    formik,
    setOption,
    modifyObservations,
    addCombination,
    deleteCombination,
    addOtherCombination,
    setCombinationOption,
    handleYes,
    handleNo
  };
};
