import { Grid } from "@mui/material";
import theme from "../../../../../theme/theme";
import { Button } from "../../../../shared/button/Button";
import React, { FC } from "react";
import { ButtonContainer } from "./EditProfile.styled";
import { Input } from "../../../../shared/ui/input/Input";
import { useEditProfile } from "./useEditProfile.vm";
import { Modal } from "../../../../shared/modal/Modal";

interface IProps {
  closeModal: () => void;
}

export const EditProfile: FC<IProps> = ({ closeModal }) => {
  const { formik } = useEditProfile(closeModal);

  return (
    <Modal clickHandler={() => closeModal()}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          maxWidth={"345px"}
        >
          <Grid
            container
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Grid item xs={5}>
              <Input
                color={"black"}
                label={"Numer PWZ"}
                name={"pwz"}
                type={"text"}
                value={String(formik.values.pwz)}
                onChange={formik.handleChange}
                borderColor={theme.colors.grey4}
                placeholderColor={theme.colors.grey1}
                colorLabel={theme.colors.grey2}
                background={theme.colors.white}
                placeholder={"Wpisz numer PWZ..."}
                isError={formik.touched.pwz && Boolean(formik.errors.pwz)}
              />
            </Grid>

            <Grid item xs={6}>
              <Input
                color={"black"}
                label={"Afiliacja"}
                name={"affiliation"}
                type={"text"}
                value={formik.values.affiliation}
                onChange={formik.handleChange}
                placeholder={""}
                borderColor={theme.colors.grey4}
                placeholderColor={theme.colors.grey1}
                colorLabel={theme.colors.grey2}
                background={theme.colors.white}
                isError={
                  formik.touched.affiliation &&
                  Boolean(formik.errors.affiliation)
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Input
              color={"black"}
              label={"Imię i nazwisko"}
              name={"fullName"}
              type={"text"}
              borderColor={theme.colors.grey4}
              placeholderColor={theme.colors.grey1}
              colorLabel={theme.colors.grey2}
              background={theme.colors.white}
              value={formik.values.fullName}
              onChange={formik.handleChange}
              placeholder={"Wpisz imię i nazwisko..."}
              isError={
                formik.touched.fullName && Boolean(formik.errors.fullName)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              color={"black"}
              label={"Email"}
              name={"email"}
              type={"text"}
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder={"Wpisz email..."}
              borderColor={theme.colors.grey4}
              placeholderColor={theme.colors.grey1}
              colorLabel={theme.colors.grey2}
              background={theme.colors.white}
              isError={formik.touched.email && Boolean(formik.errors.email)}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              color={"black"}
              label={"Telefon"}
              name={"phone"}
              type={"text"}
              value={formik.values.phone}
              onChange={formik.handleChange}
              placeholder={"Wpisz numer kontaktowy..."}
              borderColor={theme.colors.grey4}
              placeholderColor={theme.colors.grey1}
              colorLabel={theme.colors.grey2}
              background={theme.colors.white}
              isError={formik.touched.phone && Boolean(formik.errors.phone)}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              color={"black"}
              label={"Miejsce pracy"}
              name={"workPlace"}
              type={"text"}
              value={formik.values.workPlace}
              onChange={formik.handleChange}
              placeholder={"Wpisz miejsce pracy..."}
              borderColor={theme.colors.grey4}
              placeholderColor={theme.colors.grey1}
              colorLabel={theme.colors.grey2}
              background={theme.colors.white}
              isError={
                formik.touched.workPlace && Boolean(formik.errors.workPlace)
              }
            />
          </Grid>
          <ButtonContainer>
            <Button
              clickHandler={closeModal}
              text={"anuluj"}
              backgroundColor={theme.colors.white}
              hoverColor={theme.colors.blue4}
              color={theme.colors.grey1}
              width={"125px"}
              size={"tiny"}
            />
            <Button
              text={"zapisz"}
              backgroundColor={theme.colors.abbvie}
              hoverColor={theme.colors.lightAbbvie}
              type={"submit"}
              color={theme.colors.white}
              width={"125px"}
              size={"tiny"}
            />
          </ButtonContainer>
        </Grid>
      </form>
    </Modal>
  );
};
