import React from "react";
import { observer } from "mobx-react";

// hooks
import { useNpsStore } from "../../../../../../../../store/hooks";

// components
import { Grid } from "@mui/material";
import {
  GroupForm,
  Label,
  NormalText,
} from "../../../virusology/hcv/HcvViewCase.styled";
import { Attachments } from "../../../virusology/hcv/common/attachments/Attachments";

// lib
import { checkValueIsEmpty } from "../../../virusology/hcv/HcvViewCase.helper";
import { dateStringify } from "../../../../../../../../utils";
import { IObservations } from "../../../../../draftCase/forms/rheumatology/nps/stages/stage04/type";

export const Observations = observer(() => {
  const caseStore = useNpsStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={8}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Rinvoq:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Label>Wyniki badań przed włączeniem leku Rinvoq</Label>
          <Grid item xs={12} mb={4} mt={2}>
            <Label>Data włączenia leku</Label>
            <NormalText>
              {checkValueIsEmpty(dateStringify(caseElement.drugInitiationDate))}
            </NormalText>
          </Grid>
          <Grid container gap={"20px"} mb={4}>
            <Grid item>
              <Label>Wynik w skali Likerta pacjent</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.likertPatient4)}
              </NormalText>
            </Grid>
            <Grid item>
              <Label>Wynik w skali Likerta lekarz</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.likertDoctor4)}
              </NormalText>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={4}>
            <Label>OGÓLNA OCENA STANU CHOROBY</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.assessment4)}
            </NormalText>
          </Grid>
          <Grid container gap={"20px"}>
            <Grid item>
              <Label>lICZBA bOLESNYCH STAWÓW</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.tenderJointsCount4)}
              </NormalText>
            </Grid>
            <Grid item>
              <Label>lICZBA obrzękniętych STAWÓW</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.swollenJointsCount4)}
              </NormalText>
            </Grid>
          </Grid>
          <Grid container gap={"20px"} mt={4}>
            <Grid item>
              <Label>podaj wynik w skali</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.resultScaleOption4?.toUpperCase())}:{" "}
                {checkValueIsEmpty(caseElement.result4)}
              </NormalText>
            </Grid>
            <Grid item>
              <Label>Stopień bólu kręgosłupa</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.degreeOfBack4)}
              </NormalText>
            </Grid>
            <Grid item>
              <Label>OGÓLNA OCENA STANU CHOROBY</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.generalAssessment24)}
              </NormalText>
            </Grid>
          </Grid>
        </Grid>

        <Grid container bgcolor={"white"} p={2} mt={5}>
          <Label>Obserwacja na leku Rinvoq</Label>
          <Grid item xs={12} mt={2}>
            <Label>Interwały obserwacji</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.observationIntervals)}
            </NormalText>
          </Grid>
        </Grid>

        {caseElement.observations.map(
          (observation: IObservations, index: number) => (
            <Grid container mt={5} bgcolor={"white"} p={2} key={index}>
              <Grid item xs={12} mb={2}>
                <Label $size={"large"} $mb={0}>
                  {`Obserwacja ${index + 1}`}
                </Label>
              </Grid>
              <Grid container gap={"20px"} mb={4}>
                <Grid item>
                  <Label>Wynik w skali Likerta pacjent</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.likertPatient4followup)}
                  </NormalText>
                </Grid>
                <Grid item>
                  <Label>Wynik w skali Likerta lekarz</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.likertDoctor4followup)}
                  </NormalText>
                </Grid>
              </Grid>
              <Grid item xs={12} mb={4}>
                <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                <NormalText>
                  {checkValueIsEmpty(observation.assessment4followup)}
                </NormalText>
              </Grid>
              <Grid container gap={"20px"}>
                <Grid item>
                  <Label>lICZBA bOLESNYCH STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.tenderJointsCount4followup)}
                  </NormalText>
                </Grid>
                <Grid item>
                  <Label>lICZBA obrzękniętych STAWÓW</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.swollenJointsCount4followup)}
                  </NormalText>
                </Grid>
              </Grid>
              <Grid container gap={"20px"} mt={4}>
                <Grid item>
                  <Label>podaj wynik w skali</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.resultScaleOption4followup?.toUpperCase())}:{" "}
                    {checkValueIsEmpty(observation.result4followup)}
                  </NormalText>
                </Grid>
                <Grid item>
                  <Label>Stopień bólu kręgosłupa</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.degreeOfBack4followup)}
                  </NormalText>
                </Grid>
                <Grid item>
                  <Label>OGÓLNA OCENA STANU CHOROBY</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.generalAssessment24followup)}
                  </NormalText>
                </Grid>
              </Grid>
              <Grid container gap={'20px'} mt={4}>
                <Grid item xs={3}>
                  <Label>wynik w skali pasi</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.pasi4followup)}
                  </NormalText>
                </Grid>
                <Grid item xs={3}>
                  <Label>wynik w skali DLQI</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.dlqi4followup)}
                  </NormalText>
                </Grid>
                <Grid item xs={3}>
                  <Label>wynik w skali BSA</Label>
                  <NormalText>
                    {checkValueIsEmpty(observation.bsa4followup)}
                  </NormalText>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
        <Grid container bgcolor={"white"} p={2}>
          <Grid container gap={"20px"} mb={4}>
            <Grid item>
              <Label>Glikokortykosteroidy (GKS)</Label>
              <NormalText>{checkValueIsEmpty(caseElement.gks4)}</NormalText>
            </Grid>
            {caseElement.gks4 === "tak" && (
              <Grid item>
                <Label>Rodzaj GKS</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.gksType4)}
                </NormalText>
              </Grid>
            )}
            {caseElement.gksType4 === "iniekcja" && (
              <Grid item>
                <Label>Jednokrotne miejscowe podania GKS</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.singleGks4)}
                </NormalText>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={8} mb={5}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Wnioski:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Grid item xs={12} sx={{whiteSpace: 'pre-line'}}>
            <NormalText>
              {checkValueIsEmpty(caseElement.conclusions)}
            </NormalText>
          </Grid>
        </Grid>
        {caseElement.files && caseElement.files.length > 0 && (
          <Grid container bgcolor={"white"} p={2} mt={3}>
            <Grid item xs={12}>
              <Attachments
                title={"Zdjęcia przykładowych zmian skórnych lub obrazowania"}
                attachments={caseElement.files}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
});
