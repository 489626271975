import styled from 'styled-components';

export const TextModal = styled.div`
  & p:first-child {
    font-size: 20px;
    line-height: 27px;
  }

  & p:nth-child(2) {
    margin-top: 15px;
    font-size: 14px;
    line-height: 19px;
  }
`