import styled from 'styled-components';
import theme from '../../../../theme/theme';

export const MultipleSelectStringStyled = styled.div`
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  & select {
    margin-top: 4px;
    padding: 4px 8px 4px 16px;
    z-index: 91;
  }

  svg {
    color: rgba(0, 0, 0, 1);
    margin-right: 10px;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  & h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  & .MuiInputBase-input {
    padding: 0 22px 0 10px !important;
  }

  & .MuiList-root .MuiList-padding {
    border-bottom: 1px solid ${theme.colors.grey1};
  }
`;

export const RedSpan = styled.span`
  color: ${theme.colors.red};
`;

export const Label = styled.label<{ color?: string, size?: string }>`
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: ${props => props.size === 'small' ? '14px' : props.size === 'superSmall' ? '12px' : '20px'};
  color: ${props => props.color ? props.color : theme.colors.grey1};
`;