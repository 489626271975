import { IOthersAdmins } from "../interfaces/admin/user/othersAdmins";
import { IPublishingUser } from "../interfaces/admin/user/publishingUser";
import { IViewingUser } from "../interfaces/admin/user/viewingUser";
import { IAreas } from "../interfaces/common/areas";
import { IOption } from "../interfaces/common/form/option";
import { IRole } from "../interfaces/common/roles";

export const filterUsers = (
  users: IOthersAdmins[] | IPublishingUser[] | IViewingUser[] | null,
  selectAreaValue: number,
  selectRoleValue: number = -1
) => {
  if (users === null) {
    return [];
  }
  let newUsers = [...users];

  newUsers =
    selectRoleValue !== -1
      ? newUsers.filter((userData) => userData.roleId === selectRoleValue)
      : newUsers;

  newUsers =
    selectAreaValue !== -1
      ? newUsers.filter((userData) => {
          return Boolean(
            userData.areas.find((area) => {
              if (area.id === selectAreaValue) {
                return true;
              }
              return false;
            })
          );
        })
      : newUsers;
  return newUsers;
};

export const generateRoleOptions = (
  roles?: IRole[] | null,
  otherUsersList?: IOthersAdmins[] | null
) => {
  const selectOptions: IOption[] = [{ id: -1, title: "Rola" }];
  if (roles && otherUsersList) {
    otherUsersList.forEach((user) => {
      const roleFind = roles.find((role) => role.id === user.roleId);
      if (roleFind) {
        if (!selectOptions.find((option) => option.id === roleFind.id)) {
          selectOptions.push({ id: roleFind.id, title: roleFind.title });
        }
      }
    });
  }

  return selectOptions;
};

export const generateAreaOptions = (areas?: IAreas[] | null) => {
  const selectOptions: IOption[] = [{ id: -1, title: "Obszar terapeutyczny" }];

  areas?.forEach((area) => {
    selectOptions.push({ id: area.id, title: area.title });
  });
  return selectOptions;
};
