import { observer } from "mobx-react";
import React from "react";

// hooks
import { useNpsStore } from "../../../../../../../../store/hooks";

// components
import { Grid, Typography } from "@mui/material";
import { GroupForm, Label, NormalText } from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import { checkValueIsEmpty } from "../../../virusology/hcv/HcvViewCase.helper";
import { dateStringify } from "../../../../../../../../utils";
import theme from "../../../../../../../../theme/theme";

export const CurrentTreatmentSection = observer(() => {
  const caseStore = useNpsStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={8}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Dotychczasowe leczenie:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2} mb={5}>
          <Grid item xs={12} mb={4}>
            <Label>DATA ROZPOZNANIA</Label>
            <NormalText>
              {checkValueIsEmpty(dateStringify(caseElement.diagnosisDate))}
            </NormalText>
          </Grid>
          <Grid item xs={12} mb={4}>
            <Label>Dominujące objawy</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.symptoms)}
            </NormalText>
          </Grid>
          <Grid item xs={12} mb={4}>
            <Label>Rodzaj leczenia</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.treatmentOption)}
            </NormalText>
          </Grid>
          {caseElement.treatmentOption === 'Metotreksat' && <Grid item xs={12} mb={4}>
            <Label $mb={0}>Dawkowanie metotreksat</Label>
            <Typography variant={'caption'} color={theme.colors.orange}>mg/tydzień</Typography>
            <NormalText>
              {checkValueIsEmpty(caseElement.metotreksatDose)}
            </NormalText>
          </Grid>}
          {caseElement.treatmentOption === 'Sulfasalazyna' && <Grid item xs={12} mb={4}>
            <Label $mb={0}>Dawkowanie sulfasalazyna</Label>
            <Typography variant={'caption'} color={theme.colors.orange}>mg/tydzień</Typography>
            <NormalText>
              {checkValueIsEmpty(caseElement.sulfasalazynaDose)}
            </NormalText>
          </Grid>}
          <Grid container gap={'20px'} mb={4}>
            <Grid item>
              <Label>Glikokortykosteroidy (GKS)</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.gks)}
              </NormalText>
            </Grid>
            {caseElement.gks === 'tak' && <Grid item>
              <Label>Rodzaj GKS</Label>
              <NormalText>{checkValueIsEmpty(caseElement.gksType)}</NormalText>
            </Grid>}
            {caseElement.gksType === 'iniekcja' && <Grid item>
              <Label>Jednokrotne miejscowe podania GKS</Label>
              <NormalText>{checkValueIsEmpty(caseElement.singleGks)}</NormalText>
            </Grid>}
          </Grid>
          <Grid container gap={'20px'} mb={4}>
            <Grid item>
              <Label>Wynik w skali Likerta pacjent</Label>
              <NormalText>{checkValueIsEmpty(caseElement.likertPatient)}</NormalText>
            </Grid>
            <Grid item>
              <Label>Wynik w skali Likerta lekarz</Label>
              <NormalText>{checkValueIsEmpty(caseElement.likertDoctor)}</NormalText>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Label>OGÓLNA OCENA STANU CHOROBY</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.assessment)}
            </NormalText>
          </Grid>
          <Grid container gap={'20px'} mt={4}>
            <Grid item>
              <Label>lICZBA bOLESNYCH STAWÓW</Label>
              <NormalText>{checkValueIsEmpty(caseElement.tenderJointsCount)}</NormalText>
            </Grid>
            <Grid item>
              <Label>lICZBA obrzękniętych STAWÓW</Label>
              <NormalText>{checkValueIsEmpty(caseElement.swollenJointsCount)}</NormalText>
            </Grid>
          </Grid>
          <Grid container gap={'20px'} mt={4}>
            <Grid item>
              <Label>podaj wynik w skali</Label>
              <NormalText>{checkValueIsEmpty(caseElement.resultScaleOption?.toUpperCase())}: {' '}{checkValueIsEmpty(caseElement.result)}</NormalText>
            </Grid>
            <Grid item>
              <Label>Stopień bólu kręgosłupa</Label>
              <NormalText>{checkValueIsEmpty(caseElement.degreeOfBack)}</NormalText>
            </Grid>
            <Grid item>
              <Label>OGÓLNA OCENA STANU CHOROBY</Label>
              <NormalText>{checkValueIsEmpty(caseElement.generalAssessment2)}</NormalText>
            </Grid>
          </Grid>
        </Grid>
        <Grid container bgcolor={"white"} p={2} mb={5}>
          <Grid item>
            <Label>Komentarz istotny klinicznie</Label>
            <NormalText>{checkValueIsEmpty(caseElement.relevantComment)}</NormalText>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
