import * as yup from "yup";
// hooks
import {useFormik} from "formik";
import {useCaseSettingsStore, useLzssStore,} from "../../../../../../../../../store/hooks";
// types
import {TLzsSkyriziStage03} from "./type";
import {EnAreaNames, EnFormNames,} from "../../../../../../../../../store/caseSettingsStore";
import {useParams} from "react-router-dom";
import {useCaseApi} from "../../../../../../../../../apiHooks";
import {useButtonsEffects} from "../../../../hooks/useButtonsEffects";

const createValidationSchema = (lzsCharacter: string) => {
  return yup.object().shape({
    programEntryDate: yup.string().required(),
    criteriaPsarc3: yup
      .string()
      .nullable(),
    das28Result3: yup.string().nullable().when('criteriaPsarc3', {
      is: (value: string) => value === 'das 28',
      then: (schema) => schema.required()
    }),
    result3: yup.number().nullable().when('criteriaPsarc3', {
      is: (value: string) => value === 'das 28',
      then: (schema) => schema.required()
    }),
    likertPatient3: yup.number().nullable().when('criteriaPsarc3', {
      is: (value: string) => value === 'likert',
      then: (schema) => schema.required()
    }),
    likertDoctor3: yup.number().nullable().when('criteriaPsarc3', {
      is: (value: string) => value === 'likert',
      then: (schema) => schema.required()
    }),
    generalAssessment3: yup.number().nullable().when('criteriaPsarc3', {
      is: (value: string) => value === 'likert',
      then: (schema) => schema.required()
    }),
    tenderJointsCount3: yup.number().nullable().integer().min(0),
    swollenJointsCount3: yup.number().nullable().integer().min(0),
    resultScaleOption3: yup.string().nullable().test(
      function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === 'postać osiowa') {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }
    ),
    resultScale3: yup.number().nullable().test(
      function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === 'postać osiowa') {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }
    ),
    degreeOfBack3: yup.number().nullable().test(
      function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === 'postać osiowa') {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }
    ),
    generalAssessment23: yup.number().nullable().test(
      function (value) {
        // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
        if (lzsCharacter === 'postać osiowa') {
          return value !== null && value !== undefined;
        }
        // W przeciwnym wypadku wartość może być null
        return true;
      }
    ),
    pasi: yup.number().nullable(),
    dlqi: yup.number().nullable(),
    bsa: yup.number().nullable(),
    treatmentLines: yup.array().of(
      yup.object().shape({
        variants: yup.string().required(),
        tnfInhibitorType: yup.string().when("variants", {
          is: (value: string) => value === "Inhibitory TNF",
          then: (schema) => schema.required(),
        }),
        il17InhibitorType: yup.string().when("variants", {
          is: (value: string) => value === "Inhibitory IL-17",
          then: (schema) => schema.required(),
        }),
        il23InhibitorType: yup.string().when("variants", {
          is: (value: string) => value === "Inhibitory IL-23",
          then: (schema) => schema.required(),
        }),
        jakInhibitorType: yup.string().when("variants", {
          is: (value: string) => value === "Inhibitory JAK",
          then: (schema) => schema.required(),
        }),
        therapyDuration: yup.number().nullable(),
        durationUnit: yup.string().nullable().when("therapyDuration", {
          is: (value: number) => value > 0,
          then: (schema) => schema.required(),
        }),
        monotherapy: yup.string().required(),
        combinationWithMedicine: yup
          .array()
          .of(
            yup.object().shape({
              combinationDrugName: yup.string().required(),
              otherCombinationDrug: yup.string().when("combinationDrugName", {
                is: (value: string) => value === "Inne",
                then: (schema) => schema.required(),
              }),
              combinationDose: yup.number().required(),
              combinationUnit: yup.string().required(),
              otherCombinationUnit: yup.string().when("combinationUnit", {
                is: (value: string) => value === "Inna jednostka",
                then: (schema) => schema.required(),
              }),
              combinationDuration: yup.number().required(),
            })
          )
          .when("monotherapy", {
            is: (value: string) => value === "kombinacja",
            then: (schema) => schema.min(1).required(),
          }),
        criteriaPsarc3followup: yup
          .string()
          .nullable(),
        das28Result3followup: yup.string().nullable().when('criteriaPsarc3followup', {
          is: (value: string) => value === 'das 28',
          then: (schema) => schema.required()
        }),
        result3followup: yup.number().nullable().when('criteriaPsarc3followup', {
          is: (value: string) => value === 'das 28',
          then: (schema) => schema.required()
        }),
        likertPatient3followup: yup.number().nullable().when('criteriaPsarc3followup', {
          is: (value: string) => value === 'likert',
          then: (schema) => schema.required()
        }),
        likertDoctor3followup: yup.number().nullable().when('criteriaPsarc3followup', {
          is: (value: string) => value === 'likert',
          then: (schema) => schema.required()
        }),
        generalAssessment3followup: yup.number().nullable().when('criteriaPsarc3followup', {
          is: (value: string) => value === 'likert',
          then: (schema) => schema.required()
        }),
        tenderJointsCount3followup: yup.number().nullable(),
        swollenJointsCount3followup: yup.number().nullable(),
        resultScaleOption3followup: yup.string().nullable().test(
          function (value) {
            // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
            if (lzsCharacter === 'postać osiowa') {
              return value !== null && value !== undefined;
            }
            // W przeciwnym wypadku wartość może być null
            return true;
          }
        ),
        resultScale3followup: yup.number().nullable().test(
          function (value) {
            // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
            if (lzsCharacter === 'postać osiowa') {
              return value !== null && value !== undefined;
            }
            // W przeciwnym wypadku wartość może być null
            return true;
          }
        ),
        degreeOfBack3followup: yup.number().nullable().test(
          function (value) {
            // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
            if (lzsCharacter === 'postać osiowa') {
              return value !== null && value !== undefined;
            }
            // W przeciwnym wypadku wartość może być null
            return true;
          }
        ),
        generalAssessment23followup: yup.number().nullable().test(
          function (value) {
            // Jeśli lzsCharacter to "postać osiowa", wartość musi być stringiem i nie może być pusta
            if (lzsCharacter === 'postać osiowa') {
              return value !== null && value !== undefined;
            }
            // W przeciwnym wypadku wartość może być null
            return true;
          }
        ),
        symptomsOfIntolerance: yup.string().required()
      })
    ),
    gks: yup.string().required(),
  });
}

const initialValues: TLzsSkyriziStage03 = {
  programEntryDate: null,
  criteriaPsarc3: '',
  das28Result3: "",
  result3: null,
  likertPatient3: null,
  likertDoctor3: null,
  generalAssessment3: null,
  tenderJointsCount3: null,
  swollenJointsCount3: null,
  resultScaleOption3: '',
  resultScale3: null,
  degreeOfBack3: null,
  generalAssessment23: null,
  pasi: null,
  dlqi: null,
  bsa: null,
  treatmentLines: [
    {
      variants: "",
      tnfInhibitorType: "",
      il17InhibitorType: "",
      jakInhibitorType: "",
      il23InhibitorType: "",
      therapyDuration: null,
      durationUnit: '',
      monotherapy: "",
      combinationWithMedicine: [],
      criteriaPsarc3followup: '',
      das28Result3followup: "",
      result3followup: null,
      likertPatient3followup: null,
      likertDoctor3followup: null,
      generalAssessment3followup: null,
      tenderJointsCount3followup: null,
      swollenJointsCount3followup: null,
      resultScaleOption3followup: '',
      resultScale3followup: null,
      degreeOfBack3followup: null,
      generalAssessment23followup: null,
      symptomsOfIntolerance: "",
    },
  ],
  gks: "",
};

export const useStage03ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const lzssStore = useLzssStore();
  const {thirdStage, caseStage, secondStage} = lzssStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(thirdStage))
      : JSON.parse(JSON.stringify(initialValues));

  const lzsCharacter = secondStage?.lzsCharacter || ''

  const formik = useFormik<TLzsSkyriziStage03>({
    initialValues: {
      ...valuesObj,
      treatmentLines: valuesObj.treatmentLines.length === 0 ? [{
        variants: "",
        tnfInhibitorType: "",
        il17InhibitorType: "",
        jakInhibitorType: "",
        il23InhibitorType: "",
        therapyDuration: null,
        durationUnit: '',
        monotherapy: "",
        combinationWithMedicine: [],
        gks: "",
        criteriaPsarc3followup: '',
        das28Result3followup: "",
        result3followup: null,
        likertPatient3followup: null,
        likertDoctor3followup: null,
        generalAssessment3followup: null,
        tenderJointsCount3followup: null,
        swollenJointsCount3followup: null,
        resultScaleOption3followup: '',
        resultScale3followup: null,
        degreeOfBack3followup: null,
        generalAssessment23followup: null,
        symptomsOfIntolerance: "",
      }] : valuesObj.treatmentLines
    },
    validationSchema: createValidationSchema(lzsCharacter),
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`treatmentLines[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }

    if (field === 'variants') {
      if (value !== 'Inhibitory TNF') {
        setFieldValue(`treatmentLines[${index}].tnfInhibitorType`, '')
      }
      if (value !== 'Inhibitory IL-17') {
        setFieldValue(`treatmentLines[${index}].il17InhibitorType`, '')
      }
      if (value !== 'Inhibitory JAK') {
        setFieldValue(`treatmentLines[${index}].jakInhibitorType`, '')
      }
      if (value !== 'Inhibitory II-23') {
        setFieldValue(`treatmentLines[${index}].il23InhibitorType`, '')
      }
    }

  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `treatmentLines[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const deleteTreatment = (index: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines.filter((el, idx) => idx !== index),
    ];
    setFieldValue("treatmentLines", filteredItems);
  };

  const addLine = () => {
    setFieldValue("treatmentLines", [
      ...formik.values.treatmentLines,
      {
        variants: "",
        tnfInhibitorType: "",
        il17InhibitorType: "",
        jakInhibitorType: "",
        il23InhibitorType: "",
        therapyDuration: null,
        durationUnit: '',
        monotherapy: "",
        combinationWithMedicine: [],
        gks: "",
        criteriaPsarc3followup: '',
        das28Result3followup: "",
        result3followup: null,
        likertPatient3followup: null,
        likertDoctor3followup: null,
        generalAssessment3followup: null,
        tenderJointsCount3followup: null,
        swollenJointsCount3followup: null,
        resultScaleOption3followup: '',
        resultScale3followup: null,
        degreeOfBack3followup: null,
        generalAssessment23followup: null,
        symptomsOfIntolerance: "",
      },
    ]);
  };

  const addCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const deleteCombination = (index: number, idx: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines[index].combinationWithMedicine.filter(
        (el, i) => idx !== i
      ),
    ];
    setFieldValue(
      `treatmentLines[${index}].combinationWithMedicine`,
      filteredItems
    );
  };

  const addOtherCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      ...formik.values.treatmentLines[index].combinationWithMedicine,
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const resetForLikert = () => {
    setFieldValue('das28Result3', '')
    setFieldValue('result3', null)
  }
  const resetForDas = () => {
    setFieldValue('likertPatient3', null)
    setFieldValue('likertDoctor3', null)
  }

  const resetForLikertTreatments = (index: number) => {
    setFieldValue(`treatmentLines[${index}].das28Result3followup`, '')
    setFieldValue(`treatmentLines[${index}].result3followup`, null)
  }
  const resetForDasTreatments = (index: number) => {
    setFieldValue(`treatmentLines[${index}].likertPatient3followup`, null)
    setFieldValue(`treatmentLines[${index}].likertDoctor3followup`, null)
  }

  return {
    formik,
    setOption,
    deleteTreatment,
    addLine,
    addCombination,
    deleteCombination,
    addOtherCombination,
    setCombinationOption,
    lzsCharacter,
    resetForLikert,
    resetForDas,
    resetForLikertTreatments,
    resetForDasTreatments
  }
}

