import {useRootStore} from "../../RootStateContext";

export const useHcvStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.hcvStore) {
    throw new Error("hcvStore store should be defined");
  }

  return rootStore.hcvStore;
};
