import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";

// hooks
import { useNpsStore } from "../../../../../../../../store/hooks";

// components
import {
  GroupForm,
  Label,
  NormalText,
  NormalTextDiv,
} from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import { checkValueIsEmpty } from "../../../virusology/hcv/HcvViewCase.helper";

export const PatientSection = observer(() => {
  const caseStore = useNpsStore();
  const { case: caseElement } = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={5}>
      <Grid item xs={12} mb={4}>
        <GroupForm>Opis kliniczny:</GroupForm>
      </Grid>

      <Grid item xs={12}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid item xs={12} mb={4}>
            <Label>Czy pacjent był leczony nlpz?</Label>
            <NormalText>{checkValueIsEmpty(caseElement.nlpzCured)}</NormalText>
          </Grid>
          <Grid gap={"20px"} container>
            <Grid item xs={2}>
              <Label>Inicjały pacjenta</Label>
              <NormalText>{checkValueIsEmpty(caseElement.initials)}</NormalText>
            </Grid>
            <Grid item xs={1}>
              <Label>WIEK</Label>
              <NormalText>{checkValueIsEmpty(caseElement.age)}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>PŁEĆ</Label>
              <NormalText>{checkValueIsEmpty(caseElement.gender)}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>Masa ciała w kg</Label>
              <NormalText>{caseElement.weightKg}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>Wzrost w cm</Label>
              <NormalText>{caseElement.heightCm}</NormalText>
            </Grid>
            <Grid item xs={1}>
              <Label>BMI</Label>
              <NormalText>{caseElement.bmi}</NormalText>
            </Grid>
          </Grid>
          {caseElement.durationNps && (
            <Grid item xs={12} mt={4}>
              <Label>Czas trwania nr-axSpA w latach</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.durationNps)}
              </NormalText>
            </Grid>
          )}
          <Grid item xs={12} mt={4}>
            <Grid container borderRadius={1} bgcolor={"white"}>
              <Grid item xs={12}>
                <Label>Choroby towarzyszące</Label>
                <NormalTextDiv>
                  {caseElement.comorbidities?.length > 0 && (
                    <ul>
                      <>
                        {caseElement.comorbidities.map((element) => (
                          <li key={element}>{element}</li>
                        ))}
                      </>
                    </ul>
                  )}
                </NormalTextDiv>
              </Grid>
            </Grid>
          </Grid>
          {caseElement.comorbiditiesOther && (
            <Grid item xs={12} mt={2}>
              <Label>Inne</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.comorbiditiesOther)}
              </NormalText>
            </Grid>
          )}
          <Grid item xs={12}>
            <Label>WYWIAD</Label>
            <NormalText>{caseElement.interview}</NormalText>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={5}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid item xs={12} mb={3}>
            <Label $size={"large"} $mb={0}>
              WYWIAD:
            </Label>
          </Grid>
          <Grid container gap={"20px"}>
            {(caseElement.gender === "kobieta" ||
              caseElement.gender === "inne") && (
              <>
                <Grid item xs={12}>
                  <Label>HORMONALNA TERAPIA ZASTĘPCZA (HTZ)</Label>
                  <NormalText>{checkValueIsEmpty(caseElement.htz)}</NormalText>
                </Grid>
                <Grid item xs={12}>
                  <Label>Antykoncepcja hormonalna</Label>
                  <NormalText>
                    {checkValueIsEmpty(caseElement.contraception)}
                  </NormalText>
                </Grid>
              </>
            )}
            <Grid item xs={2}>
              <Label>PALENIE TYTONIU</Label>
              <NormalText>{checkValueIsEmpty(caseElement.smoking)}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>LICZBA PACZKOLAT</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.packYears)}
              </NormalText>
            </Grid>
            <Grid item xs={12}>
              <Label>HLA B27</Label>
              <NormalText>{checkValueIsEmpty(caseElement.hlab27)}</NormalText>
            </Grid>
            <Grid item xs={12}>
              <Label>
                Klasyczne zmiany radiograficzne w stawach krzyżowo-biodrowych
                obserwowanych na zdjęciach RTG
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.rtg)}</NormalText>
            </Grid>
            <Grid item xs={12}>
              <Label>
                Zapalenie stawów obwodowych lub przyczepów ścięgnistych z
                rozpoznaniem spondyloatropatii obwodowej na podstawie kryteriów
                klasyfikacyjnych SpA wg ASAS
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.asas)}</NormalText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
