import styled from 'styled-components';
import { Grid } from '@mui/material';
import theme from '../../../../theme/theme';


export const ParagraphFirst = styled.p`
  margin-bottom: 20px;
`;

export const ButtonContainer = styled(Grid)`
  display: flex;
  width: 100%;
  gap: 24px;

  & svg {
    margin-top: 5px;
    width: 20px;
  }

  .Mui-checked {
    color: ${theme.colors.abbvie} !important
  }

`;

export const LabelFilter = styled.label`
  color: ${theme.colors.abbvie};
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`

export const IconContainer = styled.div<{ $isAction: boolean }>`
  min-width: 51px;
  height: 49px;
  box-sizing: border-box;
  cursor: ${props => props.$isAction ? 'pointer' : 'default'};

  svg {
    max-width: 50px;
    height: 49px;
  }
  
`

export const AcceptCaseModalText = styled.p`
  margin-top: 16px;
  text-align: center;
`