import { observer } from "mobx-react-lite";

// hooks
import { useStage01ViewModel } from "./Stage01.vm";

// components
import { Grid } from "@mui/material";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";

// lib
import theme from "../../../../../../../../../theme/theme";
import { selectOptions } from "./stageSettings";

export const Stage01 = observer(() => {
  const {formik} = useStage01ViewModel();
  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      mx={"auto"}
      my={4}
      component={"form"}
    >
      <Grid item xs={12} alignSelf={"center"}>
        <Input
          isRequired={true}
          size={"small"}
          label={"Tytuł"}
          isUpperCase={true}
          name={"title"}
          type={"text"}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          colorLabel={theme.colors.grey1}
          background={theme.colors.white}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={"Wpisz tytuł opisu przypadku..."}
          isError={formik.touched.title && Boolean(formik.errors.title)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        mt={1}
        gap={"20px"}
        flexWrap={"nowrap"}
      >
        <Grid item xs={3}>
          <Input
            isRequired={true}
            label={"Inicjały pacjenta"}
            isUpperCase={true}
            name={"initials"}
            type={"text"}
            maxLength={3}
            colorLabel={theme.colors.grey1}
            value={formik.values.initials}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz inicjały..."}
            isError={formik.touched.initials && Boolean(formik.errors.initials)}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            isRequired={true}
            label={"Wiek"}
            isUpperCase={true}
            name={"age"}
            type={"number"}
            colorLabel={theme.colors.grey1}
            value={formik.values.age as string}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz wiek..."}
            isError={formik.touched.age && Boolean(formik.errors.age)}
          />
        </Grid>
        <Grid item xs={6}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"płeć"}
            isRequired={true}
          />
          <Grid sx={styles.radioForm()}>
            <RadioBox
              isError={formik.touched.gender && Boolean(formik.errors.gender)}
              id={"gender1"}
              name={"gender"}
              value={"kobieta"}
              checked={formik.values.gender === "W"}
              onChange={() => formik.setFieldValue("gender", 'W')}
            />
            <RadioBox
              isError={formik.touched.gender && Boolean(formik.errors.gender)}
              id={"gender2"}
              name={"gender"}
              value={"mężczyzna"}
              checked={formik.values.gender === "M"}
              onChange={() => formik.setFieldValue("gender", 'M')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={1}>
        <SingleSelect
          isRequired={true}
          defaultValue={'Wybierz'}
          isError={formik.touched.coInfection && Boolean(formik.errors.coInfection)}
          label={'Koinfekcja'}
          handleChange={(value) => formik.setFieldValue('coInfection', value)}
          selectedValue={selectOptions.find(el => el.id === (formik.values.coInfection))?.title ?? ''}
          elementName={'coInfection'}
          options={selectOptions}/>
      </Grid>
      <Grid item xs={12}>
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"PACJENT LECZONY PIERWSZORAZOWO"}
          isRequired={true}
        />
        <Grid sx={styles.radioForm()}>
          <RadioBox
            isError={
              formik.touched.firstTreatment &&
              Boolean(formik.errors.firstTreatment)
            }
            id={"patientTreatedOnce1"}
            name={"firstTreatment"}
            value={"tak"}
            checked={formik.values.firstTreatment === true}
            onChange={() => formik.setFieldValue("firstTreatment", true)}
          />
          <RadioBox
            isError={
              formik.touched.firstTreatment &&
              Boolean(formik.errors.firstTreatment)
            }
            id={"patientTreatedOnce2"}
            name={"firstTreatment"}
            value={"nie"}
            checked={formik.values.firstTreatment === false}
            onChange={() => formik.setFieldValue("firstTreatment", false)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

const styles = {
  radioForm: (direction: string = "row") => ({
    display: "flex",
    flexDirection: direction,
    alignItems: "center",
    gap: "16px",
    height: "32px",
  }),
  selectContainer: (isError: boolean = false) => ({
    select: {
      height: "36px",
      border: `2px solid ${isError ? theme.colors.red : theme.colors.grey4}`,
      borderRadius: "10px",
      fontSize: "16px",
      lineHeight: "22px",
      padding: "0 10px",
      margin: "0 0 15px 0",
      background: theme.colors.white,
      option: {
        color: theme.colors.black,
      },
    },
  }),
};
