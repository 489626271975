import styled from "styled-components";
import theme from "../../../../theme/theme";
import selectDropdown from "./../../../../assets/icon/selectDropdown.svg";

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled.select<{
  disabled?: boolean;
  $isError: boolean;
  $border?: string;
  $color?: string;
  $background?: string;
  $placeholderColor?: string;
  defaultValue?: string;
}>`
  color: ${(props) =>
          props.value === -1
                  ? theme.colors.grey4
                  : props.$color ?? "rgb(51, 51, 51)"};
  height: 2.25rem;
  border: 2px solid ${(props) =>
          props.$isError ? theme.colors.red : props.$border ?? theme.colors.grey2};
  background: ${(props) => props.$background ?? "transparent"};
  border-radius: 10px;
  font-size: 16px;
  line-height: 36px;
  padding: 0 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position-x: 98%;
  background-position-y: 13px;
  background-image: url(${selectDropdown});
  background-repeat: no-repeat;

  option {
    color: ${(props) => props.$color ?? "rgb(51, 51, 51)"};
  }
`;

export const RequiredStar = styled.span<{ color?: string }>`
  color: ${(props) => props.color ?? "rgb(51, 51, 51)"};
`;
