import { useEffect, useState } from "react";

import { createTableRows } from "../../../shared/customTables/utils/createTableRows";
import { checkIsActive } from "../../../shared/customTables/utils/actionsHelper";
import { useModal } from "../../../shared/modal/useModal.vm";
import { Select } from "../../../shared/ui/select/Select";
import { IUserForm } from "../../../../interfaces/admin/user/userForm";
import { IHeadCell } from "../../../../interfaces/common/table";
import { RoleFunction } from "../../../../constants/projectUserRoles";
import { ModalType } from "../../../../constants/modalType";
import { checkPermission } from "../../../../utils/checkPermission";

import { useRolesApi, useUsersApi } from "../../../../apiHooks";
import {
  useAreasStore,
  useAuthStore,
  useRolesStore,
  useUsersStore,
} from "../../../../store/hooks";
import theme from "../../../../theme/theme";
import { ActionButtonStyled } from "../../../shared/customTables/button/ActionButton.styled";
import { ReactComponent as DeleteIcon } from "../../../../assets/icon/delete.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icon/edit.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/icon/message.svg";
import { RoleSelectContainer, TopContainer } from "./OthersAdmins.styled";
import { TableCellData } from "../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled";
import { IAreas } from "../../../../interfaces/common/areas";
import {
  filterUsers,
  generateAreaOptions,
  generateRoleOptions,
} from "../../../../utils/filtredUsers";

export const useOthersAdmins = () => {
  const { roleFunction } = useAuthStore();
  const [actionId, setActionId] = useState<number | null>(null);
  const [selectRoleValue, setSelectedRoleValue] = useState(-1);
  const [selectAreaValue, setSelectedAreaValue] = useState(-1);
  const { getOtherUsers, resendActivateEmail, deleteUser, getUser, editUser } =
    useUsersApi();
  const { getRoles } = useRolesApi();
  const usersStore = useUsersStore();
  const { otherUsers, userEdit, isOtherUsersLoaded } = usersStore;
  const rolesStore = useRolesStore();
  const { roles } = rolesStore;
  const areasStore = useAreasStore();
  const { areas } = areasStore;
  const { handleModal, isModalOpened, closeModal, modalType } = useModal();

  useEffect(() => {
    if (!isOtherUsersLoaded && roles !== null) {
      if (otherUsers === null) {
        void getOtherUsers(roles);
      } else {
        void getOtherUsers(roles, false);
      }
    }
  }, [isOtherUsersLoaded, getOtherUsers, otherUsers, roles]);

  useEffect(() => {
    return () => usersStore.resetOtherUsers();
  }, [usersStore]);

  useEffect(() => {
    if (roles === null) {
      void getRoles(false);
    }
  }, [roles, getRoles]);

  useEffect(() => {
    return () => rolesStore.clearRoles();
  }, [rolesStore]);

  const editModalHandler = async (id: number | string) => {
    const user = await getUser(+id);
    if (user) {
      handleModal(ModalType.EDIT_MODAL);
    }
  };

  const editHandler = async (userForm: IUserForm) => {
    const isEdit = await editUser(userForm);
    if (isEdit && roles) {
      await handleModal(ModalType.EDIT_CONFIRMATION_MODAL);
      setActionId(null);
      usersStore.clearUserEdit();
      await getOtherUsers(roles);
    }
    return null;
  };

  const deleteHandler = (id: number | string) => {
    handleModal(ModalType.DELETE_MODAL);
    setActionId(+id);
  };

  const deleteHandlerYes = async () => {
    if (otherUsers && actionId && roles) {
      const isDelete = await deleteUser(actionId);
      if (isDelete) {
        await getOtherUsers(roles);
        setActionId(null);
        handleModal(ModalType.DELETE_CONFIRMATION_MODAL);
      }
    }
  };

  const sendLinkHandler = (id: number | string) => {
    if (otherUsers) {
      const user = otherUsers.find((user) => user.id === +id);
      if (user && !user.isActive) {
        void resendActivateEmail(+id);
      }
    }
  };

  const generateSendButton = (id: number) => {
    if (checkIsActive(+id, otherUsers ?? [])) {
      return (
        <ActionButtonStyled>
          Wyślij link <MessageIcon />
        </ActionButtonStyled>
      );
    }
    return <div>Aktywny</div>;
  };

  const generateBackground = (id: number) => {
    if (checkIsActive(id, otherUsers ?? [])) {
      return theme.colors.green;
    }
    return theme.colors.grey4;
  };

  const generateIsDisabled = (id: number) => {
    return !checkIsActive(id, otherUsers ?? []) ?? true;
  };

  const generateActions = () => {
    if (
      roleFunction &&
      checkPermission(roleFunction, [
        RoleFunction.ROLE_ADMIN,
        RoleFunction.ROLE_ACCEPTING,
      ])
    ) {
      return [
        {
          key: "send",
          actionHandler: sendLinkHandler,
          background: generateBackground,
          actionElement: generateSendButton,
          width: 138,
          isDisabled: generateIsDisabled,
        },
        {
          key: "edit",
          actionHandler: editModalHandler,
          background: theme.colors.abbvie,
          actionElement: <EditIcon />,
          width: 52,
        },
        {
          key: "delete",
          actionHandler: deleteHandler,
          background: theme.colors.blue2,
          actionElement: <DeleteIcon />,
          width: 52,
        },
      ];
    }
    return [];
  };

  const cellsSwitcher = (
    keyName: string,
    value: string | IAreas[],
    onClick?: () => void
  ) => {
    switch (keyName) {
      case "id":
        return null;
      case "areas":
        return (
          <TableCellData>
            {(value as IAreas[]).map((area) => area.title).join(", ")}
          </TableCellData>
        );
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const headCells: IHeadCell[] = [
    { id: "fullName", numeric: false, label: "Imię i nazwisko" },
    { id: "role", numeric: false, label: "Rola" },
    { id: "areas", numeric: false, label: "Obszar terapeutyczny" },
    { id: "phone", numeric: false, label: "Numer kontaktowy" },
    { id: "email", numeric: false, label: "Email" },
  ];

  const filteredData = filterUsers(
    otherUsers,
    selectAreaValue,
    selectRoleValue
  );

  const tableDataRows = createTableRows(filteredData ?? [], headCells, false);

  const selectRoleOptions = generateRoleOptions(roles, otherUsers);
  const selectAreaOptions = generateAreaOptions(areas);

  const addedContent = (
    <TopContainer>
      <RoleSelectContainer>
        <Select
          selectValue={selectRoleValue}
          elementName={"role-filter"}
          setValue={setSelectedRoleValue}
          background={"white"}
          optionsArray={selectRoleOptions}
        />
      </RoleSelectContainer>
      <RoleSelectContainer>
        <Select
          selectValue={selectAreaValue}
          elementName={"area-filter"}
          setValue={setSelectedAreaValue}
          background={"white"}
          optionsArray={selectAreaOptions}
        />
      </RoleSelectContainer>
    </TopContainer>
  );

  return {
    listIsNotNull: otherUsers !== null,
    headCells,
    cellsSwitcher,
    generateActions,
    tableDataRows,
    modalType,
    isModalOpened,
    editHandler,
    userEdit,
    deleteHandlerYes,
    closeModal,
    addedContent,
  };
};
