import styled from "styled-components";
import theme from "../../../../../theme/theme";
import { Link } from "react-router-dom";

export const RegisterViewingFormContainer = styled.form`
  z-index: 50;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  padding: 30px 8px;
  max-width: 345px;
  width: 100%;

  & button {
    margin-left: auto;
    margin-top: 15px;
    display: flex;
    align-items: center;

    & svg {
      margin-top: 0;
    }

    & path {
      fill: ${theme.colors.white};
    }
  }
`;

export const StyledLink = styled(Link)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  color: ${theme.colors.white};
  text-align: center;
  margin: 16px auto;
`;

export const Paragraph = styled.p`
  margin: 0 36px 36px 36px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${theme.colors.white};
`;

export const ParagraphLink = styled.a`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${theme.colors.white};
  text-decoration: none;
`;
