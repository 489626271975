export const criteriaOptions = [
  "A łuszczyca obecnie – 2 pkt",
  "B wywiad łuszczycy – 1 pkt",
  "C wywiad rodzinny w kierunku łuszczycy – 1 pkt",
  "D dactylitis – 1 pkt",
  "E brak czynnika reumatoidalnego – 1 pkt",
  "F dystrofia paznokci – 1 pkt",
  "G Zmiany radiologiczne stawów dłoni lub stóp charakterystyczne dla ŁZS (okołostawowe tworzenie nowej kości z wyłączeniem osteofitów) – 1 pkt",
];

export const comorbiditiesOptions = [
  "Gruźlica",
  "Wcześniejsza przebyta choroba onkologiczna",
  "Przewlekły półpasiec",
  "Nadciśnienie tętnicze",
  "Łuszczyca",
  "Choroby zapalne jelit",
  "Zapalenie błony naczyniowej oka",
  "Atopowe zapalenie skóry (AZS)",
  "Miażdżyca tętnic",
  "Inne",
  'Brak'
];