import {useFileUpload} from "./fileUpload/useFileUpload";
import {DropZone} from "./dropZone/DropZone";
import theme from '../../../theme/theme'
import {Grid} from "@mui/material";
import React, {FC} from "react";
import {ReactComponent as Plus} from '../../../assets/icon/plus.svg'
import {IImage} from "../../../interfaces/user/image";
import {
  ContainerFiles,
  Label,
  ParagraphImage,
  SpanText,
  StyledBin,
  StyledPlus,
  StyledPlusBig
} from "./FileUploadLayout.styled";
import { IFile } from '../../../interfaces/user/draftCase/file';


export enum FileSource {
  SERVER,
  STATE
}

interface IProps {
  files: IImage[] | File[];
  addFile: (file: File | null) => Promise<void> | void;
  removeFile?: (id: number | string) => Promise<void> | void;
  extensions?: string[];
  maxFileSize?: number;
  fileType?: string[]
  fileSource?: FileSource
  id: string;
  disabled?: boolean
}

export const FileUploadLayout: FC<IProps> = ({
                                               disabled,
                                               id,
                                               fileSource = FileSource.SERVER,
                                               files,
                                               addFile,
                                               removeFile,
                                               fileType = ['image'],
                                               extensions = ['jpg', 'jpeg', 'png', 'gif'],
                                               maxFileSize = 6,
                                             }): JSX.Element => {
  const {
    onDragStateChange,
    onFileDrop,
    setIsDropActive,
    isFileTooBig,
    isFileNotSupported
  } = useFileUpload(addFile, maxFileSize, fileType);

  const insideInput = (images: IImage[] | File[]) => {
    const isFileFromServer = fileSource === FileSource.SERVER;

    if (isFileFromServer) {
      const imagesServer = images as IImage[];
      return (
        imagesServer.map((image, idx) =>
          <Grid key={image.originalName ? image.originalName + idx : Number(image.name) + idx} item display={'flex'}
                pb={1}
                alignItems={'center'}>
            <StyledBin onClick={() => removeFile && removeFile(image.id ?? -1)} fill={'red'}/>
            <ParagraphImage>{image.originalName || image.name}</ParagraphImage>
          </Grid>)
      )
    }
    const imagesState = images as IFile[];

    return (
      imagesState.map((image, idx) =>
        <Grid key={image.id + idx} item display={'flex'} pb={1} alignItems={'center'}>
          <StyledBin onClick={() => removeFile && removeFile(image.id)} fill={'red'}/>
          <ParagraphImage>{image.originalName || image.name}</ParagraphImage>
        </Grid>)
    )
  }

  return (
    <DropZone
      id={id}
      disabled={disabled}
      onDragStateChange={onDragStateChange}
      onFileDrop={onFileDrop}>
      <ContainerFiles container overflow={'auto'}>

        {files.length > 0 ?
          <Grid item xs={11}>
            {insideInput(files)}
          </Grid>
          : <>

            <Grid item xs={12} md={10} display={'flex'} flexDirection={'column'}
                  justifyContent={files.length === 0 ? 'center' : 'flex-start'}
                  alignItems={'center'}>
              <Grid display={'flex'} alignItems={'center'}>
                <StyledPlus>
                  <Plus/>
                </StyledPlus>
                <Grid display={'flex'} flexDirection={'column'}>
                  <Label
                    htmlFor={id}>Przeciągnij lub wybierz z folderu z komputera
                    <input
                      disabled={disabled}
                      id={id}
                      type="file"
                      accept={extensions.join(', ')}
                      style={{display: 'none'}}
                      onChange={(event) => {
                        event.preventDefault();
                        if (event.target.files) {
                          onFileDrop(event.target.files[0]);
                          setIsDropActive(true);
                        }
                      }}
                    />
                  </Label>
                  <SpanText
                    color={isFileTooBig ? theme.colors.red : theme.colors.grey4}>Maksymalna waga
                    pliku {` ${maxFileSize}MB.`}</SpanText>
                  {isFileNotSupported && <SpanText
                    color={theme.colors.red}>Obsługiwane formaty plików to pdf, jpg, jpeg, png</SpanText>}
                </Grid>
              </Grid>
            </Grid>
          </>}
      </ContainerFiles>
      <Label
        $isCenter={true}
        htmlFor={id + 1}>
        <StyledPlusBig disabled={disabled}>
          <input
            disabled={disabled}
            id={id + 1}
            type="file"
            accept={extensions.join(', ')}
            style={{display: 'none'}}
            onChange={(event) => {
              event.preventDefault();
              if (event.target.files) {
                onFileDrop(event.target.files[0]);
                setIsDropActive(true);
              }
            }}
          />
          <Plus/>
        </StyledPlusBig>
      </Label>
    </DropZone>);
}
