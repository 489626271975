import {useRootStore} from '../RootStateContext';

export const useOthersStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.othersStore) {
    throw new Error('othersStore store should be defined');
  }

  return rootStore.othersStore;
};
