import { makeAutoObservable } from "mobx";
import { IProfile } from "../interfaces/user/profile/profile";

export class ProfileStore {
  profile: IProfile | null = null;
  isProfileLoaded: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setProfile(profile: IProfile) {
    this.profile = profile;
    this.isProfileLoaded = true;
  }

  resetProfile() {
    this.isProfileLoaded = false;
  }
}
