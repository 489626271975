import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const ParagraphContainer = styled.div`
  text-align: center;
  max-width: 350px;
  margin: auto;
`;
export const ParagraphMain = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: white;
  margin-top: 22px;
  text-align: justify;
`

export const RegisterViewingFormContainer = styled.form`
  z-index: 50;
  margin: auto 0;
  padding: 30px 8px;
  max-width: 345px;
  width: 100%;

  & button {
    margin: 25px 0 0 auto;
  }

  & svg {
    margin-top: 0;
  }

  & path {
    fill: ${theme.colors.white}
  }
;`