import { makeAutoObservable } from 'mobx';


export class WindowDimensionsStore {
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  displaySize = 'xs';
  displayOrientation = 'landscape';


  constructor() {
    makeAutoObservable(this);
  }

  setWidth = (width: number) => {
    this.width = width;
  };

  setHeight = (height: number) => {
    this.height = height;
  };

  setDisplaySize = (width: number) => {
    switch (true) {
      case width < 600:
        this.displaySize = 'xs';
        break;
      case width < 900:
        this.displaySize = 'sm';
        break;
      case width < 1200:
        this.displaySize = 'md';
        break;
      case width < 1440:
        this.displaySize = 'lg';
        break;
      default:
        this.displaySize = 'xl';
    }
  };

  setDisplayOrientation = (width: number, height: number) => {
    this.displayOrientation = width > height ? 'landscape' : 'portrait';
  };
}
