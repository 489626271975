import { FC } from "react";
import { DrugContainer } from "./Drugs.styled";
import { Grid } from "@mui/material";
import { LabelSmall, NormalText, NormalTextMarginContainer, } from "../../HcvViewCase.styled";
import { IDrug } from "../../../../../../../../../interfaces/user/draftCase/draft";

interface IProps {
  drugs: IDrug[];
}

export const Drugs: FC<IProps> = ({drugs}) => {
  return (
    <Grid container display={"flex"} gap={2} flexWrap={"nowrap"}>
      {drugs.length > 0
        ? drugs.map((drug) => (
          <Grid
            item
            xs={4}
            key={drug.id}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <DrugContainer>
              <NormalTextMarginContainer>
                <LabelSmall>LEK 1:</LabelSmall>
                <NormalText>{drug.drugName}</NormalText>
                <LabelSmall>DAWKA:</LabelSmall>
                <NormalText>{drug.drugDose}</NormalText>
              </NormalTextMarginContainer>
            </DrugContainer>
          </Grid>
        ))
        : "-"}
    </Grid>
  );
};
