import styled from 'styled-components';
import theme from '../../../theme/theme';

import { Grid } from '@mui/material';

export const StyledUserLayoutContent = styled.div`
  min-height: calc(100vh - 170px);
  margin: 0 auto;
  @media (max-width: ${theme.breakpoints.small}px) {
    height: calc(100vh - 140px);
  }
`;

export const Header = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: ${theme.colors.violet};
`;


export const Paragraph = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${theme.colors.abbvie};
  padding-bottom: 14px;

  & a {
    color: ${theme.colors.abbvie};
    font-weight: 700;
  }
`;

export const NavigationContainer = styled.div`
  position: relative;

  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  margin-bottom: 10px;
  width: 100%;
  height: 120px;
  max-height: 120px;
  padding: 0 35px;
  @media (max-width: ${theme.breakpoints.small}px) {
    background-size: auto;
    background-position: top left;
    height: unset;
    max-height: unset;
  }
`;

export const UserLayoutContainer = styled(Grid)`
  background: ${theme.colors.white};
  margin: 0 auto;
`;

export const Doctor = styled.img`
  max-height: 719px;
  position: absolute;
  width: auto;
  max-width: 599px;
  z-index: 20;
  bottom: 0;
  left: 0;
`