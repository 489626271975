import styled from "styled-components";
import {Grid} from "@mui/material";
import theme from "../../../../../theme/theme";

export const HistoryHeader = styled(Grid)`
  position: relative;
  top: -54px;
  left: 2px;
`

export const HeaderContainer = styled.div`
  display: flex;
  gap: 10px;

`

export const Header = styled.h1`
  font-size: 20px;
  line-height: 120%;
  font-weight: 800;
  text-transform: uppercase;
`

export const CaseTitle = styled.p`
  color: ${theme.colors.blue2};
  font-size: 16px;
  font-weight: 700;
  line-height: 160%; 
  letter-spacing: -0.48px;
  text-decoration-line: underline;
`

export const SearchIconContainer = styled.div`
  background: ${theme.colors.blue2};
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 30px;
    height: 30px;
  }

  & path {
    fill: white;
  }
`

export const InfoContainer = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
`