import { makeAutoObservable } from "mobx";
import { NotificationMessages, NotificationType } from "./notificationType";

interface INotification {
  notificationType: NotificationType;
  message: string;
}

export class NotificationStore {
  notification: INotification | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setNotification(notification: { message: NotificationMessages | string, type: NotificationType } | null) {

    this.notification = notification ?  {
      message: notification.message,
      notificationType: notification.type
    } : null;
  }
}