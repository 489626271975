import { Grid } from "@mui/material";
import { Rings } from "react-loader-spinner";

export const Loader = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" height="100%">
      <Rings color="#071C47" height={150} width={150} radius={1} />
    </Grid>
  );
};
