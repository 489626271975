import styled, { css } from 'styled-components';
import { Grid } from "@mui/material";
import { ReactComponent as Arrow } from '../../../../../../../../../assets/icon/arrowRight.svg';
import theme from '../../../../../../../../../theme/theme';

const arrowCommon = css`
  position: absolute;
  height: 24px;
  width: 24px;
  cursor: pointer;
`

export const ArrowRight = styled(Arrow)`
  ${arrowCommon};
  right: -32px;
`

export const ArrowLeft = styled(Arrow)`
  ${arrowCommon};
  left: -32px;
  transform: rotate(180deg);
`

export const Caption = styled.p`
  position: absolute;
  bottom: -32px;
  word-break: break-word;
  left: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 1.6px;
  color: ${theme.colors.abbvie};
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
`

export const ImageContainer = styled(Grid)`
  cursor: pointer;
  position: relative;
  padding: 12px !important;
  max-width: 224px !important;

  & img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
  }

  & svg {
    position: absolute;
    z-index: 1;
    right: 12px;
  }

  & figure {
    overflow: hidden;
  }

  & figcaption {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`