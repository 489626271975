import {observer} from "mobx-react";

// hooks
import {useCaseSettingsStore} from "../../../../../../../store/hooks";

// components
import {Stage01} from "./stages/stage01/Stage01";
import {Stage02} from "./stages/stage02/Stage02";
import {Stage03} from "./stages/stage03/Stage03";
import {Stage04} from "./stages/stage04/Stage04";
import {Summary} from "../../summary/Summary";

export const HcvForm = observer(() => {
  const {currentStage} = useCaseSettingsStore();

  switch (currentStage) {
    case 1:
      return <Stage01/>;
    case 2:
      return <Stage02/>;
    case 3:
      return <Stage03/>;
    case 4:
      return <Stage04/>;
    // TODO add real props
    case 5:
      return <Summary/>;
  }

  return null;
});
