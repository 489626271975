import styled from 'styled-components';

import theme from '../../../../../../theme/theme';
import backgroundNavbar from '../../../../../../assets/img/backgroundNavbar.png';

export const Navbar = styled.div`
  background: url(${backgroundNavbar}), linear-gradient(180deg, #87C1F3 0%, #003B6F 100%);
  background-position: bottom right;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-top: 178px;

  & img {
    height: 53px;
    z-index: 902;
    margin: 85px auto 50px;
    display: block;
  }

  & nav {
    position: relative;
  }
}
`;

export const RightBelt = styled.div`
  background: linear-gradient(180deg, ${theme.colors.blue3} 0%, ${theme.colors.blue2} 100%);
  width: 15px;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 901;
`;

export const RoleBottomContainer = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 12px;
  color: white;
`;