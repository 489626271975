import React from 'react';
import {NavLinkBottomCustom, NavLinkCustom} from "./MenuItem.styled";
import {INavLink} from "../../../../../../interfaces/common/link";

interface IProps {
  linkInfo: INavLink;
}

export const MenuItem: React.FC<IProps> = ({linkInfo}): JSX.Element => {

  if (linkInfo.bottom) {
    return (
      <NavLinkBottomCustom
        to={linkInfo.link}
        className={({isActive}) =>
          isActive ? "active" : "not-active"
        }
      >
        <p>{linkInfo.text}</p>
      </NavLinkBottomCustom>
    )
  } else {
    return (
      <NavLinkCustom
        to={linkInfo.link}
        className={({isActive}) =>
          isActive ? "active" : "not-active"
        }
      >
        <p>{linkInfo.text}</p>
      </NavLinkCustom>
    )
  }

}
