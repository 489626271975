import { createGlobalStyle } from 'styled-components';

const ResetStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-size: 16.5px;
  }

  html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;

  }

  body {
    background: rgb(249, 252, 255);
  }

  ol, ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  legend {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  /* Add more styles as needed... */
`;

const OtherStyle = createGlobalStyle`
  #root {
    position: relative;
    font-family: 'Lato', sans-serif !important;
    overflow-x: hidden;
  }

  p, span, div {
    font-family: 'Lato', sans-serif !important;
  }

  sup {
    font-size: xx-small;
    vertical-align: super;
  }

  main {
    height: 100%;
    position: relative;
  }

  * {
    scrollbar-color: #63666a #f2f2f2;
  }

  *::-webkit-scrollbar {
    width: 11px;
  }

  *::-webkit-scrollbar-track {
    background: #f2f2f2;
    border: 1px solid #BBBCBC;
    border-radius: 5px;
    margin-left: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background: #63666a;
    border: 1px solid #BBBCBC;
    border-radius: 5px;
  }

`;



export const GlobalStyle = () => <><ResetStyle/><OtherStyle/></>