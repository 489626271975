import { UserLayout } from "../../../shared/user/layout/UserLayout";
import { useOtherCases } from "./useOtherCases.vm";
import { Section, NoCases } from "./OtherCases.styled";
import { SearchBar } from "../../../shared/ui/searchBar/SearchBar";
import { Grid } from "@mui/material";
import { CaseCard } from "../../../shared/user/cards/CaseCard";
import { MuiPagination } from "../../../shared/pagination/MuiPagination";
import { observer } from "mobx-react-lite";
import { MultipleSelect } from "../../../shared/ui/multipleSelect/MultipleSelect";
import { coinfectionsSettings } from "../myCases/MyCases.helper";

export const OtherCases = observer(() => {
  const {
    filteredListOfOtherCasesByCurrentPage,
    searchValue,
    onChangeSearchHandler,
    pageCount,
    currentPage,
    setCurrentPage,
    switchCoinfectionFilters,
    forms,
    filterForms,
    switchFormFilters,
    filterCoinfections,
  } = useOtherCases();

  return (
    <UserLayout>
      <Grid container>
        <Grid item xs={12} mt={2} display={"flex"} gap={1}>
          <Grid item xs={3}>
            <MultipleSelect
              elementName={"filterCoinfection"}
              staticPlaceholder="Koinfekcja"
              options={[...Object.values(coinfectionsSettings)]}
              selectedValues={filterCoinfections}
              handleChange={(event) => switchCoinfectionFilters(event)}
              isFilterList
            />
          </Grid>
          <Grid item xs={4.5}>
            <MultipleSelect
              elementName={"filterForm"}
              staticPlaceholder="Formularz"
              options={forms ? [{ id: 0, title: "Wszystko" }, ...forms] : []}
              selectedValues={filterForms}
              handleChange={(event) => switchFormFilters(event)}
              isFilterList
            />
          </Grid>
          <Grid item xs={4.5}>
            <SearchBar
              filterValue={searchValue}
              filterChangeHandler={onChangeSearchHandler}
            />
          </Grid>
        </Grid>
      </Grid>
      <Section>
        {filteredListOfOtherCasesByCurrentPage &&
        filteredListOfOtherCasesByCurrentPage.length > 0 ? (
          <Grid
            container
            flexDirection={"column"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid container gap={"20px"} display={"flex"}>
              {filteredListOfOtherCasesByCurrentPage.map(
                ({
                  status,
                  id,
                  customId,
                  title,
                  updatedAt,
                  coInfection,
                  area,
                  form,
                }) => (
                  <Grid item width={"100%"} maxWidth={"15%"} key={customId}>
                    <CaseCard
                      area={area}
                      form={form}
                      key={id}
                      id={id}
                      coInfection={coInfection}
                      status={status}
                      customId={customId}
                      updatedAt={updatedAt}
                      title={title}
                    />
                  </Grid>
                )
              )}
            </Grid>
            <MuiPagination
              count={pageCount}
              page={currentPage}
              onChange={(e, value: number) => {
                setCurrentPage(value);
              }}
            />
          </Grid>
        ) : (
          <NoCases>Brak opisów przypadków.</NoCases>
        )}
      </Section>
    </UserLayout>
  );
});
