import { RoleCategory, RoleFunction } from "../constants/projectUserRoles";

export enum GeneralLink {
  LOGIN = "/login",
  RETRIEVE_PASSWORD = "/retrieve-password",
  CHANGE_PASSWORD = "/reset-password/:token",
  REGISTER_VIEWER = "/register/:token",
  REGISTER_PUBLISH = "/register/publish/:token",
  REGISTER_ADMIN = "/register/admin/:token",
  HOME = "/",
  PAGE_404 = "*",
}

export enum AdminLink {
  REDIRECTOR = "/",
  ADD_USER = "/add-user",
  PUBLISHING_USERS = "/publishing-users",
  VIEWING_USERS = "/viewing-users",
  OTHERS_ADMINISTRATORS = "/other-administrators",
  OTHER = "/other",
  CASES = "/cases",
  HISTORY = "/cases/:area/:form/:caseId",
  VIEW_CASE = "/case/view/:area/:form/:caseId",
  FORMS = "/forms",
  AREAS = "/areas",
  EDIT_FORM = "/forms/:id",
  EDIT_AREA = "/areas/:id",
  ADD_FORM = "/forms/new",
  ADD_AREA = "/areas/new",
}

export enum UserLink {
  HOME = "/",
  SET_AREA_AND_FORM = "/set-area-and-form",
  ADD_CASE = "/case/add/:area/:form",
  EDIT_CASE = "/case/edit/:area/:form/:caseId",
  VIEW_CASE = "/case/view/:area/:form/:caseId",
  MY_CASES = "/cases",
  OTHER_CASES = "/cases/other",
  PROFILE = "/profile",
}

export const generateLink = (
  link: GeneralLink | AdminLink | UserLink,
  id?: [string, number | string][] | (number | string)
) => {
  let newLink = String(link);
  if (id) {
    if (Array.isArray(id)) {
      id.forEach((el) => (newLink = newLink.replace(el[0], String(el[1]))));
    } else {
      newLink = newLink.replace(":id", String(id));
    }
  }
  return newLink;
};

export const generateNavigationLink = (
  roleFunction: RoleFunction | null,
  roleCategory: RoleCategory
): GeneralLink | AdminLink | UserLink => {
  let path: GeneralLink | AdminLink | UserLink = GeneralLink.LOGIN;
  if (roleCategory === RoleCategory.ADMIN) {
    if (RoleFunction.ROLE_ADMIN === roleFunction) {
      path = AdminLink.ADD_USER;
    }
    if (
      RoleFunction.ROLE_ACCEPTING === roleFunction ||
      RoleFunction.ROLE_SCIENTIFIC === roleFunction
    ) {
      path = AdminLink.CASES;
    }
  } else if (roleCategory === RoleCategory.USER) {
    path = UserLink.HOME;
  }

  return path;
};
