import { useRootStore } from "../../RootStateContext";

export const useNpsStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.npsStore) {
    throw new Error("npsStore store should be defined");
  }

  return rootStore.npsStore;
};
