import {useRootStore} from '../RootStateContext';

export const useCasesStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.casesStore) {
    throw new Error('casesStore store should be defined');
  }

  return rootStore.casesStore;
};
