import { FC } from 'react';
import { observer } from "mobx-react-lite";

// types
import { WritingCommentProps } from './WritingComment.types';

// hooks 
import { useWritingCommentVm } from "./useWritingComment.vm";

// components
import { Grid } from '@mui/material';
import { Button } from '../../../../../../shared/button/Button';
import { Input } from '../../../../../../shared/ui/input/Input';
import {
  WritingCommentContainer,
  WritingCommentForm, WritingCommentMode, WritingCommentSubTitle,
  WritingCommentTitle,
  WritingCommentValidationInfo
} from './WritingComment.styled';

// lib 
import theme from '../../../../../../../theme/theme';

export const WritingComment: FC<WritingCommentProps> = observer(({handleModalClose}) => {

  const {
    sendQuestion,
    questionContent,
    setQuestionContent
  } = useWritingCommentVm(handleModalClose) 

  return (
    <>
      <WritingCommentMode onClick={handleModalClose}></WritingCommentMode>
      <WritingCommentContainer>
        <WritingCommentForm>
          <Grid container gap={2}>
            <Grid item>
              <WritingCommentTitle>
                PYTANIE<span>*</span>:
              </WritingCommentTitle>
              <WritingCommentSubTitle>
                Zadaj pytanie autorowi przypadku. Zostanie ono przesłane na adres e-mail autora.
              </WritingCommentSubTitle>
            </Grid>
            <Input
              value={questionContent}
              onChange={(e) => setQuestionContent(e.target.value)}
              width={'100%'}
              name={"comment"}
              type={'textarea'}
              isRequired={true}
              colorLabel={theme.colors.grey1}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.blue4}
            />
          </Grid>
          <Grid container display={'flex'} justifyContent={'space-between'}>
            <WritingCommentValidationInfo>*Pola obowiązkowe</WritingCommentValidationInfo>
            <Button size={'tiny'} text={'ZAPISZ I WYŚLIJ >>'} width={'168px'} clickHandler={sendQuestion}
                    backgroundColor={theme.colors.abbvie} hoverColor={theme.colors.lightAbbvie} color={'white'}/>
          </Grid>
        </WritingCommentForm>
      </WritingCommentContainer>
    </>
  )
})