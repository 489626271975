import { IFormTypeForm } from '../interfaces/admin/form/formElementForm';
import { IForms } from '../interfaces/common/forms';
import {makeAutoObservable} from "mobx";

export class FormsStore {
  forms: IForms[] | null = null
  isFormsLoaded: boolean = false;
  formEdit: IFormTypeForm | null = null;
  constructor() {
     makeAutoObservable(this);
  }

  setForms(value: IForms[]) {
      this.forms = value;
      this.isFormsLoaded = true
   }
  
  resetForms() {
      this.forms = null
      this.isFormsLoaded = false;
  }

  setFormEdit(form: IFormTypeForm, id: number) {
    const formEditElement = {...form};
    formEditElement.id = id;
    this.formEdit = formEditElement;
  }

  clearFormEdit() {
    this.formEdit = null;
  }

}