import { IFormData } from '../../../../interfaces/common/form/formData'

type Name = 'fullName' | 'email' | 'phone'

export interface IFormDataAddUser extends IFormData {
  name: Name;
}

export const formData: IFormDataAddUser[] = [
  {
    name: 'fullName',
    label: 'Imię i nazwisko',
    type: 'text',
    placeholder: 'Wpisz imię i nazwisko...',
    isRequired: true
  },
  {
    name: 'email',
    label: 'Email',
    type: 'text',
    placeholder: 'Wpisz email...',
    isRequired: true
  },
  {
    name: 'phone',
    label: 'Numer kontaktowy',
    type: 'text',
    placeholder: 'Wpisz numer telefonu...',
    isRequired: false
  }
]
