type Type = 'text' | 'number' | 'textarea' | 'password';
type Name = 'affiliation' | 'fullName' | 'pwz' | 'phone' | 'password' | 'repeatPassword' | 'workPlace';

export interface IFormData {
  name: Name;
  label: string
  type: Type;
  placeholder: string
}

export const formDataPublish: IFormData[] = [
  {
    name: 'pwz',
    label: 'PWZ',
    type: 'number',
    placeholder: 'Wpisz swój numer PWZ...'
  },
  {
    name: 'affiliation',
    label: 'Afiliacja',
    type: 'text',
    placeholder: 'Wpisz swoją afiliację...'
  },
  {
    name: 'fullName',
    label: 'Imię i Nazwisko',
    type: 'text',
    placeholder: 'Wpisz swoje imię i nazwisko...'
  },
  {
    name: 'workPlace',
    label: 'Miejsce pracy',
    type: 'text',
    placeholder: 'Wpisz placówkę/ośrodek...'
  },
  {
    name: 'phone',
    label: 'Numer kontaktowy',
    type: 'text',
    placeholder: 'Wpisz swój numer telefonu...'
  },
  {
    name: 'password',
    label: 'Hasło',
    type: 'password',
    placeholder: 'Wpisz swoje hasło...'
  },
  {
    name: 'repeatPassword',
    label: 'Powtórz hasło',
    type: 'password',
    placeholder: 'Wpisz ponownie swoje hasło...'
  }
]