import { useRootStore } from "../RootStateContext";

export const useNotificationsStore = () => {
  const rootStore = useRootStore();

  if (!rootStore) {
    throw new Error('notifications store should be defined.');
  }

  return rootStore.notificationsStore;
}