import styled from "styled-components";
import theme from "../../../../theme/theme";
import { ILabelFieldsetContainer } from '../../../../interfaces/ui/uiInfo'

export const LabelContainer = styled.label<ILabelFieldsetContainer>`
  color: ${props => props.color};
  font-size: ${props => props.size === 'small' ? '14px' : props.size === 'superSmall' ? '12px' : '20px'};
  line-height: ${props => props.size === 'small' ? 'unset' : '140%'};
  font-weight: 700;
  text-transform: ${props => props.$isUpperCase ? 'uppercase' : 'none'};
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: ${props => props.$textAlign};
  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: ${props => props.size === 'small' ? '16px' : props.size === 'superSmall' ? '12px' : '20px'};
    line-height: 120%;
    margin-top: 6px;
  }
`

export const LabelInsideContainer = styled.div`
  margin-bottom: 8px;

  & div {
    margin-left: 0;
    margin-bottom: 0;
  }

  & span {
    margin-left: 2px;
    color: ${theme.colors.red};
  }
`
