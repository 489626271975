import React, { useEffect } from "react";
import styled from "styled-components";

import { IHeadCell } from "../../../../interfaces/common/table";
import { createTableRows } from "../../../shared/customTables/utils/createTableRows";
import { useOthersApi } from "../../../../apiHooks/useOthersApi";
import { splitData } from "../../../../utils";
import { useOthersStore } from "../../../../store/hooks";
import { TableCellData } from "../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled";

export const useOthers = () => {
  const othersApi = useOthersApi();
  const othersStore = useOthersStore();
  const { getOthers } = othersApi;
  const { others, isOthersLoaded } = othersStore;

  useEffect(() => {
    if (!isOthersLoaded) {
      if (others === null) {
        void getOthers();
      } else {
        void getOthers(false);
      }
    }
  }, [isOthersLoaded, others, getOthers]);

  useEffect(() => {
    return () => othersStore.clearOthers();
  }, [othersStore]);

  const headCells: IHeadCell[] = [
    { id: "fullName", numeric: false, label: "Imię i nazwisko" },
    { id: "email", numeric: false, label: "Email" },
    {
      id: "lastLoggedAt",
      numeric: false,
      label: "Godzina i data ostatniego logowania",
    },
    { id: "login", numeric: false, label: "Login" },
    { id: "password", numeric: false, label: "Hasło" },
  ];

  const tableDataRows = createTableRows(
    others !== null ? others : [],
    headCells,
    false
  );

  const cellsSwitcher = (
    keyName: string,
    value: string | string[] | number,
    onClick?: () => void
  ) => {
    const specialValue =
      keyName === "lastLoggedAt" ? splitData(value.toString()) : ["-", ""];

    switch (keyName) {
      case "id":
        return null;
      case "password":
        return (
          <TableCellData>
            {value === "złe hasło" ? <strong>ZŁE HASŁO</strong> : value}
          </TableCellData>
        );
      case "login":
        return (
          <TableCellData>
            {value === "zły login" ? <strong>ZŁY LOGIN</strong> : value}
          </TableCellData>
        );
      case "lastLoggedAt":
        return (
          <TableCellData>
            <CellTime>{specialValue[0]}</CellTime>
            <span>{specialValue[1]}</span>
          </TableCellData>
        );
      default:
        return <TableCellData>{value}</TableCellData>;
    }
  };

  return {
    othersIsNotNull: others !== null,
    others,
    headCells,
    tableDataRows,
    cellsSwitcher,
  };
};

const CellTime = styled.span`
  margin-right: 8px;
`;
