import { useApiCall } from "./useApiCall";
import { useCallback } from "react";
import { useRolesStore } from "../store/hooks/useRolesStore";
import { IResponse } from "../interfaces/common/response";
import { IRole } from "../interfaces/common/roles";

export const useRolesApi = () => {
  const { api } = useApiCall();
  const rolesStore = useRolesStore();

  const getRoles = useCallback(
    async (isLoader: boolean = true) => {
      const res: IResponse<IRole> = await api.apiInstance.get(
        "roles",
        isLoader
      );

      if (res) {
        rolesStore.setRoles(res.items);
      }
    },
    [api.apiInstance, rolesStore]
  );

  return {
    getRoles,
  };
};
