import {useRootStore} from "../../RootStateContext";

export const useLzssStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.lzssStore) {
    throw new Error("lzssStore store should be defined");
  }

  return rootStore.lzssStore;
};
