import { observer } from "mobx-react-lite";

// hooks
import { useZzskStore } from "../../../../../../../store/hooks";
import { useViewCase } from "../../../viewCaseCreator/useViewCase";
// types
import { EnAreaNames, EnFormNames, } from "../../../../../../../store/caseSettingsStore";

// components
import { TopViewCase } from "../../topViewCase/TopViewCase";
import { UserLayout } from "../../../../../../shared/user/layout/UserLayout";
import { PatientSection } from "./patientSection/PatientSection";
import { CurrentTreatmentSection } from "./currentTreatmentSection/CurrentTreatmentSection";
import { TreatmentProgramSection } from "./treatmentProgramSection/TreatmentProgramSection";
import { Observations } from "./obervations/Observations";

export const ZzskViewCase = observer(() => {
  const zzskStore = useZzskStore();
  const {isCase} = useViewCase(
    EnAreaNames.rheumatology,
    EnFormNames.zzsk,
    zzskStore
  );

  return (
    <UserLayout>
      {isCase && (
        <>
          <TopViewCase caseStore={zzskStore}/>
          <PatientSection/>
          <CurrentTreatmentSection/>
          <TreatmentProgramSection/>
          <Observations/>
        </>
      )}
    </UserLayout>
  );
});
