import { IFormData } from "../../../../../interfaces/common/form/formData";

type Name = 'password' | 'repeatPassword';

export interface IFormDataChangePassword extends IFormData {
  name: Name;
}

export const formData: IFormDataChangePassword[] = [
  {
    name: 'password',
    label: 'Hasło',
    type: 'password',
    placeholder: 'Wpisz swoje hasło...'
  }, {
    name: 'repeatPassword',
    label: 'Potwierdź nowe hasło',
    type: 'password',
    placeholder: 'Wpisz jeszcze raz nowe hasło...'
  }
]