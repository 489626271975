import { useParams } from "react-router-dom";
import { useState } from "react";

// lib
import { useAuthStore } from '../../../../../../../store/hooks';
import { useCaseApi } from '../../../../../../../apiHooks';
import { EnAreaNames, EnFormNames } from '../../../../../../../store/caseSettingsStore';

export const useWritingCommentVm = (handleModalClose: () => void) => {
  // params
  const {caseId, area, form} = useParams()

  // define API calls
  const {sendQuestionEmail} = useCaseApi()

  // auth store
  const authStore = useAuthStore()

  const [questionContent, setQuestionContent] = useState('')

  const sendQuestion = async () => {
    if (caseId) {
      const questionobject = {
        fullName: authStore.fullName,
        email: authStore.user,
        message: questionContent
      }
      const res = await sendQuestionEmail(+caseId, questionobject, area as EnAreaNames, form as EnFormNames)
      if (res) {
        handleModalClose()
        setQuestionContent('')
      }
    }
  }

  return {
    sendQuestion,
    questionContent,
    setQuestionContent
  }
}