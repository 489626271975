import { useRootStore } from "../../RootStateContext";

export const useLzsrStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.lzsrStore) {
    throw new Error("lzsrStore store should be defined");
  }

  return rootStore.lzsrStore;
};
