import { useEffect } from "react";
import { useParams } from "react-router-dom";

// hooks
import { TUniversalStage, useCaseApi } from "../../../../../../apiHooks";
import { useCaseSettingsStore } from "../../../../../../store/hooks";

// types
import {
  EnAreaNames,
  EnFormNames,
} from "../../../../../../store/caseSettingsStore";

export const useButtonsEffects = (
  values: TUniversalStage,
  submitForm: () => Promise<any>
) => {
  // params and location
  const params = useParams();
  const { area, form, caseId } = params;

  // caseSettingsStore
  const caseSettingsStore = useCaseSettingsStore();
  const { isNextButtonClicked, isPrevButtonClicked, isDraftButtonClicked } =
    caseSettingsStore;

  const { saveDraft } = useCaseApi();

  // isNextButtonCliked true then submit form and wait for response
  // if response is success then go to next stage
  // and set isNextButtonClicked to false

  useEffect(() => {
    if (isNextButtonClicked) {
      (async () => {
        const res = await submitForm();
        if (res) {
          caseSettingsStore.increaseCurrentStage();
        } else {
          caseSettingsStore.unCLickNextButton();
        }
      })();
    }
    // eslint-disable-next-line
  }, [isNextButtonClicked]);

  // isPreviosButtonClicked true then go to previos stage without submit form
  // and set isPreviosButtonClicked to false
  useEffect(() => {
    if (isPrevButtonClicked) {
      caseSettingsStore.decreaseCurrentStage();
    }
  }, [caseSettingsStore, isPrevButtonClicked]);

  // isDraftButtonClicked true then save draft case
  // and set isDraftButtonClicked to false
  useEffect(() => {
    if (isDraftButtonClicked) {
      void saveDraft({
        values: values,
        caseId: Number(caseId),
        area: area as EnAreaNames,
        form: form as EnFormNames,
      });
      caseSettingsStore.unClickDraftButton();
    }
    // eslint-disable-next-line
  }, [isDraftButtonClicked]);
};
