import { useApiCall } from "./useApiCall";
import { useCallback } from "react";
import { useHomeStore } from "../store/hooks";

export const useHomeApi = () => {
  const { api } = useApiCall();
  const homeStore = useHomeStore();

  const getListOfLastCases = useCallback(
    async (isLoading: boolean = true) => {
      const res = await api.apiInstance.get("situations/last", isLoading);

      if (res) {
        homeStore.setListOfLastCases(res.items);
      }
    },
    [api.apiInstance, homeStore]
  );

  return {
    getListOfLastCases,
  };
};
