import { useRootStore } from '../RootStateContext';

export const useFormsStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.formsStore) {
    throw new Error('formsStore store should be defined');
  }

  return rootStore.formsStore;
};
