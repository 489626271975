import {UserLink} from "../../../../../../../utils";
import {useNavigate} from "react-router-dom";

export const useCompletedCase = (viewLink: () => void, onClickSubmit: () => Promise<void>, finishProcess: () => void) => {
  const navigate = useNavigate()
  
  const navigateToView = () => {
    viewLink();
  }

  const navigateToHome = () => {
    navigate(UserLink.HOME);
  }

  const submitHandler = async () => {
    await onClickSubmit()
    finishProcess()
  }

  return {navigateToView, submitHandler, navigateToHome}
}
