import React from 'react'
import { useRegisterViewingForm } from './useRegisterViewingForm.vm'
import { RegisterForm } from "../../form/RegisterForm";
import { formDataViewing } from "./registerFormData";
import { observer } from "mobx-react-lite";

interface IProps {
  labelColor: string
  buttonBcgColor: string;
  buttonTextColor: string
}

export const RegisterFormViewingComponent: React.FC<IProps> = observer(({
                                                                          labelColor,
                                                                          buttonBcgColor,
                                                                          buttonTextColor
                                                                        }) => {
  const {
    formik,
    confirmForm,
    emailInfo
  } = useRegisterViewingForm()

  return <RegisterForm formik={formik}
                       confirmForm={confirmForm}
                       labelColor={labelColor}
                       buttonTextColor={buttonTextColor}
                       buttonBcgColor={buttonBcgColor}
                       formData={formDataViewing}
                       emailInfo={emailInfo}
  />
})
