
import {makeAutoObservable} from "mobx";
import {IHistory} from '../interfaces/user/history/history';

export class HistoryStore {
  history: IHistory | null = null;
  isHistoryLoaded: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setHistory(history: IHistory) {
    this.history = history;
    this.isHistoryLoaded = true;
  }

  resetHistory() {
    this.history = null;
    this.isHistoryLoaded = false;
  }
}
