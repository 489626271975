import { Grid } from "@mui/material";
import { GroupForm, Label, NormalText, NormalTextDiv, } from "../HcvViewCase.styled";
import { ReactComponent as CalendarIcon } from "../../../../../../../../assets/icon/calendar.svg";
import { Attachments } from "../common/attachments/Attachments";
import { Drugs } from "./drugs/Drugs";
import { observer } from "mobx-react-lite";
import { checkValueIsEmpty } from "../HcvViewCase.helper";
import { yearStringify } from "../../../../../../../../utils";
import { useHcvStore } from "../../../../../../../../store/hooks";
import { placesOfDetectionSelect } from "../../../../../draftCase/forms/virusology/hcv/stages/stage02/stageSettings";

export const DiagnosisSection = observer(() => {
  const caseStore = useHcvStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={5}>
      <Grid item xs={12} mb={"36px"}>
        <GroupForm>Rozpoznanie:</GroupForm>
      </Grid>
      <Grid item xs={12}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid item xs={3}>
            <Label>DATA WYKRYCIA ZAKAŻENIA*</Label>
            <NormalTextDiv>
              <Grid container display={"flex"} gap={2}>
                <CalendarIcon/>
                <span>
                  {checkValueIsEmpty(
                    new Date(caseElement.dateOfDetection ?? "").getFullYear()
                  )}
                </span>
              </Grid>
            </NormalTextDiv>
          </Grid>
          <Grid item xs={9}>
            <Label>MIEJSCE WYKRYCIA*</Label>
            <NormalText>
              {checkValueIsEmpty(
                placesOfDetectionSelect?.find(
                  (el) => el.id === (caseElement?.placeOfDetection as number)
                )?.title
              )}
            </NormalText>
          </Grid>
          <Grid item xs={12} mt={3}>
            <Label>DROGA ZAKAŻENIA</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.routeOfInfection)}
            </NormalText>
            <NormalText>{caseElement.routeOfInfectionDesc}</NormalText>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid item xs={12} mb={3}>
              <Label $size={"large"} $mb={0}>
                ZAAWANSOWANE CHOROBY WĄTROBY W MOMENCIE WYKRYCIA
              </Label>
            </Grid>
            {caseElement.scaleScore && (
              <Grid item xs={12} ml={2} mb={2}>
                <Label $mb={0}>{caseElement.scaleScore?.toUpperCase()}</Label>
                <NormalText>
                  {caseElement.scaleScore === "wynik w skali METAVIR" &&
                    caseElement.metavirDesc}
                  {caseElement.scaleScore === "APRI lub FIB-4" &&
                    caseElement.apriDesc}
                </NormalText>
              </Grid>
            )}
            <Grid item xs={12} ml={2} mb={2}>
              <Label $mb={0}>KLINICZNE OBJAWY MARSKOŚCI</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.clinicalSignsOfCirrhosis)}
              </NormalText>
            </Grid>
            <Grid item xs={12} ml={2} mb={2}>
              <Label $mb={0}>POZAWĄTROBOWE MANIFESTACJE HCV</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.extrahepaticManifestationsOfHCV)}
              </NormalText>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Label $mb={0}>
                OZNACZENIE HCV RNA METODĄ ILOŚCIOWĄ{" "}
                <span className={"unit"}>[IU/mL, kopii/ml]</span>
              </Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.hcvRna)}{" "}
                {checkValueIsEmpty(caseElement.hcvRnaUnit)}
              </NormalText>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid item xs={12} mb={2}>
              <Label $mb={0}>MORFOLOGIA KRWI</Label>
            </Grid>
            <Grid item xs={1.5}>
              <Label $mb={0}>
                RBC <span className={"unit"}>[mln/µl]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.rbc)}</NormalText>
            </Grid>
            <Grid item xs={1.5}>
              <Label $mb={0}>
                HGB <span className={"unit"}>[g/dl]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.hgb)}</NormalText>
            </Grid>
            <Grid item xs={1.5}>
              <Label $mb={0}>
                HCT <span className={"unit"}>[%]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.htc)}</NormalText>
            </Grid>
            <Grid item xs={1.5}>
              <Label $mb={0}>
                PLT <span className={"unit"}>[tys/µl]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.plt)}</NormalText>
            </Grid>
            <Grid item xs={1.5}>
              <Label $mb={0}>
                WBC <span className={"unit"}>[tys/µl]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.wbc)}</NormalText>
            </Grid>
            <Grid item xs={1.5}>
              <Label $mb={0}>
                MCV <span className={"unit"}>[fl]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.mcv)}</NormalText>
            </Grid>
            <Grid item xs={1.5}>
              <Label $mb={0}>
                MCH <span className={"unit"}>[pg]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.mch)}</NormalText>
            </Grid>
            <Grid item xs={1.5}>
              <Label $mb={0}>
                MCHC <span className={"unit"}>[g/dl]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.mchc)}</NormalText>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2} gap={4}>
            <Grid item mb={3}>
              <Label $mb={0}>
                OZNACZENIE AKTYWNOŚCI AIAT <span className={"unit"}>[U/l]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.aiat)}</NormalText>
            </Grid>
            <Grid item mb={3}>
              <Label $mb={0}>
                OZNACZENIE STĘŻENIA ALBUMINY{" "}
                <span className={"unit"}>[g/dl]</span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.albumin)}</NormalText>
            </Grid>
            <Grid item mb={3}>
              <Label $mb={0}>
                OZNACZENIE STĘŻENIA BILIRUBINY{" "}
                <span className={"unit"}>[mg/dl]</span>
              </Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.bilirubin)}
              </NormalText>
            </Grid>
            <Grid item mb={3}>
              <Label $mb={0}>
                CZAS LUB WSKAŻNIK PROTROMBINOWY{" "}
                <span className={"unit"}>{caseElement.prothrombinUnit}</span>
              </Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.prothrombin)}
              </NormalText>
            </Grid>
            <Grid item>
              <Label $mb={0}>
                OZNACZENIE POZIOMU KREATYNINY{" "}
                <span className={"unit"}>[mg/dl]</span>
              </Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.creatinine)}
              </NormalText>
            </Grid>
            <Grid item>
              <Label $mb={0}>
                OZNACZENIE POZIOMU eGFR{" "}
                <span className={"unit"}>
                  [ml/min/1,73m<sup>2</sup>]
                </span>
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.egfr)}</NormalText>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid item xs={4}>
              <Label $mb={0}>
                OZNACZENIE W KIERUNKU ZAKAŻENIA HIV (PRZECIWCIAŁA
                ANTY-HIV/ANTYGEN P24)
              </Label>
              <NormalText>{checkValueIsEmpty(caseElement.antiHiv)}</NormalText>
            </Grid>
            <Grid item xs={8}>
              <Label $mb={0}>OZNACZENIE ANTYGENU HBS</Label>
              <NormalText>{checkValueIsEmpty(caseElement.hbs)}</NormalText>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid item xs={12} mb={3}>
              <Label $mb={0}>
                USG jamy brzusznej lub inne badanie obrazowe istotne z punktu
                widzenia terapeutycznego (JEŚLI NIE BYŁO WYKONYWANE W OKRESIE
                OSTATNICH 6 MIESIĘCY)
              </Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.abdominalUltrasound)}
              </NormalText>
            </Grid>
            {caseElement.abdominalUltrasoundFiles &&
              caseElement.abdominalUltrasoundFiles.length > 0 && (
                <Grid item xs={12}>
                  <Attachments
                    attachments={caseElement.abdominalUltrasoundFiles}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>

        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid item xs={12} mb={3}>
              <Label $size={"large"} $mb={0}>
                OCENA WŁÓKNIENIA WĄTROBY
              </Label>
            </Grid>
            {caseElement.liverFibrosis && (
              <Grid item xs={12} mb={2}>
                <Label $mb={0}>
                  {caseElement.liverFibrosis ===
                    "FIBROSKAN/ELASTIGRAFIA [kPa]" && (
                      <>
                        FIBROSKAN/ELASTOGRAFIA{" "}
                        <span className={"unit"}>[kPa]</span>
                      </>
                    )}
                  {caseElement.liverFibrosis === "APRI" && <>APRI</>}
                  {caseElement.liverFibrosis === "FIB-4" && <>FIB-4</>}
                </Label>
                <NormalText>
                  {caseElement.liverFibrosis ===
                    "FIBROSKAN/ELASTIGRAFIA [kPa]" && caseElement.fibroSkanDesc}
                  {caseElement.liverFibrosis === "APRI" &&
                    caseElement.apriLiverDesc}
                  {caseElement.liverFibrosis === "FIB-4" &&
                    caseElement.fibLiverDesc}
                </NormalText>
              </Grid>
            )}
            <Grid item xs={12} mb={2}>
              <Label $mb={0}>Biopsja wątroby</Label>
              {caseElement.liverBiopsy && (
                <NormalText>{caseElement.liverBiopsyDesc ?? ""}</NormalText>
              )}
            </Grid>
            {caseElement.liverBiopsyFiles &&
              caseElement.liverBiopsyFiles.length > 0 && (
                <Grid item xs={12}>
                  <Attachments attachments={caseElement.liverBiopsyFiles}/>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid item xs={12} mb={2}>
              <Label $mb={0}>OCENA GENOTYPU HCV</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.hcvGenotype)}
              </NormalText>
              {caseElement.hcvGenotypeMix && <NormalText>
                {checkValueIsEmpty(caseElement.hcvGenotypeMix)}
              </NormalText>}
            </Grid>
            <Grid item xs={12} mb={2}>
              <Label $mb={0}>CHOROBY WSPÓŁISTNIEJĄCE</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.presenceOfComorbidities)}
              </NormalText>
            </Grid>
            {caseElement.dataHiv && (
              <Grid item xs={12} mb={2}>
                <Label $mb={0}>DANE NA TEMAT KOINFEKCJI HIV</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.dataHiv)}
                </NormalText>
              </Grid>
            )}
            {caseElement.dataHbv && (
              <Grid item xs={12} mb={2}>
                <Label $mb={0}>DANE NA TEMAT KOINFEKCJI HBV</Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.dataHbv)}
                </NormalText>
              </Grid>
            )}
            <Grid item xs={12} mb={2}>
              <Label $mb={0}>WCZEŚNIEJSZA HISTORIA MEDYCZNA PACJENTA</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.previousHistory)}
              </NormalText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid container mb={2} flexDirection={"row"} gap={4}>
              <Grid item>
                <Label $mb={0}>
                  CZY PACJENT BYŁ WCZEŚNIEJ LECZONY PRZECIWWIRUSOWO?
                </Label>
                <NormalText $capitalize={true}>
                  <strong>
                    {checkValueIsEmpty(
                      caseElement.patientPreviouslyTreatedWithAntivirals
                    )}
                  </strong>
                </NormalText>
              </Grid>
              {caseElement.patientPreviouslyTreatedCount !== null &&
                caseElement.patientPreviouslyTreatedCount >= 0 && (
                  <Grid item>
                    <Label $mb={0}>ile razy?</Label>
                    <NormalText $capitalize={true}>
                      <strong>
                        {caseElement.patientPreviouslyTreatedCount}
                      </strong>
                    </NormalText>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>

        {caseElement.patientPreviouslyTreatedItems.length > 0 && (
          <Grid item xs={12} mt={5}>
            {caseElement.patientPreviouslyTreatedItems.map((el, index) => (
              <Grid
                container
                borderRadius={1}
                key={index}
                bgcolor={"white"}
                p={2}
              >
                <Grid item xs={12} mb={2}>
                  <Label $mb={0}>{index + 1} leczenie</Label>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Label $mb={0}>rok leczenia</Label>
                  <NormalText>
                    {checkValueIsEmpty(yearStringify(el.year as string))}
                  </NormalText>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Label $mb={0}>opisz schemat</Label>
                  <NormalText>{checkValueIsEmpty(el.description)}</NormalText>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Label $mb={0}>odpowiedź na terapię</Label>
                  <NormalText>{checkValueIsEmpty(el.response)}</NormalText>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

        {caseElement.otherElements && (
          <Grid item xs={12} mt={5}>
            <Grid container borderRadius={1} bgcolor={"white"} p={2}>
              <Grid item xs={12} mb={2}>
                <Label $size={"large"} $mb={8}>
                  INNE ELEMENTY WAŻNE Z PERSPEKTYWY PACJENTA
                </Label>
                <NormalTextDiv>
                  {caseElement.otherElements.length > 0 && (
                    <ul>
                      <>
                        {caseElement.otherElements.map((element) => (
                          <li key={element}>{element}</li>
                        ))}
                      </>
                    </ul>
                  )}
                </NormalTextDiv>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            <Grid item xs={12}>
              <Label $size={"large"}>AKTUALNIE PRZYJMOWANE LEKI</Label>
            </Grid>
            <Drugs drugs={caseElement.drugs}/>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={5}>
          <Grid container borderRadius={1} bgcolor={"white"} p={2}>
            {caseElement.liverFibrosis && (
              <Grid item xs={12} mb={2}>
                <Label $mb={0}>
                  INFORMACJA O POTENCJALNYCH INTERAKCJACH LEKOWYCH LUB ICH BRAKU
                </Label>
                <NormalText>
                  {checkValueIsEmpty(caseElement.potentialDrugInteractions)}
                </NormalText>
              </Grid>
            )}
            <Grid item xs={12} mb={2}>
              <Label $mb={0}>
                raport z internetowej wyszukiwarki interakcji
              </Label>
            </Grid>
            {caseElement.potentialDrugInteractionFiles &&
              caseElement.potentialDrugInteractionFiles.length > 0 && (
                <Grid item xs={12}>
                  <Attachments
                    attachments={caseElement.potentialDrugInteractionFiles}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
