import {IFormData} from "../../../../../interfaces/common/form/formData";

type Name = 'email';

export interface IFormDataRetrievePassword extends IFormData {
  name: Name;
}

export const formData: IFormDataRetrievePassword =
  {
    name: 'email',
    label: 'Email',
    type: 'text',
    placeholder: 'Wpisz swój email...'
  };