import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  checkboxClasses,
  buttonClasses,
} from "@mui/material";

import {
  MultipleSelectStringStyled,
  Label,
  RedSpan,
} from "./MultipleSelectString.styled";
import theme from "../../../../theme/theme";

interface IProps {
  selectedValues: string[];
  elementName: string;
  subtitle?: string;
  options: string[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  subtitleLength?: number;
  color?: string;
  size?: string;
  label?: string;
  defaultValue?: string;
  isRequired?: boolean;
  isError?: boolean;
}

export const MultipleSelectString: React.FC<IProps> = ({
                                                         defaultValue = "Wybierz",
                                                         isError,
                                                         selectedValues,
                                                         elementName,
                                                         options,
                                                         setFieldValue,
                                                         color,
                                                         label,
                                                         isRequired,
                                                       }) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    if (event.target.value.includes('Brak')) {
      if (selectedValues.includes('Brak')) {
        const value = event.target.value as string[] || []
        setFieldValue(elementName, value?.filter(el => el !== 'Brak'));
      } else {
        setFieldValue(elementName, ['Brak']);
      }

    } else {
      setFieldValue(elementName, event.target.value);
    }

  };

  return (
    <MultipleSelectStringStyled>
      <FormControl
        fullWidth={true}
        sx={{
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
            borderWidth: "0.15rem",
          },
        }}
      >
        {label && (
          <Label color={color} size={"small"}>
            {label}
            {isRequired && <RedSpan>*</RedSpan>}
          </Label>
        )}
        <Select
          IconComponent={() => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="8"
              viewBox="0 0 11 8"
              fill="none"
            >
              <path
                d="M0.47792 1.95236L1.6077 0.688477L5.31983 4.84125L9.03196 0.688477L10.1617 1.95236L5.31983 7.36903L0.47792 1.95236Z"
                fill="#646464"
              />
            </svg>
          )}
          onChange={handleChange}
          id={elementName}
          multiple
          value={selectedValues}
          displayEmpty={true}
          renderValue={(value) =>
            value?.length
              ? Array.isArray(value)
                ? value.join(", ")
                : value
              : <span style={{color: '#AFAFAF'}}>{defaultValue}</span>
          }
          sx={{
            color: "#646464",
            borderRadius: "10px",
            fontSize: "16px",
            lineHeight: "36px",
            height: "36px",
            outline: "none",
            backgroundColor: 'rgba(255,255,255)',
            border: isError
              ? "2px solid #A41313"
              : "2px solid #E0E0E0",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          {options.map((option: string) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                padding: "12px 16px",
                height: "auto",
                margin: 0,
                whiteSpace: "normal",
                maxWidth: 704,
                borderBottom: "1px solid #D3D3D3",
              }}
            >
              <ListItemText primary={option}/>
              <Checkbox
                sx={{
                  padding: "0 8px",
                  "& .MuiButtonBase-root": {
                    color: "#3370D9",
                  },
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: theme.colors.abbvie,
                  },
                  [`&, &.${buttonClasses.root}`]: {
                    color: theme.colors.grey1,
                  },
                }}
                checked={selectedValues.includes(option)}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MultipleSelectStringStyled>
  );
};
