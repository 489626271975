import {useNavigate, useParams} from "react-router-dom";

import {useModal} from '../../../shared/modal/useModal.vm';
import {IFormTypeForm} from "../../../../interfaces/admin/form/formElementForm";
import {useFormsApi} from "../../../../apiHooks/useFormsApi";
import {ValidationMessages} from "../../../../constants/validationMessages";
import {AdminLink} from "../../../../utils";
import {ModalType} from "../../../../constants/modalType";
import {useEffect} from "react";
import {useFormsStore} from "../../../../store/hooks";

export const useAddForm = (isEdit: boolean) => {
  const {id} = useParams();
  const formsStore = useFormsStore();
  const { formEdit } = formsStore;
  const formsApi = useFormsApi();
  const {getForm, addForm, editForm} = formsApi
  const navigate = useNavigate()
  const {handleModal, isModalOpened, closeModal} = useModal();

  useEffect(() => {
    if(isEdit && id && id !== 'new' && formEdit === null){
      void getForm(+id)
    }
  }, [isEdit, id, formEdit, getForm])

  useEffect(() => {
    return () => formsStore.clearFormEdit();
  }, [formsStore])

  const actionHandler = async (formTypeForm: IFormTypeForm) => {
    const isExecuted = await (isEdit ? editForm({...formTypeForm, id: id ? +id : -1}) : addForm(formTypeForm));

    if (isExecuted) {
      handleModal(isEdit ? ModalType.EDIT_MODAL : ModalType.ADD_MODAL);
      return null;
    } else {
      return ValidationMessages.INVALID_FORM_FORM;
    }
  }

  const handleNo = () => {
    navigate(AdminLink.FORMS)
  }

  return { actionHandler, handleModal, isModalOpened, closeModal, handleNo, formEdit}
}