import * as yup from "yup";
// hooks
import {useFormik} from "formik";
import {useCaseSettingsStore, useRzsStore,} from "../../../../../../../../../store/hooks";
// types
import {TRzsStage03} from "./type";
import {EnAreaNames, EnFormNames,} from "../../../../../../../../../store/caseSettingsStore";
import {useParams} from "react-router-dom";
import {useCaseApi} from "../../../../../../../../../apiHooks";
import {useButtonsEffects} from "../../../../hooks/useButtonsEffects";

const validationSchema = yup.object().shape({
  programEntryDate: yup.string().required(),
  das28ResultMoi: yup.string().required(),
  resultMoi: yup.number().required(),
  vasResultMoi: yup.number().required(),
  obResultMoi: yup
    .number()
    .nullable()
    .when("das28ResultMoi", {
      is: (value: string) => value === "crp",
      then: (schema) => schema.required(),
    }),
  crpResultMoi: yup
    .number()
    .nullable()
    .when("das28ResultMoi", {
      is: (value: string) => value === "ob",
      then: (schema) => schema.required(),
    }),
  tenderJointsCountMoi: yup.number().min(0).integer().required(),
  swollenJointsCountMoi: yup.number().min(0).integer().required(),
  treatmentLines: yup.array().of(
    yup.object().shape({
      variants: yup.string().required(),
      otherVariantType: yup.string().when("variants", {
        is: (value: string) => value === "Inne",
        then: (schema) => schema.required(),
      }),
      dose: yup.string().when("variants", {
        is: (value: string) => value === "Inne",
        then: (schema) => schema.required(),
      }),
      tnfInhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory TNF",
        then: (schema) => schema.required(),
      }),
      adalimumabOtherVariant: yup.string().when("tnfInhibitorType", {
        is: (value: string) => value === "Adalimumab inne",
        then: (schema) => schema.required(),
      }),
      adalimumabOtherDose: yup.string().when("tnfInhibitorType", {
        is: (value: string) => value === "Adalimumab inne",
        then: (schema) => schema.required(),
      }),
      il6Dose: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory IL-6",
        then: (schema) => schema.required(),
      }),
      jakInhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory JAK",
        then: (schema) => schema.required(),
      }),
      therapyDuration: yup.number().nullable(),
      durationUnit: yup.string().nullable().when("therapyDuration", {
        is: (value: number) => value > 0,
        then: (schema) => schema.required(),
      }),
      monotherapy: yup.string().required(),
      combinationWithMedicine: yup
        .array()
        .of(
          yup.object().shape({
            combinationDrugName: yup.string().required(),
            otherCombinationDrug: yup.string().when("combinationDrugName", {
              is: (value: string) => value === "Inne",
              then: (schema) => schema.required(),
            }),
            combinationDose: yup.number().required(),
            combinationUnit: yup.string().required(),
            otherCombinationUnit: yup.string().when("combinationUnit", {
              is: (value: string) => value === "Inna jednostka",
              then: (schema) => schema.required(),
            }),
            combinationDuration: yup.number().integer().required(),
          })
        )
        .when("monotherapy", {
          is: (value: string) => value === "kombinacja",
          then: (schema) => schema.min(1).required(),
        }),
      das28FollowupResult: yup.string().required(),
      followupResult: yup.number().required(),
      followupVasResult: yup.number().required(),
      followupObResult: yup
        .number()
        .nullable()
        .when("das28FollowupResult", {
          is: (value: string) => value === "crp",
          then: (schema) => schema.required(),
        }),
      followupCrpResult: yup
        .number()
        .nullable()
        .when("das28FollowupResult", {
          is: (value: string) => value === "ob",
          then: (schema) => schema.required(),
        }),
      followupTenderJointsCount: yup.number().min(0).integer().required(),
      followupSwollenJointsCount: yup.number().min(0).integer().required(),
      symptomsOfIntolerance: yup.string().required(),
    })
  ),
});

const initialValues: TRzsStage03 = {
  programEntryDate: null,
  das28ResultMoi: "",
  resultMoi: null,
  vasResultMoi: null,
  obResultMoi: null,
  crpResultMoi: null,
  tenderJointsCountMoi: null,
  swollenJointsCountMoi: null,
  treatmentLines: [
    {
      variants: "",
      otherVariantType: "",
      dose: "",
      tnfInhibitorType: "",
      adalimumabOtherVariant: "",
      adalimumabOtherDose: "",
      il6Dose: "",
      jakInhibitorType: "",
      therapyDuration: null,
      durationUnit: '',
      monotherapy: "",
      combinationWithMedicine: [],
      das28FollowupResult: "",
      followupResult: null,
      followupVasResult: null,
      followupObResult: null,
      followupCrpResult: null,
      followupTenderJointsCount: null,
      followupSwollenJointsCount: null,
      symptomsOfIntolerance: "",
    },
  ],
};

export const useStage03ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const rzsStore = useRzsStore();
  const {thirdStage, caseStage} = rzsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(thirdStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TRzsStage03>({
    initialValues: {
      ...valuesObj,
      treatmentLines: valuesObj.treatmentLines.length === 0 ? [{
        variants: "",
        otherVariantType: "",
        dose: "",
        tnfInhibitorType: "",
        adalimumabOtherVariant: "",
        adalimumabOtherDose: "",
        il6Dose: "",
        jakInhibitorType: "",
        therapyDuration: null,
        durationUnit: '',
        monotherapy: "",
        combinationWithMedicine: [],
        das28FollowupResult: "",
        followupResult: null,
        followupVasResult: null,
        followupObResult: null,
        followupCrpResult: null,
        followupTenderJointsCount: null,
        followupSwollenJointsCount: null,
        symptomsOfIntolerance: "",
      }] : valuesObj.treatmentLines
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`treatmentLines[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }

    if (field === 'variants') {
      if (value !== 'Inne') {
        setFieldValue(`treatmentLines[${index}].otherVariantType`, '')
        setFieldValue(`treatmentLines[${index}].dose`, '')
      }

      if (value !== 'Inhibitory TNF') {
        setFieldValue(`treatmentLines[${index}].tnfInhibitorType`, '')
      }

      if (value !== 'Inhibitory IL-6') {
        setFieldValue(`treatmentLines[${index}].il6Dose`, '')
      }
      if (value !== 'Inhibitory JAK') {
        setFieldValue(`treatmentLines[${index}].jakInhibitorType`, '')
      }
    }

    if (field === 'tnfInhibitorType') {
      if (value !== 'Adalimumab inne') {
        setFieldValue(`treatmentLines[${index}].adalimumabOtherVariant`, '')
        setFieldValue(`treatmentLines[${index}].adalimumabOtherDose`, '')
      }
    }

  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `treatmentLines[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const deleteTreatment = (index: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines.filter((el, idx) => idx !== index),
    ];
    setFieldValue("treatmentLines", filteredItems);
  };

  const addLine = () => {
    setFieldValue("treatmentLines", [
      ...formik.values.treatmentLines,
      {
        variants: "",
        otherVariantType: "",
        dose: "",
        tnfInhibitorType: "",
        adalimumabOtherVariant: "",
        adalimumabOtherDose: "",
        il6Dose: "",
        jakInhibitorType: "",
        therapyDuration: null,
        durationUnit: '',
        monotherapy: "",
        combinationWithMedicine: [],
        das28FollowupResult: "",
        followupResult: null,
        followupVasResult: null,
        followupObResult: null,
        followupCrpResult: null,
        followupTenderJointsCount: null,
        followupSwollenJointsCount: null,
        symptomsOfIntolerance: "",
      },
    ]);
  };

  const addCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const deleteCombination = (index: number, idx: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines[index].combinationWithMedicine.filter(
        (el, i) => idx !== i
      ),
    ];
    setFieldValue(
      `treatmentLines[${index}].combinationWithMedicine`,
      filteredItems
    );
  };

  const addOtherCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      ...formik.values.treatmentLines[index].combinationWithMedicine,
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  return {
    formik,
    setOption,
    deleteTreatment,
    addLine,
    addCombination,
    deleteCombination,
    addOtherCombination,
    setCombinationOption,
  };
};
