import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const RegisterFormContainer = styled.form<{ $buttonTextColor?: string }>`
  z-index: 50;
  margin: auto 0;
  padding: 30px 8px;
  max-width: 352px;
  width: 100%;

  & button {
    margin-left: auto;
  }


  & svg {
    margin-top: 0;
  }

  & path {
    fill: ${theme.colors.white}
  }
`
