import { useEffect } from "react";
import { useCasesStore } from "../../../../store/hooks";
import { useCasesApi } from "../../../../apiHooks";
import { UserLink } from "../../../../utils";
import { useNavigate } from "react-router-dom";

export const useOtherCases = () => {
  const casesStore = useCasesStore();
  const navigate = useNavigate();

  const {
    listOfOtherCases,
    isListOfOtherCasesLoaded,
    filteredListOfOtherCasesByCurrentPage,
    searchValue,
    otherPageCount,
    coinfections,
    currentPage,
    setCurrentPage,
    switchCoinfectionFilters,
    forms,
    filterForms,
    switchFormFilters,
  } = casesStore;

  const { getListOfOtherCases } = useCasesApi();

  useEffect(() => {
    if (!isListOfOtherCasesLoaded) {
      if (listOfOtherCases === null) {
        void getListOfOtherCases();
      } else {
        void getListOfOtherCases(false);
      }
    }
  }, [getListOfOtherCases, listOfOtherCases, isListOfOtherCasesLoaded]);

  useEffect(() => {
    return () => {
      casesStore.resetListOfOtherCases();
    };
  }, [casesStore]);

  const addCase = () => {
    navigate(UserLink.ADD_CASE);
  };

  const onChangeSearchHandler = (value: string) =>
    casesStore.setSearchValue(value);

  return {
    filteredListOfOtherCasesByCurrentPage,
    addCase,
    searchValue,
    onChangeSearchHandler,
    pageCount: otherPageCount,
    currentPage,
    setCurrentPage,
    switchCoinfectionFilters,
    forms,
    filterForms,
    switchFormFilters,
    filterCoinfections: coinfections,
  };
};
