import React, {FC} from "react";
import {ContentModal} from "../../ContentModal";
import {BinIcon} from "../../modalIcons/BinIcon";


interface IProps {
  handleYes: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  handleNo: () => void;
  textAdded: string;
}

export const DeleteModal: FC<IProps> = ({handleYes, handleNo, textAdded}) => {
  return <ContentModal
    iconType={<BinIcon/>}
    handleYes={handleYes}
    handleNo={handleNo}
    text={<p>Czy jesteś pewny, że chcesz <b> usunąć</b> {textAdded}?</p>}
  />
}