import React from "react";
import { observer } from "mobx-react-lite";

import { MenuList } from "../menuList/MenuList";
import { useNavBarViewModel } from "./useNavBarViewModel";
import { INavLink } from "../../../../../../interfaces/common/link";
import { Navbar, RightBelt, RoleBottomContainer } from "./Navbar.styled";

interface IProps {
  links: INavLink[];
}

export const NavBar: React.FC<IProps> = observer(({ links }) => {
  const { roleTitle } = useNavBarViewModel();

  return (
    <Navbar>
      <RightBelt />
      <MenuList linkList={links} />

      <RoleBottomContainer>{roleTitle}</RoleBottomContainer>
    </Navbar>
  );
});
