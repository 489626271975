import styled from 'styled-components';

export const UserLayoutStyled = styled.div`
  background: linear-gradient(180deg, #E1EDFA 0%, rgba(249, 252, 255, 0.00) 100%);
  min-height: 100vh;
  overflow-y: auto;
`

export const Container = styled.div`
  width: 1080px;
  margin: auto;
  position: relative;
`
export const ContainerLayout = styled.div`
  width: 1080px;
  margin: 0 auto;
  position: relative;
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
`

export const Doctor = styled.img`
  max-height: 719px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  height: 80%;
  max-width: 599px;
  transform: scaleX(-1);
  z-index: 20;
`