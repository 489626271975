import { useCallback, useMemo } from 'react';

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuthStore, useLoadersStore, useNotificationsStore } from '../store/hooks';
import { notificationHandler } from "../utils/notificationHelper";

export const useApiCall = () => {
  const loadersStore = useLoadersStore();
  const notificationsStore = useNotificationsStore();
  const authStore = useAuthStore();

  const errorHandler = useCallback(
    (status: number, message: string) => {
      notificationsStore.setNotification(notificationHandler(status, message));
      if (status === 403) {
        authStore.appLogout();
        window.location.href = '/login'
      }
    },
    [authStore, notificationsStore]
  );

  const api = useMemo(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    const responseBody = (response: AxiosResponse) => response.data;

    const apiInstance = {
      get: async (url: string, isGlobalLoader: boolean = true, config?: AxiosRequestConfig) => {
        isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();

        return axios
          .get(url, config)
          .then(responseBody)
          .catch((error) => errorHandler(error.response?.status, error.response?.data?.message))
          .finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
      },

      post: async (url: string, body: string | unknown, isGlobalLoader: boolean = true, config?: AxiosRequestConfig) => {
        isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();

        return axios
          .post(url, body, config)
          .then(responseBody)
          .catch((error) => errorHandler(error.response?.status, error.response?.data?.message))
          .finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
      },

      put: async (url: string, body: string | unknown, isGlobalLoader?: boolean) => {
        isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();

        return axios
          .put(url, body)
          .then(responseBody)
          .catch((error) => errorHandler(error.response?.status, error.response?.data?.message))
          .finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
      },
      patch: async (url: string, body: string | unknown, isGlobalLoader?: boolean) => {
        isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();

        return axios
          .patch(url, body)
          .then(responseBody)
          .catch((error) => errorHandler(error.response?.status, error.response?.data?.message))
          .finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
      },

      delete: async (url: string, isGlobalLoader?: boolean) => {
        isGlobalLoader ? loadersStore.setGlobalLoader() : loadersStore.setLocalLoader();

        return axios
          .delete(url)
          .then(responseBody)
          .catch((error) => errorHandler(error.response?.status, error.response?.data?.message))
          .finally(() => isGlobalLoader ? loadersStore.unsetGlobalLoader() : loadersStore.unsetLocalLoader());
      }
    };

    return {apiInstance};
  }, [loadersStore, errorHandler]);

  return {api};
};
