import styled from "styled-components";
import theme from "../../../../theme/theme";

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledSelect = styled.select<{ disabled?: boolean, $isError: boolean, $border?: string, $color?: string, $background?: string }>`
  color: ${props => props.color ?? 'rgb(51, 51, 51)'};
  height: 2.25rem;
  border: 2px solid ${props => props.$isError ? theme.colors.red : props.$border ?? theme.colors.grey2};
  background: ${props => props.$background ?? 'transparent'};
  border-radius: 10px;
  font-size: 16px;
  line-height: 36px;
  padding: 0 10px;
  opacity: 0.7;
  -webkit-appearance: ${props => props.disabled && 'none'};
  -moz-appearance: ${props => props.disabled && 'none'};
`

export const RequiredStar = styled.span<{ color?: string }>`
  color: ${props => props.color ?? 'rgb(51, 51, 51)'};
`
