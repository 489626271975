import { useRootStore } from '../RootStateContext';

export const useWindowDimensionsStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.windowDimensionsStore) {
    throw new Error('windowDimensionsStore store should be defined');
  }

  return rootStore.windowDimensionsStore;
};
