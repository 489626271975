import * as yup from 'yup'
import { useFormik } from 'formik';
import { useAuthApi } from "../../../../../../apiHooks";
import { useRegisterForm } from "../../form/useRegisterForm.vm";
import { useNavigate, useParams } from "react-router-dom";
import { GeneralLink } from "../../../../../../utils";
import { ValidationMessages } from '../../../../../../constants/validationMessages';
import { IRegisterAdmin } from "../../../../../../interfaces/common/register";

import { useEffect } from "react";
import { useAuthStore } from "../../../../../../store/hooks";

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, ValidationMessages.WEAK_PASSWORD)
    .required(ValidationMessages.EMPTY_FIELD),
  repeatPassword: yup
    .string()
    .test('passwords-match', ValidationMessages.INVALID_PASSWORD, function (value) {
      return this.parent.password === value
    })
    .required(ValidationMessages.EMPTY_FIELD),
  termsAndConditions: yup.boolean().oneOf([true])
})

export const useRegisterAdminForm = () => {
  const {register} = useAuthApi()
  const navigate = useNavigate()
  const params = useParams()
  const authStore = useAuthStore()
  const {verifyToken} = useAuthApi()
  const initialValue: IRegisterAdmin = {
    password: '',
    repeatPassword: '',
    termsAndConditions: false
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const res = await register(values.password)
      if (res) {
        navigate(GeneralLink.LOGIN)
      }
    }
  })

  const emailInfo = authStore.emailInfo

  useEffect(() => {
    if (params.token) {
      void verifyToken(params.token)
    }
  }, [verifyToken, params.token])

  const {
    confirmForm
  } = useRegisterForm(formik)

  return {formik, confirmForm, emailInfo}
}
  
  

