
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import { IAreaForm } from '../../../../interfaces/admin/areas/areaForm';
import { useModal } from '../../../shared/modal/useModal.vm';
import {useAreasStore} from "../../../../store/hooks";
import {useAreasApi} from "../../../../apiHooks";
import {ModalType} from "../../../../constants/modalType";
import {ValidationMessages} from "../../../../constants/validationMessages";
import {AdminLink} from "../../../../utils";
export const useAddArea = (isEdit: boolean) => {
  const {id} = useParams();
  const areasStore = useAreasStore();
  const { areaEdit } = areasStore;
  const areasApi = useAreasApi();
  const {getArea, addArea, editArea} = areasApi
  const navigate = useNavigate()
  const {handleModal, isModalOpened, closeModal} = useModal();

  useEffect(() => {
    if(isEdit && id && id !== 'new' && areaEdit === null){
      void getArea(+id)
    }
  }, [isEdit, id, areaEdit, getArea])

  useEffect(() => {
    return () => areasStore.clearAreaEdit();
  }, [areasStore])

  const actionHandler = async (areaForm: IAreaForm) => {
    const isExecuted = await (isEdit ? editArea({...areaForm, id: id ? +id : -1}) : addArea(areaForm));

    if (isExecuted) {
      handleModal(isEdit ? ModalType.EDIT_MODAL : ModalType.ADD_MODAL);
      return null;
    } else {
      return ValidationMessages.INVALID_FORM_FORM;
    }
  }

  const handleNo = () => {
    navigate(AdminLink.AREAS)
  }

  return { actionHandler, handleModal, isModalOpened, closeModal, handleNo, areaEdit}
}