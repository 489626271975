import {styled, TableCell} from "@mui/material";
import theme from "../../../../../theme/theme";

interface ITableCellData {
  isBold?: boolean;
}

export const TableCellData = styled(TableCell)<ITableCellData>`
  height: 50px;
  padding: 0 6px 0 6px;
  color: ${theme.colors.grey1};
  background: white;
  border: 3px solid ${theme.colors.grey3};
  font-weight: ${props => props.isBold ? '700' : '400'};
`

export const TableCellDataPointer = styled(TableCellData)`
  cursor: pointer;
`