import {observer} from "mobx-react";
// hooks
import {useCaseSettingsStore} from "../../../../../../../store/hooks";
// components
import {Stage01} from "./stages/stage01/Stage01";
import {Stage02} from "./stages/stage02/Stage02";
import {Stage03} from "./stages/stage03/Stage03";
import {Stage05} from "./stages/Stage05/Stage05";
import {Stage04} from "./stages/stage04/Stage04";
import {Summary} from "../../summary/Summary";
import {Loader} from "../../../../../../shared/loader/Loader";

export const ZzskForm = observer(() => {
  const {currentStage} = useCaseSettingsStore();

  switch (currentStage) {
    case 1:
      return <Stage01/>;
    case 2:
      return <Stage02/>;
    case 3:
      return <Stage03/>;
    case 4:
      return <Stage04/>;
    case 5:
      return <Stage05/>;
    // TODO add real props
    case 6:
      return <Summary/>;
  }

  return <Loader/>;
});
