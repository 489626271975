import * as yup from "yup";
// hooks
import {useFormik} from "formik";
import {useCaseSettingsStore, useNpsStore,} from "../../../../../../../../../store/hooks";
// types
import {TNpsStage03} from "./type";
import {EnAreaNames, EnFormNames,} from "../../../../../../../../../store/caseSettingsStore";
import {useParams} from "react-router-dom";
import {useCaseApi} from "../../../../../../../../../apiHooks";
import {useButtonsEffects} from "../../../../hooks/useButtonsEffects";

const validationSchema = yup.object().shape({
  programEntryDate: yup.string().required(),
  likertPatient3: yup.number().required(),
  likertDoctor3: yup.number().required(),
  assessment3: yup.number().required(),
  tenderJointsCount3: yup.number().min(0).integer(),
  swollenJointsCount3: yup.number().min(0).integer(),
  resultScaleOption3: yup.string().required(),
  result3: yup.number().required(),
  degreeOfBack3: yup.number().required(),
  generalAssessment23: yup.number().required(),
  treatmentLines: yup.array().of(
    yup.object().shape({
      variants: yup.string().required(),
      tnfInhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory TNF",
        then: (schema) => schema.required(),
      }),
      il17InhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory IL-17",
        then: (schema) => schema.required(),
      }),
      jakInhibitorType: yup.string().when("variants", {
        is: (value: string) => value === "Inhibitory JAK",
        then: (schema) => schema.required(),
      }),
      therapyDuration: yup.number().nullable(),
      durationUnit: yup.string().nullable().when("therapyDuration", {
        is: (value: number) => value > 0,
        then: (schema) => schema.required(),
      }),
      monotherapy: yup.string().required(),
      combinationWithMedicine: yup
        .array()
        .of(
          yup.object().shape({
            combinationDrugName: yup.string().required(),
            otherCombinationDrug: yup.string().when("combinationDrugName", {
              is: (value: string) => value === "Inne",
              then: (schema) => schema.required(),
            }),
            combinationDose: yup.number().required(),
            combinationUnit: yup.string().required(),
            otherCombinationUnit: yup.string().when("combinationUnit", {
              is: (value: string) => value === "Inna jednostka",
              then: (schema) => schema.required(),
            }),
            combinationDuration: yup.number().integer().required(),
          })
        )
        .when("monotherapy", {
          is: (value: string) => value === "kombinacja",
          then: (schema) => schema.min(1).required(),
        }),
      likertPatient3followup: yup.number().required(),
      likertDoctor3followup: yup.number().required(),
      assessment3followup: yup.number().required(),
      tenderJointsCount3followup: yup.number().min(0).integer(),
      swollenJointsCount3followup: yup.number().min(0).integer(),
      resultScaleOption3followup: yup.string().required(),
      result3followup: yup.number().required(),
      degreeOfBack3followup: yup.number().required(),
      generalAssessment23followup: yup.number().required(),
      symptomsOfIntolerance: yup.string().required(),
    })
  ),
  gks3: yup.string().required(),
  gksType3: yup
    .string()
    .nullable()
    .when("gks3", {
      is: (value: string) => value === "tak",
      then: (schema) => schema.required(),
    }),
  singleGks3: yup
    .string()
    .nullable()
    .when("gksType3", {
      is: (value: string) => value === "iniekcja",
      then: (schema) => schema.required(),
    }),
});

const initialValues: TNpsStage03 = {
  programEntryDate: null,
  likertPatient3: null,
  likertDoctor3: null,
  assessment3: null,
  tenderJointsCount3: null,
  swollenJointsCount3: null,
  resultScaleOption3: "",
  result3: null,
  degreeOfBack3: null,
  generalAssessment23: null,
  treatmentLines: [
    {
      variants: "",
      tnfInhibitorType: "",
      il17InhibitorType: "",
      jakInhibitorType: "",
      therapyDuration: null,
      durationUnit: '',
      monotherapy: "",
      combinationWithMedicine: [],
      likertPatient3followup: null,
      likertDoctor3followup: null,
      assessment3followup: null,
      tenderJointsCount3followup: null,
      swollenJointsCount3followup: null,
      resultScaleOption3followup: "",
      result3followup: null,
      degreeOfBack3followup: null,
      generalAssessment23followup: null,
      symptomsOfIntolerance: "",
    },
  ],
  gks3: "",
  gksType3: "",
  singleGks3: "",
};

export const useStage03ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const npsStore = useNpsStore();
  const {thirdStage, caseStage} = npsStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(thirdStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TNpsStage03>({
    initialValues: {
      ...valuesObj,
      treatmentLines:
        valuesObj.treatmentLines.length === 0
          ? [
            {
              variants: "",
              tnfInhibitorType: "",
              il17InhibitorType: "",
              jakInhibitorType: "",
              therapyDuration: null,
              durationUnit: '',
              monotherapy: "",
              combinationWithMedicine: [],
              likertPatient3followup: null,
              likertDoctor3followup: null,
              assessment3followup: null,
              tenderJointsCount3followup: null,
              swollenJointsCount3followup: null,
              resultScaleOption3followup: "",
              result3followup: null,
              degreeOfBack3followup: null,
              generalAssessment23followup: null,
              symptomsOfIntolerance: "",
            },
          ]
          : valuesObj.treatmentLines,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: {...values, gksType3: formik.values.gksType3},
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`treatmentLines[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }

    if (field === "variants") {
      if (value !== "Inhibitory TNF") {
        setFieldValue(`treatmentLines[${index}].tnfInhibitorType`, "");
      }

      if (value !== "Inhibitory IL-17") {
        setFieldValue(`treatmentLines[${index}].il17InhibitorType`, "");
      }
      if (value !== "Inhibitory JAK") {
        setFieldValue(`treatmentLines[${index}].jakInhibitorType`, "");
      }

      if (value === "Inhibitory JAK") {
        setFieldValue(
          `treatmentLines[${index}].jakInhibitorType`,
          "upadacytynib"
        );
      }
    }
  };

  const setCombinationOption = (
    value: number | string,
    field: string,
    index?: number,
    idx?: number
  ) => {
    if (index !== undefined && idx !== undefined) {
      setFieldValue(
        `treatmentLines[${index}].combinationWithMedicine[${idx}].${field}`,
        value
      );
    }
  };

  const deleteTreatment = (index: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines.filter((el, idx) => idx !== index),
    ];
    setFieldValue("treatmentLines", filteredItems);
  };

  const addLine = () => {
    setFieldValue("treatmentLines", [
      ...formik.values.treatmentLines,
      {
        variants: "",
        tnfInhibitorType: "",
        il17InhibitorType: "",
        jakInhibitorType: "",
        therapyDuration: null,
        durationUnit: '',
        monotherapy: "",
        combinationWithMedicine: [],
        likertPatient3followup: null,
        likertDoctor3followup: null,
        assessment3followup: null,
        tenderJointsCount3followup: null,
        swollenJointsCount3followup: null,
        resultScaleOption3followup: "",
        result3followup: null,
        degreeOfBack3followup: null,
        generalAssessment23followup: null,
        symptomsOfIntolerance: "",
      },
    ]);
  };

  const addCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  const deleteCombination = (index: number, idx: number) => {
    const filteredItems = [
      ...formik.values.treatmentLines[index].combinationWithMedicine.filter(
        (el, i) => idx !== i
      ),
    ];
    setFieldValue(
      `treatmentLines[${index}].combinationWithMedicine`,
      filteredItems
    );
  };

  const addOtherCombination = (index: number) => {
    setFieldValue(`treatmentLines[${index}].combinationWithMedicine`, [
      ...formik.values.treatmentLines[index].combinationWithMedicine,
      {
        combinationDrugName: "",
        otherCombinationDrug: "",
        combinationDose: null,
        combinationUnit: "",
        otherCombinationUnit: "",
        combinationDuration: null,
      },
    ]);
  };

  return {
    formik,
    setOption,
    deleteTreatment,
    addLine,
    addCombination,
    deleteCombination,
    addOtherCombination,
    setCombinationOption,
  };
};
