import styled from "styled-components";
import theme from "../../../../theme/theme";

export const ParagraphErrorMessage = styled.p`
  color: ${theme.colors.red};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
`