import styled from "styled-components";
import theme from "../../../theme/theme";

interface IModalContent {
  $lightbox?: boolean;
}

interface IModalContainerInside extends IModalContent {
  $position: string;
}

interface IContentModalContainer {
  scrolled?: boolean;
}

export const ContentModalContainer = styled.div<IContentModalContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 500px;
  justify-content: center;

  & a {
    font-size: 14px;
    display: block;
    margin-top: 20px;
  }

  p {
    
  }


  @media (max-width: ${theme.breakpoints.medium}px) {
    justify-content: ${props => props.scrolled ? 'unset' : 'center'};
    p {
      text-align: center;
      max-width: 100%;
    }
  }

`
export const ModalContent = styled.div<IModalContent>`
  position: relative;
  border: 1px solid rgba(249, 252, 255, 0.8);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.$lightbox ?
          `max-width: 100%;
           padding: 0px;
           background: white;
           @media (max-width: ${theme.breakpoints.medium}px) {
              margin: auto;
           }
          ` :
          `width: 780px; 
          min-height: 500px; 
          height: auto; 
          max-height: 100%; 
          background: rgba(249, 252, 255, 1);
          padding: 20px 0;
          @media (max-width: ${theme.breakpoints.medium}px) {
              margin: auto;
              width: auto;
          }
`};
`
export const ModalContentInside = styled.div<IModalContainerInside>`
  position: ${props => props.$position};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.breakpoints.medium}px) {

    ${props => props.$lightbox ?
            `margin: auto 0px;` :
            props.$position === 'relative' ? `
            width: 90%;
            overflow: auto;
            min-height: 500px; 
            height: auto; 
            max-height: 100%; 
          ` : 'margin: auto 16px;'
    };
  }
`

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  gap: 135px;
  @media (max-width: ${theme.breakpoints.small}px) {
    gap: 50px;
  }
`

export const ModalContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1100;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
`


