import React from "react";
import { observer } from "mobx-react";

// hooks
import { useStage05ViewModel } from "./Stage05.vm";

// components
import { Grid } from "@mui/material";
import { Checkbox } from "../../../../../../../../shared/ui/checkbox/Checkbox";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import {
  MultipleFileUpload
} from "../../../../../../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload";
import { FileSource } from "../../../../../../../../shared/uploadFiles/FileUploadLayout";

// lib
import theme from "../../../../../../../../../theme/theme";

export const Stage05 = observer(() => {
  const {formik, removePicturesFile, addPicturesFile} = useStage05ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
    >
      <Grid item xs={12} alignSelf={"center"} mb={4}>
        <Input
          size={"small"}
          label={"Wnioski"}
          type={"textarea"}
          isUpperCase={true}
          height={"292px"}
          name={"conclusions"}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          colorLabel={theme.colors.grey1}
          background={theme.colors.white}
          value={formik.values.conclusions}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={"Wpisz"}
          isError={
            formik.touched.conclusions && Boolean(formik.errors.conclusions)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Zdjęcia przykładowych zmian skórnych lub obrazowania"}
        />
        <Grid item xs={12}>
          <Checkbox
            fontWeight={"700"}
            id={"statement"}
            name={"statement"}
            fontSize={"12px"}
            textColor={theme.colors.abbvie}
            label={
              "Oświadczam, że na załączonych plikach nie znajdują się dane pacjenta*"
            }
            checked={formik.values.statement ?? false}
            onChange={() =>
              formik.setFieldValue("statement", !formik.values.statement)
            }
            isError={
              formik.touched.statement &&
              Boolean(formik.errors.statement)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleFileUpload
            disabled={!formik.values.statement}
            id={"files"}
            files={formik.values.files}
            removeFile={removePicturesFile}
            addFile={addPicturesFile}
            maxFileSize={10}
            extensions={["pdf", "jpg", "jpeg", "png", "gif"]}
            fileType={["application/pdf", "image"]}
            fileSource={FileSource.STATE}
            size={"small"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});
