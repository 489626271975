import background from "../../../../assets/img/background_blue.jpg";
import { ChangePasswordForm } from "./form/ChangePasswordForm";
import { AuthLayout } from "../register/layout/RegisterLayout";

const ChangePassword = () => {
  return (
    <AuthLayout bcgImage={background}><ChangePasswordForm/></AuthLayout>
  )
}

export default ChangePassword