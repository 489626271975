import {useApiCall} from "./useApiCall";
import {useCallback} from "react";
import {useOthersStore} from "../store/hooks";
import {IOthers} from '../interfaces/admin/others/others';
import {IResponse} from '../interfaces/common/response';

export const useOthersApi = () => {
  const {api} = useApiCall();
  const othersStore = useOthersStore();

  const getOthers = useCallback(
    async (isLoading: boolean = true) => {
      const res: IResponse<IOthers> = await api.apiInstance.get('login-histories', isLoading);
      if (res) {
        othersStore.setOthers(res.items);
      }
    },
    [api.apiInstance, othersStore]
  );

  return {
    getOthers
  }
}