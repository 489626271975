import React from "react";
import { observer } from "mobx-react";

// hooks
import { useStage02ViewModel } from "./Stage02.vm";

// components
import { Grid } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";
import { DateInput } from "../../../../../../../../shared/ui/date/Date";

// helpers, types, styles
import { treatmentOptions } from "./Stage02.helper";
import theme from "../../../../../../../../../theme/theme";

import {
  likertOptions,
  scaleOptions,
} from "../../../lzs-rinvoq/stages/stage02/Stage02.helper";
import { RadioForm } from "../../../styles";

export const Stage02 = observer(() => {
  const {formik, setOption} = useStage02ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
    >
      <Grid item xs={12}>
        <DateInput
          disableFuture={true}
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", "month", "day"]}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data rozpoznania</>}
          isUpperCase={true}
          name={"diagnosisDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.diagnosisDate}
          onClose={() => {
            formik.setFieldTouched("diagnosisDate", true);
            formik.validateField("diagnosisDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("diagnosisDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("diagnosisDate", true);
            setTimeout(() => {
              formik.validateField("diagnosisDate");
            }, 0);
          }}
          isError={
            formik.touched.diagnosisDate && Boolean(formik.errors.diagnosisDate)
          }
        />
      </Grid>
      <Grid
        mt={2}
        xs={12}
        item
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        mb={4}
      >
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Dominujące objawy"}
          isRequired={true}
        />
        <RadioForm $flexDirection={"column"} $alignItems={"flex-start"}>
          <RadioBox
            isError={formik.touched.symptoms && Boolean(formik.errors.symptoms)}
            id={"symptoms"}
            name={"symptoms"}
            value={"ze strony kręgosłupa (postać osiowa)"}
            checked={
              formik.values.symptoms === "ze strony kręgosłupa (postać osiowa)"
            }
            onChange={formik.handleChange}
          />
          <RadioBox
            isError={formik.touched.symptoms && Boolean(formik.errors.symptoms)}
            id={"symptoms2"}
            name={"symptoms"}
            value={"ze strony stawów obwodowych (postać obwodowa)"}
            checked={
              formik.values.symptoms ===
              "ze strony stawów obwodowych (postać obwodowa)"
            }
            onChange={formik.handleChange}
          />
        </RadioForm>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mt={2}>
        <Grid item xs={12}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.treatmentOption &&
              Boolean(formik.errors.treatmentOption)
            }
            label={"Rodzaj leczenia"}
            handleChange={(value) => {
              if (value === "Metotreksat") {
                formik.setFieldValue("sulfasalazynaDose", null);
              } else if (value === "Sulfasalazyna") {
                formik.setFieldValue("metotreksatDose", null);
              }

              setOption(value, "treatmentOption");
            }}
            selectedValue={formik.values.treatmentOption ?? ""}
            elementName={"treatmentOption"}
            options={treatmentOptions}
          />
        </Grid>
      </Grid>
      {formik.values.treatmentOption === "Metotreksat" && (
        <Grid item>
          <Input
            isRequired={true}
            label={"Dawkowanie metotreksat"}
            before={
              <Label
                isUpperCase={false}
                style={{margin: "8px 0 0"}}
                color={theme.colors.orange}
                size={"superSmall"}
                label={"mg/tydzień"}
              />
            }
            isUpperCase={true}
            name={"metotreksatDose"}
            type={"text"}
            colorLabel={theme.colors.grey1}
            value={formik.values.metotreksatDose || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.metotreksatDose &&
              Boolean(formik.errors.metotreksatDose)
            }
          />
        </Grid>
      )}
      {formik.values.treatmentOption === "Sulfasalazyna" && (
        <Grid item>
          <Input
            isRequired={true}
            label={"Dawkowanie sulfasalazyna"}
            before={
              <Label
                isUpperCase={false}
                style={{margin: "8px 0 0"}}
                color={theme.colors.orange}
                size={"superSmall"}
                label={"mg/tydzień"}
              />
            }
            isUpperCase={true}
            name={"sulfasalazynaDose"}
            type={"text"}
            colorLabel={theme.colors.grey1}
            value={formik.values.sulfasalazynaDose || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.sulfasalazynaDose &&
              Boolean(formik.errors.sulfasalazynaDose)
            }
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        pb={2}
        display={"flex"}
        flexDirection={"column"}
        mt={2}
      >
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Glikokortykosteroidy (GKS)"}
          isRequired={true}
        />
        <RadioForm>
          <RadioBox
            isError={formik.touched.gks && Boolean(formik.errors.gks)}
            id={"gks"}
            name={"gks"}
            value={"tak"}
            checked={formik.values.gks === "tak"}
            onChange={formik.handleChange}
          />
          <RadioBox
            isError={formik.touched.gks && Boolean(formik.errors.gks)}
            id={"gks2"}
            name={"gks"}
            value={"nie"}
            checked={formik.values.gks === "nie"}
            onChange={formik.handleChange}
          />
        </RadioForm>
      </Grid>
      {formik.values.gks === "tak" && (
        <Grid item xs={12} pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Rodzaj GKS"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={formik.touched.gksType && Boolean(formik.errors.gksType)}
              id={"gksType"}
              name={"gksType"}
              value={"tabletka"}
              checked={formik.values.gksType === "tabletka"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.gksType && Boolean(formik.errors.gksType)}
              id={"gksType2"}
              name={"gksType"}
              value={"iniekcja"}
              checked={formik.values.gksType === "iniekcja"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
      )}
      {formik.values.gksType === "iniekcja" && (
        <Grid item xs={12} pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Jednokrotne miejscowe podania GKS"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.singleGks && Boolean(formik.errors.singleGks)
              }
              id={"singleGks"}
              name={"singleGks"}
              value={"tak"}
              checked={formik.values.singleGks === "tak"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.singleGks && Boolean(formik.errors.singleGks)
              }
              id={"singleGks2"}
              name={"singleGks"}
              value={"nie"}
              checked={formik.values.singleGks === "nie"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
      )}
      <Grid container display={"flex"} gap={"20px"} mt={2}>
        <Grid item xs={5}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.likertPatient &&
              Boolean(formik.errors.likertPatient)
            }
            label={"Wynik w skali Likerta pacjent"}
            handleChange={(value) => setOption(value, "likertPatient")}
            selectedValue={formik.values.likertPatient ?? ""}
            elementName={"likertPatient"}
            options={likertOptions}
          />
        </Grid>
        <Grid>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.likertDoctor && Boolean(formik.errors.likertDoctor)
            }
            label={"Wynik w skali Likerta lekarz"}
            handleChange={(value) => setOption(value, "likertDoctor")}
            selectedValue={formik.values.likertDoctor ?? ""}
            elementName={"likertDoctor"}
            options={likertOptions}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={5}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.assessment && Boolean(formik.errors.assessment)
            }
            label={"OGÓLNA OCENA STANU CHOROBY"}
            handleChange={(value) => setOption(value, "assessment")}
            selectedValue={formik.values.assessment ?? ""}
            elementName={"assessment"}
            options={scaleOptions}
          />
        </Grid>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid>
          <Input
            label={"Liczba bolesnych stawów"}
            isUpperCase={true}
            isRequired={true}
            name={"tenderJointsCount"}
            type={"number"}
            maxWidth={"163px"}
            onChangeCustom={() =>
              formik.setFieldValue("tenderJointsCount", null)
            }
            colorLabel={theme.colors.grey1}
            value={formik.values.tenderJointsCount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.tenderJointsCount &&
              Boolean(formik.errors.tenderJointsCount)
            }
          />
        </Grid>
        <Grid>
          <Input
            label={"Liczba obrzękniętych stawów"}
            isUpperCase={true}
            isRequired={true}
            name={"swollenJointsCount"}
            type={"number"}
            maxWidth={"163px"}
            onChangeCustom={() =>
              formik.setFieldValue("swollenJointsCount", null)
            }
            colorLabel={theme.colors.grey1}
            value={formik.values.swollenJointsCount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.swollenJointsCount &&
              Boolean(formik.errors.swollenJointsCount)
            }
          />
        </Grid>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Podaj wynik w skali"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.resultScaleOption &&
                Boolean(formik.errors.resultScaleOption)
              }
              id={"resultScaleOption"}
              name={"resultScaleOption"}
              value={"BASDAI"}
              checked={formik.values.resultScaleOption === "BASDAI"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.resultScaleOption &&
                Boolean(formik.errors.resultScaleOption)
              }
              id={"resultScaleOption2"}
              name={"resultScaleOption"}
              value={"ASDAS"}
              checked={formik.values.resultScaleOption === "ASDAS"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid>
          <Input
            isRequired={true}
            label={"Wynik"}
            isUpperCase={true}
            name={"result"}
            type={"number"}
            maxWidth={"163px"}
            onChangeCustom={() => formik.setFieldValue("result", null)}
            colorLabel={theme.colors.grey1}
            value={formik.values.result}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.result && Boolean(formik.errors.result)}
          />
        </Grid>
      </Grid>
      <Grid container display={"flex"} gap={"20px"}>
        <Grid item xs={5}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.degreeOfBack && Boolean(formik.errors.degreeOfBack)
            }
            label={"Stopień bólu kręgosłupa"}
            handleChange={(value) => setOption(value, "degreeOfBack")}
            selectedValue={formik.values.degreeOfBack ?? ""}
            elementName={"degreeOfBack"}
            options={scaleOptions}
          />
        </Grid>
        <Grid>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.generalAssessment2 &&
              Boolean(formik.errors.generalAssessment2)
            }
            label={"OGÓLNA OCENA STANU CHOROBY"}
            handleChange={(value) => setOption(value, "generalAssessment2")}
            selectedValue={formik.values.generalAssessment2 ?? ""}
            elementName={"generalAssessment2"}
            options={scaleOptions}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2} mb={2}>
        <Input
          label={"Komentarz istotny klinicznie"}
          isUpperCase={true}
          name={"relevantComment"}
          type={"textarea"}
          colorLabel={theme.colors.grey1}
          value={formik.values.relevantComment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          background={theme.colors.white}
          placeholder={"Wpisz"}
          isError={
            formik.touched.relevantComment &&
            Boolean(formik.errors.relevantComment)
          }
        />
      </Grid>
    </Grid>
  );
});
