import { useRootStore } from "../RootStateContext";

export const useCaseSettingsStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.caseSettingsStore) {
    throw new Error("caseSettingsStore store should be defined");
  }

  return rootStore.caseSettingsStore;
};
