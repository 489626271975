import { GeneralLink } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useProfileStore } from "../../../../store/hooks";
import { useProfileApi } from "../../../../apiHooks/useProfileApi";
import { useModal } from "../../../shared/modal/useModal.vm";
import { ModalType } from "../../../../constants/modalType";

export const useProfile = () => {
  const navigate = useNavigate();
  const {getProfile} = useProfileApi();
  const profileStore = useProfileStore()
  const {profile, isProfileLoaded} = profileStore;
  const {handleModal, isModalOpened, closeModal} = useModal();
  const resetNavigateHandler = () => {
    navigate(GeneralLink.RETRIEVE_PASSWORD);
  }
  useEffect(() => {
    if (!isProfileLoaded) {
      if (!profile) {
        void getProfile()
      } else {
        void getProfile(false)
      }

    }
  }, [getProfile, isProfileLoaded, profile])

  useEffect(() => {
    return profileStore.resetProfile();
  }, [profileStore])

  const editModalHandler = () => {
    handleModal(ModalType.EDIT_MODAL);
  }


  return {
    resetNavigateHandler,
    profile,
    editModalHandler,
    isModalOpened,
    closeModal
  }

}