import { useCasesApi, useHomeApi } from "../../../../apiHooks";
import { useEffect } from "react";
import {
  useAuthStore,
  useCaseSettingsStore,
  useCasesStore,
  useHomeStore,
} from "../../../../store/hooks";
import { useNavigate } from "react-router-dom";

import { generateLink, UserLink } from "../../../../utils";
import { RoleFunction } from "../../../../constants/projectUserRoles";

export const useHome = () => {
  const navigate = useNavigate();

  const { getListOfLastCases } = useHomeApi();
  const { getListOfOtherCases } = useCasesApi();

  const homeStore = useHomeStore();
  const casesStore = useCasesStore();
  const caseSettingsStore = useCaseSettingsStore();
  const { userPrescribedAreas } = caseSettingsStore;

  const { listOfLastCases } = homeStore;
  const { listOfLast3Cases } = casesStore;

  const authStore = useAuthStore();
  const { fullName, affiliation, roleFunction } = authStore;

  const isParticipant = RoleFunction.ROLE_PARTICIPANT === roleFunction;

  const addCase = () => {
    const isOneAreaAndOneForm =
      userPrescribedAreas?.length === 1 &&
      userPrescribedAreas[0].forms.length === 1;

    navigate(
      isOneAreaAndOneForm
        ? generateLink(UserLink.ADD_CASE, [
            [":area", userPrescribedAreas[0].name],
            [":form", userPrescribedAreas[0].forms[0].name],
          ])
        : UserLink.SET_AREA_AND_FORM
    );
  };

  useEffect(() => {
    if (isParticipant && !listOfLast3Cases) void getListOfOtherCases(true, 3);
    if (!isParticipant && !listOfLastCases) void getListOfLastCases(true);
  }, [
    getListOfLastCases,
    getListOfOtherCases,
    isParticipant,
    listOfLast3Cases,
    listOfLastCases,
  ]);

  useEffect(() => {
    return () => {
      homeStore.resetListOfLastCases();
      casesStore.resetListOfOfLast3Cases();
    };
  }, [homeStore, casesStore]);

  const navigateToOthersCases = () => {
    navigate(UserLink.OTHER_CASES);
  };
  return {
    addCase,
    listOfLastCases,
    listOfLast3Cases,
    fullName,
    affiliation,
    navigateToOthersCases,
    isParticipant,
  };
};
