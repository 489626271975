import React from "react";
import { MenuItem } from "../menuItem/MenuItem";
import { INavLink } from "../../../../../../interfaces/common/link";

interface IProps {
  linkList: INavLink[]
}

export const MenuList: React.FC<IProps> = ({linkList}) => {

  return (
    <nav>
      {
        linkList.map((link, index) => (
          <MenuItem
            key={index}
            linkInfo={link}
          />
        ))
      }
    </nav>
  )
}
