import styled from "styled-components";
import theme from "../../../../theme/theme";
import checkboxCheck from "../../../../assets/icon/checkboxCheck.svg";

export interface ILabel {
  checked?: boolean,
  $isError?: boolean,
  $textColor?: string
  $fontSize?: string
  $fontWeight?: string
}

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 16px;
  gap: 20px;
`;

export const Label = styled.label<ILabel>`
  margin-left: 10px;
  position: relative;
  line-height: 140%;
  letter-spacing: 1.2px;
  cursor: pointer;
  color: ${props => props.$textColor};
  font-size: ${props => props.$fontSize || '16px'};
  font-weight: ${props => props.$fontWeight || 400};

  & a {
    font-weight: 700;
    text-decoration: none;
    color: inherit;
  }


  &:before {
    content: '';
    position: absolute;
    left: -24px;
    width: 14px;
    height: 14px;
    top: 0;
    border-radius: 2px;
    background: ${theme.colors.b3};
    border: ${props => !props.checked ? `1px solid ${theme.colors.grey1}` : `1px solid ${theme.colors.abbvie}`};
    border: ${props => props.$isError && !props.checked && `2px solid ${theme.colors.red}`};
    margin-right: 13px;
  }

  ${props => props.checked && `
   &:after {
    content: '';
    position: absolute;
    left: -24px;
    top: 0;
    background: ${theme.colors.abbvie};
    width: 14px;
    height: 14px;
    background-image: url(${checkboxCheck});
    background-position: center;
    background-repeat: no-repeat; 
    background-size: auto
   }
  `}
  & span {
    color: ${theme.colors.red};
    font-weight: 700;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    font-size: 10px;
    line-height: 16px;
    &:before {
      top: 0;
    }

    ${props => props.checked && `
   &:after {
   
    top: 0;
    }`}
  }
`;

export const Input = styled.input`
  margin: 0;
  height: 30px;
  padding: 5px 10px;
  display: none;
`;

