import styled from "styled-components";
import theme from "../../../../theme/theme";

export const Container = styled.div<{ $bcg: string }>`
  position: absolute;
  top: 10%;
  text-transform: uppercase;
  min-width: 380px;
  height: 40px;
  background-color: ${props => props.$bcg};
  border-radius: 48px;
  text-align: center;
  color: ${theme.colors.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 1.4px;
  left: -42px;

  & span {
    font-weight: 400;
    text-transform: none;
  }
`