import { AuthLayout } from "../register/layout/RegisterLayout";
import background from "../../../../assets/img/background_blue.jpg";
import { RetrievePasswordForm } from "./form/RetrievePasswordForm";

const RetrievePassword = () => {
  return <AuthLayout bcgImage={background}>
    <RetrievePasswordForm/>
  </AuthLayout>
}

export default RetrievePassword