import { observer } from "mobx-react";
import React from "react";

// hooks
import { useZzskStore } from "../../../../../../../../store/hooks";

// components
import { Grid, Typography } from "@mui/material";
import {
  GroupForm,
  Label,
  NormalText,
} from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import { checkValueIsEmpty } from "../../../virusology/hcv/HcvViewCase.helper";
import { dateStringify } from "../../../../../../../../utils";
import theme from "../../../../../../../../theme/theme";

export const CurrentTreatmentSection = observer(() => {
  const caseStore = useZzskStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={8}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Dotychczasowe leczenie:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Grid item xs={12} mb={4}>
            <Label>
              Rozpoznanie ZZSK na podstawie zmodyfikowanych kryteriów
              nowojorskich z uwzględnieniem sacroiliitis w badaniu RTG
            </Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.zzskDiagnosis)}
            </NormalText>
            <Typography
              fontWeight={700}
              display={"block"}
              paddingTop={"24px"}
              variant={"caption"}
              color={theme.colors.orange}
            >
              Niezadowalająca odpowiedz na co najmniej dwa niesteroidowe leki
              przeciwzapalne, z których każdy zastosowany był w maksymalnej
              rekomendowanej lub tolerowanej przez chorego dawce przez co
              najmniej 4 tygodnie w monoterapii. Niezadowalająca odpowiedź na
              leczenie oznacza utrzymywanie się aktywnej postaci choroby pomimo
              zastosowanego leczenia.
            </Typography>
          </Grid>
          <Grid item xs={12} mb={4}>
            <Label>DATA ROZPOZNANIA</Label>
            <NormalText>
              {checkValueIsEmpty(dateStringify(caseElement.diagnosisDate))}
            </NormalText>
          </Grid>
          <Grid container gap={"20px"} mb={4}>
            <Grid item xs={3}>
              <Label>podaj wynik w skali</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.resultScaleOption?.toUpperCase())}
              </NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>WYNIK</Label>
              <NormalText>{checkValueIsEmpty(caseElement.result)}</NormalText>
            </Grid>
          </Grid>
          <Grid item xs={12} mb={4}>
            <Label>Wynik VAS</Label>
            <NormalText>{checkValueIsEmpty(caseElement.vasResult)}</NormalText>
          </Grid>
          <Grid item xs={12} mb={4}>
            <Label>Komentarz istotny klinicznie</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.relevantComment)}
            </NormalText>
          </Grid>
          <Grid item xs={12} mb={4}>
            <Label>Glikokortykosteroidy (GKS)</Label>
            <NormalText>{checkValueIsEmpty(caseElement.gks)}</NormalText>
          </Grid>
          <Grid item xs={12}>
            <Label>jednokrotne miejscowe podania GKS</Label>
            <NormalText>{checkValueIsEmpty(caseElement.singleGks)}</NormalText>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
