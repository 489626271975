import { makeAutoObservable } from "mobx";
import { TNps } from "../../components/pages/user/draftCase/forms/rheumatology/nps/type";
import { TNpsStage01 } from "../../components/pages/user/draftCase/forms/rheumatology/nps/stages/stage01/type";
import { TNpsStage02 } from "../../components/pages/user/draftCase/forms/rheumatology/nps/stages/stage02/type";
import { TNpsStage03 } from "../../components/pages/user/draftCase/forms/rheumatology/nps/stages/stage03/type";
import { TNpsStage04 } from "../../components/pages/user/draftCase/forms/rheumatology/nps/stages/stage04/type";
import { TNpsStage05 } from "../../components/pages/user/draftCase/forms/rheumatology/nps/stages/Stage05/type";

export class NpsStore {
  case: TNps | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCase(value: TNps) {
    this.case = value;
  }

  get caseStage() {
    return this.case?.step;
  }

  get firstStage() {
    return this.case as TNpsStage01;
  }

  get secondStage() {
    return this.case as TNpsStage02;
  }

  get thirdStage() {
    return this.case as TNpsStage03;
  }

  get fourthStage() {
    return this.case as TNpsStage04;
  }

  get fifthStage() {
    return this.case as TNpsStage05
  }

  resetCase() {
    this.case = null;
  }
}
