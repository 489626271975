import { useEffect } from 'react';
import { useParams } from 'react-router';


// types 
import { EnAreaNames, EnFormNames } from '../../../../../store/caseSettingsStore';

// hooks
import { useCaseApi } from '../../../../../apiHooks';
import { HcvStore } from '../../../../../store/forms/hcvStore';
import { RzsStore } from '../../../../../store/forms/rzsStore';
import { LzsrStore } from "../../../../../store/forms/lzsrStore";
import { LzssStore } from "../../../../../store/forms/lzssStore";
import { ZzskStore } from "../../../../../store/forms/zzskStore";
import { NpsStore } from "../../../../../store/forms/npsStore";

export const useViewCase = (area: EnAreaNames, form: EnFormNames, store: HcvStore | RzsStore | LzsrStore | LzssStore | ZzskStore | NpsStore) => {
  const {getCase} = useCaseApi();
  const {caseId} = useParams();

  useEffect(() => {
    if (caseId) {
      void getCase({area, form, caseId});
    }
  }, [getCase, caseId, area, form]);

  useEffect(() => {
    return () => {
      store.resetCase();
    };
  }, [store]);


  return {
    isCase: Boolean(store.case)
  }
}