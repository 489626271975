import * as yup from "yup";

// hooks
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useCaseSettingsStore, useZzskStore, } from "../../../../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../../../../apiHooks";
import { useButtonsEffects } from "../../../../hooks/useButtonsEffects";

// types
import { TZzskStage02 } from "./type";
import { EnAreaNames, EnFormNames, } from "../../../../../../../../../store/caseSettingsStore";

const validationSchema = yup.object().shape({
  zzskDiagnosis: yup.string().oneOf(['tak'], "Nie można wypełnić formularza ponieważ nie ma rozpoznania ZZSK").required(''),
  diagnosisDate: yup.string().required(),
  resultScaleOption: yup.string().required(),
  result: yup.number().required(),
  vasResult: yup.number().required(),
  relevantComment: yup.string(),
  gks: yup.string().required(),
  singleGks: yup.string().required(),
});

const initialValues: TZzskStage02 = {
  zzskDiagnosis: '',
  diagnosisDate: null,
  resultScaleOption: "",
  result: null,
  vasResult: null,
  relevantComment: '',
  gks: '',
  singleGks: '',
};

export const useStage02ViewModel = () => {
  // params and location
  const params = useParams();
  const {area, form, caseId} = params;

  // case settings store
  const caseSettingsStore = useCaseSettingsStore();
  const {currentStage} = caseSettingsStore;

  // rzs store
  const zzskStore = useZzskStore();
  const {secondStage, caseStage} = zzskStore;

  // define API calls
  const caseApi = useCaseApi();
  const valuesObj =
    caseStage && caseStage >= currentStage!
      ? JSON.parse(JSON.stringify(secondStage))
      : JSON.parse(JSON.stringify(initialValues));

  const formik = useFormik<TZzskStage02>({
    initialValues: {
      ...valuesObj,
    }
    ,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const isValidArea = Object.values(EnAreaNames)?.includes(
        area as EnAreaNames
      );
      const isValidForm = Object.values(EnFormNames)?.includes(
        form as EnFormNames
      );

      if (!area || !form || !isValidArea || !isValidForm) {
        throw new Error("Invalid URL");
      }

      if (!caseId) throw new Error("Case ID is missing");
      if (!currentStage) throw new Error("Current stage is missing");

      await caseApi.updateCase({
        area: area as EnAreaNames,
        form: form as EnFormNames,
        caseId,
        value: values,
        stage: currentStage,
      });
    },
  });

  // button logic: previous step, next step, and save draft
  useButtonsEffects(formik.values, formik.submitForm);

  const {setFieldValue} = formik;

  const setOption = (value: number | string, field: string, index?: number) => {
    if (index !== undefined) {
      setFieldValue(`drugs[${index}].${field}`, value);
    } else {
      setFieldValue(field, value);
    }
  };


  return {
    formik,
    setOption,
  };
};
