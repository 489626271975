import React from "react";
import { observer } from "mobx-react";
import { FormikErrors } from "formik";

// hooks
import { useStage03ViewModel } from "./Stage03.vm";

// components
import { Divider, Grid, Typography } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";
import { DateInput } from "../../../../../../../../shared/ui/date/Date";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";

// lib, helpers, styles
import theme from "../../../../../../../../../theme/theme";
import {
  combinationsOptions,
  il17Options,
  jakOptions,
  tnfOptions,
  variantOptions,
  vasOptions,
} from "./Stage03.helper";
import { ICombinations, ITreatmentLines } from "./type";
import { ButtonAddDrug } from "../stage02/Stage02.styled";
import { RadioForm } from "../../../styles";

// icons
import { ReactComponent as Bin } from "../../../../../../../../../assets/icon/abbvieBin.svg";
import { ReactComponent as Plus } from "../../../../../../../../../assets/icon/abbviePlus.svg";
import { checkEmptyNumber } from "../../../../../../../../../utils/checkEmptyNumber";

export const Stage03 = observer(() => {
  const {
    formik,
    setOption,
    deleteTreatment,
    addLine,
    addCombination,
    deleteCombination,
    setCombinationOption,
    addOtherCombination,
  } = useStage03ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
    >
      <Grid item xs={12} mb={2}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Moment włączenia do programu lekowego
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DateInput
          disableFuture={true}
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", "month", "day"]}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data wejścia do programu lekowego</>}
          isUpperCase={true}
          name={"programEntryDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.programEntryDate}
          onClose={() => {
            formik.setFieldTouched("programEntryDate", true);
            formik.validateField("programEntryDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("programEntryDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("programEntryDate", true);
            setTimeout(() => {
              formik.validateField("programEntryDate");
            }, 0);
          }}
          isError={
            formik.touched.programEntryDate &&
            Boolean(formik.errors.programEntryDate)
          }
        />
      </Grid>

      <Grid container display={"flex"} gap={"20px"} mt={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"podaj wynik w skali"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.resultScaleOption3 && Boolean(formik.errors.resultScaleOption3)
              }
              id={"resultScaleOption3"}
              name={"resultScaleOption3"}
              value={"BASDAI"}
              checked={formik.values.resultScaleOption3 === "BASDAI"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.resultScaleOption3 && Boolean(formik.errors.resultScaleOption3)
              }
              id={"resultScaleOption32"}
              name={"resultScaleOption3"}
              value={"ASDAS"}
              checked={formik.values.resultScaleOption3 === "ASDAS"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid item xs={2}>
          <Input
            isRequired={true}
            label={"Wynik"}
            isUpperCase={true}
            name={"result3"}
            type={"number"}
            onChangeCustom={() => formik.setFieldValue('result3', null)}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.result3}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.result3 && Boolean(formik.errors.result3)}
          />
        </Grid>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid item xs={3}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.vasResult3 && Boolean(formik.errors.vasResult3)
            }
            label={"Wynik Vas"}
            handleChange={(value) => setOption(value, "vasResult3")}
            selectedValue={formik.values.vasResult3 ?? ""}
            elementName={"vasResult3"}
            options={vasOptions}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            isRequired={true}
            label={"Wynik CRP"}
            isUpperCase={true}
            name={"crpResult3"}
            type={"number"}
            maxWidth={"163px"}
            onChangeCustom={() => formik.setFieldValue('crpResult3', null)}
            colorLabel={theme.colors.grey1}
            value={formik.values.crpResult3}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.crpResult3 &&
              Boolean(formik.errors.crpResult3)
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            label={"Wynik OB"}
            onChangeCustom={() => formik.setFieldValue('obResult3', null)}
            isUpperCase={true}
            name={"obResult3"}
            type={"number"}
            maxWidth={"163px"}
            colorLabel={theme.colors.grey1}
            value={formik.values.obResult3}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.obResult3 && Boolean(formik.errors.obResult3)
            }
          />
        </Grid>
      </Grid>
      <Divider
        style={{width: "100%"}}
        variant={"fullWidth"}
        sx={{height: "2px", margin: "0 0 16px 0"}}
      />
      <Grid
        item
        xs={12}
        mb={2}
        mt={2}
        display={"flex"}
        justifyContent={"center"}
      >
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Linie leczenia
        </Typography>
      </Grid>
      <Grid container>
        {formik.values.treatmentLines?.map((line, index) => (
          <Grid
            key={index}
            item
            xs={12}
            mb={4}
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "0 16px",
              backgroundColor: "rgba(255, 255, 255, 0.70)",
            }}
          >
            <Grid
              item
              xs={12}
              mt={2}
              justifyContent={"space-between"}
              display={"flex"}
            >
              <Typography
                variant={"h6"}
                color={theme.colors.grey1}
                textTransform={"uppercase"}
                fontWeight={700}
              >
                Linia leczenia {index + 1}
              </Typography>
              {formik.values.treatmentLines.length !== 1 && (
                <ButtonAddDrug type="button" onClick={() => deleteTreatment(index)}>
                  Usuń linię leczenia
                  <Bin/>
                </ButtonAddDrug>
              )}
            </Grid>
            <Grid item xs={12} mt={1}>
              <SingleSelect
                isRequired={true}
                defaultValue={"Wybierz"}
                isError={
                  Array.isArray(formik.touched.treatmentLines) &&
                  formik.touched.treatmentLines[index]?.variants &&
                  Array.isArray(formik.errors.treatmentLines) &&
                  Boolean(
                    (
                      formik.errors.treatmentLines[
                        index
                        ] as FormikErrors<ITreatmentLines>
                    )?.variants
                  )
                }
                label={"Wybierz wariant"}
                handleChange={(value) => setOption(value, "variants", index)}
                selectedValue={
                  formik.values.treatmentLines[index]?.variants || ""
                }
                elementName={`treatmentLines[${index}].variants`}
                options={variantOptions}
              />
            </Grid>
            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory TNF" && (
                <Grid item xs={12} mt={1}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.tnfInhibitorType &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.tnfInhibitorType
                      )
                    }
                    label={"Rodzaj inhibitora TNF"}
                    handleChange={(value) =>
                      setOption(value, "tnfInhibitorType", index)
                    }
                    selectedValue={
                      formik.values.treatmentLines[index]?.tnfInhibitorType ||
                      ""
                    }
                    elementName={`treatmentLines[${index}].tnfInhibitorType`}
                    options={tnfOptions}
                  />
                </Grid>
              )}
            {formik.values.treatmentLines[index]?.tnfInhibitorType ===
              "Adalimumab inne" && (
                <>
                  <Grid>
                    <Input
                      isRequired={true}
                      label={"adalimumab inne rodzaj"}
                      isUpperCase={true}
                      name={`treatmentLines[${index}].adalimumabOtherVariant`}
                      type={"text"}
                      colorLabel={theme.colors.grey1}
                      value={
                        formik.values.treatmentLines?.[index]
                          ?.adalimumabOtherVariant || ""
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      borderColor={theme.colors.grey5}
                      placeholderColor={theme.colors.grey4}
                      background={theme.colors.white}
                      placeholder={"Wpisz"}
                      isError={
                        formik.touched.treatmentLines?.[index]
                          ?.adalimumabOtherVariant &&
                        Boolean(
                          (
                            formik.errors.treatmentLines?.[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.adalimumabOtherVariant
                        )
                      }
                    />
                  </Grid>
                  <Grid>
                    <Input
                      isRequired={true}
                      label={"adalimumab inne dawka"}
                      before={
                        <Label
                          isUpperCase={false}
                          style={{margin: "8px 0 0"}}
                          color={theme.colors.orange}
                          size={"superSmall"}
                          label={"mg/tydzień"}
                        />
                      }
                      isUpperCase={true}
                      name={`treatmentLines[${index}].adalimumabOtherDose`}
                      type={"text"}
                      colorLabel={theme.colors.grey1}
                      value={
                        formik.values.treatmentLines?.[index]
                          ?.adalimumabOtherDose || ""
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      borderColor={theme.colors.grey5}
                      placeholderColor={theme.colors.grey4}
                      background={theme.colors.white}
                      placeholder={"Wpisz"}
                      isError={
                        formik.touched.treatmentLines?.[index]
                          ?.adalimumabOtherDose &&
                        Boolean(
                          (
                            formik.errors.treatmentLines?.[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.adalimumabOtherDose
                        )
                      }
                    />
                  </Grid>
                </>
              )}
            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory IL-17" && (
                <Grid item xs={12} mt={1}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.il17InhibitorType &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.il17InhibitorType
                      )
                    }
                    label={"Rodzaj inhibitora IL-17"}
                    handleChange={(value) =>
                      setOption(value, "il17InhibitorType", index)
                    }
                    selectedValue={
                      formik.values.treatmentLines[index]?.il17InhibitorType ||
                      ""
                    }
                    elementName={`treatmentLines[${index}].il17InhibitorType`}
                    options={il17Options}
                  />
                </Grid>
              )}
            {formik.values.treatmentLines[index]?.variants ===
              "Inhibitory JAK" && (
                <>
                  <Grid item xs={12} mt={1}>
                    <SingleSelect
                      isRequired={true}
                      defaultValue={"Wybierz"}
                      isError={
                        Array.isArray(formik.touched.treatmentLines) &&
                        formik.touched.treatmentLines[index]?.jakInhibitorType &&
                        Array.isArray(formik.errors.treatmentLines) &&
                        Boolean(
                          (
                            formik.errors.treatmentLines[
                              index
                              ] as FormikErrors<ITreatmentLines>
                          )?.jakInhibitorType
                        )
                      }
                      label={"Rodzaj inhibitora JAK"}
                      handleChange={(value) =>
                        setOption(value, "jakInhibitorType", index)
                      }
                      selectedValue={
                        formik.values.treatmentLines[index]?.jakInhibitorType ||
                        ""
                      }
                      elementName={`treatmentLines[${index}].jakInhibitorType`}
                      options={jakOptions}
                    />
                  </Grid>
                </>
              )}
            <Grid
              container
              mb={2}
              display={"flex"}
              gap={"10px"}
              alignItems={"flex-end"}
            >
              <Grid mt={2}>
                <Input
                  label={"Czas trwania terapii"}
                  isUpperCase={true}
                  name={`treatmentLines[${index}].therapyDuration`}
                  type={"number"}
                  onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].therapyDuration `, null)}
                  maxWidth={"163px"}
                  colorLabel={theme.colors.grey1}
                  value={
                    formik.values.treatmentLines?.[index]
                      ?.therapyDuration
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz"}
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.therapyDuration &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                          ] as FormikErrors<ITreatmentLines>
                      )?.therapyDuration
                    )
                  }
                />
              </Grid>
              <Grid pb={2}>
                <RadioForm>
                  <RadioBox
                    id={`treatmentLines[${index}].durationUnit`}
                    name={`treatmentLines[${index}].durationUnit`}
                    value={"miesiące"}
                    isUpperCase={false}
                    checked={line.durationUnit === "miesiące"}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `treatmentLines[${index}].durationUnit`,
                        e.target.value
                      )
                    }
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.durationUnit &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.durationUnit
                      )
                    }
                  />
                  <RadioBox
                    id={`treatmentLines[${index}].durationUnit2`}
                    name={`treatmentLines[${index}].durationUnit`}
                    value={"lata"}
                    isUpperCase={false}
                    checked={
                      line.durationUnit === "lata"
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        `treatmentLines[${index}].durationUnit`,
                        e.target.value
                      )
                    }
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]?.durationUnit &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.durationUnit
                      )
                    }
                  />
                </RadioForm>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              pb={2}
              display={"flex"}
              flexDirection={"column"}
            >
              <Label
                size={"small"}
                isUpperCase={true}
                color={theme.colors.grey1}
                label={"Monoterapia czy kombinacja z innym lekiem?"}
                isRequired={true}
              />
              <RadioForm>
                <RadioBox
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.monotherapy &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                          ] as FormikErrors<ITreatmentLines>
                      )?.monotherapy
                    )
                  }
                  id={`monotherapy1-${index}`}
                  name={`treatmentLines[${index}].monotherapy`}
                  value={"monoterapia"}
                  checked={
                    formik.values.treatmentLines[index].monotherapy ===
                    "monoterapia"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `treatmentLines[${index}].monotherapy`,
                      e.target.value
                    );
                    formik.setFieldValue(
                      `treatmentLines[${index}].combinationWithMedicine`,
                      []
                    );
                  }}
                />
                <RadioBox
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.monotherapy &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                          ] as FormikErrors<ITreatmentLines>
                      )?.monotherapy
                    )
                  }
                  id={`monotherapy2-${index}`}
                  name={`treatmentLines[${index}].monotherapy`}
                  value={"kombinacja"}
                  checked={
                    formik.values.treatmentLines[index].monotherapy ===
                    "kombinacja"
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `treatmentLines[${index}].monotherapy`,
                      e.target.value
                    );
                    addCombination(index);
                  }}
                />
              </RadioForm>
            </Grid>
            {formik.values.treatmentLines &&
              formik.values.treatmentLines.length > 0 &&
              formik.values.treatmentLines[index]?.combinationWithMedicine
                .length > 0 &&
              formik.values.treatmentLines[index]?.combinationWithMedicine?.map(
                (combination, idx) => (
                  <Grid style={{backgroundColor: "rgba(7, 28, 71, 0.10)"}}>
                    <Grid key={idx} item xs={12} p={2} mb={2}>
                      <Grid
                        item
                        xs={12}
                        mt={2}
                        justifyContent={"space-between"}
                        display={"flex"}
                      >
                        <Typography
                          variant={"h6"}
                          color={theme.colors.grey1}
                          textTransform={"uppercase"}
                          fontWeight={700}
                        >
                          Kombinacja {idx + 1}
                        </Typography>
                        {formik.values.treatmentLines[index]
                          .combinationWithMedicine?.length !== 1 && (
                          <ButtonAddDrug
                            type="button"
                            onClick={() => deleteCombination(index, idx)}
                          >
                            Usuń kombinację
                            <Bin/>
                          </ButtonAddDrug>
                        )}
                      </Grid>
                      <Grid item xs={12} mt={1}>
                        <SingleSelect
                          isRequired={true}
                          defaultValue={"Wybierz"}
                          isError={
                            Array.isArray(formik.touched.treatmentLines) &&
                            Array.isArray(formik.errors.treatmentLines) &&
                            typeof formik.errors.treatmentLines[index] ===
                            "object" &&
                            formik.touched.treatmentLines[index]
                              ?.combinationWithMedicine?.[idx]
                              ?.combinationDrugName &&
                            Boolean(
                              (
                                (
                                  formik.errors.treatmentLines[
                                    index
                                    ] as FormikErrors<ITreatmentLines>
                                )?.combinationWithMedicine?.[
                                  idx
                                  ] as FormikErrors<ICombinations>
                              )?.combinationDrugName
                            )
                          }
                          label={"Leczenie"}
                          handleChange={(value) =>
                            setCombinationOption(
                              value,
                              "combinationDrugName",
                              index,
                              idx
                            )
                          }
                          selectedValue={
                            formik.values.treatmentLines[index]
                              ?.combinationWithMedicine[idx]
                              .combinationDrugName || ""
                          }
                          elementName={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDrugName`}
                          options={combinationsOptions}
                        />
                      </Grid>
                      {formik.values.treatmentLines[index]
                          .combinationWithMedicine[idx].combinationDrugName ===
                        "Inne" && (
                          <Grid item xs={12}>
                            <Input
                              isRequired={true}
                              label={"nazwa innego leku*"}
                              isUpperCase={true}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].otherCombinationDrug`}
                              type={"text"}
                              colorLabel={theme.colors.grey1}
                              value={
                                formik.values.treatmentLines?.[index]
                                  ?.combinationWithMedicine[idx]
                                  .otherCombinationDrug || ""
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              borderColor={theme.colors.grey5}
                              placeholderColor={theme.colors.grey4}
                              background={theme.colors.white}
                              placeholder={"Wpisz"}
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.otherCombinationDrug &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                        ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                      ] as FormikErrors<ICombinations>
                                  )?.otherCombinationDrug
                                )
                              }
                            />
                          </Grid>
                        )}
                      <Grid
                        container
                        display={"flex"}
                        gap={"10px"}
                        alignItems={"flex-end"}
                      >
                        <Grid>
                          <Input
                            isRequired={true}
                            label={"Dawka"}
                            isUpperCase={true}
                            name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDose`}
                            type={"number"}
                            maxWidth={"163px"}
                            onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDose`, null)}
                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.treatmentLines?.[index]
                                ?.combinationWithMedicine[idx]
                                .combinationDose
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.treatmentLines) &&
                              Array.isArray(formik.errors.treatmentLines) &&
                              typeof formik.errors.treatmentLines[index] ===
                              "object" &&
                              formik.touched.treatmentLines[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.combinationDose &&
                              Boolean(
                                (
                                  (
                                    formik.errors.treatmentLines[
                                      index
                                      ] as FormikErrors<ITreatmentLines>
                                  )?.combinationWithMedicine?.[
                                    idx
                                    ] as FormikErrors<ICombinations>
                                )?.combinationDose
                              )
                            }
                          />
                        </Grid>
                        <Grid pb={2}>
                          <RadioForm>
                            <RadioBox
                              id={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit1`}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"g/m"}
                              isUpperCase={false}
                              checked={combination.combinationUnit === "g/m"}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                )
                              }
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                        ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                      ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                            <RadioBox
                              id={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit2`}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"mg/kg m.c."}
                              isUpperCase={false}
                              checked={
                                combination.combinationUnit === "mg/kg m.c."
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                )
                              }
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                        ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                      ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                            <RadioBox
                              id={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit3`}
                              name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`}
                              value={"Inna jednostka"}
                              isUpperCase={false}
                              checked={
                                combination.combinationUnit === "Inna jednostka"
                              }
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `treatmentLines[${index}].combinationWithMedicine[${idx}].combinationUnit`,
                                  e.target.value
                                )
                              }
                              isError={
                                Array.isArray(formik.touched.treatmentLines) &&
                                Array.isArray(formik.errors.treatmentLines) &&
                                typeof formik.errors.treatmentLines[index] ===
                                "object" &&
                                formik.touched.treatmentLines[index]
                                  ?.combinationWithMedicine?.[idx]
                                  ?.combinationUnit &&
                                Boolean(
                                  (
                                    (
                                      formik.errors.treatmentLines[
                                        index
                                        ] as FormikErrors<ITreatmentLines>
                                    )?.combinationWithMedicine?.[
                                      idx
                                      ] as FormikErrors<ICombinations>
                                  )?.combinationUnit
                                )
                              }
                            />
                          </RadioForm>
                        </Grid>
                        {formik.values.treatmentLines[index]
                            .combinationWithMedicine[idx].combinationUnit ===
                          "Inna jednostka" && (
                            <Grid>
                              <Input
                                isRequired={true}
                                label={"Inna jednostka"}
                                isUpperCase={true}
                                name={`treatmentLines[${index}].combinationWithMedicine[${idx}].otherCombinationUnit`}
                                type={"text"}
                                maxWidth={"163px"}
                                colorLabel={theme.colors.grey1}
                                value={
                                  formik.values.treatmentLines?.[index]
                                    ?.combinationWithMedicine[idx]
                                    .otherCombinationUnit || ""
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                borderColor={theme.colors.grey5}
                                placeholderColor={theme.colors.grey4}
                                background={theme.colors.white}
                                placeholder={"Wpisz"}
                                isError={
                                  Array.isArray(formik.touched.treatmentLines) &&
                                  Array.isArray(formik.errors.treatmentLines) &&
                                  typeof formik.errors.treatmentLines[index] ===
                                  "object" &&
                                  formik.touched.treatmentLines[index]
                                    ?.combinationWithMedicine?.[idx]
                                    ?.otherCombinationUnit &&
                                  Boolean(
                                    (
                                      (
                                        formik.errors.treatmentLines[
                                          index
                                          ] as FormikErrors<ITreatmentLines>
                                      )?.combinationWithMedicine?.[
                                        idx
                                        ] as FormikErrors<ICombinations>
                                    )?.otherCombinationUnit
                                  )
                                }
                              />
                            </Grid>
                          )}
                        <Grid item xs={12}>
                          <Input
                            before={
                              <Label
                                isUpperCase={false}
                                style={{margin: "8px 0 0"}}
                                color={theme.colors.orange}
                                size={"superSmall"}
                                label={"liczba tygodni"}
                              />
                            }
                            maxWidth={"72px"}
                            isRequired={true}
                            label={"Zakres czasu przyjmowania"}
                            isUpperCase={true}
                            name={`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDuration`}
                            type={"number"}
                            onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].combinationWithMedicine[${idx}].combinationDuration`, null)}

                            colorLabel={theme.colors.grey1}
                            value={
                              formik.values.treatmentLines?.[index]
                                ?.combinationWithMedicine[idx]
                                .combinationDuration
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            borderColor={theme.colors.grey5}
                            placeholderColor={theme.colors.grey4}
                            background={theme.colors.white}
                            placeholder={"Wpisz"}
                            isError={
                              Array.isArray(formik.touched.treatmentLines) &&
                              Array.isArray(formik.errors.treatmentLines) &&
                              typeof formik.errors.treatmentLines[index] ===
                              "object" &&
                              formik.touched.treatmentLines[index]
                                ?.combinationWithMedicine?.[idx]
                                ?.combinationDuration &&
                              Boolean(
                                (
                                  (
                                    formik.errors.treatmentLines[
                                      index
                                      ] as FormikErrors<ITreatmentLines>
                                  )?.combinationWithMedicine?.[
                                    idx
                                    ] as FormikErrors<ICombinations>
                                )?.combinationDuration
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}

            {formik.values.treatmentLines[index].monotherapy ===
              "kombinacja" && (
                <Grid
                  mt={4}
                  mb={2}
                  display={"flex"}
                  item
                  xs={12}
                  justifyContent={"center"}
                >
                  <ButtonAddDrug type="button" onClick={() => addOtherCombination(index)}>
                    Dodaj kombinację z lekiem
                    <Plus/>
                  </ButtonAddDrug>
                </Grid>
              )}

            <Divider
              style={{width: "100%"}}
              variant={"fullWidth"}
              sx={{height: "2px", margin: "0 0 16px 0"}}
            />

            <Grid container display={"flex"} gap={"20px"} mt={2}>
              <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                <Label
                  size={"small"}
                  isUpperCase={true}
                  color={theme.colors.grey1}
                  label={"Podaj wynik w skali"}
                  isRequired={true}
                />
                <RadioForm>
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.resultScaleOption3followup &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.resultScaleOption3followup
                      )
                    }
                    id={`resultScaleOption3followup-${index}`}
                    name={`treatmentLines[${index}].resultScaleOption3followup`}
                    value={"BASDAI"}
                    checked={
                      formik.values.treatmentLines[index]
                        .resultScaleOption3followup === "BASDAI"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].resultScaleOption3followup`,
                        e.target.value
                      );
                    }}
                  />
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.resultScaleOption3followup &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.resultScaleOption3followup
                      )
                    }
                    id={`resultScaleOption3followup2-${index}`}
                    name={`treatmentLines[${index}].resultScaleOption3followup`}
                    value={"ASDAS"}
                    checked={
                      formik.values.treatmentLines[index]
                        .resultScaleOption3followup === "ASDAS"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].resultScaleOption3followup`,
                        e.target.value
                      );
                    }}
                  />
                </RadioForm>
              </Grid>
              <Grid item xs={2}>
                <Input
                  isRequired={true}
                  label={"Wynik"}
                  isUpperCase={true}
                  name={`treatmentLines[${index}].result3followup`}
                  type={"number"}
                  onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].result3followup`, null)}
                  maxWidth={"163px"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.treatmentLines?.[index]
                    ?.result3followup}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz"}
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.result3followup &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                          ] as FormikErrors<ITreatmentLines>
                      )?.result3followup
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
              <Grid item xs={3}>
                <SingleSelect
                  isRequired={true}
                  defaultValue={"Wybierz"}
                  isError={
                    Array.isArray(formik.touched.treatmentLines) &&
                    formik.touched.treatmentLines[index]?.vasResult3followup &&
                    Array.isArray(formik.errors.treatmentLines) &&
                    Boolean(
                      (
                        formik.errors.treatmentLines[
                          index
                          ] as FormikErrors<ITreatmentLines>
                      )?.vasResult3followup
                    )
                  }
                  label={"Wynik Vas"}
                  handleChange={(value) =>
                    setOption(value, "vasResult3followup", index)
                  }
                  selectedValue={
                    formik.values.treatmentLines[index]?.vasResult3followup ?? ""
                  }
                  elementName={`treatmentLines[${index}].vasResult3followup`}
                  options={vasOptions}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  isRequired={true}
                  label={"Wynik CRP"}
                  isUpperCase={true}
                  name={`treatmentLines[${index}].crpResult3followup`}
                  type={"number"}
                  onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].crpResult3followup`, null)}
                  colorLabel={theme.colors.grey1}
                  value={
                    formik.values.treatmentLines?.[index]
                      ?.crpResult3followup
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz"}
                  isError={
                    formik.touched.treatmentLines?.[index]
                      ?.crpResult3followup &&
                    Boolean(
                      (
                        formik.errors.treatmentLines?.[
                          index
                          ] as FormikErrors<ITreatmentLines>
                      )?.crpResult3followup
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  label={"Wynik OB"}
                  isUpperCase={true}
                  onChangeCustom={() => formik.setFieldValue(`treatmentLines[${index}].obResult3followup`, null)}
                  name={`treatmentLines[${index}].obResult3followup`}
                  type={"number"}
                  colorLabel={theme.colors.grey1}
                  value={
                    checkEmptyNumber(formik.values.treatmentLines?.[index]
                      ?.obResult3followup)
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz"}
                  isError={
                    formik.touched.treatmentLines?.[index]
                      ?.obResult3followup &&
                    Boolean(
                      (
                        formik.errors.treatmentLines?.[
                          index
                          ] as FormikErrors<ITreatmentLines>
                      )?.obResult3followup
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
              <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                <Label
                  size={"small"}
                  isUpperCase={true}
                  color={theme.colors.grey1}
                  label={"Czy były objawy nietolerancji wybranego leku?"}
                  isRequired={true}
                />
                <RadioForm>
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.symptomsOfIntolerance &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.symptomsOfIntolerance
                      )
                    }
                    id={`symptomsOfIntolerance1-${index}`}
                    name={`treatmentLines[${index}].symptomsOfIntolerance`}
                    value={"tak"}
                    checked={
                      formik.values.treatmentLines[index]
                        .symptomsOfIntolerance === "tak"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].symptomsOfIntolerance`,
                        e.target.value
                      );
                    }}
                  />
                  <RadioBox
                    isError={
                      Array.isArray(formik.touched.treatmentLines) &&
                      formik.touched.treatmentLines[index]
                        ?.symptomsOfIntolerance &&
                      Array.isArray(formik.errors.treatmentLines) &&
                      Boolean(
                        (
                          formik.errors.treatmentLines[
                            index
                            ] as FormikErrors<ITreatmentLines>
                        )?.symptomsOfIntolerance
                      )
                    }
                    id={`symptomsOfIntolerance2-${index}`}
                    name={`treatmentLines[${index}].symptomsOfIntolerance`}
                    value={"nie"}
                    checked={
                      formik.values.treatmentLines[index]
                        .symptomsOfIntolerance === "nie"
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        `treatmentLines[${index}].symptomsOfIntolerance`,
                        e.target.value
                      );
                    }}
                  />
                </RadioForm>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid mt={1} display={"flex"} item xs={12} justifyContent={"center"}>
        <ButtonAddDrug type="button" onClick={addLine}>
          Dodaj linię leczenia
          <Plus/>
        </ButtonAddDrug>
      </Grid>
    </Grid>
  );
});
