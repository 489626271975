import {IHeadCell} from '../../../../interfaces/common/table';

export const searchByFilter = <T>(searchValue: string, targetKeys: IHeadCell[]) => (element: T) => {
  if (!searchValue) return true;

  const searchArray = searchValue.trim().toLowerCase().split(' ').filter(el => el.length > 1);

  const targetString = targetKeys.map(key => element[key.id as keyof T]).join(' ').toLowerCase();

  return searchArray.every(searchWord => targetString.includes(searchWord));
}