import React, { FC } from "react";
import { TableCellData, TableCellDataPointer } from "./CellsSwitcher.styled";

interface IProps<Value> {
  id: number;
  keyName: string;
  type?: string;
  value: string | string[] | number;
  onClick?: () => void;
  cellsSwitcher?: (keyName: string, value: Value, onClick?: () => void, id?: number, type?: string) => null | JSX.Element;
}


export const CellsSwitcher: FC<IProps<any>> = ({
                                                 id,
                                                 keyName,
                                                 value,
                                                 onClick,
                                                 cellsSwitcher,
                                                 type
                                               }): JSX.Element | null => {

  if (cellsSwitcher) {
    return cellsSwitcher(keyName, value, onClick, id, type);
  }
  switch (keyName) {
    case 'id':
      return null
    case 'name':
      return (
        <TableCellDataPointer
          onClick={() => onClick && onClick()}
        >
          {value}
        </TableCellDataPointer>
      )
    default:
      return <TableCellData>{value}</TableCellData>;
  }
}


