import {
  coinfectionsSettings,
  statusesSettings,
} from "../components/pages/user/myCases/MyCases.helper";
import { ICaseCard } from "../interfaces/admin/cases/cases";
import { CaseStatus } from "../interfaces/user/draftCase/draft";

export const filterByCurrentPage =
  <T>(currentPage: number, cardsPerPage: number) =>
    (_: T, index: number) =>
      index >= (currentPage - 1) * cardsPerPage &&
      index < currentPage * cardsPerPage;

export const filterByStatus = (status: number[]) => (element: ICaseCard) =>
  status.length === 0 || (element.status === 'TO_ACCEPT' && status.includes(4)) ||
  (statusesSettings[element.status as CaseStatus] !== undefined &&
    status.includes(statusesSettings[element.status as CaseStatus]?.id!));


export const filterByCoinfection =
  (coinfection: number[]) => (element: ICaseCard) => {
    const coinfectionFistIndex = coinfection.length > 0 ? coinfection[0] : 0;

    const checkCoinfections = Object.values(coinfectionsSettings).find(
      (coinfectionFind) => coinfectionFind.id === coinfectionFistIndex
    );

    return (
      coinfection.length === 0 ||
      (checkCoinfections &&
        checkCoinfections.value.includes(element.coInfection))
    );
  };

export const filterByForms = (filtredForms: number[]) => (element: ICaseCard) =>
  filtredForms.length === 0 || filtredForms.includes(element.form.id);

export const searchByFilter =
  <T>(searchValue: string, targetKeys: string[]) =>
    (element: T) => {
      const searchArray = searchValue.trim().toLowerCase().split(" ");

      const targetString = targetKeys
        .map((el) => element[el as keyof T])
        .join(" ");

      return searchArray.every((searchWord) =>
        targetString.toLowerCase().includes(searchWord)
      );
    };
