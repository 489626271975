import { ICase } from "../../../../interfaces/admin/cases/cases";
import { CaseStatus } from "../../../../interfaces/user/draftCase/draft";

export const filterCases = (
  cases: ICase[] | null,
  selectedStatus: CaseStatus | "",
  selectedForm: number | ""
) => {
  if (cases === null) return [];

  let newCases = [...cases];

  if (selectedStatus !== "") {
    newCases = newCases.filter(
      (caseElement) => caseElement.status === selectedStatus
    );
  }
  if (selectedForm !== "") {
    newCases = newCases.filter(
      (caseElement) => caseElement.form.id === +selectedForm
    );
  }

  return newCases.map((el) => {
    return { ...el, id: el.id + "" + el.form.id };
  });
};

export const getCaseIDFromKey = (id: number | string) =>
  String(id).substring(0, String(id).length - 1);
