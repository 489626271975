import { Grid } from "@mui/material";

import { GroupForm, Label, NormalText } from "../HcvViewCase.styled";
import { observer } from "mobx-react-lite";
import { checkValueIsEmpty, selectOptions } from "../HcvViewCase.helper";
import { useHcvStore } from "../../../../../../../../store/hooks";
import { switchGender } from "../../../../../../../../utils/switchGender";
import { convertYesNoOption } from "../../../../../../../../utils/convertYesNo";

export const PatientSection = observer(() => {
  const caseStore = useHcvStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <Grid container mt={5}>
      <Grid item xs={12} mb={"36px"}>
        <GroupForm>Pacjent: {caseElement.initials}</GroupForm>
      </Grid>

      <Grid item xs={12}>
        <Grid container borderRadius={1} bgcolor={"white"} p={2}>
          <Grid item xs={1}>
            <Label>WIEK*</Label>
            <NormalText>{checkValueIsEmpty(caseElement.age)}</NormalText>
          </Grid>
          <Grid item xs={1.5}>
            <Label>PŁEĆ*</Label>
            <NormalText>
              {switchGender(checkValueIsEmpty(caseElement.gender))}
            </NormalText>
          </Grid>
          <Grid item xs={2}>
            <Label>KOINFEKCJA</Label>
            <NormalText>
              {caseElement.coInfection !== null && caseElement.coInfection > -1
                ? selectOptions[caseElement.coInfection].title
                : "-"}
            </NormalText>
          </Grid>
          <Grid item xs={7.5}>
            <Label>PACJENT LECZONY JEDNORAZOWO</Label>
            <NormalText>
              {convertYesNoOption(Boolean(caseElement.firstTreatment))}
            </NormalText>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
