import styled from 'styled-components';

export const RadioButtons = styled.div`
  gap: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8px;

  & label {
    display: flex;
  }
`;