import styled from "styled-components";
import theme from "../../../../theme/theme";

export const SearchCustom = styled.div`
  margin: auto 0 30px auto;
  position: relative;

  svg {
    position: absolute;
    right: 12px;
    top: 9px;

    path {
      fill: ${theme.colors.abbvie}
    }
  }

  input {
    width: 100%;
  }
`