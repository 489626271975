export const numberConverter = (number: number) => {
  const maxDay = 9;
  const minDay = 0;

  return number <= maxDay && number >= minDay ? `0${number}` : number;
};

export const additionalDay = 1;

export const dateStringify = (date: string | null, isDay = true) => {
  if (date === null) {
    return "";
  }

  const convertDate = new Date(date);

  if (isNaN(convertDate.getDate())) {
    return "";
  }

  return convertDate
    ? `${
        isDay ? numberConverter(convertDate.getDate()) + "." : ""
      }${numberConverter(
        convertDate.getMonth() + additionalDay
      )}.${convertDate.getFullYear()}`
    : "";
};

export const timeStringify = (date: string) => {
  const convertDate = new Date(date);

  if (isNaN(convertDate.getDate())) {
    return "";
  }

  return convertDate
    ? convertDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    : "";
};

export const convertDate = (dateElement: number[]) => {
  return dateElement[1] + "/" + String(dateElement[0]).slice(2, 4);
};

export const yearStringify = (date: string, isDay = true) => {
  const convertDate = new Date(date);

  if (isNaN(convertDate.getDate())) {
    return "";
  }

  return convertDate && convertDate.getFullYear();
};
