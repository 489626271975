
import {Grid} from "@mui/material";
import {ReactComponent as Success} from "../../../../../../../assets/icon/thick.svg";
import {ContainerSixthStage, ParagraphSixthStage, StyledImage} from "./FinishMessage.styled";

export const FinishMessage = () => {
  return (
    <Grid container maxWidth={'714px'} justifyContent={'center'} display={'flex'}
          margin={'auto auto'} mt={7}>
      <ContainerSixthStage>
        <StyledImage ><Success/></StyledImage>
        <ParagraphSixthStage><strong>Opis przypadku został wysłany do akceptacji.</strong> <br/>
            Po zaakceptowaniu będzie widoczny dla innych użytkowników serwisu. <br/>
            Zarówno opis "zaakceptowany" jak i "do edycji" będzie dla Ciebie widoczny w zakładce MOJE OPISY PRZYPADKÓW.
        </ParagraphSixthStage>
      </ContainerSixthStage>
    </Grid>
  )
};
