import doctor from './../../../../../assets/img/doctor.png'
import { Container, Doctor } from './RegisterLayout.styled'
import { Grid } from "@mui/material";
import React from "react";
import backgroundForm from './../../../../../assets/img/form_background.png'
import { Footer } from "../../../../shared/user/layout/footer/Footer";

interface IProps {
  bcgImage: string
  children: React.ReactNode
}

export const AuthLayout: React.FC<IProps> = ({bcgImage, children}) => {
  return (
    <Grid>
      <Container $bcgImage={bcgImage}>
        <Grid container height={'100%'}>
          <Grid item xs={12} sm={6} height={'100%'} alignItems={'flex-end'} display={'flex'}>
            <Doctor src={doctor}/>
          </Grid>
          <Grid position={'relative'} item xs={12} sm={5} height={'100%'} display={'flex'}
                maxWidth={'508px'} alignItems={'center'} flexDirection={'column'}
                sx={{backgroundImage: `url(${backgroundForm})`, backgroundSize: '100% 100%'}}>
            {children}
          </Grid>
        </Grid>
      </Container>
      <Footer/>
    </Grid>
  )
}