import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFormsStore } from '../../../../store/hooks'
import { IAction, IHeadCell } from '../../../../interfaces/common/table'
import { ModalType } from '../../../../constants/modalType'
import { AdminLink, generateLink } from '../../../../utils'
import { useModal } from '../../../shared/modal/useModal.vm'
import { createTableRows } from '../../../shared/customTables/utils/createTableRows'
import { ReactComponent as DeleteIcon } from '../../../../assets/icon/delete.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icon/edit.svg';
import theme from '../../../../theme/theme'
import { useFormsApi } from "../../../../apiHooks/useFormsApi";
import { TableCellData } from "../../../shared/customTables/cells/cellsSwitcher/CellsSwitcher.styled";

export const useForms = () => {
  const [loaded, setLoaded] = useState(false)
  const navigate = useNavigate();
  const formsStore = useFormsStore()
  const {isFormsLoaded, forms} = formsStore
  const {getForms} = useFormsApi()
  const [actionId, setActionId] = useState<number | null>(null);
  const {handleModal, isModalOpened, closeModal, modalType} = useModal();

  useEffect(() => {
    if (!loaded) {
      if (forms === null) {
        void getForms()
      } else {
        void getForms(false)
      }
      setLoaded(true)
    }
  }, [loaded, forms, getForms])

  useEffect(() => {
    return () => formsStore.resetForms();
  }, [formsStore])

  const deleteHandler = (id: number | string) => {
    handleModal(ModalType.DELETE_MODAL);
    setActionId(+id);
  }

  const newHandler = () => {
    navigate(generateLink(AdminLink.ADD_FORM));
  }

  const editModalHandler = (id: number | string) => {
    // navigate(generateLink(AdminLink.EDIT_FORM, id));
  }

  const deleteHandlerYes = async () => {
    if (forms && actionId) {
      // const isDelete = await deleteForm(actionId);
      //if (isDelete) {
      await getForms();
      setActionId(null);
      handleModal(ModalType.DELETE_CONFIRMATION_MODAL);
      // }
    }
  }

  const generateActions = () => {
    const actions: IAction[] = [
      {
        key: 'edit',
        actionHandler: editModalHandler,
        background: theme.colors.abbvie,
        actionElement: <EditIcon/>,
        width: 52,
      },
      {
        key: 'delete',
        actionHandler: deleteHandler,
        background: theme.colors.blue2,
        actionElement: <DeleteIcon/>,
        width: 52,
      }
    ];
    return actions;
  };

  const cellsSwitcher = (keyName: string, value: string | string[], onClick?: () => void) => {

    switch (keyName) {
      case 'id':
        return <TableCellData>{value.toString()}</TableCellData>;
      case 'areas':
        return <TableCellData>{(value as string[]).join(', ')}</TableCellData>;
      default:
        return <TableCellData>{value.toString()}</TableCellData>;
    }
  };

  const headCells: IHeadCell[] = [
    {id: 'id', numeric: true, label: 'id'},
    {id: 'title', numeric: false, label: 'Nazwa formularza (wskazania)'},
    {id: 'areas', numeric: false, label: 'Obszary'},
  ];

  const tableDataRows = createTableRows(formsStore.forms ?? [], headCells, false)

  return {
    isFormsLoaded,
    generateActions,
    headCells,
    cellsSwitcher,
    newHandler,
    isModalOpened,
    closeModal,
    modalType,
    tableDataRows,
    actionId,
    deleteHandlerYes
  }
}