import {FC} from 'react'

// components
import {Grid} from '@mui/material';
import {Input} from '../../../../../../../../../shared/ui/input/Input';
import {DrugContainer, Title} from './Drug.styled';
import {ButtonTransparent} from '../../../../../../saveDraftBtn/SaveDraftBtn.styled';
import {ReactComponent as Bin} from '../../../../../../../../../../assets/icon/bin.svg'

// lib
import theme from '../../../../../../../../../../theme/theme';
import {FormikErrors} from "formik";
import {IDrug} from "../type";

interface IDrugElement {
  formik: any;
  deleteDrug: null | ((index: number) => void)
  index: number;
}

export const DrugElement: FC<IDrugElement> = ({formik, deleteDrug, index}) => {

  return <DrugContainer container>
    <Grid item xs={12} mt={2} mb={2} position='relative'>
      {deleteDrug && <ButtonTransparent onClick={() => deleteDrug(index)}>usuń lek<Bin/></ButtonTransparent>}
      <Title>{`LEK ${index + 1}`}</Title>
    </Grid>
    <Grid item xs={12}>
      <Input
        name={`drugs[${index}].drugName`}
        type={"text"}
        isUpperCase={true}
        label={"Lek:"}
        colorLabel={theme.colors.grey1}
        value={formik.values.drugs[index].drugName}
        onChange={formik.handleChange}
        borderColor={theme.colors.grey5}
        placeholderColor={theme.colors.grey4}
        background={theme.colors.white}
        placeholder={"Wpisz lek..."}
        isError={
          Array.isArray(formik.touched.drugs) &&
          formik.touched.drugs[index]?.drugName &&
          Array.isArray(formik.errors.drugs) &&
          Boolean(
            (
              formik.errors.drugs[
                index
                ] as FormikErrors<IDrug>
            )?.drugName
          )
        }
      />
    </Grid>
    <Grid item xs={3} mb={2}>
      <Input
        name={`drugs[${index}].drugDose`}
        type={"text"}
        isUpperCase={true}
        label={"Dawka:"}
        colorLabel={theme.colors.grey1}
        value={formik.values.drugs[index].drugDose}
        onChange={formik.handleChange}
        borderColor={theme.colors.grey5}
        placeholderColor={theme.colors.grey4}
        background={theme.colors.white}
        placeholder={"Wpisz dawkę leku..."}
        isError={
          Array.isArray(formik.touched.drugs) &&
          formik.touched.drugs[index]?.drugDose &&
          Array.isArray(formik.errors.drugs) &&
          Boolean(
            (
              formik.errors.drugs[
                index
                ] as FormikErrors<IDrug>
            )?.drugDose
          )
        }
      />
    </Grid>
  </DrugContainer>
}