import { observer } from "mobx-react-lite";
import { UserLayout } from "../../../shared/user/layout/UserLayout";
import { Grid } from '@mui/material';
import {
  Header,
  ProfileElement,
  Container,
  ResetLinkContainer,
  ResetLink, Separator
} from './Profile.styled';
import { ReactComponent as ProfileIcon } from '../../../../assets/icon/profile.svg';
import { useProfile } from "./useProfile.vm";
import { Button } from "../../../shared/button/Button";
import theme from "../../../../theme/theme";
import { ReactComponent as Pen } from "../../../../assets/icon/edit.svg";
import React from "react";
import { EditProfile } from "./editProfile/EditProfile";

export const Profile = observer(() => {
  const {
    resetNavigateHandler, profile, editModalHandler, isModalOpened,
    closeModal
  } = useProfile()

  return (
    <UserLayout isDoctor={true}>
      <Container>
        {profile && <Grid container flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
          <Grid item flexDirection={'column'}>
            <Header>
              <ProfileIcon/>
              Profil użytkownika:</Header>
            <Grid item>
              <p><strong>Przypisane obszary terapeutyczne:</strong></p>
              <ProfileElement>{profile.areas.map(area => area.title).join(', ')}</ProfileElement>
            </Grid>
            <Separator/>
            <Grid item>
              <p><strong>Imię i nazwisko:</strong></p>
              <ProfileElement>{profile.affiliation} {profile.fullName}</ProfileElement>
            </Grid>
            <Grid item>
              <p><strong>E-mail:</strong></p>
              <ProfileElement>{profile.email}</ProfileElement>
            </Grid>
            <Grid item>
              <p><strong>Numer kontaktowy:</strong></p>
              <ProfileElement>{profile.phone || '-'}</ProfileElement>
            </Grid>
            <Grid item>
              <p><strong>Numer PWZ:</strong></p>
              <ProfileElement>{profile.pwz}</ProfileElement>
            </Grid>
            <Grid item>
              <p><strong>Miejsce pracy:</strong></p>
              <ProfileElement>{profile.workPlace || '-'}</ProfileElement>
            </Grid>
            <ResetLinkContainer>Kliknij <ResetLink onClick={resetNavigateHandler}>tutaj</ResetLink> aby
              zresetować hasło</ResetLinkContainer>
            <Grid item>
              <Button
                clickHandler={editModalHandler}
                text={'edytuj'}
                backgroundColor={theme.colors.abbvie}
                hoverColor={theme.colors.lightAbbvie}
                color={theme.colors.white}
                size={'tiny'}
                maxWidth={'126px'}
                icon={<Pen/>}
              />
            </Grid>
          </Grid>
        </Grid>
        }
      </Container>
      {isModalOpened && <EditProfile closeModal={closeModal}/>}
    </UserLayout>
  )
})