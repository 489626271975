const setZeroIfLessThanTen = (element: number) => {
  if (element >= 10) {
    return element.toString();
  }
  return "0" + element;
}

export type SplitData = [string, string];

export const splitData = (data: string | Date): SplitData => {
  if (typeof data === 'string' && data === '-') {
    return ["-", ""];
  }

  const convertData = new Date(data);

  if (convertData) {
    const dateElement = `${setZeroIfLessThanTen(convertData.getDate())}.${setZeroIfLessThanTen(convertData.getMonth() + 1)}.${setZeroIfLessThanTen(convertData.getFullYear())}`
    const timeElement = `${setZeroIfLessThanTen(convertData.getHours())}:${setZeroIfLessThanTen(convertData.getMinutes())}`;

    return [timeElement, dateElement];
  }
  return ["-", ""];
}
