import {observer} from "mobx-react";

// hooks
import {useRzsStore} from "../../../../../../../../store/hooks";

// components
import {Divider, Grid, Typography} from "@mui/material";
import {GroupForm, Label, NormalText} from "../../../virusology/hcv/HcvViewCase.styled";

// lib
import {checkValueIsEmpty} from "../../../virusology/hcv/HcvViewCase.helper";
import {dateStringify} from "../../../../../../../../utils";
import React from "react";
import {Attachments} from "../../../virusology/hcv/common/attachments/Attachments";
import theme from "../../../../../../../../theme/theme";

export const Observations = observer(() => {
  const caseStore = useRzsStore();
  const {case: caseElement} = caseStore;

  if (!caseElement) {
    return <></>;
  }

  return (
    <>
      <Grid container mt={8}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Rinvoq:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Label>Wyniki badań przed włączeniem leku Rinvoq</Label>
          <Grid item xs={12} mb={4} mt={2}>
            <Label>Data włączenia leku</Label>
            <NormalText>
              {checkValueIsEmpty(dateStringify(caseElement.drugInitiationDate))}
            </NormalText>
          </Grid>
          <Grid container gap={'20px'} mb={4}>
            <Grid item xs={2}>
              <Label>WYNIK DAS 28</Label>
              <NormalText>
                {checkValueIsEmpty(caseElement.das28Result3?.toUpperCase())}
              </NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>WYNIK</Label>
              <NormalText>{checkValueIsEmpty(caseElement.result3)}</NormalText>
            </Grid>
            <Grid item>
              <Label>WYNIK skali</Label>
              <NormalText> &lt;2,6 - remisja <br/>
                &le;3,2 – aktywność mała <br/>
                &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                &gt;5,1 – aktywność duża</NormalText>
            </Grid>
          </Grid>
          <Grid container gap={'20px'} mb={4}>
            <Grid item xs={2}>
              <Label>WYNIK VAS</Label>
              <NormalText>{checkValueIsEmpty(caseElement.vasResult3)}</NormalText>
            </Grid>
            <Grid item xs={2}>
              <Label>
                WYNIK {caseElement.das28Result3 === "crp" ? "OB" : "CRP"}
              </Label>
              <NormalText>
                {checkValueIsEmpty(
                  caseElement.das28Result3 === "crp"
                    ? caseElement.obResult3
                    : caseElement.crpResult3
                )}
              </NormalText>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Label>LICZBA BOLESNYCH STAWÓW</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.tenderJointsCount3)}
            </NormalText>
          </Grid>
          <Grid item xs={4}>
            <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.swollenJointsCount3)}
            </NormalText>
          </Grid>
        </Grid>

        <Grid container bgcolor={"white"} p={2} mt={5}>
          <Label>Obserwacja na leku Rinvoq</Label>
          <Grid item xs={12} mt={2}>
            <Label>Interwały obserwacji</Label>
            <NormalText>
              {checkValueIsEmpty(caseElement.observationIntervals)}
            </NormalText>
          </Grid>
        </Grid>


        {caseElement.observations.map((observation, index) => (
          <Grid container mt={5} bgcolor={"white"} p={2} key={index}>
            <Grid item xs={12} mb={2}>
              <Label $size={"large"} $mb={0}>
                {`Obserwacja ${index + 1}`}
              </Label>
            </Grid>
            <Grid item xs={12} mt={4} mb={4}>
              <Label>Monoterapia czy kombinacja z innym lekiem?</Label>
              <NormalText>{checkValueIsEmpty(observation.monotherapy)}</NormalText>
            </Grid>
            {observation.combinationWithMedicine.length > 0 && <Divider
              style={{width: "100%"}}
              variant={"fullWidth"}
              sx={{height: "2px", margin: '0 0 32px 0'}}
            />}
            {
              observation.combinationWithMedicine.map((el, idx) => (
                <Grid container key={idx}>
                  <Grid item xs={12} mb={2}>
                    <Label $size={"large"} $mb={0}>
                      {`Kombinacja ${idx + 1}`}
                    </Label>
                  </Grid>
                  <Grid container gap={2}>
                    <Grid item xs={12}>
                      <Label>Kombinacja Z jakim lekiem?</Label>
                      <NormalText>{checkValueIsEmpty(el.combinationDrugName)}</NormalText>
                    </Grid>
                    {el.otherCombinationDrug && el.combinationDrugName === 'Inne' && <Grid item xs={12}>
                      <Label>nazwa innego leku</Label>
                      <NormalText>{checkValueIsEmpty(el.otherCombinationDrug)}</NormalText>
                    </Grid>}
                    <Grid item xs={12}>
                      <Label>Dawka</Label>
                      <NormalText>{checkValueIsEmpty(el.combinationDose)}
                        {checkValueIsEmpty(el.combinationUnit === 'Inna jednostka' ? el.otherCombinationUnit : el.combinationUnit)}
                      </NormalText>
                    </Grid>
                    <Grid item xs={12}>
                      <Label>Zakres czasu przyjmowania</Label>
                      <Typography variant={'caption'} color={theme.colors.orange}>liczba
                        tygodni</Typography>
                      <NormalText>{checkValueIsEmpty(el.combinationDuration)}</NormalText>
                    </Grid>
                  </Grid>
                  <Divider
                    style={{width: "100%"}}
                    variant={"fullWidth"}
                    sx={{height: "2px", margin: "32px 0"}}
                  />
                </Grid>
              ))
            }


            <Grid container gap={'20px'} mb={4}>
              <Grid item xs={2}>
                <Label>WYNIK DAS 28</Label>
                <NormalText>
                  {checkValueIsEmpty(observation.das28Result)}
                </NormalText>
              </Grid>
              <Grid item xs={2}>
                <Label>WYNIK</Label>
                <NormalText>{checkValueIsEmpty(observation.result)}</NormalText>
              </Grid>
              <Grid item>
                <Label>WYNIK skali</Label>
                <NormalText> &lt;2,6 - remisja <br/>
                  &le;3,2 – aktywność mała <br/>
                  &gt;3,2 i &le;5,1 – aktywność umiarkowana <br/>
                  &gt;5,1 – aktywność duża</NormalText>
              </Grid>
            </Grid>
            <Grid container gap={'20px'} mb={4}>
              <Grid item xs={2}>
                <Label>WYNIK VAS</Label>
                <NormalText>{checkValueIsEmpty(observation.vasResult)}</NormalText>
              </Grid>
              <Grid item xs={2}>
                <Label>
                  WYNIK {observation.das28Result === "crp" ? "OB" : "CRP"}
                </Label>
                <NormalText>
                  {checkValueIsEmpty(
                    observation.das28Result === "crp"
                      ? observation.obResult
                      : observation.crpResult
                  )}
                </NormalText>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Label>LICZBA BOLESNYCH STAWÓW</Label>
              <NormalText>
                {checkValueIsEmpty(observation.tenderJointsCount)}
              </NormalText>
            </Grid>
            <Grid item xs={4}>
              <Label>LICZBA OBRZĘKNIĘTYCH STAWÓW</Label>
              <NormalText>
                {checkValueIsEmpty(observation.swollenJointsCount)}
              </NormalText>
            </Grid>
            <Grid item xs={12} mt={4}>
              <Label>Zmiany w zakresie skal łuszczycy</Label>
              <NormalText>
                {checkValueIsEmpty(observation.psoriasisChanges)}
              </NormalText>
            </Grid>
          </Grid>))}
        <Grid container bgcolor={"white"} p={2}>
          <Grid item xs={12}>
            <Label>Glikokortykosteroidy (GKS)</Label>
            <NormalText>{checkValueIsEmpty(caseElement.gks)}</NormalText>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={8} mb={5}>
        <Grid item xs={12} mb={4}>
          <GroupForm>Wnioski:</GroupForm>
        </Grid>
        <Grid container bgcolor={"white"} p={2}>
          <Grid item xs={12} sx={{whiteSpace: 'pre-line'}}>
            <NormalText>
              {checkValueIsEmpty(caseElement.conclusions)}
            </NormalText>
          </Grid>
        </Grid>
        {caseElement.rzsFiles && caseElement.rzsFiles.length > 0 &&
          <Grid container bgcolor={"white"} p={2} mt={3}>
            <Grid item xs={12}>
              <Attachments
                title={'Zdjęcia przykładowych zmian skórnych lub obrazowania'}
                attachments={
                  caseElement.rzsFiles
                }
              />
            </Grid>
          </Grid>}
      </Grid>
    </>
  );
});
