import React from 'react'
import {RegisterFormPublishComponent} from "./form/RegisterFormPublishComponent";
import theme from "../../../../../theme/theme";
import {AuthLayout} from "../layout/RegisterLayout";
import background from './../../../../../assets/img/background_purple.jpg'

const RegisterPublishingUser = () => {
  return <AuthLayout bcgImage={background}>
    <RegisterFormPublishComponent
      labelColor={theme.colors.white}
      buttonBcgColor={theme.colors.abbvie}
      buttonTextColor={theme.colors.white}/>
  </AuthLayout>
}

export default RegisterPublishingUser
