import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Snackbar from '@mui/material/Snackbar';
import { useNotificationsStore } from '../../../../store/hooks'
import { Alert } from "@mui/material";


export const Notifications = observer(() => {
  const notificationsStore = useNotificationsStore();

  const {notification} = notificationsStore;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (notification) {
      setIsOpen(true);
    }
  }, [notification])

  const closeNotificationHandler = () => {
    setIsOpen(false);
  }

  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={isOpen}
      onClose={closeNotificationHandler}
      autoHideDuration={3000}
      key={new Date().getTime().toString() + Math.random()}
      sx={{
        zIndex: 10000
      }}
    >
      <Alert
        onClose={closeNotificationHandler}
        severity={notification?.notificationType}
        sx={{
          width: '100%',
          zIndex: 11001
        }}
        variant='filled'
      >
        {notification?.message}
      </Alert>
    </Snackbar>
  )
})