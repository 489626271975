import React, {memo} from 'react';
import {Container} from "../FileUploadLayout.styled";
import {useDropZone} from "./useDropZone";
import {Tooltip} from "@mui/material";

// Define interface for component props/api:
export interface IDropZoneProps {
  onDragStateChange?: (isDragActive: boolean) => void;
  onDrag?: () => void;
  onDragIn?: () => void;
  onDragOut?: () => void;
  onDrop?: () => void;
  onFileDrop?: (file: File) => void;
  onFilesDrop?: (files: File[]) => void;
  style?: React.CSSProperties;
  id: string
  disabled?: boolean
}

export const DropZone = memo((props: React.PropsWithChildren<IDropZoneProps>) => {
  const {dropZoneRef} = useDropZone(props);

  const containerProps = {
    disabled: props.disabled,
    id: props.id,
    item: true,
    ref: dropZoneRef,
  };

  const containerElement = <Container {...containerProps}>{props.children}</Container>;

  return props.disabled ? (
    <Tooltip placement={'bottom'} title={'Zaznacz oświadczenie powyżej'}>
      {containerElement}
    </Tooltip>
  ) : (
    <>{containerElement}</>
  );
});
