import React from 'react'

import { useAddUser } from './useAddUser';
import { AdminLayout } from '../../../shared/admin/layout/AdminLayout';
import { Modal } from '../../../shared/modal/Modal';
import { ConfirmationModal } from '../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import { UserForm } from '../../../shared/admin/userForm/UserForm';
import { AdminLink } from '../../../../utils';
import { TextModal } from './AddUser.styled';
import { observer } from "mobx-react-lite";

const AddUser = observer(() => {
  const {
    actionHandler,
    isModalOpened,
    closeModal,
    handleNo
  } = useAddUser()


  return <AdminLayout centralize={true}>
    <UserForm handleNo={handleNo} link={AdminLink.ADD_USER} actionHandler={actionHandler}/>
    {isModalOpened && <Modal clickHandler={() => closeModal()}>
      <ConfirmationModal
        text={
          <TextModal><p>Użytkownik został <b>dodany poprawnie.</b></p>
            <p><b>Automatycznie link aktywacyjny </b> zostanie wysłany aby użytkownik mógł się
              zarejestrować.
              (Dotyczy użytkownika publikującego, administratora oraz rozliczającego)</p>
          </TextModal>}
      /></Modal>}
  </AdminLayout>
})

export default AddUser;

