import React, { ReactNode } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  InputLabel,
  SelectChangeEvent,
  checkboxClasses,
} from "@mui/material";

import { MultipleSelectStyled } from "./MultipleSelect.styled";
import { IOption } from "../../../../interfaces/common/form/option";
import { ReactComponent as SelectDropdown } from "./../../../../assets/icon/selectDropdown.svg";

interface IProps {
  selectedValues: number[];
  elementName: string;
  title?: string;
  subtitle?: string;
  subtitleArea?: string;
  options: IOption[];
  handleChange: (event: SelectChangeEvent<number[]>, child: ReactNode) => void;
  subtitleLength?: number;
  isError?: boolean;
  staticPlaceholder?: string;
  isFilterList?: boolean;
}

export const MultipleSelect: React.FC<IProps> = ({
  isError,
  subtitle,
  selectedValues,
  elementName,
  options,
  handleChange,
  title,
  subtitleLength,
  staticPlaceholder,
  subtitleArea,
  isFilterList,
}) => {
  const renderedValue = (selectedValues: number[]) => {
    if (staticPlaceholder) return staticPlaceholder;

    if (subtitleLength) {
      if (selectedValues && selectedValues.includes(0)) {
        return "";
      } else {
        return ` (${subtitleLength})`;
      }
    } else {
      if (selectedValues && selectedValues.includes(0)) {
        return "Wszystko";
      } else {
        return (
          selectedValues &&
          selectedValues
            .map(
              (id) => options.find((option: IOption) => option.id === id)?.title
            )
            .join(", ")
        );
      }
    }
  };

  const renderValueList = (selectedValues: number[]) => {
    return selectedValues.length > 0
      ? selectedValues
          .filter((option) => option > 0)
          .map(
            (val) => options.find((option) => option.id === val)?.title || ""
          )
          .join(", ")
      : staticPlaceholder;
  };

  return (
    <MultipleSelectStyled>
      <FormControl fullWidth={true}>
        {title && <h5>{title}</h5>}
        {selectedValues && selectedValues.length === 0 && (
          <InputLabel
            sx={{
              paddingBottom: "4px",
              "&.MuiFormLabel-root": {
                transform: "translate(14px, 7px)",
              },
            }}
            id={elementName}
          >
            {subtitle}
            {subtitleArea}
          </InputLabel>
        )}
        <Select
          IconComponent={SelectDropdown}
          error={isError}
          labelId={elementName}
          id={elementName}
          multiple
          value={selectedValues || []}
          onChange={handleChange}
          displayEmpty={true}
          renderValue={(selected: number[]) =>
            isFilterList ? (
              renderValueList(selected)
            ) : (
              <div>
                {subtitleLength && subtitle
                  ? subtitle
                  : subtitle
                  ? subtitle + ": "
                  : ""}
                {renderedValue(selected)}
              </div>
            )
          }
          sx={{
            color: "#646464",
            borderRadius: "10px",
            padding: "0 10px",
            fontSize: "16px",
            ".MuiOutlinedInput-notchedOutline": {
              border: "2px solid #E0E0E0;",
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: "none",
              },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #E0E0E0;",
            },
          }}
        >
          {options.map((option: IOption) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{ padding: "10px 16px", height: "28px", margin: "4px 0" }}
            >
              <ListItemText primary={option.title} />
              <Checkbox
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: "#071C47",
                  },
                }}
                checked={selectedValues && selectedValues.includes(option.id)}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MultipleSelectStyled>
  );
};
