import {observer} from "mobx-react-lite";

// hooks
import {useRzsStore} from "../../../../../../../store/hooks";

// types
import {EnAreaNames, EnFormNames,} from "../../../../../../../store/caseSettingsStore";

// components
import {TopViewCase} from "../../topViewCase/TopViewCase";
import {UserLayout} from "../../../../../../shared/user/layout/UserLayout";
import {PatientSection} from "./patientSection/PatientSection";
import {CurrentTreatmentSection} from "./currentTreatmentSection/CurrentTreatmentSection";
import {TreatmentProgramSection} from "./treatmentProgramSection/TreatmentProgramSection";

// lib
import {useViewCase} from "../../../viewCaseCreator/useViewCase";
import {Observations} from "./obervations/Observations";

export const RzsViewCase = observer(() => {
  const rzsStore = useRzsStore();
  const {isCase} = useViewCase(
    EnAreaNames.rheumatology,
    EnFormNames.rzs,
    rzsStore
  );

  return (
    <UserLayout>
      {isCase && (
        <>
          <TopViewCase caseStore={rzsStore}/>
          <PatientSection/>
          <CurrentTreatmentSection/>
          <TreatmentProgramSection/>
          <Observations/>
        </>
      )}
    </UserLayout>
  );
});
