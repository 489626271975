import styled from "styled-components";

export const RadioForm = styled.div<{ $flexDirection?: string, $alignItems?: string }>`
  display: flex;
  flex-direction: ${(props) =>
          props.$flexDirection ? props.$flexDirection : "row"};
  gap: 16px;
  height: 32px;
  align-items: ${(props) =>
          props.$alignItems ? props.$alignItems : "center"};
`;