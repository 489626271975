import { useRootStore } from "../../RootStateContext";

export const useRzsStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.rzsStore) {
    throw new Error("rzsStore store should be defined");
  }

  return rootStore.rzsStore;
};
