import { useLocation, useNavigate } from 'react-router-dom';
import { RoleCategory, RoleFunction } from '../../../../constants/projectUserRoles';
import { useAuthStore, useNotificationsStore } from '../../../../store/hooks';
import { useAuthApi } from '../../../../apiHooks';
import { GeneralLink, UserLink } from '../../../../utils';
import { checkPermission } from '../../../../utils/checkPermission';

export const useUserTop = () => {
  const location = useLocation()
  const authStore = useAuthStore();
  const {fullName, roleFunction, roleCategory} = authStore;
  const notificationStore = useNotificationsStore();

  const authApi = useAuthApi();
  const navigate = useNavigate();

  const profileHandler = async () => {
    if (!checkPermission(roleFunction, [RoleFunction.ROLE_ACCEPTING, RoleFunction.ROLE_SCIENTIFIC, RoleFunction.ROLE_ADMIN])) {
      navigate(UserLink.HOME);
    }
  }

  const logoutHandler = async () => {
    await authApi.serverLogoutUser();
    notificationStore.setNotification(null)
    navigate(GeneralLink.LOGIN);
  }

  const isActiveHome = location.pathname === UserLink.HOME;
  const isUser = roleCategory === RoleCategory.USER;
  const isActiveLink = location.pathname === UserLink.PROFILE && isUser;

  return {
    isUser,
    isActiveLink,
    isActiveHome,
    profileHandler,
    logoutHandler,
    fullName,
    location
  }
}