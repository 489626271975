import { makeAutoObservable } from "mobx";
import { TRzs } from "../../components/pages/user/draftCase/forms/rheumatology/rzs/type";
import { TRzsStage01 } from "../../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage01/type";
import { TRzsStage02 } from "../../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage02/type";
import { TRzsStage03 } from "../../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage03/type";
import { TRzsStage04 } from "../../components/pages/user/draftCase/forms/rheumatology/rzs/stages/stage04/type";
import { TRzsStage05 } from "../../components/pages/user/draftCase/forms/rheumatology/rzs/stages/Stage05/type";

export class RzsStore {
  case: TRzs | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCase(value: TRzs) {
    this.case = value;
  }

  get caseStage() {
    return this.case?.step;
  }

  get firstStage() {
    return this.case as TRzsStage01;
  }

  get secondStage() {
    return this.case as TRzsStage02;
  }

  get thirdStage() {
    return this.case as TRzsStage03;
  }

  get fourthStage() {
    return this.case as TRzsStage04;
  }

  get fifthStage() {
    return this.case as TRzsStage05
  }

  resetCase() {
    this.case = null;
  }
}
