import React from 'react'
import {Input} from "../../../../shared/ui/input/Input";
import {Button} from "../../../../shared/button/Button";
import {Grid} from "@mui/material";
import {ReactComponent as ArrowRight} from './../../../../../assets/icon/arrowRight.svg'
import {formData} from "./retrievePasswordFormData";
import {useRetrievePasswordForm} from "./useRetrievePasswordForm.vm";
import {ParagraphContainer, ParagraphMain, RegisterViewingFormContainer} from "./RetrievePasswordForm.styled";
import theme from "../../../../../theme/theme";
import {AuthLabel} from "../../../../shared/ui/authLabel/AuthLabel";

export const RetrievePasswordForm = () => {
  const {
    formik,
    confirmForm
  } = useRetrievePasswordForm()

  return <RegisterViewingFormContainer onSubmit={formik.handleSubmit}>
    <AuthLabel title={'odzyskiwanie hasła'} bcg={theme.colors.blue2}/>
    <Grid container justifyContent={'space-between'}>
      <Input key={formData.name} label={formData.label} name={formData.name} type={formData.type}
             value={formik.values.email}
             isUpperCase={true}
             color={'white'}
             placeholderColor={'rgba(255, 255, 255, 0.70)'}
             background={'rgba(255,255,255,0.1)'}
             onChange={formik.handleChange}
             placeholder={formData.placeholder}
             textError={formik.errors.email}
             isError={formik.touched.email && Boolean(formik.errors.email)}
      />
    </Grid>
    <ParagraphContainer>
      <ParagraphMain>Na ten adres zostanie wysłany link aktywacyjny, dzięki któremu nadasz nowe hasło.</ParagraphMain>
      <ParagraphMain>
        Jeśli w ciągu 5min nie pojawi się na twojej skrzynce pocztowej sprawdź folder “SPAM”. Jeżeli dalej nie
        otrzymałeś emaila z linkiem aktywacyjnym skontaktuj się z naszym supportem.</ParagraphMain>
    </ParagraphContainer>

    <Button text={'wyślij'} clickHandler={confirmForm}
            backgroundColor={theme.colors.abbvie}
            hoverColor={theme.colors.lightAbbvie}
            color={'white'}
            size={'tiny'} type={'submit'}
            maxWidth={'135px'} icon={<ArrowRight/>}/>
  </RegisterViewingFormContainer>
}
