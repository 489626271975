import React from 'react'
import { RegisterFormAdminComponent } from "./form/RegisterFormAdminComponent";
import theme from "../../../../../theme/theme";
import { AuthLayout } from "../layout/RegisterLayout";
import background from './../../../../../assets/img/background_purple.jpg'

const RegisterAdminUser = () => {
  return <AuthLayout bcgImage={background}>
    <RegisterFormAdminComponent
      labelColor={theme.colors.white}
      buttonBcgColor={theme.colors.abbvie}
      buttonTextColor={theme.colors.white}/>
  </AuthLayout>
}

export default RegisterAdminUser