import { Grid, styled } from "@mui/material";
import { ReactComponent as Search } from "../../../../assets/icon/search.svg";
import theme from '../../../../theme/theme';

export const TopActionsContainer = styled(Grid)`
  margin-top: 22px;
  position: relative;
  display: flex;
  align-content: end;
  height: 60px;

  & input {
    width: 341px;
    display: block;
    margin-left: auto;
    margin-right: 84px;
    background: rgba(7, 28, 71, 0.24);
    border-color: rgba(7, 28, 71, 0.24);
    color: ${theme.colors.abbvie};
    margin-bottom: 0
  }

  & svg {
    top: 18px;
    vertical-align: middle;

    path {
      fill: ${theme.colors.abbvie}
    }
  }
`

export const SearchCustom = styled(Search)`
  position: absolute;
  right: 95px;
  top: 8px;
`