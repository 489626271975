import { MouseEvent  } from "react";
import { FormikValues } from "formik";

export const useRegisterForm = (formik: FormikValues) => {

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }


  return {confirmForm}
}
  
  

