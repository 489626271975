import { makeAutoObservable } from "mobx";
import { RootStore } from "./rootStore";

export enum EnAreaNames {
  virusology = "virusology",
  rheumatology = "rheumatology",
}

export enum EnAreaTitles {
  virusology = "Wirusologia",
  rheumatology = "Reumatologia",
}

export enum EnFormNames {
  hcv = "hcv",
  nps = "nps",
  lzss = "lzss",
  lzsr = "lzsr",
  rzs = "rzs",
  zzsk = "zzsk",
}

export enum EnFormTitles {
  hcv = "HCV",
  nps = "Nieradiograficzna postać spondyloartropatii",
  lzss = "Łuszczycowe zapalenie stawów - Skyrizi",
  lzsr = "Łuszczycowe zapalenie stawów - Rinvoq",
  rzs = "Reumatoidalne zapalenie stawów",
  zzsk = "Zesztywniejące zapalenie stawów kręgosłupa",
}

export type TForm = {
  id: number;
  name: EnFormNames;
  title: EnFormTitles;
  stages: number;
};

export type TArea = {
  id: number;
  name: EnAreaNames;
  title: EnAreaTitles;
  forms: TForm[];
};

export class CaseSettingsStore {
  userPrescribedAreas: TArea[] | null;
  currentArea: EnAreaNames | null;
  currentForm: EnFormNames | null;
  stageCount: number | null;
  currentStage: number | null;
  lastModifiedStage: number | null;
  isNextButtonClicked: boolean;
  isPrevButtonClicked: boolean;
  isDraftButtonClicked: boolean;

  constructor(private rootStore: RootStore) {
    this.userPrescribedAreas = null;
    this.currentArea = null;
    this.currentForm = null;
    this.currentStage = null;
    this.stageCount = null;
    this.lastModifiedStage = null;
    this.isNextButtonClicked = false;
    this.isPrevButtonClicked = false;
    this.isDraftButtonClicked = false;
    makeAutoObservable(this);
  }

  setCurrentArea(area: EnAreaNames) {
    this.currentArea = area;
  }

  setCurrentForm(form: EnFormNames) {
    this.currentForm = form;
  }

  setCurrentStage(stage: number) {
    this.currentStage = stage;
  }

  setStageCount(area: EnAreaNames, form: EnFormNames) {
    const areaObj = this.userPrescribedAreas?.find(
      (areaObj) => areaObj.name === area
    );
    const formObj = areaObj?.forms.find((formObj) => formObj.name === form);
    this.stageCount = formObj?.stages || null;
  }

  setUserPrescribedAreas(areas: TArea[]) {
    this.userPrescribedAreas = [...areas];
  }

  clickNextButton() {
    this.isNextButtonClicked = true;
  }

  unCLickNextButton() {
    this.isNextButtonClicked = false;
  }

  clickPrevButton() {
    this.isPrevButtonClicked = true;
  }

  unClickPrevButton() {
    this.isPrevButtonClicked = false;
  }

  clickDraftButton() {
    this.isDraftButtonClicked = true;
  }

  unClickDraftButton() {
    this.isDraftButtonClicked = false;
  }

  increaseCurrentStage() {
    if (
      this.currentStage &&
      this.stageCount &&
      this.currentStage <= this.stageCount
    ) {
      this.currentStage = this.currentStage + 1;
    }
    this.unCLickNextButton();
  }

  decreaseCurrentStage() {
    if (this.currentStage && this.stageCount && this.currentStage > 1) {
      this.currentStage = this.currentStage - 1;
    }
    this.unClickPrevButton();
  }

  reset() {
    this.currentArea = null;
    this.currentForm = null;
    this.currentStage = null;
    this.stageCount = null;
    this.lastModifiedStage = null;
    this.rootStore.hcvStore.resetCase();
    this.rootStore.rzsStore.resetCase();
    this.rootStore.lzsrStore.resetCase();
    this.rootStore.lzssStore.resetCase();
    this.rootStore.zzskStore.resetCase();
    this.rootStore.npsStore.resetCase();
  }
}
