import { FC } from 'react';
import { IAction } from '../../../../interfaces/common/table';
import { ActionButtonContainer } from "./ActionButton.styled";

interface IProps extends IAction {
  id: number | string;
}

export const ActionButton: FC<IProps> = ({
                                           tooltip,
                                           background,
                                           color,
                                           border,
                                           id,
                                           actionHandler,
                                           actionElement,
                                           width,
                                           isDisabled,
                                           isNotVisible,
                                           userContract,
                                           customId
                                         }) => {
  return <ActionButtonContainer title={tooltip} color={color} border={border} isNotVisible={isNotVisible}
                                $isDisabled={typeof isDisabled === 'function' ? isDisabled(Number(id)) : isDisabled}
                                onClick={() => actionHandler && actionHandler(id, userContract, customId)} width={width}
                                $background={typeof background === 'function' ? background(Number(id)) : background}>{typeof actionElement === 'function' ? actionElement(+id, customId) : actionElement}</ActionButtonContainer>
}
