import React from 'react';
import { createPortal } from 'react-dom';
import { CloseIcon } from "./modalIcons/CloseIcon";
import { ModalContainer, ModalContent, ModalContentInside } from "./Modal.styled";

interface IModal {
  children: React.ReactNode;
  clickHandler: () => void;
  lightbox?: boolean;
  position?: 'relative' | 'static'
}

export const Modal: React.FC<IModal> = ({children, clickHandler, lightbox, position = 'relative'}) => {

  return createPortal(
    <ModalContainer>
      <ModalContent $lightbox={lightbox}>
        <CloseIcon clickHandler={clickHandler}/>
        <ModalContentInside $lightbox={lightbox} $position={position}>{children}</ModalContentInside>
      </ModalContent>
    </ModalContainer>,
    document.getElementById('modal-root') as HTMLElement
  );
};
