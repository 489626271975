import { RouteObject } from 'react-router-dom';

import AddUser from '../../../../pages/admin/addUser/AddUser';
import { AdminLink } from '../../../../../utils';
import { generalLinks } from './RoutesGuest';
import { PublishingUsers } from '../../../../pages/admin/publishingUsers/PublishingUsers';
import { ViewingUsers } from '../../../../pages/admin/viewingUsers/ViewingUsers';
import { Others } from '../../../../pages/admin/others/Others';
import { OthersAdmins } from '../../../../pages/admin/othersAdmins/OthersAdmins';
import { Redirector } from '../../../../pages/admin/redirector/Redirector';
import { Areas } from '../../../../pages/admin/areas/Areas';
import { Forms } from '../../../../pages/admin/forms/Forms';
import AddArea from '../../../../pages/admin/addEditArea/AddArea';
import AddForm from "../../../../pages/admin/addEditForm/AddForm";

export const adminLinks: RouteObject[] = [
  {
    path: AdminLink.REDIRECTOR,
    element: <Redirector/>
  },
  {
    path: AdminLink.ADD_USER,
    element: <AddUser/>
  },
  {
    path: AdminLink.PUBLISHING_USERS,
    element: <PublishingUsers/>
  },
  {
    path: AdminLink.VIEWING_USERS,
    element: <ViewingUsers/>
  },
  {
    path: AdminLink.OTHERS_ADMINISTRATORS,
    element: <OthersAdmins/>
  },
  {
    path: AdminLink.FORMS,
    element: <Forms/>
  },
  {
    path: AdminLink.AREAS,
    element: <Areas/>
  },
  {
    path: AdminLink.ADD_AREA,
    element: <AddArea />
  },
  {
    path: AdminLink.EDIT_AREA,
    element: <AddArea isEdit={true}/>
  },
  {
    path: AdminLink.ADD_FORM,
    element: <AddForm/>
  },
  {
    path: AdminLink.EDIT_FORM,
    element: <AddForm isEdit={true}/>
  },

  {
    path: AdminLink.OTHER,
    element: <Others/>
  }
]

export const adminLinksExtends = adminLinks.concat(generalLinks);