import theme from "../../../../theme/theme";

import { formData } from "../../../pages/admin/addUser/addUserData";

import React, { FC } from "react";
import { IUserForm } from "../../../../interfaces/admin/user/userForm";
import { useUserForm } from "./useUserForm";
import { observer } from "mobx-react-lite";
import { Container, Label } from "./UserForm.styled";
import { Input } from '../../ui/input/Input';
import { ErrorMessage } from '../../ui/errorMessage/ErrorMesage';
import { FormButtons } from '../../ui/formButton/FormButtons';
import { Select } from '../../ui/select/Select';
import { Grid } from "@mui/material";
import { MultipleSelect } from "../../ui/multipleSelect/MultipleSelect";

interface IProps {
  isEdit?: boolean;
  link: string;
  userForm?: IUserForm | null;
  actionHandler: (userForm: IUserForm) => Promise<string | null>;
  handleNo?: () => void;
}

export const UserForm: FC<IProps> = observer(({isEdit, link, userForm, actionHandler, handleNo}) => {
  const {
    formik,
    selectOptions,
    isButtonActive,
    confirmForm,
    message,
    selectOption,
    selectValue,
    selectAreasOptions,
    handleChange,
    selectedAreas
  } = useUserForm(actionHandler, userForm)

  return (
    <Container>
      <Grid mb={2}>
        <Select color={theme.colors.grey1}
                colorLabel={theme.colors.grey1}
                borderColor={theme.colors.grey5}
                background={'white'}
                placeholderColor={theme.colors.grey4}
                defaultValue={'Wybierz rolę'}
                disabled={isEdit}
                label={'rola'}
                isRequired={true}
                selectValue={selectValue}
                elementName={'role'}
                setValue={selectOption}
                optionsArray={selectOptions} isError={formik.submitCount > 0 && selectValue <= 0}/>
      </Grid>
      <Grid>
        <Label>
          Obszar terapeutyczny*
        </Label>
        <MultipleSelect
          isError={formik.submitCount > 0 && selectedAreas.length === 0}
          selectedValues={selectedAreas}
          elementName={'areaSelect'}
          options={selectAreasOptions}
          handleChange={handleChange}
          subtitleArea={'Obszar'}
        />
      </Grid>
      {formData.map(el =>
        <Input color={theme.colors.grey1}
               colorLabel={theme.colors.grey1}
               borderColor={theme.colors.grey5}
               placeholderColor={theme.colors.grey4}
               background={'white'}
               isRequired={el.isRequired}
               key={el.name}
               label={el.label}
               name={el.name}
               isUpperCase={true}
               type={el.type}
               value={formik.values[el.name]}
               onChange={formik.handleChange}
               placeholder={el.placeholder}
               isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
        />
      )}
      {formik.submitCount > 0 &&
        <ErrorMessage errors={formik.errors} errorServer={false} message={message}/>
      }
      <FormButtons isButtonActive={isButtonActive} confirmForm={confirmForm}
                   link={link} onClickCancel={handleNo}
                   buttonText={isEdit ? 'zapisz' : 'zapisz i dodaj +'}/>
    </Container>
  )
});
