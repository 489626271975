import { IHeadCell, ITableData } from '../../../../interfaces/common/table';

export const createTableRows = (data: object[], headCells: IHeadCell[], isCheckBox: boolean): ITableData[] => {
  const tableRows: ITableData[] = []
  data && data.length > 0 && data.forEach(row => {
    const tableRow: ITableData = {}

    headCells.forEach(({id}) => {
      const key = id as keyof typeof row
      if (key === 'actions') return;


      if (row[key] === null || row[key] === "") {
        tableRow[key] = "-"
      } else {
        tableRow[key] = row[key];
      }
    })

    if (row.hasOwnProperty('uId')) {
      tableRow['id'] = row['uId' as keyof typeof row]
    } else {
      tableRow['id'] = row['id' as keyof typeof row]
    }

    tableRows.push(tableRow)
  })

  return tableRows;
}