import React from 'react';

import {SearchCustom, TopActionsContainer} from "./TopActions.styled";
import {FilterBar} from "../../admin/filter/FilterBar";
import {Input} from "../../ui/input/Input";

type SearchEvent = React.ChangeEvent<HTMLInputElement>;

interface IProps {
  isSearchBar?: boolean;
  filterValue: string;
  filterChangeHandler: (event: SearchEvent) => void;
  searchBarAddedContent?: JSX.Element;
  selectedYear?: number;
  isYearSelect?: boolean;
  switchFilters?: (year: number) => void,
}

export const TopActions: React.FC<IProps> = ({
                                               selectedYear,
                                               isYearSelect,
                                               switchFilters,
                                               isSearchBar,
                                               filterValue,
                                               filterChangeHandler,
                                               searchBarAddedContent
                                             }) => {

  return (
    <TopActionsContainer
      className={'search-bar-container'}
      container
      justifyContent='space-between'
      alignItems='center'
      flexWrap='nowrap'
    >
      {searchBarAddedContent}
      {isYearSelect && <FilterBar filter={selectedYear!} switchFilters={switchFilters!}/>}
      {isSearchBar && <><Input width={searchBarAddedContent ? 'auto' : '100%'} placeholder={'Wpisz...'}
                               type="text" name={'search'}
                               borderColor={'#E0E0E0 !important'}
                               background={'white !important'}
                               value={filterValue}
                               onChange={e => {
                                 const event = e as SearchEvent;
                                 filterChangeHandler(event)
                               }}/>
        <SearchCustom/></>}
    </TopActionsContainer>
  )
}
