import theme from "../../../../theme/theme";
import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Grid } from '@mui/material';

import { Input } from '../../ui/input/Input';
import { ErrorMessage } from '../../ui/errorMessage/ErrorMesage';
import { FormButtons } from '../../ui/formButton/FormButtons';
import { IAreaForm } from '../../../../interfaces/admin/areas/areaForm';
import { MultipleSelect } from '../../ui/multipleSelect/MultipleSelect';
import { useAreaForm } from './useAreaForm';
import { Container, Label } from "./AreaForm.styled";

interface IProps {
  isEdit?: boolean;
  link: string;
  areaForm?: IAreaForm | null;
  actionHandler: (areaForm: IAreaForm) => Promise<string | null>;
  handleNo?: () => void;
}

export const AreaForm: FC<IProps> = observer(({isEdit, link, areaForm, actionHandler, handleNo}) => {
  const {
    formik,
    isButtonActive,
    confirmForm,
    message,
    handleChange,
    selectFormsOptions,
    selectedForms,
  } = useAreaForm(actionHandler, areaForm)

  return (
    <Container>
      <Input color={theme.colors.grey1}
             colorLabel={theme.colors.grey1}
             borderColor={theme.colors.grey5}
             placeholderColor={theme.colors.grey4}
             background={'white'}
             isRequired={true}
             label={'Nazwa obszaru terapeutycznego'}
             name={'title'}
             isUpperCase={true}
             type={'text'}
             value={formik.values.title}
             onChange={formik.handleChange}
             placeholder={'Wpisz'}
             isError={formik.touched.title && Boolean(formik.errors.title)}/>
      <Input color={theme.colors.grey1}
             colorLabel={theme.colors.grey1}
             borderColor={theme.colors.grey5}
             placeholderColor={theme.colors.grey4}
             background={'white'}
             isRequired={true}
             label={'Nazwa skrócona'}
             name={'name'}
             isUpperCase={true}
             type={'text'}
             value={formik.values.name}
             onChange={formik.handleChange}
             placeholder={'Wpisz'}
             isError={formik.touched.name && Boolean(formik.errors.name)}/>
      <Grid>
        <Label>
          Formularze<span>*</span>
        </Label>
        <MultipleSelect
          isError={formik.submitCount > 0 && selectedForms.length === 0}
          selectedValues={selectedForms}
          elementName={'formTypes'}
          options={selectFormsOptions}
          handleChange={handleChange}
          subtitleArea={'Wybierz'}
        />
      </Grid>
      {formik.submitCount > 0 &&
        <ErrorMessage errors={formik.errors} errorServer={false} message={message}/>
      }
      <FormButtons isButtonActive={isButtonActive} confirmForm={confirmForm}
                   link={link} onClickCancel={handleNo}
                   buttonText={isEdit ? 'zapisz' : 'zapisz i dodaj +'}/>
    </Container>
  )
});
