import { observer } from "mobx-react";
import { FormikErrors } from "formik";

// hooks
import { useStage04ViewModel } from "./Stage04.vm";

// components
import { Divider, Grid, Typography } from "@mui/material";
import { Label } from "../../../../../../../../shared/ui/label/Label";
import { Input } from "../../../../../../../../shared/ui/input/Input";
import { SingleSelect } from "../../../../../../../../shared/ui/singleSelect/SingleSelect";
import { RadioBox } from "../../../../../../../../shared/ui/radioBox/RadioBox";
import { DateInput } from "../../../../../../../../shared/ui/date/Date";

// lib
import theme from "../../../../../../../../../theme/theme";

//types, helpers, styles
import { IObservations } from "./type";
import React from "react";
import {
  likertOptions,
  scaleOptions,
} from "../../../lzs-rinvoq/stages/stage02/Stage02.helper";
import { RadioForm } from "../../../styles";

export const Stage04 = observer(() => {
  const {formik, setOption, modifyObservations, handleYes, handleNo} =
    useStage04ViewModel();

  return (
    <Grid
      container
      maxWidth={"714px"}
      display={"flex"}
      margin={"0 auto"}
      my={4}
      component="form"
    >
      <Grid item mb={5} display={"flex"} flexDirection={"column"}>
        <Label
          size={"small"}
          isUpperCase={true}
          color={theme.colors.grey1}
          label={"Czy lek Rinvoq był używany w pierwszej linii leczenia?"}
          isRequired={true}
        />
        <RadioForm>
          <RadioBox
            isError={formik.touched.wasUsed && Boolean(formik.errors.wasUsed)}
            id={"wasUsed"}
            name={"wasUsed"}
            value={"tak"}
            checked={formik.values.wasUsed === "tak"}
            onChange={(e) => {
              handleYes();
              formik.handleChange(e);
            }}
          />
          <RadioBox
            isError={formik.touched.wasUsed && Boolean(formik.errors.wasUsed)}
            id={"wasUsed2"}
            name={"wasUsed"}
            value={"nie"}
            checked={formik.values.wasUsed === "nie"}
            onChange={(e) => {
              handleNo();
              formik.handleChange(e);
            }}
          />
        </RadioForm>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Wyniki badań przed włączeniem leku Rinvoq
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DateInput
          disableFuture={true}
          openTo={"year"}
          inputFormat={"yyyy/MM/dd"}
          views={["year", "month", "day"]}
          isRequired={true}
          maxWidth={"254px"}
          label={<>Data włączenia leku Rinvoq</>}
          isUpperCase={true}
          name={"drugInitiationDate"}
          size={"small"}
          color={theme.colors.grey1}
          value={formik.values.drugInitiationDate}
          onClose={() => {
            formik.setFieldTouched("drugInitiationDate", true);
            formik.validateField("drugInitiationDate");
          }}
          onChange={(e, context) => {
            formik.setFieldValue("drugInitiationDate", e);
          }}
          onBlur={() => {
            formik.setFieldTouched("drugInitiationDate", true);
            setTimeout(() => {
              formik.validateField("drugInitiationDate");
            }, 0);
          }}
          isError={
            formik.touched.drugInitiationDate &&
            Boolean(formik.errors.drugInitiationDate)
          }
        />
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mt={2}>
        <Grid item xs={5}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.likertPatient4 &&
              Boolean(formik.errors.likertPatient4)
            }
            label={"Wynik w skali Likerta pacjent"}
            handleChange={(value) => setOption(value, "likertPatient4")}
            selectedValue={formik.values.likertPatient4 ?? ""}
            elementName={"likertPatient4"}
            options={likertOptions}
          />
        </Grid>
        <Grid>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.likertDoctor4 &&
              Boolean(formik.errors.likertDoctor4)
            }
            label={"Wynik w skali Likerta lekarz"}
            handleChange={(value) => setOption(value, "likertDoctor4")}
            selectedValue={formik.values.likertDoctor4 ?? ""}
            elementName={"likertDoctor4"}
            options={likertOptions}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={5}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.assessment4 && Boolean(formik.errors.assessment4)
            }
            label={"OGÓLNA OCENA STANU CHOROBY"}
            handleChange={(value) => setOption(value, "assessment4")}
            selectedValue={formik.values.assessment4 ?? ""}
            elementName={"assessment4"}
            options={scaleOptions}
          />
        </Grid>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid>
          <Input
            label={"Liczba bolesnych stawów"}
            isRequired={true}
            isUpperCase={true}
            name={"tenderJointsCount4"}
            type={"number"}
            maxWidth={"163px"}
            onChangeCustom={() =>
              formik.setFieldValue("tenderJointsCount4", null)
            }
            colorLabel={theme.colors.grey1}
            value={formik.values.tenderJointsCount4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.tenderJointsCount4 &&
              Boolean(formik.errors.tenderJointsCount4)
            }
          />
        </Grid>
        <Grid>
          <Input
            label={"Liczba obrzękniętych stawów"}
            isUpperCase={true}
            isRequired={true}
            name={"swollenJointsCount4"}
            type={"number"}
            maxWidth={"163px"}
            onChangeCustom={() =>
              formik.setFieldValue("swollenJointsCount4", null)
            }
            colorLabel={theme.colors.grey1}
            value={formik.values.swollenJointsCount4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={
              formik.touched.swollenJointsCount4 &&
              Boolean(formik.errors.swollenJointsCount4)
            }
          />
        </Grid>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Podaj wynik w skali"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.resultScaleOption4 &&
                Boolean(formik.errors.resultScaleOption4)
              }
              id={"resultScaleOption4-1"}
              name={"resultScaleOption4"}
              value={"BASDAI"}
              checked={formik.values.resultScaleOption4 === "BASDAI"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={
                formik.touched.resultScaleOption4 &&
                Boolean(formik.errors.resultScaleOption4)
              }
              id={"resultScaleOption4-2"}
              name={"resultScaleOption4"}
              value={"ASDAS"}
              checked={formik.values.resultScaleOption4 === "ASDAS"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        <Grid>
          <Input
            isRequired={true}
            label={"Wynik"}
            isUpperCase={true}
            name={"result4"}
            type={"number"}
            maxWidth={"163px"}
            onChangeCustom={() => formik.setFieldValue("result4", null)}
            colorLabel={theme.colors.grey1}
            value={formik.values.result4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz"}
            isError={formik.touched.result4 && Boolean(formik.errors.result4)}
          />
        </Grid>
      </Grid>
      <Grid container display={"flex"} gap={"20px"}>
        <Grid item xs={5}>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.degreeOfBack4 &&
              Boolean(formik.errors.degreeOfBack4)
            }
            label={"Stopień bólu kręgosłupa"}
            handleChange={(value) => setOption(value, "degreeOfBack4")}
            selectedValue={formik.values.degreeOfBack4 ?? ""}
            elementName={"degreeOfBack4"}
            options={scaleOptions}
          />
        </Grid>
        <Grid>
          <SingleSelect
            isRequired={true}
            defaultValue={"Wybierz"}
            isError={
              formik.touched.generalAssessment24 &&
              Boolean(formik.errors.generalAssessment24)
            }
            label={"OGÓLNA OCENA STANU CHOROBY"}
            handleChange={(value) => setOption(value, "generalAssessment24")}
            selectedValue={formik.values.generalAssessment24 ?? ""}
            elementName={"generalAssessment24"}
            options={scaleOptions}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mb={2} mt={3}>
        <Typography
          variant={"h6"}
          color={theme.colors.grey1}
          textTransform={"uppercase"}
          fontWeight={700}
        >
          Obserwacja na leku Rinvoq
        </Typography>
      </Grid>
      <Grid container display={"flex"} gap={"20px"} mb={2}>
        <Grid item pb={2} display={"flex"} flexDirection={"column"}>
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Interwały obserwacji"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals"}
              name={"observationIntervals"}
              value={"co 1 msc."}
              checked={formik.values.observationIntervals === "co 1 msc."}
              onChange={(e) => {
                formik.handleChange(e);
                modifyObservations(e.target.value);
              }}
            />
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals2"}
              name={"observationIntervals"}
              value={"co 3 msc."}
              checked={formik.values.observationIntervals === "co 3 msc."}
              onChange={(e) => {
                formik.handleChange(e);
                modifyObservations(e.target.value);
              }}
            />
            <RadioBox
              isError={
                formik.touched.observationIntervals &&
                Boolean(formik.errors.observationIntervals)
              }
              id={"observationIntervals3"}
              name={"observationIntervals"}
              value={"co 6 msc."}
              checked={formik.values.observationIntervals === "co 6 msc."}
              onChange={(e) => {
                formik.handleChange(e);
                modifyObservations(e.target.value);
              }}
            />
          </RadioForm>
        </Grid>
      </Grid>
      {formik.values.observations.length > 0 && (
        <>
          <Divider
            style={{width: "100%"}}
            variant={"fullWidth"}
            sx={{height: "2px", margin: "0 0 24px 0"}}
          />
          <Grid
            item
            xs={12}
            mb={2}
            mt={2}
            display={"flex"}
            justifyContent={"center"}
          >
            <Typography
              variant={"h6"}
              color={theme.colors.grey1}
              textTransform={"uppercase"}
              fontWeight={700}
            >
              Obserwacje
            </Typography>
          </Grid>
        </>
      )}
      <Grid container>
        {formik.values.observations?.map((line, index) => (
          <Grid
            key={index}
            item
            xs={12}
            mb={4}
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "0 16px",
              backgroundColor: "rgba(255, 255, 255, 0.70)",
            }}
          >
            <Grid
              item
              xs={12}
              mt={2}
              mb={4}
              justifyContent={"space-between"}
              display={"flex"}
            >
              <Typography
                variant={"h6"}
                color={theme.colors.grey1}
                textTransform={"uppercase"}
                fontWeight={700}
              >
                Obserwacja {index + 1}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent={"space-between"}
              pb={2}
              display={"flex"}
              flexDirection={"column"}
            >
              <Grid container display={"flex"} gap={"20px"} mt={2}>
                <Grid item xs={5}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.observations) &&
                      formik.touched.observations[index]
                        ?.likertPatient4followup &&
                      Array.isArray(formik.errors.observations) &&
                      Boolean(
                        (
                          formik.errors.observations[
                            index
                            ] as FormikErrors<IObservations>
                        )?.likertPatient4followup
                      )
                    }
                    label={"Wynik w skali Likerta pacjent"}
                    handleChange={(value) =>
                      setOption(value, "likertPatient4followup", index)
                    }
                    selectedValue={
                      formik.values.observations[index]
                        ?.likertPatient4followup || ""
                    }
                    elementName={`treatmentLines[${index}].likertPatient4followup`}
                    options={likertOptions}
                  />
                </Grid>
                <Grid>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.observations) &&
                      formik.touched.observations[index]
                        ?.likertDoctor4followup &&
                      Array.isArray(formik.errors.observations) &&
                      Boolean(
                        (
                          formik.errors.observations[
                            index
                            ] as FormikErrors<IObservations>
                        )?.likertDoctor4followup
                      )
                    }
                    label={"Wynik w skali Likerta lekarz"}
                    handleChange={(value) =>
                      setOption(value, "likertDoctor4followup", index)
                    }
                    selectedValue={
                      formik.values.observations[index]
                        ?.likertDoctor4followup || ""
                    }
                    elementName={`observations[${index}].likertDoctor4followup`}
                    options={likertOptions}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={5}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.observations) &&
                      formik.touched.observations[index]?.assessment4followup &&
                      Array.isArray(formik.errors.observations) &&
                      Boolean(
                        (
                          formik.errors.observations[
                            index
                            ] as FormikErrors<IObservations>
                        )?.assessment4followup
                      )
                    }
                    label={"OGÓLNA OCENA STANU CHOROBY"}
                    handleChange={(value) =>
                      setOption(value, "assessment4followup", index)
                    }
                    selectedValue={
                      formik.values.observations[index]?.assessment4followup ||
                      ""
                    }
                    elementName={`observations[${index}].assessment4followup`}
                    options={scaleOptions}
                  />
                </Grid>
              </Grid>
              <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
                <Grid>
                  <Input
                    label={"Liczba bolesnych stawów"}
                    isRequired={true}
                    isUpperCase={true}
                    name={`observations[${index}].tenderJointsCount4followup`}
                    type={"number"}
                    maxWidth={"163px"}
                    onChangeCustom={() =>
                      formik.setFieldValue(
                        `observations[${index}].tenderJointsCount4followup`,
                        null
                      )
                    }
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.observations?.[index]
                        ?.tenderJointsCount4followup
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]
                        ?.tenderJointsCount4followup &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                            ] as FormikErrors<IObservations>
                        )?.tenderJointsCount4followup
                      )
                    }
                  />
                </Grid>
                <Grid>
                  <Input
                    label={"Liczba obrzękniętych stawów"}
                    isRequired={true}
                    isUpperCase={true}
                    name={`observations[${index}].swollenJointsCount4followup`}
                    type={"number"}
                    maxWidth={"163px"}
                    onChangeCustom={() =>
                      formik.setFieldValue(
                        `observations[${index}].swollenJointsCount4followup`,
                        null
                      )
                    }
                    colorLabel={theme.colors.grey1}
                    value={
                      formik.values.observations?.[index]
                        ?.swollenJointsCount4followup
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]
                        ?.swollenJointsCount4followup &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                            ] as FormikErrors<IObservations>
                        )?.swollenJointsCount4followup
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid container display={"flex"} gap={"20px"} mb={2} mt={2}>
                <Grid item pb={2} display={"flex"} flexDirection={"column"}>
                  <Label
                    size={"small"}
                    isUpperCase={true}
                    color={theme.colors.grey1}
                    label={"Podaj wynik w skali"}
                    isRequired={true}
                  />
                  <RadioForm>
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]
                          ?.resultScaleOption4followup &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                              ] as FormikErrors<IObservations>
                          )?.resultScaleOption4followup
                        )
                      }
                      id={`resultScaleOption4followup-${index}`}
                      name={`observations[${index}].resultScaleOption4followup`}
                      value={"BASDAI"}
                      checked={
                        formik.values.observations[index]
                          .resultScaleOption4followup === "BASDAI"
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `observations[${index}].resultScaleOption4followup`,
                          e.target.value
                        );
                      }}
                    />
                    <RadioBox
                      isError={
                        Array.isArray(formik.touched.observations) &&
                        formik.touched.observations[index]
                          ?.resultScaleOption4followup &&
                        Array.isArray(formik.errors.observations) &&
                        Boolean(
                          (
                            formik.errors.observations[
                              index
                              ] as FormikErrors<IObservations>
                          )?.resultScaleOption4followup
                        )
                      }
                      id={`resultScaleOption4followup2-${index}`}
                      name={`observations[${index}].resultScaleOption4followup`}
                      value={"ASDAS"}
                      checked={
                        formik.values.observations[index]
                          .resultScaleOption4followup === "ASDAS"
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `observations[${index}].resultScaleOption4followup`,
                          e.target.value
                        );
                      }}
                    />
                  </RadioForm>
                </Grid>
                <Grid>
                  <Input
                    isRequired={true}
                    label={"Wynik"}
                    isUpperCase={true}
                    name={`observations[${index}].result4followup`}
                    type={"number"}
                    maxWidth={"163px"}
                    onChangeCustom={() =>
                      formik.setFieldValue(
                        `observations[${index}].result4followup`,
                        null
                      )
                    }
                    colorLabel={theme.colors.grey1}
                    value={formik.values.observations?.[index]?.result4followup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]?.result4followup &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                            ] as FormikErrors<IObservations>
                        )?.result4followup
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid container display={"flex"} gap={"20px"}>
                <Grid item xs={4}>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.observations) &&
                      formik.touched.observations[index]
                        ?.degreeOfBack4followup &&
                      Array.isArray(formik.errors.observations) &&
                      Boolean(
                        (
                          formik.errors.observations[
                            index
                            ] as FormikErrors<IObservations>
                        )?.degreeOfBack4followup
                      )
                    }
                    label={"Stopień bólu kręgosłupa"}
                    handleChange={(value) =>
                      setOption(value, "degreeOfBack4followup", index)
                    }
                    selectedValue={
                      formik.values.observations[index]
                        ?.degreeOfBack4followup ?? ""
                    }
                    elementName={`observations[${index}].degreeOfBack4followup`}
                    options={scaleOptions}
                  />
                </Grid>
                <Grid>
                  <SingleSelect
                    isRequired={true}
                    defaultValue={"Wybierz"}
                    isError={
                      Array.isArray(formik.touched.observations) &&
                      formik.touched.observations[index]
                        ?.generalAssessment24followup &&
                      Array.isArray(formik.errors.observations) &&
                      Boolean(
                        (
                          formik.errors.observations[
                            index
                            ] as FormikErrors<IObservations>
                        )?.generalAssessment24followup
                      )
                    }
                    label={"OGÓLNA OCENA STANU CHOROBY"}
                    handleChange={(value) =>
                      setOption(value, "generalAssessment24followup", index)
                    }
                    selectedValue={
                      formik.values.observations[index]
                        ?.generalAssessment24followup ?? ""
                    }
                    elementName={`observations[${index}].generalAssessment24followup`}
                    options={scaleOptions}
                  />
                </Grid>
              </Grid>
              <Grid container gap={'20px'} mt={2}>
                <Grid item xs={3}>
                  <Input
                    label={"wynik w skali pasi"}
                    isUpperCase={true}
                    name={`observations[${index}].pasi4followup`}
                    type={"number"}
                    maxWidth={"163px"}
                    onChangeCustom={() =>
                      formik.setFieldValue(
                        `observations[${index}].pasi4followup`,
                        null
                      )
                    }
                    colorLabel={theme.colors.grey1}
                    value={formik.values.observations?.[index]?.pasi4followup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]?.pasi4followup &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                            ] as FormikErrors<IObservations>
                        )?.pasi4followup
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label={"wynik w skali DLQI"}
                    isUpperCase={true}
                    name={`observations[${index}].dlqi4followup`}
                    type={"number"}
                    maxWidth={"163px"}
                    onChangeCustom={() =>
                      formik.setFieldValue(
                        `observations[${index}].dlqi4followup`,
                        null
                      )
                    }
                    colorLabel={theme.colors.grey1}
                    value={formik.values.observations?.[index]?.dlqi4followup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]?.dlqi4followup &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                            ] as FormikErrors<IObservations>
                        )?.dlqi4followup
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    label={"wynik w skali BSA"}
                    isUpperCase={true}
                    name={`observations[${index}].bsa4followup`}
                    type={"number"}
                    maxWidth={"163px"}
                    onChangeCustom={() =>
                      formik.setFieldValue(
                        `observations[${index}].bsa4followup`,
                        null
                      )
                    }
                    colorLabel={theme.colors.grey1}
                    value={formik.values.observations?.[index]?.bsa4followup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    borderColor={theme.colors.grey5}
                    placeholderColor={theme.colors.grey4}
                    background={theme.colors.white}
                    placeholder={"Wpisz"}
                    isError={
                      formik.touched.observations?.[index]?.bsa4followup &&
                      Boolean(
                        (
                          formik.errors.observations?.[
                            index
                            ] as FormikErrors<IObservations>
                        )?.bsa4followup
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid
          item
          xs={12}
          pb={2}
          display={"flex"}
          flexDirection={"column"}
          mt={2}
        >
          <Label
            size={"small"}
            isUpperCase={true}
            color={theme.colors.grey1}
            label={"Glikokortykosteroidy (GKS)"}
            isRequired={true}
          />
          <RadioForm>
            <RadioBox
              isError={formik.touched.gks4 && Boolean(formik.errors.gks4)}
              id={"gks4"}
              name={"gks4"}
              value={"tak"}
              checked={formik.values.gks4 === "tak"}
              onChange={formik.handleChange}
            />
            <RadioBox
              isError={formik.touched.gks4 && Boolean(formik.errors.gks4)}
              id={"gks42"}
              name={"gks4"}
              value={"nie"}
              checked={formik.values.gks4 === "nie"}
              onChange={formik.handleChange}
            />
          </RadioForm>
        </Grid>
        {formik.values.gks4 === "tak" && (
          <Grid item xs={12} pb={2} display={"flex"} flexDirection={"column"}>
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Rodzaj GKS"}
              isRequired={true}
            />
            <RadioForm>
              <RadioBox
                isError={
                  formik.touched.gksType4 && Boolean(formik.errors.gksType4)
                }
                id={"gksType4"}
                name={"gksType4"}
                value={"tabletka"}
                checked={formik.values.gksType4 === "tabletka"}
                onChange={formik.handleChange}
              />
              <RadioBox
                isError={
                  formik.touched.gksType4 && Boolean(formik.errors.gksType4)
                }
                id={"gksType42"}
                name={"gksType4"}
                value={"iniekcja"}
                checked={formik.values.gksType4 === "iniekcja"}
                onChange={formik.handleChange}
              />
            </RadioForm>
          </Grid>
        )}
        {formik.values.gksType4 === "iniekcja" && (
          <Grid item xs={12} pb={2} display={"flex"} flexDirection={"column"}>
            <Label
              size={"small"}
              isUpperCase={true}
              color={theme.colors.grey1}
              label={"Jednokrotne miejscowe podania GKS"}
              isRequired={true}
            />
            <RadioForm>
              <RadioBox
                isError={
                  formik.touched.singleGks4 && Boolean(formik.errors.singleGks4)
                }
                id={"singleGks4"}
                name={"singleGks4"}
                value={"tak"}
                checked={formik.values.singleGks4 === "tak"}
                onChange={formik.handleChange}
              />
              <RadioBox
                isError={
                  formik.touched.singleGks4 && Boolean(formik.errors.singleGks4)
                }
                id={"singleGks42"}
                name={"singleGks4"}
                value={"nie"}
                checked={formik.values.singleGks4 === "nie"}
                onChange={formik.handleChange}
              />
            </RadioForm>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});
