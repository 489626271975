import { observer } from "mobx-react-lite";

// hooks
import { useNpsStore } from "../../../../../../../store/hooks";

// types
import { EnAreaNames, EnFormNames, } from "../../../../../../../store/caseSettingsStore";

// components
import { TopViewCase } from "../../topViewCase/TopViewCase";
import { UserLayout } from "../../../../../../shared/user/layout/UserLayout";
import { PatientSection } from "./patientSection/PatientSection";
import { CurrentTreatmentSection } from "./currentTreatmentSection/CurrentTreatmentSection";
import { TreatmentProgramSection } from "./treatmentProgramSection/TreatmentProgramSection";

// lib
import { useViewCase } from "../../../viewCaseCreator/useViewCase";
import { Observations } from "./obervations/Observations";

export const NpsViewCase = observer(() => {
  const npsStore = useNpsStore();
  const {isCase} = useViewCase(
    EnAreaNames.rheumatology,
    EnFormNames.nps,
    npsStore
  );

  return (
    <UserLayout>
      {isCase && (
        <>
          <TopViewCase caseStore={npsStore}/>
          <PatientSection/>
          <CurrentTreatmentSection/>
          <TreatmentProgramSection/>
          <Observations/>
        </>
      )}
    </UserLayout>
  );
});
