import { useCallback } from "react";
import { useApiCall } from "./useApiCall";
import { useFormsStore } from "../store/hooks";
import { IFormTypeForm } from '../interfaces/admin/form/formElementForm';

export const useFormsApi = () => {
  const {api} = useApiCall();
  const formsStore = useFormsStore()

  const getForms = useCallback(async (isLoading: boolean = false) => {
    const res = await api.apiInstance.get('/form-types', isLoading);
    if (res) {
      formsStore.setForms(res.items)
    }

  }, [api.apiInstance, formsStore])

  const addForm = useCallback(
    async (formType: IFormTypeForm) => {
      const res = await api.apiInstance.post('/form-type', formType, true);
      return !!res;
    },
    [api.apiInstance]
  );

  const deleteForm = useCallback(async (id: number) => {
    const res = await api.apiInstance.delete(`/form-type/${id}`);
    return !!res;
  }, [api.apiInstance])

  const editForm = useCallback(async (formType: IFormTypeForm) => {
    if (formType.id) {
      let res: IFormTypeForm= await api.apiInstance.put(`/form-type/${formType.id}`, formType);
      return !!res;
    }
    return false;

  }, [api.apiInstance])

  const getForm = useCallback(async (id: number) => {
    const res: IFormTypeForm = await api.apiInstance.get(`/form-type/${id}`);
    if (res) {
      formsStore.setFormEdit(res, id)
    }
    return !!res;
  }, [api.apiInstance, formsStore])


  return {
    getForms,
    addForm,
    editForm,
    deleteForm,
    getForm,
  }
}