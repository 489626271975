import { useCallback } from "react";
import { useApiCall } from "./useApiCall";
import { useAreasStore } from "../store/hooks";
import { IAreaForm } from '../interfaces/admin/areas/areaForm';

export const useAreasApi = () => {
  const {api} = useApiCall();
  const areasStore = useAreasStore()

  const getAreas = useCallback(async (isLoaded: boolean = false) => {
    const res = await api.apiInstance.get('/areas', isLoaded);
    if (res) {
      areasStore.setAreas(res.items)
    }

  }, [api.apiInstance, areasStore])

  const addArea = useCallback(
    async (areaForm: IAreaForm) => {
      const res = await api.apiInstance.post('area', areaForm, true);
      return !!res;
    },
    [api.apiInstance]
  );

  const deleteArea = useCallback(async (id: number) => {
    const res = await api.apiInstance.delete(`/area/${id}`);
    return !!res;
  }, [api.apiInstance])

  const editArea = useCallback(async (areaForm: IAreaForm) => {
    if (areaForm.id) {
      const res = await api.apiInstance.put(`/area/${areaForm.id}`, areaForm);
      return !!res;
    }

  }, [api.apiInstance])

  const getArea = useCallback(async (id: number) => {
    const res: IAreaForm = await api.apiInstance.get(`/area/${id}`);
    if (res) {
      areasStore.setAreaEdit(res, id)
    }
    return !!res;
  }, [api.apiInstance, areasStore])


  return {
    getAreas,
    addArea,
    editArea,
    deleteArea,
    getArea,
  }
}