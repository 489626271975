import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { adminLinksExtends } from "./routesByRole/RoutesAdmin";
import { guestLinksExtends } from "./routesByRole/RoutesGuest";
import { adminAcceptingScientificLinksExtends } from "./routesByRole/RoutesAdminAcceptingScientific";
import { publishingUserLinksExtends } from "./routesByRole/RoutesPublishingUser";
import { viewingUserLinksExtends } from "./routesByRole/RoutesViewingUser";
import { GlobalLoader } from "../globalLoader/GlobalLoader";
import { useAuthStore } from "../../../../store/hooks";
import { RoleFunction } from "../../../../constants/projectUserRoles";

export const AppRouting = observer(() => {
  const authStore = useAuthStore();

  const userLinks = () => {
    switch (authStore.roleFunction) {
      case RoleFunction.ROLE_ADMIN:
        return adminLinksExtends;
      case RoleFunction.ROLE_ACCEPTING:
        return adminAcceptingScientificLinksExtends;
      case RoleFunction.ROLE_SCIENTIFIC:
        return adminAcceptingScientificLinksExtends;
      case RoleFunction.ROLE_PUBLISHING:
        return publishingUserLinksExtends;
      case RoleFunction.ROLE_PARTICIPANT:
        return viewingUserLinksExtends;
      default:
        return guestLinksExtends;
    }
  };

  return (
    <RouterProvider
      router={createBrowserRouter(userLinks())}
      fallbackElement={<GlobalLoader />}
    />
  );
});
