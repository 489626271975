import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthStore } from '../../../../store/hooks';
import { RoleFunction } from '../../../../constants/projectUserRoles';
import { AdminLink } from '../../../../utils';
import { observer } from "mobx-react-lite";

export const Redirector = observer(() => {

  const {roleFunction} = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    switch (roleFunction) {
      case RoleFunction.ROLE_ADMIN:
        navigate(AdminLink.ADD_USER);
        break;
      case RoleFunction.ROLE_ACCEPTING:
        navigate(AdminLink.CASES);
        break;
      case RoleFunction.ROLE_SCIENTIFIC:
        navigate(AdminLink.CASES);
        break;
    }
  }, [navigate, roleFunction])

  return <></>
})