// enum
import { EnFormNames } from "../../../../../../store/caseSettingsStore";
// settings
import { rzsStages } from "../../forms/rheumatology/rzs/rzsStages";
import { hcvStages } from "../../forms/virusology/hcv/hcvStages";
import { lzsSkyriziStages } from "../../forms/rheumatology/lzs-skyrizi/lzsSkyriziStages";
import { lzsRinvoqStages } from "../../forms/rheumatology/lzs-rinvoq/lzsRinvoqStages";
import { zzskStages } from "../../forms/rheumatology/zzsk/zzskStages";
import { npsStages } from "../../forms/rheumatology/nps/npsStages";

export const stageSwitcher = (currentForm: EnFormNames) => {
  switch (currentForm) {
    case EnFormNames.hcv:
      return hcvStages;
    case EnFormNames.rzs:
      return rzsStages;
    case EnFormNames.lzss:
      return lzsSkyriziStages;
    case EnFormNames.lzsr:
      return lzsRinvoqStages;
    case EnFormNames.zzsk:
      return zzskStages;
    case EnFormNames.nps:
      return npsStages;
    default:
      return [];
  }
};
