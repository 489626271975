import {ContentModal} from "../../ContentModal";
import React, {FC} from "react";
import {SuccessIcon} from "../../modalIcons/SuccessIcon";
import {ConfirmationTextModal} from "./ConfirmationModal.styled";

interface IProps {
  text: JSX.Element;
}

export const ConfirmationModal: FC<IProps> = ({text}) => {
  return <ContentModal
    iconType={<SuccessIcon/>}
    text={<ConfirmationTextModal>{text}</ConfirmationTextModal>}
  />
}
