import { ButtonTransparent, DraftBtnContainer } from "./SaveDraftBtn.styled";
import { ReactComponent as Pen } from "../../../../../assets/icon/edit.svg";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useCaseSettingsStore } from "../../../../../store/hooks";

export const SaveDraftBtn = observer(() => {
  const { caseId } = useParams();

  const caseSettingsStore = useCaseSettingsStore();

  if (!caseId) {
    return null;
  }

  return (
    <DraftBtnContainer>
      <ButtonTransparent
        $disable={String(caseSettingsStore.isDraftButtonClicked)}
        onClick={() => caseSettingsStore.clickDraftButton()}
      >
        zapisz szkic
        <Pen />
      </ButtonTransparent>
    </DraftBtnContainer>
  );
});
