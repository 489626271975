import styled from "styled-components";
import theme from "../../../../theme/theme";

export const Header = styled.h1`
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  color: ${theme.colors.abbvie};

  span {
    font-weight: 800
  }
`

export const Subheader = styled.h3`
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  color: ${theme.colors.abbvie};
  margin-bottom: 20px;
`

export const Section = styled.div`
  display: flex;
  min-height: 180px;
  min-width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: left;
  padding-bottom: 80px;
`

export const NoCases = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin: auto;
`