import { observer } from "mobx-react-lite";
import {FC} from "react";

import { useAddForm } from './useAddForm';
import { AdminLayout } from '../../../shared/admin/layout/AdminLayout';
import { Modal } from '../../../shared/modal/Modal';
import { ConfirmationModal } from '../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal';
import { AdminLink } from '../../../../utils';
import {TextModal, Title} from './AddForm.styled';
import {FormTypeForm} from "../../../shared/admin/formTypeForm/FormTypeForm";

interface IAddFormProps {
  isEdit?: boolean
}
const AddForm : FC<IAddFormProps> = observer(({isEdit = false}) => {
  const {
    actionHandler,
    isModalOpened,
    closeModal,
    handleNo,
    formEdit
  } = useAddForm(isEdit)

  return <AdminLayout centralize={true}>
    <Title>{isEdit ? 'edytowanie' : 'dodawanie'} formularza</Title>
    {(!isEdit || (isEdit && formEdit)) && <FormTypeForm formTypeForm={formEdit} handleNo={handleNo} link={AdminLink.ADD_FORM} actionHandler={actionHandler}/>}
    {isModalOpened && <Modal clickHandler={() => closeModal()}>
      <ConfirmationModal
        text={
          <TextModal><p>Formularz został <b>{isEdit ? 'wyedytowany' : 'dodany'} poprawnie.</b></p>
          </TextModal>}
      /></Modal>}
  </AdminLayout>
})

export default AddForm;

