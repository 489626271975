import {Pagination} from '@mui/material';
import {styled} from '@mui/material/styles';
import theme from '../../../theme/theme';

export const MuiPagination = styled(Pagination)(() => ({
  '& .MuiButtonBase-root': {
    color: '#333333',
    marginBottom: 10,
    marginTop: 10
  },
  '& .MuiPaginationItem-ellipsis': {
    color: theme.colors.abbvie,
  },
  '& .Mui-selected': {
    backgroundColor: `${theme.colors.green} !important`,
    color: theme.colors.white,
    fontWeight: '400',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    lineHeight: '40px'
  },
  '& .MuiPaginationItem-icon': {
    color: '#333333',
  }
}));
