export interface LabelProps {
  $size?: "small" | "normal" | "large";
  $mb?: number;
  $isNoneCase?: boolean
}

export const checkValueIsEmpty = (value?: string | number | null) => {
  if (value === '') return '-'
  return value ?? "-";
};

export const selectOptions = [
  {
    id: 0,
    title: "Brak koinfekcji",
  },
  {
    id: 1,
    title: "HIV + HCV",
  },
  {
    id: 2,
    title: "HBV + HCV",
  },
  {
    id: 3,
    title: "HIV + HBV + HCV",
  },
];
