import { INavLink } from '../../../../interfaces/common/link';
import { AdminLink } from '../../../../utils';
import {RoleFunction} from '../../../../constants/projectUserRoles';

const allLinks = {
  addUser: {
    text: 'dodaj użytkownika',
    link: AdminLink.ADD_USER
  },
  publishingUsers: {
    text: 'użytkownicy publikujący',
    link: AdminLink.PUBLISHING_USERS
  },
  viewingUsers: {
    text: 'użytkownicy oglądający',
    link: AdminLink.VIEWING_USERS
  },
  othersAdministrators: {
    text: 'inni administratorzy',
    link: AdminLink.OTHERS_ADMINISTRATORS
  },
  cases: {
    text: 'opisy przypadków',
    link: AdminLink.CASES
  },
  forms: {
    text: 'formularze',
    link: AdminLink.FORMS
  },
  areas: {
    text: 'obszary terapeutyczne',
    link: AdminLink.AREAS
  },
  other: {
    text: 'inne',
    link: AdminLink.OTHER
  },
}
export const generateNavbar = (roleFunction: RoleFunction) => {

  let links: INavLink[] = [];

  switch (roleFunction) {
    case RoleFunction.ROLE_ADMIN:
      links = [
        allLinks.addUser, allLinks.publishingUsers, allLinks.viewingUsers, allLinks.othersAdministrators, allLinks.forms, allLinks.areas, allLinks.other
      ];
      break;
    case RoleFunction.ROLE_ACCEPTING:
      links = [
        allLinks.cases, allLinks.publishingUsers
      ];
      break
    case RoleFunction.ROLE_SCIENTIFIC:
      links = [
        allLinks.cases, allLinks.publishingUsers
      ];
      break
  }

  return links;
}