import styled from 'styled-components';
import theme from '../../../../theme/theme';

interface IIconContainer {
  $background?: string;
  $isCancel?: boolean;
}

export const IconContainer = styled.div<IIconContainer>`
  background-color: ${props => props.$background ? props.$background : theme.colors.abbvie};
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${props => props.$isCancel &&
          `position: absolute;
    cursor: pointer;
  right: -22px;
  top: -22px;
  z-index: 50;
  @media (max-width: ${theme.breakpoints.small}px) {
    right: -16px;
  }`}
`;

export const ModalIconStyled = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  right: -22px;
  top: -22px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BinIconContainer = styled(ModalIconStyled)`
  background-color: ${theme.colors.abbvie};
  margin-bottom: 15px;
`;
export const CancelIconContainer = styled(ModalIconStyled)`
  background-color: ${theme.colors.red};
  margin-bottom: 15px;
`;
export const SuccessIconContainer = styled(ModalIconStyled)`
  background: ${theme.colors.green}
`;
