
import {makeAutoObservable} from "mobx";
import {IRole} from '../interfaces/common/roles';
import {IRoleForm} from '../interfaces/admin/role/roleForm';

export class RolesStore {
  private _roles: IRole[] | null = null;
  private _isRolesLoaded: boolean = false;
  private _roleEdit: IRoleForm | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setRoles(roles: IRole[]) {
    this._roles = roles;
    this._isRolesLoaded = true;
  }

  setRoleEdit(roleEdit: IRoleForm) {
    this._roleEdit = roleEdit;
    this._isRolesLoaded = true;
  }


  get roles(): IRole[] | null {
    return this._roles;
  }

  set roles(value: IRole[] | null) {
    this._roles = value;
  }

  get isRolesLoaded(): boolean {
    return this._isRolesLoaded;
  }

  set isRolesLoaded(value: boolean) {
    this._isRolesLoaded = value;
  }

  get roleEdit(): IRoleForm | null {
    return this._roleEdit;
  }

  set roleEdit(value: IRoleForm | null) {
    this._roleEdit = value;
  }

  clearRoleEdit() {
    this._roleEdit = null;
  }

  clearRoles() {
    this._isRolesLoaded = false;
  }
}
