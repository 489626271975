import React from "react";
import { ButtonContainer, StyledLink } from "./Button.styled";

interface IProps {
  clickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: ButtonSize;
  color?: string;
  text: string;
  backgroundColor?: string;
  icon?: JSX.Element | string;
  startIcon?: JSX.Element | string;
  maxWidth?: string;
  width?: string;
  type?: ButtonType;
  border?: string;
  link?: string;
  fontSize?: string;
  fontColor?: string;
  margin?: string;
  disabled?: boolean;
  fontWeight?: ButtonFontWeight;
  hoverColor?: string;
}

export type ButtonSize = "tiny" | "small" | "big";
export type ButtonType = "button" | "submit" | "reset";
export type ButtonFontWeight = 300 | 400 | 500 | 600 | 700;

export const Button: React.FC<IProps> = ({
                                           clickHandler,
                                           size,
                                           border,
                                           color,
                                           text,
                                           type = "button",
                                           backgroundColor,
                                           icon,
                                           startIcon,
                                           maxWidth,
                                           width,
                                           link,
                                           fontSize,
                                           margin,
                                           disabled,
                                           fontWeight = 700,
                                           hoverColor,
                                         }) => {
  const ButtonLayer = (
    <ButtonContainer
      $isIcon={!!icon}
      $border={border}
      type={type}
      size={size}
      color={color}
      $width={width}
      onClick={clickHandler && clickHandler}
      $maxWidth={maxWidth}
      fontSize={fontSize}
      $margin={margin}
      disabled={disabled}
      fontWeight={fontWeight}
      $hoverColor={hoverColor}
      $backgroundColor={backgroundColor}
    >
      {startIcon &&
        (typeof startIcon === "string" ? <img src={startIcon} alt={text}/> : startIcon)}
      {text}
      {icon &&
        (typeof icon === "string" ? <img src={icon} alt={text}/> : icon)}
    </ButtonContainer>
  );

  return link ? (
    <StyledLink to={link}>{ButtonLayer}</StyledLink>
  ) : (
    <>{ButtonLayer}</>
  );
};
