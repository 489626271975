import styled, { css } from "styled-components";
import theme from "../../../../../../../theme/theme";

export const adminCommentCommon = css`
  padding: 24px 42px;
  width: 100%;
  height: calc(100% - 424px);
  overflow: visible;
  background: ${theme.colors.white};
`;

export const AdminCommentContainer = styled.div`
  ${adminCommentCommon};
`;

export const AdminCommentFirstContainer = styled.div`
  ${adminCommentCommon};
  mask-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255) 76px
  );
`;

export const AdminDate = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const AdminComment = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 1.6px;
  margin-top: 12px;
  color: ${theme.colors.abbvie};
  position: relative;
  padding-left: 16px;
`;

const commonExtendedButton = css`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: 2.4px;
  text-transform: uppercase;

  & svg {
    margin-left: 2px;
    width: 16px;
  }
  & path {
    fill: ${theme.colors.abbvie};
  }
`;

export const ExtendedButtonMore = styled.div`
  ${commonExtendedButton};
  position: absolute;
  width: calc(100% + 84px);
  left: -42px;
  bottom: 0;

  & svg {
    transform: rotate(90deg);
    margin-bottom: 2px;
  }
`;

export const ExtendedButtonLess = styled.div`
  ${commonExtendedButton};

  & svg {
    transform: rotate(-90deg);
    margin-bottom: 2px;
  }
`;
