import styled from 'styled-components';

export const HeaderStyled = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
  margin-bottom: 28px;

  nav {
    display: flex;
    gap: 10px;
  }
`