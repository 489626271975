export const switchGender = (value: string | null | number) => {
  switch (value) {
    case 'kobieta' :
      return 'W';
    case 'mężczyzna' :
      return 'M';
    case 'W' :
      return 'kobieta';
    case 'M' :
      return 'mężczyzna'
    case '' :
      return null
    case null :
      return ''
    default:
      return ''
  }
}