import { makeAutoObservable } from "mobx";

export class HomeStore {
  listOfLastCases: null | any[] = null
  isListOfLastCasesLoaded: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setListOfLastCases(listOfLastCases: any[]) {
    this.listOfLastCases = listOfLastCases;
    this.isListOfLastCasesLoaded = true;
  }

  resetListOfLastCases() {
    this.isListOfLastCasesLoaded = false;
  }

}
