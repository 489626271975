import styled from 'styled-components';
import theme from '../../../../../../theme/theme';

export const SmallParagraph = styled.span`
  font-size: 12px;
  line-height: 120%;
  margin: 8px 0px 12px;
  display: block;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-content: space-between;
  margin-top: 8px;
`;

export const AddedInfo = styled.span`
  font-size: 10px;
  line-height: 14px;
  color: ${theme.colors.red};
  align-self: flex-end;
  margin: 0 auto 0 0;
`;
