import React, { useState } from "react";
import openEye from "./../../../../assets/icon/openEye.svg";
import closedEye from "./../../../../assets/icon/closedEye.svg";
import { Label } from "../label/Label";
import { InputContainer, InputElement, PasswordIcon, TextareaElement, } from "./Input.styled";
import { Size, Type } from "../../../../interfaces/ui/uiInfo";

interface IProps {
  name: string;
  type?: Type;
  value?: string | number | null;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  label?: string | JSX.Element;
  isUpperCase?: boolean;
  color?: string;
  isRequired?: boolean;
  isError?: boolean;
  textError?: string | boolean;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  readonly?: boolean;
  component?: JSX.Element;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  size?: Size;
  maxWidth?: string;
  width?: string;
  height?: string;
  after?: JSX.Element;
  colorLabel?: string;
  borderColor?: string;
  background?: string;
  placeholderColor?: string;
  margin?: string;
  rows?: number;
  supLabel?: JSX.Element;
  before?: JSX.Element;
  onChangeCustom?: () => void
}

export const Input: React.FC<IProps> = ({
                                          rows,
                                          name,
                                          type = "text",
                                          value,
                                          onChange,
                                          label,
                                          isUpperCase = false,
                                          placeholder,
                                          textError,
                                          color = "#646464",
                                          isError = false,
                                          isRequired = false,
                                          readonly = false,
                                          maxLength,
                                          disabled = false,
                                          component,
                                          onBlur,
                                          size = "small",
                                          maxWidth,
                                          width,
                                          height,
                                          after,
                                          colorLabel,
                                          borderColor,
                                          background,
                                          placeholderColor = '#AFAFAF',
                                          margin,
                                          supLabel,
                                          before,
                                          onChangeCustom
                                        }) => {
  const [passwordIsVisible, setPasswordVisible] = useState(false);
  const textFieldType =
    type === "password" ? (passwordIsVisible ? "text" : "password") : type;

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Enter',
      'ArrowLeft',
      'ArrowRight'
    ];

    if (
      (e.ctrlKey || e.metaKey) &&
      (e.key === 'c' || e.key === 'v' || e.key === 'x')
    ) {

      return;
    }

    if (!(e.key >= '0' && e.key <= '9') && e.key !== '-' && e.key !== ',' && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  }

  const content = component ? (
    component
  ) : type === "textarea" ? (
    <TextareaElement
      rows={rows}
      $isError={isError}
      maxLength={maxLength}
      readOnly={readonly}
      disabled={disabled}
      id={name}
      name={name}
      placeholder={placeholder}
      value={value !== null ? value : ''}
      onChange={onChange}
      onBlur={onBlur}
      $maxWidth={maxWidth}
      height={height}
      $border={borderColor ?? color}
      color={color}
      $background={background}
      $placeholderColor={placeholderColor}
    />
  ) : (
    <>
      <InputElement
        $border={borderColor ?? color}
        $isError={isError}
        maxLength={maxLength}
        readOnly={readonly}
        disabled={disabled}
        type={textFieldType}
        id={name}
        $margin={margin}
        name={name}
        value={typeof value === 'number' ? value : value || ''}
        onKeyDown={(e) => {
          if (type === "number") {
            onKeyDown(e)
          }
        }}
        onChange={(e) => {
          onChange && onChange(e)
          if (type === 'number' && e.target.value === '') {
            onChangeCustom && onChangeCustom()
          }
        }}
        placeholder={placeholder}

        onBlur={onBlur}
        $maxWidth={maxWidth}
        height={height}
        color={color}
        $background={background}
        $placeholderColor={placeholderColor}
        autoComplete={type === "password" ? "current-password" : "off"}
      />
      {type === "password" && (
        <PasswordIcon
          src={!passwordIsVisible ? openEye : closedEye}
          onClick={() => setPasswordVisible(!passwordIsVisible)}
        />
      )}
    </>
  );

  return (
    <InputContainer
      $isRequired={isRequired}
      type={type ?? "text"}
      $width={width}
    >
      <>
        {label ? (
          <Label
            before={before}
            supLabel={supLabel}
            isUpperCase={isUpperCase}
            size={size}
            content={content}
            color={colorLabel ?? color}
            label={label}
            isRequired={isRequired}
          />
        ) : (
          <>{before}{content}</>
        )}
      </>
      {isError && textError && <p>{textError}</p>}
      {after}
    </InputContainer>
  );
};
