import React from 'react';
import { Container } from './OnlyDesktop.styled'
import { Grid } from "@mui/material";
import background from './../../../assets/img/background_purple.jpg'
import { ReactComponent as Monitor } from './../../../assets/icon/desktop.svg'

export const OnlyDesktop = () => {
  return (
    <Container $bcg={background}>
      <Grid item alignItems={'center'} justifyContent={'center'} display={'flex'} flexDirection={'column'}
            width={'420px'} height={'100vh'}
      >
        <Grid item margin={'auto'}>
          <Monitor/>
          <h1>Nie można wyświetlić treści</h1>
          <p>
            W celu skorzystania z serwisu prosimy o uruchomienie go na urządzeniu z większym ekranem np. komputerze
            stacjonarnym lub laptopie.
          </p>
        </Grid>
      </Grid>
    </Container>
  );
};
