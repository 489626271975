import React from 'react'
import { useRegisterAdminForm } from './useRegisterAdminForm.vm'
import { RegisterForm } from "../../form/RegisterForm";
import { formDataPublish } from "./registerFormData";
import { observer } from "mobx-react-lite";

interface IProps {
  labelColor: string
  buttonBcgColor: string;
  buttonTextColor: string
}

export const RegisterFormAdminComponent: React.FC<IProps> = observer(({
                                                                        labelColor,
                                                                        buttonBcgColor,
                                                                        buttonTextColor
                                                                      }) => {
  const {
    formik,
    confirmForm,
    emailInfo
  } = useRegisterAdminForm()

  return <RegisterForm formik={formik}
                       confirmForm={confirmForm}
                       labelColor={labelColor}
                       buttonTextColor={buttonTextColor}
                       buttonBcgColor={buttonBcgColor}
                       formData={formDataPublish}
                       emailInfo={emailInfo}
  />
})
