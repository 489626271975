import styled from "styled-components";
import theme from "../../../../../../theme/theme";

export const AdminTitle = styled.h1`
  font-size: 24px;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: 3.2px;
  color: ${theme.colors.abbvie};
`;
