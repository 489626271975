export const vasOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const variantOptions = ['Inhibitory TNF', 'Inhibitory IL-17', 'Inhibitory JAK', 'Inhibitory II-23']

export const tnfOptions = ['Certolizumab pegol', 'Adalimumab - Humira', 'Adalimumab inne', 'Etanercept', 'Golimumab', 'Infliksimab']

export const jakOptions = ['Upadacytynib (Rinvoq 15mg/dobę)', 'Tofacytynib']

export const il17Options = ['Iksekizumab', 'Sekukinumab', 'Bimekizumab']

export const ii23Options = ['Ryzankizumab (Skyrizi 150 mg/co 12 tyg.)', 'Guselkumab']

export const combinationsOptions = ['Metotreksat', 'Sulfasalazyna', 'Hydroxychlorochina', 'Leflunomid', 'Inne']