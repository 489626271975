import React, {FC} from "react";
import {CellTimeStyled} from "./CellTime.styled";
import {SplitData} from "../../../../../utils";

interface IProps {
  specialValue: SplitData;
}

export const CellTime: FC<IProps> = ({specialValue}) => {
  return (
    <>
      <CellTimeStyled>{specialValue[0]}</CellTimeStyled><span>{specialValue[1]}</span>
    </>
  )
}