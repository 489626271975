import React from 'react';
import {observer} from 'mobx-react-lite';

import {AppLayer} from './shared/appLayer/AppLayer';
import {useRootStore} from '../store/RootStateContext';

export const App = observer(() => {
  const rootStore = useRootStore();
  const isStoreReady = !!rootStore;

  return isStoreReady ? <AppLayer/> : null;
});


export default App;
